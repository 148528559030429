import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { Add, Flag } from "@mui/icons-material";
import AddIotDeviceDialog from "./AddIotDeviceDialog";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import { type } from "@testing-library/user-event/dist/type";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCreateIOTdevice,
  EditIOTdevice,
  GetIotDevicesList,
} from "../../Redux/Actions/adddCreateBatchAction";
import moment from "moment";

function IotDevicesMain() {
  const dispatch = useDispatch();
  const [openAddIotDialog, setOpenAddIotDialog] = React.useState(false);
  const [selectedIotDetails, setSelectedIotDetails] = React.useState({});
  const [filterLabel, setFilterLabel] = React.useState("device_id");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [editIotClick, setEditIotClick] = React.useState(false);
  const WfsData = useSelector((state) => state.batchData);
  console.log("batchData", WfsData);

  React.useEffect(() => {
    dispatch(
      GetIotDevicesList({
        page_number: pageNumber,
        page_size: pageSize,
        [filterLabel]: filterSearchText || undefined,
      })
    );
  }, [pageSize, pageNumber, filterSearchText]);

  const handleopenAddIotDialog = () => {
    setSelectedIotDetails({});
    setOpenAddIotDialog(true);
  };

  const handleAddIot = (data) => {
    console.log("datttaa", data);
    const reqObj = {
      mft_date: moment(data.mft_date).format(),
      sim_number: data.sim_number,
      line_id: data.line_id,
      device_id: data.device_id,
      batch_number: data.batch_number,
      is_active: data.is_active === "true" ? 1 : 0,
      // device_status: 100,
    };
    if (editIotClick === true) {
      console.log("---true-----");
      dispatch(EditIOTdevice(reqObj));
      GetIotDevicesList({
        page_number: pageNumber,
        page_size: pageSize,
        // [filterLabel]: filterSearchText || undefined,
      });
      setEditIotClick(false);
      setOpenAddIotDialog(false);
    } else {
      dispatch(AddCreateIOTdevice(reqObj));
      setOpenAddIotDialog(false);
    }
  };
  const handleCloseAddIot = () => {
    setOpenAddIotDialog(false);
  };
  const handleRowClick = (row) => {
    console.log("row", row);
    setSelectedIotDetails(row);
    setOpenAddIotDialog(true);
    setEditIotClick(true);
  };

  const handleFilterClose = (filterData) => {
    console.log(filterData);
    setFilterLabel(filterData.value);
    setFilterSearchText(filterData.searchText);
  };
  return (
    <Box>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CustomTable
          tableData={WfsData?.IotData}
          tableHead={[
            "Device Id",
            "Batch Number",
            "Line Id",
            "Sim Card Number",
            "Mft Date ",
          ]}
          tableDataKey={[
            // "fullname",
            {
              rowClickEnable: true,
              component: (props) => {
                return (
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: "blue",
                    }}
                  >
                    {" "}
                    {props?.row?.device_id}
                  </Typography>
                );
              },
            },
            "batch_number",
            "line_id",
            "sim_number",
            {
              component: (row) => moment(row.mft_date).format("DD-MM-YYYY"),
            },
          ]}
          filter={true}
          filterSelectOption={[
            { label: "Device Id", value: "device_id" },
            { label: "Batch Number", value: "batch_number" },
            { label: "Line Id", value: "line_id" },
          ]}
          handleFilterClose={handleFilterClose}
          sort={true}
          sortSelectOption={[{ label: "Created at", value: "created_at" }]}
          // handleSortClose={handleAddUserSortClose}
          addButton={true}
          handleOnClickAddButton={handleopenAddIotDialog}
          tableRowClickFunction={(index, props) => handleRowClick(props)}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          isCardViewEnable={true}
          // cardHeight="200px"
          cardTitle={{ label: "Device Id", key: "device_id" }}
          cardSubTitle={{ label: "Batch Number", key: "batch_number" }}
          cardInput={(row) => [
            {
              data: [
                { label: "Line ID", value: row.line_id },
                { label: "Sim Number", value: row.sim_number },
              ],
            },
            {
              data: [
                {
                  label: "Manufacture Date",
                  value: () => moment(row.mft_date).format("DD-MM-YYYY"),
                },
              ],
            },
          ]}
          cardButton={(row) => [
            {
              label: "Edit",
              handleClick: handleRowClick,
            },
          ]}
          isFormViewEnable={true}
          formSearchOptionKey="device_id"
          formSearchLabel="Enter Device ID"
          formInput={(row) => [
            { label: "Device ID", value: "device_id" },
            { label: "Batch Number", value: "batch_number" },
            { label: "Sim Number", value: "sim_number" },
            { label: "Line ID", value: "line_id" },
          ]}
          formButton={(row) => [
            {
              label: "Edit",
              handleClick: handleRowClick,
            },
          ]}
        />
      </Box>
      {openAddIotDialog && (
        <AddIotDeviceDialog
          openAddIotDialog={openAddIotDialog}
          handleAddIot={handleAddIot}
          handleCloseAddIot={handleCloseAddIot}
          selectedIotDetails={selectedIotDetails}
        />
      )}
    </Box>
  );
}

export default IotDevicesMain;
