const tabValues = {
  //
  sku: "sku",
  rawMaterials: "raw materials",
  finishedGoods: "finished goods",
  finalProducts: "final products",
  crates: "crates",
  pincode: "pincode",
  //
  manufacturersSuppliers: "manufacturer/supplier",
  processingPartners: 200,
  //
  orderProducts: "orderProducts",
  purchaseProducts: 100,

  //wfs
  createBatch: 0,
  iot: 1,

  //subtab
  addLinesToFacility: 0,
  linkIotToFacility: 2,

  // processingPartnersForOrders: "processing partners for orders",
};

const object = {
  skuMainSelectData: [
    {
      value: tabValues.sku,
      menuItem: "SKU",
    },
    {
      value: tabValues.manufacturersSuppliers,
      menuItem: "Manufacturers/Suppliers",
    },
    {
      value: tabValues.orderProducts,
      menuItem: "Order Product",
    },
  ],
  skuSubSelectData: [
    // {
    //   value: tabValues.rawMaterials,
    //   menuItem: "Raw Materials",
    // },
    // {
    //   value: tabValues.finishedGoods,
    //   menuItem: "Finished Goods",
    // },
    {
      value: tabValues.finalProducts,
      menuItem: "Final Products",
    },
    // {
    //   value: tabValues.crates,
    //   menuItem: "Crates",
    // },
    {
      value: tabValues.pincode,
      menuItem: "Pincode and areas",
    },
  ],
  skuOrderSubSelect: [
    {
      value: tabValues.purchaseProducts,
      menuItem: "Purchase Products",
    },
    // {
    //   value: tabValues.processingPartners,
    //   menuItem: "Processing Partners",
    // },
  ],
  skuOrderPurchaseProductSubSelect: [
    {
      value: tabValues.finalProducts,
      menuItem: "Final Products",
    },
    // {
    //   value: tabValues.rawMaterials,
    //   menuItem: "Raw Materials",
    // },
    // {
    //   value: tabValues.finishedGoods,
    //   menuItem: "Finished Goods",
    // },
  ],
  supplierManufacturer: [
    {
      value: tabValues.manufacturersSuppliers,
      menuItem: "Suppliers/Manufacturers",
    },
    {
      value: tabValues.processingPartners,
      menuItem: "Processing partners",
    },
  ],
  userManagerMainSelect: [
    {
      value: 0,
      menuItem: "Role Manager",
    },
    {
      value: 1,
      menuItem: "API Manager",
    },
    // {
    //   value: 2,
    //   menuItem: "Access Manager",
    // },
    {
      value: 3,
      menuItem: "Facility Manager",
    },
    {
      value: 4,
      menuItem: "Add users",
    },
  ],
  userManagerAccessProviderSubSelect: [
    {
      value: 0,
      menuItem: "Provide access",
    },
    {
      value: 1,
      menuItem: "Provided access",
    },
  ],
  waterStationMainSelect: [
    {
      value: 0,
      menuItem: "Create batch for filling",
    },
    {
      value: 1,
      menuItem: "Link IOT",
    },
  ],
  fillingStationSubSelector: [
    {
      value: 0,
      menuItem: "Add lines to WFS",
    },
    // {
    //   value: 1,
    //   menuItem: "Add IOT device",
    // },
    {
      value: 2,
      menuItem: "Link IOT to filling lines",
    },
  ],
  bookwaterOrderSelect: [
    {
      value: 0,
      menuItem: "Existing product order",
    },
    {
      value: 1,
      menuItem: "New product order",
    },
  ],
  codeManagement: [
    {
      value: 0,
      menuItem: "Existing product order",
    },
    {
      value: 1,
      menuItem: "New product order",
    },
  ],
  sideBarAPIAddSelectOptions: [
    {
      value: "Home",
      menuItem: "Home",
    },
    {
      value: "STOCK KEEPING UNIT",
      menuItem: "STOCK KEEPING UNIT",
    },
    {
      value: "USER MANAGER",
      menuItem: "USER MANAGER",
    },
    {
      value: "WATER STATION",
      menuItem: "WATER STATION",
    },
    {
      value: "FACILITY ORDER MANAGER",
      menuItem: "FACILITY ORDER MANAGER",
    },
    {
      value: "ORGANISATION ORDER MANAGER",
      menuItem: "ORGANISATION ORDER MANAGER",
    },
    {
      value: "ALL ORDERS",
      menuItem: "ALL ORDERS",
    },
    {
      value: "BOOKWATER ORDER MANAGER",
      menuItem: "BOOKWATER ORDER MANAGER",
    },
    {
      value: "QR/BARCODE MANAGER",
      menuItem: "QR/BARCODE MANAGER",
    },
    {
      value: "RECYCLE ORDERS",
      menuItem: "RECYCLE ORDERS",
    },
  ],
  httpMethodApiAddSelectOption: [
    {
      value: "GET",
      menuItem: "GET",
    },
    {
      value: "PUT",
      menuItem: "PUT",
    },
    {
      value: "POST",
      menuItem: "POST",
    },
  ],
};

export { object as default, tabValues };
