/**
 * @author Janarthanan
 * @description  User Management API details to perform a particular action
 * @date 5-11-24
 */
import actionType from "../actionTypes";
const userManagementApis = {
  // Country Codes
  get_country_codes: {
    api_name: "/PSCM/countries",
    https_method: "GET",
    action_type: actionType.GET_COUNTRY_CODES,
    description: "",
  },

  add_user: {
    api_name: "/PSCM/user/add/account",
    https_method: "POST",
    action_type: actionType.ADD_USER,
    description: "",
  },

  get_users_list: {
    api_name: "/PSCM/user/all",
    https_method: "GET",
    action_type: actionType.GET_USERS_LIST,
    description: "To get all the users and their details",
  },
  update_user: {
    api_name: "/PSCM/user/admin/profile/update",
    https_method: "PUT",
    action_type: actionType.UPDATE_USER,
    description: "To get all the users and their details",
  },
  change_user_status: {
    api_name: "/PSCM/user/status",
    https_method: "PUT",
    action_type: actionType.CHANGE_USER_STATUS,
    description: "",
  },
  delete_user: {
    api_name: "/PSCM/user/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_USER,
    description: "",
  },
  change_user_role: {
    api_name: "/PSCM/user/change/role",
    https_method: "PUT",
    action_type: actionType.CHANGE_USER_ROLE,
    description: "",
  },
  // Roles
  add_role: {
    api_name: "/PSCM/roles/add",
    https_method: "POST",
    action_type: actionType.ADD_ROLE,
    description: "",
  },
  get_roles_list: {
    api_name: "/PSCM/roles/all",
    https_method: "GET",
    action_type: actionType.GET_ROLES_LIST,
    description: "",
  },
  update_role: {
    api_name: "/PSCM/roles/update",
    https_method: "PUT",
    action_type: actionType.UPDATE_ROLE,
    description: "",
  },
  remove_chapters: {
    api_name: "/PSCM/roles/remove/legal-chapters",
    https_method: "PUT",
    action_type: actionType.REMOVE_CHAPTERS,
    description: "",
  },
  change_role_status: {
    api_name: "/PSCM/roles/status",
    https_method: "PUT",
    action_type: actionType.CHANGE_ROLE_STATUS,
    description: "",
  },
  delete_role: {
    api_name: "/PSCM/roles/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_ROLE,
    description: "",
  },
  add_page_access: {
    api_name: "/PSCM/roles/add/access/pages",
    https_method: "PUT",
    action_type: actionType.ADD_PAGE_ACCESS,
    description: "",
  },
  remove_page_access: {
    api_name: "/PSCM/roles/remove/access/pages",
    https_method: "PUT",
    action_type: actionType.REMOVE_PAGE_ACCESS,
    description: "",
  },
  add_feature_access: {
    api_name: "/PSCM/roles/add/access/features",
    https_method: "PUT",
    action_type: actionType.ADD_FEATURE_ACCESS,
    description: "",
  },
  remove_feature_access: {
    api_name: "/PSCM/roles/remove/access/features",
    https_method: "PUT",
    action_type: actionType.REMOVE_FEATURE_ACCESS,
    description: "",
  },
  // Features
  get_features_list: {
    api_name: "/PSCM/features/all",
    https_method: "GET",
    action_type: actionType.GET_FEATURES_LIST,
    description: "",
  },
  //Users
  get_user_list: {
    api_name: "/PSCM/user/details",
    https_method: "GET",
    action_type: actionType.GET_USER_ROLES_DOMAIN_DETAILS,
    description: "",
  },
  edit_user_role: {
    api_name: "/PSCM/user/change/role",
    https_method: "PUT",
    action_type: actionType.EDIT_USER_ROLE,
    description: "",
  },
  Delete_user: {
    api_name: "/PSCM/user/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_USER,
    description: "",
  },
  //Facility
  add_new_facility: {
    api_name: "/PSCM/facility",
    https_method: "POST",
    action_type: actionType.ADD_FACILITY,
    description: "",
  },
  add_facility_incharge: {
    api_name: "/PSCM/add_facility_incharge",
    https_method: "POST",
    action_type: actionType.ADD_FACILITY_INCHARGE,
    description: "",
  },
  //organisation
  get_all_organization: {
    api_name: "/PSCM/organization",
    https_method: "GET",
    action_type: actionType.GET_ORGANIZATION_LIST,
    description: "",
  },
  add_new_organization: {
    api_name: "/PSCM/organization",
    https_method: "POST",
    action_type: actionType.ADD_ORGANIZATION,
    description: "",
  },
  //Distributor
  add_distributor_profile: {
    api_name: "/PSCM/facility/distributor/add",
    https_method: "POST",
    action_type: actionType.ADD_DISTRIBUTOR_PROFILE,
    description: "",
  },
  create_order_distributor_to_wfs: {
    api_name: "/PSCM/facility/distributor/placeOrderToWfs",
    https_method: "POST",
    action_type: actionType.CREATE_ORDER_DISTRIBUTOR_TO_WFS,
    description: "",
  },
  complete_wfs_order: {
    api_name: "/PSCM/facility/distributor/orderComplete",
    https_method: "POST",
    action_type: actionType.COMPLETE_WFS_ORDER,
    description: "",
  },
  get_distributor_products_based_on_organization: {
    api_name: "/PSCM/facility/productsByOrgId",
    https_method: "GET",
    action_type: actionType.GET_DISTRIBUTOR_PRODUCTS,
    description: "",
  },
  get_distributor_wfs_orders: {
    api_name: "/PSCM/facility/ordersByOrgId",
    https_method: "GET",
    action_type: actionType.GET_DISTRIBUTOR_WFS_ORDERS,
    description: "",
  },
  put_verify_otp_order: {
    api_name: "/PSCM/booking/verify-otp",
    https_method: "PUT",
    action_type: actionType.CONSUMER_VERIFY_OTP_ORDER,
    description: "",
  },
  post_complete_consumer_order: {
    api_name: "/PSCM/booking/complete/delivery",
    https_method: "PUT",
    action_type: actionType.COMPLETE_CONSUMER_ORDER,
    description: "",
  },
  get_distributor_stock: {
    api_name: "/PSCM/facility/distributorStocks/distributorId",
    https_method: "GET",
    action_type: actionType.GET_DISTRIBUTOR_STOCK,
    description: "",
  },
  get_distributor_List: {
    api_name: "/PSCM/facility/getDistributors",
    https_method: "GET",
    action_type: actionType.GET_DISTRIBUTOR_LIST,
    description: "",
  },
  get_distributor_consumer_orders: {
    api_name: "/PSCM/facility/getAllConsumerOrder",
    https_method: "GET",
    action_type: actionType.GET_DISTRIBUTOR_CONSUMER_ORDERS,
    description: "",
  },
  put_in_scan: {
    api_name: "/PSCM/booking/inscan",
    https_method: "PUT",
    action_type: actionType.PUT_IN_SCAN,
    description: "",
  },
  put_out_scan: {
    api_name: "/PSCM/booking/outscan",
    https_method: "PUT",
    action_type: actionType.PUT_OUT_SCAN,
    description: "",
  },
};

export default userManagementApis;
