import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";
import appFonts from "../../../AppMeta/appFonts";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";

function EditWfsForm({ setOpenEditWfsDialog, getWfs, selectedRowForEdit }) {
  //hooks
  const dispatch = useDispatch();
  console.log("selectedRowForEdit", selectedRowForEdit);

  //State
  const [isProfilePicUploaded, setIsProfilePicUploaded] = useState(false);

  //function
  const editWfs = async (reqObj) => {
    let res = await dispatch(
      HandleApiActions({
        ...wfsApis.update_wfs,
        params: reqObj,
        show_toast: true,
      })
    );
    console.log("---res---", res);
    res.error === false && setOpenEditWfsDialog(false);
  };

  const handleEditWfsSubmit = (formData) => {
    console.log("--FormData--", formData);
    let editWfsObject = {
      wfs_id: selectedRowForEdit.wfs_id,
      wfs_name: formData.wfs_name,
      facility_id: selectedRowForEdit.facility_id,
      wfs_incharge: {
        name: formData.wfs_incharge_name,
        phone_number: formData.wfs_incharge_phone,
        // aadhaar_number: formData.wfs_incharge_aadhar,
        email: formData.wfs_incharge_email,
      },
      address: {
        district: formData.district,
        pincode: formData.pincode,
        state: formData.state,
        street: formData.street,
        village: formData.village,
        country: formData.country,
        door_no: formData.door_no,
      },
      // wfs_details_json: {
      //   gst_number: formData.gst_number,
      //   company_pan_number: formData.company_pan_number,
      //   fssai_number: formData.fssai_number,
      //   isi_number: formData.isi_number,
      // },
      is_active: formData.is_active,
      is_force_update: formData.isConfirmed,
      is_deleted: formData.is_deleted,
      wfs_files: {
        gst_file_url: formData.gst_file_url,
        company_pan_file_url: formData.company_pan_file_url,
        aadhaar_file_url: formData.aadhaar_file_url,
        fssai_file_url: formData.fssai_file_url,
        isi_file_url: formData.isi_file_url,
      },
    };
    console.log("---editWfsObject---", editWfsObject);
    editWfs(editWfsObject);
    setTimeout(() => {
      getWfs();
    }, 1000);
  };
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant={appFonts.cardTitle} sx={{ textAlign: "center" }}>
            INCHARGE DETAILS
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: 800 }}>Email:</Typography>
            <Typography>
              {selectedRowForEdit.wfs_incharge_details[0]?.email}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: 800 }}>Name</Typography>
            <Typography>
              {selectedRowForEdit.wfs_incharge_details[0]?.fullname}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: 800 }}>Phone</Typography>
            <Typography>
              {selectedRowForEdit.wfs_incharge_details[0]?.phone}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <CustomForm
        configConfirmation={{
          isConfirmationRequired: true,
          dialogContent: "Are you sure you want to edit data?",
        }}
        data={[
          {
            numberOfColumn: 1,
            title: "Edit WFS",
            data: [
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Company Name",
                defaultValue: selectedRowForEdit.wfs_name,
                name: "wfs_name",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "Company PAN Number",
              //   defaultValue:
              //     selectedRowForEdit.wfs_details_json.company_pan_number,
              //   name: "company_pan_number",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "ISI number",
              //   defaultValue: selectedRowForEdit.wfs_details_json.isi_number,
              //   name: "isi_number",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "FSSAI number",
              //   defaultValue: selectedRowForEdit.wfs_details_json.fssai_number,
              //   name: "fssai_number",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "GSTIN number",
              //   defaultValue: selectedRowForEdit.wfs_details_json.gst_number,
              //   name: "gst_number",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
            ],
          },
          {
            title: "WFS Incharge",
            numberOfColumn: 2,
            data: [
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Name",
                defaultValue:
                  selectedRowForEdit.wfs_incharge_details[0]?.fullname,
                name: "wfs_incharge_name",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Phone",
                defaultValue: selectedRowForEdit.wfs_incharge_details[0]?.phone,
                name: "wfs_incharge_phone",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "Aadhar number",
              //   defaultValue:
              //     selectedRowForEdit.wfs_incharge_details[0]?.aadhaar_number,
              //   name: "wfs_incharge_aadhar",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Email",
                defaultValue: selectedRowForEdit.wfs_incharge_details[0]?.email,
                name: "wfs_incharge_email",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
            ],
          },
          {
            numberOfColumn: 2,
            title: "Address",
            data: [
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Door Number",
                defaultValue: selectedRowForEdit.address.door_no,
                name: "door_no",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Street",
                defaultValue: selectedRowForEdit.address.street,
                name: "street",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Village",
                defaultValue: selectedRowForEdit.address.village,
                name: "village",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "District",
                defaultValue: selectedRowForEdit.address.district,
                name: "district",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "State",
                defaultValue: selectedRowForEdit.address.state,
                name: "state",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Pincode",
                defaultValue: selectedRowForEdit.address.pincode,
                name: "pincode",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Country",
                defaultValue: selectedRowForEdit.address.country,
                name: "country",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
            ],
          },
          {
            numberOfColumn: 2,
            title: "Document Upload",
            data: [
              {
                inputType: appMeta.inputFieldTypes.FILE,
                name: "fssai_file_url",
                defaultValue: selectedRowForEdit.wfs_files.fssai_file_url,
                placeHolder: "FSSAI*",
                setIsFileUpload: setIsProfilePicUploaded,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.FILE,
                name: "isi_file_url",
                defaultValue: selectedRowForEdit.wfs_files.isi_file_url,
                placeHolder: "ISI*",
                setIsFileUpload: setIsProfilePicUploaded,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.FILE,
                name: "gst_file_url",
                defaultValue: selectedRowForEdit.wfs_files.gst_file_url,
                placeHolder: "GSTIN*",
                setIsFileUpload: setIsProfilePicUploaded,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.FILE,
                name: "company_pan_file_url",
                defaultValue: selectedRowForEdit.wfs_files.company_pan_file_url,
                placeHolder: "Company PAN*",
                setIsFileUpload: setIsProfilePicUploaded,
                isRequired: true,
              },

              {
                inputType: appMeta.inputFieldTypes.FILE,
                name: "aadhaar_file_url",
                defaultValue: selectedRowForEdit.wfs_files.aadhaar_file_url,
                placeHolder: "Aadhar*",
                setIsFileUpload: setIsProfilePicUploaded,
                isRequired: true,
              },
            ],
          },
          {
            data: [
              {
                inputType: appMeta.inputFieldTypes.SWITCH,
                placeHolder: {
                  on: "Active",
                  off: "Active",
                },
                name: "is_active",
                defaultValue: selectedRowForEdit.is_active,
              },
              {
                inputType: appMeta.inputFieldTypes.SWITCH,
                placeHolder: {
                  on: "Delete",
                  off: "Delete",
                },
                name: "is_deleted",
                defaultValue: selectedRowForEdit.is_deleted,
              },
            ],
          },
        ]}
        handleSubmit={handleEditWfsSubmit}
        handleClose={() => {
          setOpenEditWfsDialog(false);
        }}
      />
    </Box>
  );
}

export default EditWfsForm;
