import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomCheckBox from "../../../Components/CustomComp/CustomCheckBox";
import CustomButton from "../../../Components/CustomComp/CustomButton";

function WaterDetailConfiguration({
  masterConfigObject,
  setMasterConfigObject,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <CustomCheckBox
          name="quality_report_data"
          defaultValue={masterConfigObject.quality_report_data}
          input={[
            {
              menuItem: "Water filling station details",
              value: "wfs_details",
            },
            {
              menuItem: "pH",
              value: "ph",
            },
            {
              menuItem: "TDS",
              value: "tds",
            },
            {
              menuItem: "Number of Times Used",
              value: "no_times_used",
            },
            {
              menuItem: "Container Detail",
              value: "container_details",
            },
            {
              menuItem: "Owner Details",
              value: "owner_details",
            },
            {
              menuItem: "Expiry Date",
              value: "exp_date",
            },
            {
              menuItem: "Manufacture Date",
              value: "mft_date",
            },
            {
              menuItem: "Temperature",
              value: "temp",
            },
          ]}
          onChange={(name, value) => {
            const updatedQualityReportData = Object.fromEntries(
              [
                "wfs_details",
                "ph",
                "tds",
                "no_times_used",
                "container_details",
                "owner_details",
                "exp_date",
                "mft_date",
                "temp",
              ].map((key) => [key, value.includes(key)])
            );

            setMasterConfigObject((prev) => ({
              ...prev,
              [name]: updatedQualityReportData, // Update the quality_report_data field
            }));
          }}
        />
      </Box>
    </Box>
  );
}

export default WaterDetailConfiguration;
