import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

function LineChartForIot() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const xLabels = [
    "Batch 1",
    "Batch 2",
    "Batch 3",
    "Batch 4",
    "Batch 5",
    "Batch 6",
    "Batch 7",
  ];
  return (
    <LineChart
      width={isMobile ? 250 : 500}
      height={300}
      series={[
        { data: pData, label: "TDS" },
        { data: uData, label: "pH" },
      ]}
      xAxis={[{ scaleType: "point", data: xLabels }]}
    />
  );
}

export default LineChartForIot;
