import React from "react";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import CustomQuantityIncrementDecrementChange from "../../Components/CustomComp/CustomQuantityIncrementDecrementChange";
import { Box, Divider, Typography } from "@mui/material";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import userManagementApis from "../../Redux/Actions/userManagement";

function DistriburoePlaceOrder(props) {
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const products = useSelector((state) => state.userManagementReducer);

  const dispatch = useDispatch();

  const [searchWfs, setSearchWfs] = React.useState("");
  const [wfsValue, setWfsValue] = React.useState("");
  const [finalProducts, setFinalProducts] = React.useState([]);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    requried_qty: 0,
    return_qty: 0,
  });
  console.log("wfsDetails");
  const [productDetails, setProductDetails] = React.useState({
    material: "",
    capacity: "",
  });
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_products_based_on_organization,
        show_toast: false,
      })
    );
  }, []);

  React.useEffect(() => {
    console.log("Product Data");
    console.log(products);
    // setProducts(productDetails.finalProduct.product_details);
    // productDetails?.finalProduct?.map((product) => {
    //   setFinalProducts(product.product_details);
    // });
    setFinalProducts(products?.distributor_products_List);
  }, [products]);

  React.useEffect(() => {
    searchAndUpdateWfs();
  }, [searchWfs]);

  const handleChangeProduct = (value) => {
    const productId = value;
    setSelectedProductId(productId);
    const selectedProduct = finalProducts.find(
      (item) => item.container_master_id === productId
    );
    if (selectedProduct) {
      setProductDetails({
        material: selectedProduct.container_material,
        capacity: selectedProduct.container_capacity,
      });
    }
  };

  const handelRequriedQuantityChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      requried_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.requried_qty),
    }));
  };
  const handelReturnQuantityChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      return_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.return_qty),
    }));
  };
  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          // ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };
  const handleChangeWfs = (value) => {
    console.log("value", value);
    setWfsValue(value);
  };

  const handleCloseDrawer = () => {
    props.handleCloseDrawer();
  };
  const handleSubmitDrawer = () => {
    props.handleSubmitDrawer({
      container_master_id: selectedProductId,
      wfs_id: wfsValue,
      order_quantity: orderParams.requried_qty,
      return_quantity: orderParams.return_qty,
    });
  };
  return (
    <CustomDrawer open={props.opendistributorPlaceOrder} title="Place Order">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "5px",
          // marginTop: "1rem",
          m: "10px 0px 20px 0px",
        }}
      >
        <CustomAutoComplete
          field={{
            optionKey: "wfs_name",
            outputKey: "wfs_id",
            searchAndUpdateAPI: (searchText) => {
              setSearchWfs(searchText);
            },
            input: wfsList || [],
            onChange: (name, value) => {
              handleChangeWfs(value);
            },
            name: "wfs_id",
            label: "Enter WFS*",
          }}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "5px",
          // marginTop: "1rem",
          m: "10px 0px 20px 0px",
        }}
      >
        <CustomAutoComplete
          field={{
            optionKey: "name",
            outputKey: "container_master_id",
            searchAndUpdateAPI: (searchText) => {
              setSearchWfs(searchText);
            },
            input: finalProducts || [],
            onChange: (name, value) => {
              handleChangeProduct(value);
            },
            name: "product id",
            label: "Enter Product*",
          }}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Typography sx={{ fontWeight: 800, fontSize: "17px", pl: "20px" }}>
        &bull; Requried Quantity*&nbsp;&nbsp;
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "5px",
          // marginTop: "1rem",
          ml: "0px",
        }}
      >
        <CustomQuantityIncrementDecrementChange
          handelAddQuantity={() =>
            handelRequriedQuantityChange(parseInt(orderParams.requried_qty) + 1)
          }
          handelMinusQuantity={() =>
            handelRequriedQuantityChange(parseInt(orderParams.requried_qty) - 1)
          }
          handelQuantityChange={(event) =>
            handelRequriedQuantityChange(event.target.value)
          }
          inputValue={orderParams.requried_qty}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Typography
        sx={{
          fontWeight: 800,
          fontSize: "17px",
          pl: "20px",
          textAlign: "start",
        }}
      >
        &bull; Return Quantity&nbsp;&nbsp;
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "10px",
          // marginTop: "1rem",
        }}
      >
        <CustomQuantityIncrementDecrementChange
          handelAddQuantity={() =>
            handelReturnQuantityChange(parseInt(orderParams.return_qty) + 1)
          }
          handelMinusQuantity={() =>
            handelReturnQuantityChange(parseInt(orderParams.return_qty) - 1)
          }
          handelQuantityChange={(event) =>
            handelReturnQuantityChange(event.target.value)
          }
          inputValue={orderParams.return_qty}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Box sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
        <CustomButton
          label="close"
          color="error"
          handleClick={handleCloseDrawer}
        />
        <CustomButton label="submit" handleClick={handleSubmitDrawer} />
      </Box>
    </CustomDrawer>
  );
}

export default DistriburoePlaceOrder;
