import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomCheckBox from "../../../Components/CustomComp/CustomCheckBox";

function ScanningLevelConfiguration({
  masterConfigObject,
  setMasterConfigObject,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <CustomCheckBox
          selectAllPreviousCheckBox={true}
          defaultValue={masterConfigObject?.scanning_level}
          // {Object.keys(masterConfigObject?.scanning_level).filter(
          //   (key) => masterConfigObject?.scanning_level[key]
          // )}
          name="scanning_level"
          input={[
            {
              menuItem: "Filling station",
              value: "wfs",
            },
            {
              menuItem: "Distributor",
              value: "dist",
            },
            {
              menuItem: "Consumer",
              value: "cons",
            },
          ]}
          onChange={(name, value) => {
            const updatedScanningLevel = Object.fromEntries(
              ["wfs", "dist", "cons"].map((key) => [key, value.includes(key)])
            );
            setMasterConfigObject((prev) => ({
              ...prev,
              [name]: updatedScanningLevel,
            }));
          }}
        />
      </Box>
    </Box>
  );
}

export default ScanningLevelConfiguration;
