import { Box } from "@mui/material";
import React from "react";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";

function EditUserRoleDialog({
  setOpenEditUserRoleDialog,
  rolesList,
  selectedRow,
  setRoleSearch,
  getUsers,
}) {
  //hooks
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenEditUserRoleDialog(false);
  };
  const searchAndUpdateOrganization = (searchText) => {
    setRoleSearch(searchText);
  };
  const editUserRoles = (formData) => {
    const reqObj = {
      user_id: selectedRow.user_id,
      role_id: parseInt(formData.role_id),
    };
    console.log(reqObj);
    dispatch(
      HandleApiActions({
        ...userManagementApis.edit_user_role,
        params: reqObj,
        show_toast: true,
      })
    );
    setTimeout(() => {
      getUsers();
    }, 1000);
    setOpenEditUserRoleDialog(false);
  };

  return (
    <Box>
      <CustomForm
        handleClose={handleClose}
        handleSubmit={editUserRoles}
        configConfirmation={{
          isConfirmationRequired: true,
          dialogContent: "Are you sure you want to update Role?",
        }}
        data={[
          {
            data: [
              {
                inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                optionKey: "role_name",
                optionKey2: "department",
                outputKey: "role_id",
                searchAndUpdateAPI: searchAndUpdateOrganization,
                input: rolesList || [],
                name: "role_id",
                label: "Enter new role",
                defaultValue: selectedRow?.role_details?.role_name,
              },
            ],
          },
        ]}
      />
    </Box>
  );
}

export default EditUserRoleDialog;
