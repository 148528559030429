import actionTypes from "../actionTypes";

export const masterConfigApi = {
  get_master_setting: {
    api_name: "PSCM/settings/master",
    https_method: "GET",
    // action_type: actionTypes.SCAN_BATCH_QR,
    description: "",
  },
  update_master_setting: {
    api_name: "/PSCM/settings/master",
    https_method: "PUT",
    // action_type: actionTypes.UNAUTH_SCAN_BATCH_QR,
    description: "",
  },
};
