/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Organization details main component
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   CreateOrganization,
//   GetOrganizationList,
// } from "../../Redux/Actions/organizationAction";
import {
  CreateOrganization,
  GetOrganizationList,
} from "../../../Redux/Actions/organizationAction";
import OrganizationDetilsList from "./OrganizationDetailsList";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import OrganizationDetailsList from "./OrganizationDetailsList";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import appMeta from "../../../AppMeta/appMeta";
import { toast } from "react-toastify";
import { EventNote } from "@mui/icons-material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import {
  UploadFile,
  getFiles,
} from "../../../Redux/Actions/fileHandlingActions";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import appStyle from "../../../AppMeta/appStyle";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import appFonts from "../../../AppMeta/appFonts";
import CustomSelect from "../../../Components/CustomComp/CustomSelect";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import CustomDrawer from "../../../Components/CustomComp/CustomDrawer";

const styles = {
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "5px",
  },
};

function OrganizationDetails({ drawOpenProp, setDrawOpenProp }) {
  const organizationDetail = useSelector((state) => state.organizationData);

  const facilityIconRef = React.useRef(null);
  const facilityImgRef = React.useRef(null);
  //File Upload Variables
  const [facilityIconFile, setFacilityIconFile] = React.useState("");
  const [facilityIconUrl, setFacilityIconUrl] = React.useState("");
  const [facilityIconUploadBtn, setFacilityIconUploadBtn] =
    React.useState(false);
  const [facilityIconFlag, setFacilityIconFlag] = React.useState(false);

  const [facilityImgFile, setFacilityImgFile] = React.useState("");
  const [facilityImgUrl, setFacilityImgUrl] = React.useState("");
  const [facilityImgUploadBtn, setFacilityImgUploadBtn] = React.useState(false);
  const [facilityImgFlag, setFacilityImgFlag] = React.useState(false);
  // Required variables declared here
  const dispatch = useDispatch();
  const rolesData = useSelector(
    (state) => state.userManagementReducer.rolesList
  );

  const filesDetails = useSelector((state) => state.filesData);

  const [organizationList, setOrganizationList] = React.useState([]);
  const [listOfRoles, setListOfRoles] = React.useState([]);
  console.log("listOfRoles", listOfRoles);
  console.log("---rolesList--", rolesData);
  const [organizationName, setOrganizationName] = React.useState("");
  const [facilityName, setFacilityName] = React.useState("");
  const [facilityId, setFacilityId] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [roleSearch, setRoleSearch] = React.useState();
  const [lastMore, setLastMore] = React.useState(null);

  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [roleName, setRoleName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [domain, setDomain] = React.useState("");

  const [areaName, setAreaName] = React.useState("");
  const [pincode, setPincode] = React.useState(null);
  const [state, setState] = React.useState("");
  const [district, setDistrict] = React.useState("");

  const [addOrganizationDialog, setAddOrganizationDialog] =
    React.useState(drawOpenProp);
  const [isAddFacility, setIsAddFacility] = React.useState(false);
  const [isValidPincode, setIsValidPincode] = React.useState(true);
  const [isValidDomain, setIsValidDomain] = React.useState(true);
  const [isValidPhone, setIsValidPhone] = React.useState(true);
  const [isValidPassword, setIsValidPassword] = React.useState(true);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = React.useState(true);

  // Pagination and filter variables
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [isActive, setIsActive] = React.useState(true);
  const [filterLabel, setFilterLabel] = React.useState("");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [sortLabel, setSortLabel] = React.useState("");
  const [ascOrDesc, setAscOrDesc] = React.useState("DESC");
  const [lastPageNumber, setlastPageNumber] = React.useState(null);
  const [isEdited, setIsEdited] = React.useState(false);

  // Accessesing the organization redux store to get organization data
  React.useEffect(() => {
    console.log("---organizationDetails---", organizationDetail);
    if (organizationDetail.error === false) {
      if (organizationDetail?.organizationList?.length > 0) {
        console.log("getting it");
        setOrganizationList(organizationDetail.organizationList);
      } else {
        setOrganizationList([]);
      }
    } else {
      setOrganizationList([]);
    }
  }, [organizationDetail.organizationList]);

  // Accessessing the roles redux store

  React.useEffect(() => {
    console.log("roles Data");
    console.log(rolesData);
    if (rolesData?.length > 0) {
      setListOfRoles(rolesData);
    }
  }, [rolesData]);

  // React.useEffect(() => {
  //   console.log(rolesData);
  //   if (rolesData.error === false) {
  //     setlastPageNumber(rolesData.rolesList.last_page_number);
  //     console.log("rolesData.rolesList", rolesData.rolesList);
  //     if (rolesData.rolesList?.data?.length > 0) {
  //       console.log("---rolesList---", rolesData.rolesList);
  //       setLastMore(rolesData.rolesList.last_page_number);
  //       setListOfRoles((prevRolesList) =>
  //         prevRolesList
  //           ? [...prevRolesList, ...rolesData.rolesList.data]
  //           : rolesData.rolesList.data
  //       );
  //     }
  //   }
  // }, [rolesData]);

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          // page_number: pageNumber,
          // page_size: pageSize,
          // created_at_sort: ascOrDesc,
          // sort_order: ascOrDesc,
          // ...(filterSearchText && { [filterLabel]: filterSearchText }),
          role_name: roleSearch,
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
    console.log("---rolesData", rolesData);
    setListOfRoles(rolesData);
  }, [roleSearch]);

  // React.useEffect(() => {
  //   dispatch(GetRoles(getMoreRoles, 25, true, "ASC"));
  // }, [getMoreRoles]);
  // API call for getting the organization details
  React.useEffect(() => {
    // dispatch(GetOrganizationList(pageNumber, pageSize, isActive, ascOrDesc));

    getAllOrganization();
  }, [
    pageNumber,
    pageSize,
    sortLabel,
    ascOrDesc,
    filterLabel,
    filterSearchText,
    isEdited,
  ]);

  // Handling organization name change
  const handleChangeOrganizationName = (event) => {
    const newValue = event.target.value;
    setOrganizationName(newValue);
    console.log(newValue);
  };

  // Handling facility name change
  // const handleChangeFacilityName = (event) => {
  //   const newValue = event.target.value;
  //   setFacilityName(newValue);
  //   console.log(newValue);
  // };

  // Handling domain name change
  const handleChangeDomain = (event) => {
    const newValue = event.target.value;
    setDomain(newValue);
    console.log(newValue);
  };
  // Handling area name change
  const handleChangeAreaName = (event) => {
    const newValue = event.target.value;
    setAreaName(newValue);
    console.log(newValue);
  };
  // Handling email name change
  const handleChangeEmail = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
    console.log(newValue);
  };
  // Handling pincode change
  const handleChangePincode = (event) => {
    const newValue = event.target.value;
    setPincode(newValue);
    console.log(newValue);
  };
  // Handling state change
  const handleChangeState = (event) => {
    const newValue = event.target.value;
    setState(newValue);
    console.log(newValue);
  };
  // Handling district name change
  const handleChangeDistrict = (event) => {
    const newValue = event.target.value;
    setDistrict(newValue);
    console.log(newValue);
  };

  const handleUploadFacilityClick = () => {
    facilityIconRef.current.click();
  };
  const handleUploadFacilityClickImg = () => {
    // console.log("facilityImgRef", facilityImgRef);
    facilityImgRef.current.click();
  };
  ///Functions for Filters
  const handleFacilityFilterClose = (filterData) => {
    console.log("Facility filter Data", filterData);
    setFilterLabel(filterData.value);
    setFilterSearchText(filterData.searchText);
    // getAllOrganization();
  };

  const handleFacilitySortClose = (sortData) => {
    console.log("Facility Sort Data", sortData);
    setSortLabel(sortData.value);
    setAscOrDesc(sortData.searchText);
    // getAllOrganization();
  };

  const getAllOrganization = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          page_number: pageNumber,
          page_size: pageSize,
          created_at_sort: ascOrDesc,
          // sort_order: ascOrDesc,
          ...(filterSearchText !== "" && { [filterLabel]: filterSearchText }),
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    if (facilityName) {
      setFacilityName(facilityName);
    }
  }, [facilityName]);

  // This function will add new organization with API call will req Obj and validation check
  const handleAddOrganizationClick = (formData) => {
    // const reqObj = {
    //   org_name: organizationName,
    //   domain: domain,
    //   details: {
    //     area_name: areaName,
    //     pincode: pincode,
    //     state: state,
    //     district: district,
    //   },
    // };
    // const reqObj1 = {
    //   name: organizationName,
    //   domain: domain,
    //   facility: {
    //     facility_name: facilityName,
    //     facility_id: facilityId,
    //   },
    //   details: {
    //     area_name: areaName,
    //     pincode: pincode,
    //     state: state,
    //     district: district,
    //   },
    //   user_details: {
    //     role_name: selectedRole,
    //     email: email,
    //     password: password,
    //     org_name: organizationName,
    //     fullname: fullName,
    //     phone: phone,
    //   },
    //   facilityImg: facilityImgFile,
    //   facilityIcon: facilityIconFile,
    // };

    // const reqObj2 = {
    //   name: organizationName,
    //   domain: domain,
    //   details: {
    //     area_name: areaName,
    //     pincode: pincode,
    //     state: state,
    //     district: district,
    //   },
    //   user_details: {
    //     role_name: selectedRole,
    //     email: email,
    //   },
    // };
    // console.log("first", facilityName);

    const OrganizationObject = {
      name: formData.facilityName,
      domain: formData.domain,
      facility: {
        facility_name: facilityName,
        facility_id: parseInt(facilityId),
      },
      details: {
        area_name: formData.area,
        pincode: formData.pincode,
        state: formData.state,
        district: formData.district,
      },
      user_details: {
        role_name: formData.role_name,
        email: formData.email,
        password: formData.password,
        org_name: formData.facilityName,
        fullname: formData.fullName,
        phone: formData.phone,
      },
    };

    const facilityObject = {
      name: formData.facilityName,
      domain: formData.domain,
      details: {
        area_name: formData.area,
        pincode: formData.pincode,
        state: formData.state,
        district: formData.district,
      },
      user_details: {
        role_name: formData.role_name,
        org_name: formData.facilityName,
        fullname: formData.fullName,
        phone: formData.phone,
        email: formData.email,
        password: formData.password,
      },
      facility_icon: formData.facility_icon,
      facility_image: formData.facility_image,
    };

    console.log("-------------facilityObject----------------", formData);
    if (isAddFacility === false) {
      console.log(OrganizationObject);
      // dispatch(CreateOrganization(OrganisationObject));
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_new_organization,
          params: OrganizationObject,
          show_toast: true,
        })
      );
    } else if (isAddFacility === true) {
      console.log(facilityObject);
      // dispatch(CreateOrganization(facilityObject));
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_new_facility,
          params: facilityObject,
          show_toast: true,
        })
      );
      //resetting farm data
      handleClose();
    }

    // if (
    //   isValidPincode &&
    //   organizationName !== "" &&
    //   domain !== "" &&
    //   areaName !== "" &&
    //   state !== "" &&
    //   district !== ""
    // ) {
    //   setIsAddButtonDisabled(false);

    //   // dispatch(CreateOrganization(reqObj));
    // } else {
    //   toast.error("Enter all Required Fields", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
    setTimeout(() => {
      // dispatch(GetOrganizationList(pageNumber, pageSize, isActive, ascOrDesc));
      getAllOrganization();
    }, 1000);
  };

  // React.useEffect(() => {
  //   setOrganizationName("");
  //   setFacilityName("");
  //   setFacilityId("");
  //   setAnchorEl(null);
  //   setHasMoreData(true);
  //   setGetMoreRoles(1);
  //   setSelectedRole("");
  //   setLastMore(null);
  //   setIsValid(true);
  //   setRoleName("");
  //   setEmail("");
  //   setDomain("");
  //   setAreaName("");
  //   setPincode("");
  //   setState("");
  //   setDistrict("");
  // }, [isAddFacility]);

  const handleClose = () => {
    setAddOrganizationDialog(false);
    // setAddOrganizationDialog(false);
    setFacilityImgUrl("");
    setFacilityIconUrl("");

    //Reste all fields
    setOrganizationName("");
    setFacilityName("");
    setFacilityId("");
    setAnchorEl(null);
    setHasMoreData(true);
    setGetMoreRoles(1);
    setSelectedRole("");
    setLastMore(null);
    // setIsValid(true);
    setRoleName("");
    setEmail("");
    setDomain("");
    setAreaName("");
    setPincode("");
    setState("");
    setDistrict("");
    setFullName("");
    setPhone("");
    setPassword("");
  };

  const handleChangeFacilityName = (event) => {
    console.log(event.target.value);
    setFacilityName(event.target.value);
  };
  // const handleChangeFacilityId = (event) => {
  //   setFacilityId(event.target.value);
  // };
  const handleChangeRoleName = (event) => {
    setRoleName(event.target.value);
  };
  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  const handleChangeFacilityIcon = (event) => {
    // console.log("Selected Facility Icon :", event.target.files[0]);
    // setFacilityIconFile(event.target.files[0]);
    // setFacilityIconUploadBtn(true);
  };

  const handleChangeFacilityImg = (event) => {
    console.log("Selected Facility Icon :", event.target.files[0]);
    setFacilityImgFile(event.target.files[0]);
    setFacilityImgUploadBtn(true);
  };

  React.useEffect(() => {
    organizationList.map((data) => {
      if (facilityName === data.org_name) {
        setFacilityId(data.id);
      }
    });
  }, [facilityName]);

  React.useEffect(() => {
    if (facilityIconUploadBtn) {
      console.log("facility file upload");
      dispatch(UploadFile(facilityIconFile));
      setFacilityIconUploadBtn(false);
      setFacilityIconFlag(true);
    }
  }, [facilityIconFile]);

  React.useEffect(() => {
    console.log(`facilityIconUrl ${facilityIconUrl}`);
  }, [facilityIconUrl]);

  React.useEffect(() => {
    console.log("fileDetails");
    console.log(filesDetails);
    if (facilityIconFlag) {
      console.log("setting facility url");
      console.log(filesDetails.fileName);
      setFacilityIconUrl(filesDetails.fileName);
      setFacilityIconFlag(false);
    }
  }, [filesDetails]);

  React.useEffect(() => {
    if (facilityImgUploadBtn) {
      console.log("facility file upload");
      dispatch(UploadFile(facilityImgFile));
      setFacilityImgUploadBtn(false);
      setFacilityImgFlag(true);
    }
  }, [facilityImgFile]);

  React.useEffect(() => {
    console.log(`facilityImgUrl ${facilityImgUrl}`);
  }, [facilityImgUrl]);

  React.useEffect(() => {
    console.log("fileDetails");
    console.log(filesDetails);
    if (facilityImgFlag) {
      console.log("setting facility url");
      console.log(filesDetails.fileName);
      setFacilityImgUrl(filesDetails.fileName);
      setFacilityImgFlag(false);
    }
  }, [filesDetails]);

  useEffect(() => {
    setAddOrganizationDialog(drawOpenProp);
    setIsAddFacility(true);
  }, [drawOpenProp]);

  return (
    <Box
    // display="flex"
    // flexDirection="column"
    // alignItems="center"
    // justifyContent="center"
    // gap={2}
    // padding="30px"
    // sx={{
    //   display: "flex",

    //   justifyContent: "flex-end",
    //   padding: "10px",
    // }}
    >
      {/* <Box sx={appStyle.general.button.addButtonBox}>
          <Button
            variant="contained"
            color="secondary"
            label="Add New Facility"
            sx={appStyle.general.button.addButton}
            onClick={() => {
              setAddOrganizationDialog(true);
              setIsAddFacility(true);
            }}
            endIcon={<CorporateFareRoundedIcon />}
          >
            Add New Facility
          </Button>
        </Box> */}
      <CustomDrawer
        open={addOrganizationDialog}
        setOpen={setAddOrganizationDialog}
        title={isAddFacility ? "Add facility" : "Add organization"}
      >
        {/* <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              padding: "20px",
              paddingTop: "9rem",
            }}
          > */}
        <>
          {/* <Box sx={{ minWidth: 120 }}>
                <Button onClick={handleOpenDropdown} variant="outlined">
                  Select Role Name
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseDropdown}
                >
                  {listOfRoles?.map((list) => (
                    <MenuItem
                      key={list.role_name}
                      onClick={() => {
                        handleCloseDropdown();
                        setSelectedRole(list.role_name);
                      }}
                    >
                      {list.role_name}
                    </MenuItem>
                  ))}
                  {hasMoreData && (
                    <MenuItem
                      onClick={handleLoadMore}
                      sx={{ fontWeight: "bold" }}
                      disabled={getMoreRoles < lastMore ? false : true}
                    >
                      More <ExpandMoreRoundedIcon />
                    </MenuItem>
                  )}
                </Menu>
              </Box> */}

          {/* <CustomSelect
                  name="Select Role Name"
                  input={listOfRoles?.map((role) => {
                    return {
                      value: role.role_name,
                      menuItem: role.role_name,
                    };
                  })}
                  handleChange={(event) => {
                    setSelectedRole(event.target.value);
                  }}
                  defaultValue={selectedRole}
                  variant="standard"
                  sx={{ width: "11rem" }}
                /> */}

          <CustomForm
            handleSubmit={handleAddOrganizationClick}
            handleClose={handleClose}
            data={[
              {
                numberOfColumn: 1,
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                    optionKey: "role_name",
                    outputKey: "role_name",
                    searchAndUpdateAPI: (searchText) => {
                      setRoleSearch(searchText);
                    },
                    input: listOfRoles || [],
                    name: "role_name",
                    label: "Enter Role Name",
                  },
                ],
              },
              {
                numberOfColumn: 2,
                title: `${isAddFacility ? "facility" : "Organization"} details`,
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: `${
                      isAddFacility ? "facility" : "Organization"
                    } name`,
                    defaultValue: "",
                    name: "facilityName",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: `${
                      isAddFacility ? "facility" : "Organization"
                    } domain`,
                    defaultValue: "",
                    name: "domain",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ],
              },
              {
                numberOfColumn: 2,
                title: "User Detail",
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Full Name",
                    defaultValue: "",
                    name: "fullName",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Phone",
                    defaultValue: "",
                    name: "phone",
                    textFieldType: appMeta.textFieldTypes.PHONE,
                    isHintRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Email",
                    defaultValue: "",
                    name: "email",
                    textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Password",
                    defaultValue: "",
                    name: "password",
                    textFieldType: appMeta.textFieldTypes.PASSWORD,
                    isHintRequired: true,
                  },
                ],
              },
              {
                title: "Address",
                numberOfColumn: 2,
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Area",
                    defaultValue: "",
                    name: "area",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "PINCODE",
                    defaultValue: "",
                    name: "pincode",
                    textFieldType: appMeta.textFieldTypes.PINCODE,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "District",
                    defaultValue: "",
                    name: "district",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "State",
                    defaultValue: "",
                    name: "state",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ],
              },
              {
                title: "File Upload",
                numberOfColumn: 2,
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.FILE,
                    name: "facility_icon",
                    placeHolder: `${
                      isAddFacility ? "facility" : "Organization"
                    }* Logo`,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.FILE,
                    name: "facility_image",
                    placeHolder: `${
                      isAddFacility ? "facility" : "Organization"
                    }* Image`,
                  },
                ],
              },
            ]}
          />

          {/* <Box>
                <Autocomplete
                  id="country-select-demo"
                  options={listOfRoles || []}
                  autoHighlight
                  getOptionLabel={(option) => {
                    return option?.role_name;
                  }}
                  onChange={(_, selectedOption) => {
                    setSelectedRole(selectedOption);
                  }}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;

                    return (
                      <Box
                        key={key}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...optionProps}
                      >
                        {`${option?.role_name}${
                          option?.department && "/" + option?.department
                        }`}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    console.log("---renderInput---", params);
                    return (
                      <TextField
                        {...params}
                        fullWidth={false}
                        label="Enter Role"
                        variant="standard"
                        onChange={(e) => setRoleSearch(e.target.value)}
                        value={roleSearch}
                        sx={{
                          minWidth: "25ch",
                        }}
                        slotProps={{
                          htmlInput: {
                            ...params.inputProps,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Box> */}

          {/* <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder={`${
                    isAddFacility ? "facility" : "organization"
                  } name`}
                  size="large"
                  value={organizationName}
                  setValue={setOrganizationName}
                  type={appMeta.textFieldTypes.TEXT}
                  handleChange={handleChangeOrganizationName}
                  isRequired={true}
                />
                <CustomInputTextField
                  placeholder={`${
                    isAddFacility ? "facility" : "organization"
                  } domain`}
                  size="large"
                  value={domain}
                  setValue={setDomain}
                  type={appMeta.textFieldTypes.TEXT}
                  handleChange={handleChangeDomain}
                  isValid={isValidDomain}
                  setIsValid={setIsValidDomain}
                  isRequired={true}
                />
              </Box> */}
          {/* {isAddFacility === false && (
                <Box style={styles.mainDiv}>
                  <Box sx={{ minWidth: 230 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Facility name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={facilityName}
                        label="Facility Name"
                        onChange={handleChangeFacilityName}
                      >
                        {organizationList?.map((data) => {
                          return (
                            <MenuItem value={data.org_name}>
                              {data.org_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 230 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Facility ID
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={facilityId}
                      label="Facility ID"    
                      onChange={handleChangeFacilityId}
                    >
                      {organizationList.map((data) => {
                        return <MenuItem value={data.id}>{data.id}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box>
                </Box>
              )} */}

          {/* <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="Full name"
                  size="large"
                  value={fullName}
                  setValue={setFullName}
                />
                <CustomInputTextField
                  placeholder="Phone"
                  size="large"
                  value={phone}
                  setValue={setPhone}
                  // handleChange={handleChangePincode}
                  type={appMeta.textFieldTypes.PHONE}
                  isValid={isValidPhone}
                  setIsValid={setIsValidPhone}
                  isHintRequired={true}
                />
              </Box>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="Email"
                  size="large"
                  value={email}
                  setValue={setEmail}
                  type={appMeta.textFieldTypes.EMAIL}
                  isHintRequired={true}
                  isValid={isValidEmail}
                  setIsValid={setIsValidEmail}
                />
                <CustomInputTextField
                  placeholder="Password"
                  size="large"
                  value={password}
                  setValue={setPassword}
                  handleChange={handleChangePincode}
                  type={appMeta.textFieldTypes.PASSWORD}
                  isValid={isValidPassword}
                  setIsValid={setIsValidPassword}
                  isHintRequired={true}
                />
              </Box>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="Area Name"
                  size="large"
                  value={areaName}
                  setValue={setAreaName}
                  handleChange={handleChangeAreaName}
                  isRequired={true}
                />
                <CustomInputTextField
                  placeholder="Pincode"
                  size="large"
                  value={pincode}
                  setValue={setPincode}
                  handleChange={handleChangePincode}
                  type={appMeta.textFieldTypes.PINCODE}
                  isValid={isValidPincode}
                  setIsValid={setIsValidPincode}
                  isHintRequired={true}
                  isRequired={true}
                />
              </Box>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="State"
                  size="large"
                  value={state}
                  setValue={setState}
                  handleChange={handleChangeState}
                  isRequired={true}
                />
                <CustomInputTextField
                  placeholder="District"
                  size="large"
                  value={district}
                  setValue={setDistrict}
                  handleChange={handleChangeDistrict}
                  isRequired={true}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Paper elevation={23} sx={{ padding: "10px" }}>
                  <Box>
                    <Typography>
                      {isAddFacility ? "FACILITY" : "ORGANIZATION"} ICON UPLOAD
                    </Typography>
                    <Tooltip title="Upload Icon File" position="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadFacilityClick}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                        {facilityIconUrl !== "" && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => dispatch(getFiles(facilityIconUrl))}
                            edge="end"
                          >
                            <GetAppRoundedIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Tooltip>
                    {facilityIconUrl !== "" && (
                      <Chip label="Upload Success" color="success" />
                    )}
                    <input
                      type="file"
                      ref={facilityIconRef}
                      style={{ display: "none" }}
                      onChange={handleChangeFacilityIcon}
                    />
                  </Box>
                </Paper>
                <Paper elevation={23} sx={{ padding: "10px" }}>
                  <Box>
                    <Typography>
                      {" "}
                      {isAddFacility ? "FACILITY" : "ORGANIZATION"} IMAGE UPLOAD
                    </Typography>
                    <Tooltip title="Upload Icon File" position="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadFacilityClickImg}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                        {facilityImgUrl !== "" && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => dispatch(getFiles(facilityImgUrl))}
                            edge="end"
                          >
                            <GetAppRoundedIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Tooltip>
                    {facilityImgUrl !== "" && (
                      <Chip label="Upload Success" color="success" />
                    )}
                    <input
                      type="file"
                      ref={facilityImgRef}
                      style={{ display: "none" }}
                      onChange={handleChangeFacilityImg}
                    />
                  </Box>
                </Paper>
              </Box> */}
        </>
      </CustomDrawer>
      {/* </DialogContent> */}
      {/* <DialogActions>
            <CustomButton
              label={`Add ${isAddFacility ? "facility" : "organization"}`}
              rounded={true}
              size="medium"
              handleClick={handleAddOrganizationClick}
              variant="contained"
            />
            <CustomButton
              label="Close"
              size="medium"
              handleClick={handleClose}
              color="error"
            />
          </DialogActions> */}

      {/* Organization table component call */}

      <>
        <OrganizationDetailsList
          organizationDetails={organizationDetail}
          organizationList={organizationList}
          addOrganizationDialog={addOrganizationDialog}
          setAddOrganizationDialog={setAddOrganizationDialog}
          isAddFacility={isAddFacility}
          setIsAddFacility={setIsAddFacility}
          pageNumber={pageNumber}
          pageSize={pageSize}
          isActive={isActive}
          ascOrDesc={ascOrDesc}
          lastPageNumber={lastPageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          setIsActive={setIsActive}
          setAscOrDesc={setAscOrDesc}
          facilityId={facilityId}
          setFacilityId={setFacilityId}
          setFacilityName={setFacilityName}
          handleFacilityFilterClose={handleFacilityFilterClose}
          handleFacilitySortClose={handleFacilitySortClose}
          getAllOrganization={getAllOrganization}
          setRoleSearch={setRoleSearch}
          listOfRoles={listOfRoles}
        />
      </>
    </Box>
  );
}

export default OrganizationDetails;
