import React, { useEffect, useState } from "react";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomFileUploadButton from "../../../Components/CustomComp/CustomFileUploadButton";
import UploadIcon from "@mui/icons-material/Upload";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
import CustomDialog from "../../../Components/CustomComp/CustomDialog";
import WfsStockDetail from "./WfsStockDetail";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box } from "@mui/material";

function WfsTable({
  setOpenAddWfsDialog,
  setOpenEditWfsDialog,
  setPageNumber,
  setPageSize,
  setFilterLabel,
  setFilterSearchText,
  setSortLabel,
  setSortOrder,
  wfsList,
  setSelectedRowForEdit,
}) {
  //hooks
  // const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const dispatch = useDispatch();

  //states
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const [stockDetail, setStockDetail] = useState({});

  ////Functions
  const handleWfsFilterClose = ({ value, searchText }) => {
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  const handleWfsSortClose = ({ value, searchText }) => {
    setSortLabel(value);
    setSortOrder(searchText);
  };
  // Add Wfs details
  const handleAddWfs = () => {
    setOpenAddWfsDialog(true);
  };
  // Edit Wfs details
  const handleWfsRowClick = (index, row) => {
    setSelectedRowForEdit(row);
    // setOpenEditWfsDialog(true);
    setOpenAddWfsDialog(true);
  };

  const uploadWaterQuality = (reqObj) => {
    dispatch(
      HandleApiActions({
        ...wfsApis.upload_water_quality,
        params: reqObj,
        show_toast: true,
      })
    );
  };

  const handleStockCheck = (row) => {
    setOpenStockDialog(true);
    setStockDetail(row.master_stock_records);
  };

  return (
    <>
      <CustomTable
        tableHead={[
          "Company",
          "Facility",
          "",
          "PAN",
          "Phone",
          "Created",
          "StoInchargeck",
          "Upload report",
        ]}
        tableData={wfsList}
        tableDataKey={[
          "wfs_name",
          "facility_name",
          "wfs_incharge.name",
          "wfs_details_json.company_pan_number",
          "wfs_incharge.phone_number",
          {
            rowClickEnable: true,
            component: (props) => {
              return moment(props?.row?.created_at).format("DD-MM-YYYY");
            },
          },

          {
            component: ({ row }) => {
              return (
                <CustomButton
                  startIcon={<ShoppingCartIcon />}
                  handleClick={() => {
                    handleStockCheck(row);
                  }}
                  label="Stock"
                />
              );
            },
          },
          {
            component: (props) => {
              return (
                <CustomFileUploadButton
                  field={{ name: "uploadWaterReport" }}
                  hideDownloadButton={true}
                  formData={{
                    uploadWaterReport: "",
                  }}
                  handleInputChange={(name, value) => {
                    uploadWaterQuality({
                      water_quality_file: value,
                      wfs_id: props.row.wfs_id,
                    });
                  }}
                  uploadButton={<UploadIcon />}
                />
              );
            },
          },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "wfs_name", label: "Company" },
          { value: "phone_number", label: "Phone" },
        ]}
        handleFilterClose={handleWfsFilterClose}
        sort={true}
        sortSelectOption={[
          { value: "created_at_sort", label: "Created at" },
          { value: "wfs_name_sort", label: "WFS name" },
          { value: "facility_name_sort", label: "Facility name" },
        ]}
        handleSortClose={handleWfsSortClose}
        addButton={true}
        handleOnClickAddButton={handleAddWfs}
        tableRowClickFunction={handleWfsRowClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        ////////
        isCardViewEnable={true}
        cardHeight="200px"
        cardTitle={{ label: "WFS name", key: "wfs_name" }}
        cardSubTitle={{ label: "Incharge", key: "wfs_incharge.name" }}
        cardInput={(row) => [
          {
            data: [
              {
                label: "PAN",
                value: row.wfs_details_json.company_pan_number,
              },
              {
                label: "Phone",
                value: row.wfs_incharge.phone_number,
              },
            ],
          },
          {
            data: [
              {
                label: "Created At",
                value: (row) => {
                  return moment(row?.created_at).format("DD-MM-YYYY");
                },
              },
              {
                label: "Upload",
                value: (row) => {
                  return (
                    <Box sx={{ marginTop: "-5px", marginLeft: "-1rem" }}>
                      <CustomFileUploadButton
                        field={{ name: "uploadWaterReport" }}
                        hideDownloadButton={true}
                        formData={{
                          uploadWaterReport: "",
                        }}
                        handleInputChange={(name, value) => {
                          uploadWaterQuality({
                            water_quality_file: value,
                            wfs_id: row.wfs_id,
                          });
                        }}
                        uploadButton={<UploadIcon />}
                      />
                    </Box>
                  );
                },
              },
            ],
          },
        ]}
        cardButton={[
          {
            label: "Edit",
            color: "blue",
            handleClick: (row) => {
              handleWfsRowClick(0, row);
            },
          },
          {
            label: "Stock ",
            color: "blue",
            handleClick: (row) => {
              handleStockCheck(row);
            },
          },
        ]}
        isFormViewEnable={true}
        formSearchOptionKey="wfs_name"
        formSearchLabel="Enter WFS"
        formInput={[
          { label: "Wfs Name", value: "wfs_name" },
          { label: "Facility Name", value: "facility_name" },
          { label: "Incharge", value: "wfs_incharge.name" },
          { label: "Incharge Phone", value: "wfs_incharge.phone_number" },
          { label: "Incharge Email", value: "wfs_incharge.email" },
          { label: "Facility Name", value: "facility_name" },
          {
            label: "Upload",
            value: (row) => {
              return (
                <Box sx={{ marginTop: "-5px", marginLeft: "-1rem" }}>
                  <CustomFileUploadButton
                    field={{ name: "uploadWaterReport" }}
                    hideDownloadButton={true}
                    formData={{
                      uploadWaterReport: "",
                    }}
                    handleInputChange={(name, value) => {
                      uploadWaterQuality({
                        water_quality_file: value,
                        wfs_id: row.wfs_id,
                      });
                    }}
                    uploadButton={<UploadIcon />}
                  />
                </Box>
              );
            },
          },
        ]}
        formButton={[
          {
            label: "Edit",
            color: "blue",
            handleClick: (row) => {
              handleWfsRowClick(null, row);
            },
          },
          {
            label: "Stock ",
            color: "blue",
            handleClick: (row) => {
              handleStockCheck(row);
            },
          },
        ]}
      />

      <CustomDialog
        open={openStockDialog}
        onClose={() => {
          setOpenStockDialog(false);
        }}
        title={"Stock Details"}
      >
        <WfsStockDetail
          stockDetail={stockDetail}
          setOpenStockDialog={setOpenStockDialog}
        />
      </CustomDialog>
    </>
  );
}

export default WfsTable;
