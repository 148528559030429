import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../../Components/CustomComp/CustomSelect";

function QRConfiguration({ masterConfigObject, setMasterConfigObject }) {
  //state
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomSelect
          name="linking_qr_barcode"
          placeHolder="Select QR Type"
          defaultValue={
            masterConfigObject?.qr_settings?.linking_qr_barcode?.only_qrcode
              ? "only_qrcode"
              : "qr_and_bar"
          }
          input={[
            {
              menuItem: "QR",
              value: "only_qrcode",
            },
            {
              menuItem: "QR and Barcode",
              value: "qr_and_bar",
            },
          ]}
          getNameAndValueOnChange={(name, value) => {
            setMasterConfigObject((prev) => ({
              ...prev,
              qr_settings: {
                ...prev.qr_settings,
                linking_qr_barcode: {
                  ...prev.qr_settings.linking_qr_barcode,
                  [value === "only_qrcode"
                    ? "only_qrcode"
                    : "qr_and_bar"]: true,
                  [value === "only_qrcode"
                    ? "qr_and_bar"
                    : "only_qrcode"]: false,
                },
              },
            }));
          }}
        />

        <CustomSelect
          name="is_url"
          placeHolder="Select QR Value type"
          defaultValue={masterConfigObject?.qr_settings?.is_url}
          input={[
            {
              menuItem: "URL",
              value: true,
            },
            {
              menuItem: "ID",
              value: false,
            },
          ]}
          getNameAndValueOnChange={(name, value) => {
            setMasterConfigObject((prev) => ({
              ...prev,
              qr_settings: {
                ...prev.qr_settings,
                [name]: value, // Update the specific field based on the name
              },
            }));
          }}
        />

        <CustomSelect
          name="privacy"
          defaultValue={masterConfigObject?.qr_settings?.privacy}
          placeHolder="Water Quality page access"
          input={[
            {
              menuItem: "Everyone",
              value: false,
            },
            {
              menuItem: "Registered Users only",
              value: true,
            },
          ]}
          // defaultValue={appData.httpMethodApiAddSelectOption
          getNameAndValueOnChange={(name, value) => {
            setMasterConfigObject((prev) => ({
              ...prev,
              qr_settings: {
                ...prev.qr_settings,
                [name]: value, // Dynamically update the `privacy` property
              },
            }));
          }}
        />
      </Box>
    </Box>
  );
}

export default QRConfiguration;
