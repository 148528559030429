import { Box, Chip, TextField, Typography } from "@mui/material";
import React from "react";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";
import CustomForm from "../../Components/CustomComp/CustomForm";
import { GetPincodeAreas } from "../../Redux/Actions/pincodeAreasAction";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { Add } from "@mui/icons-material";

function DistributorUpdateProfileCompany(props) {
  const dispatch = useDispatch();
  const addUserRolesDomainDetails = useSelector(
    (state) => state.addUserRolesDomainDetails
  );
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);
  console.log("---allPincodesAndAreas------", allPincodesAndAreas);
  const [distributors, setDistributors] = React.useState([]);
  const [searchCountry, setSearchCountry] = React.useState("");
  console.log("searchCountry", searchCountry);
  const [searchUser, setSearchUser] = React.useState("");
  const [searchPincode, setSearchPincode] = React.useState("");
  const [singlePincode, setSinglePincode] = React.useState("");

  const [storePincodesForChips, setStorePincodesForChips] = React.useState([]);

  console.log("searchPincode", searchPincode);
  console.log(
    "addUserRolesDomainDetails",
    addUserRolesDomainDetails.userDetails
  );

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_user_list,
        // params: {
        //   page_number: 1,
        //   page_size: 25,
        // },
        show_toast: false,
      })
    );
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_country_codes,
        show_toast: false,
      })
    );
    dispatch(GetPincodeAreas());
  }, []);

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    const Distributorusers = addUserRolesDomainDetails.userDetails?.filter(
      (item) => item?.role_details?.role_name === "DISTRIBUTOR"
    );
    console.log("Distributorusers", Distributorusers);
    setDistributors(Distributorusers);
  }, [addUserRolesDomainDetails.userDetails]);

  const handleChangeUser = (value) => {
    console.log("value", value);
    setSearchUser(value);
  };
  const handleSubmit = (data) => {
    console.log("props", data);
    const reqobj = {
      name: data.name,
      user_id: data.user_id,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      city: data.city,
      state: data.state,
      country_code: data.country_code,
      pincode_id: data.pincode_id,
      delivering_pincodes: storePincodesForChips.map((obj) => obj.id),
    };

    props.handleSubmit(reqobj);
  };
  const handleClose = () => {
    props.handleClose();
  };

  const handleChangePincode = (value) => {
    setSearchPincode(value);
  };
  // const handleAddPincodes = () => {
  //   setStorePincodes((prev) => [...prev, searchPincode]);
  // };
  const handleAddPincodes = () => {
    console.log("---handleAddPincodes---", searchPincode);

    allPincodesAndAreas.map((obj) => {
      if (obj.id === searchPincode) {
        setStorePincodesForChips((prev) => [
          ...prev,
          { pincode: obj.pincode, id: obj.id },
        ]);
      }
    });
  };

  const handleDeleteDeliveryPincode = (index) => {
    console.log(
      "---handleDeleteDeliveryPincode--",
      index,
      storePincodesForChips
    );

    setStorePincodesForChips((prev) =>
      prev.filter((obj) => {
        if (obj.id !== storePincodesForChips[index].id) {
          return true;
        } else return false;
      })
    );
    console.log("--deletedDeliveryPincode-", storePincodesForChips);
  };

  return (
    <CustomDrawer
      open={props.openupdatecompanyprofile}
      title="Add Distributor Company Profile"
    >
      {" "}
      {/* <Box>
        <Typography sx={{ textAlign: "center", fontWeight: 600, mt: "20px" }}>
          ASSIGN PINCODES TO DISTRIBUTOR
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CustomAutoComplete
          field={{
            optionKey: "pincode",
            outputKey: "id",
            searchAndUpdateAPI: (searchText) => {
              setSearchPincode(searchText);
            },
            input: allPincodesAndAreas || [],
            onChange: (name, value) => {
              handleChangePincode(value);
            },
            name: "id",
            label: "Enter Pincode",
          }}
        />
        <CustomButton label="add" handleClick={handleAddPincodes} />
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CustomForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          // configConfirmation={{
          //   isConfirmationRequired: true,
          //   dialogContent: "Are you sure you want to update changes?",
          // }}
          data={[
            {
              data: [
                {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "fullname",
                  outputKey: "user_id",
                  searchAndUpdateAPI: (searchText) => {
                    setSearchUser(searchText);
                  },
                  input: distributors,
                  name: "user_id",
                  label: "Enter Distributor Name",
                },

                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Company name",
                  defaultValue: "",
                  name: "name",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "address_line1",
                  defaultValue: "",
                  name: "address_line1",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },

                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "address_line2",
                  defaultValue: "",
                  name: "address_line2",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "City",
                  defaultValue: "",
                  name: "city",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "State",
                  defaultValue: "",
                  name: "state",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "pincode",
                  outputKey: "id",
                  searchAndUpdateAPI: (searchText) => {
                    setSinglePincode(searchText);
                  },
                  input: allPincodesAndAreas || [],
                  name: "pincode_id",
                  label: "Enter Pincode ",
                },
                {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "time_zone",
                  outputKey: "country_code",
                  searchAndUpdateAPI: (searchText) => {
                    setSearchCountry(searchText);
                  },
                  input: countryCodes,
                  name: "country_code",
                  label: "Enter country ",
                },
                {
                  component: (formData, setFormData) => {
                    let arr = [];
                    let text = "";
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              mt: "20px",
                            }}
                          >
                            ASSIGN PINCODES TO DISTRIBUTOR
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            // ml: "70px",
                          }}
                        >
                          <CustomAutoComplete
                            field={{
                              optionKey: "pincode",
                              outputKey: "id",
                              searchAndUpdateAPI: (searchText) => {
                                setSearchPincode(searchText);
                              },
                              input: allPincodesAndAreas || [],
                              onChange: (name, value) => {
                                handleChangePincode(value);
                              },
                              name: "id",
                              label: "Enter Pincode",
                            }}
                          />
                          <CustomButton
                            label="add"
                            startIcon={<Add />}
                            handleClick={handleAddPincodes}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
                        >
                          {storePincodesForChips?.map((pincode, index) => (
                            <Chip
                              label={pincode.pincode}
                              onDelete={() => {
                                handleDeleteDeliveryPincode(index);
                              }}
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      </Box>
                      // <Box sx={{ height: "100px" }}>
                      //   <TextField
                      //     onChange={(e) => {
                      //       text = e.target.value;
                      //     }}
                      //   />
                      //   <CustomButton
                      //     handleClick={() => {
                      //       arr.push(text);
                      //       setFormData({
                      //         ...formData,
                      //         test: arr,
                      //       });
                      //     }}
                      //     label={"Click"}
                      //   />
                      // </Box>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </CustomDrawer>
  );
}

export default DistributorUpdateProfileCompany;
