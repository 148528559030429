import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";

function DistributorStockMain(props) {
  const dispatch = useDispatch();
  const StockDetails = useSelector(
    (state) => state.userManagementReducer.distributor_stock
  );
  console.log("StockDetails", StockDetails);
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_stock,
        show_toast: false,
      })
    );
  }, []);
  const [displayData, setDisplayData] = React.useState([
    { header: "container material :", data: StockDetails.container_material },
    { header: "container capacity:", data: StockDetails.container_capacity },
    { header: "empty containers:", data: StockDetails.empty_containers },
    { header: "filled containers :", data: StockDetails.filled_containers },
    {
      header: "physically damaged:",
      data: StockDetails.physically_damaged_container,
    },
    {
      header: "qr bar code damaged containers:",
      data: StockDetails.qr_barcode_damaged_container,
    },
    {
      header: "new containers to get:",
      data: StockDetails.new_containers_to_get,
    },
    { header: "ongoing containers:", data: StockDetails.ongoing_containers },
    { header: "delivery containes:", data: StockDetails.delivery_containers },
  ]);
  React.useEffect(() => {
    setDisplayData(() => [
      { header: "container material :", data: StockDetails.container_material },
      { header: "container capacity:", data: StockDetails.container_capacity },
      { header: "empty containers:", data: StockDetails.empty_containers },
      { header: "filled containers :", data: StockDetails.filled_containers },
      {
        header: "physically damaged:",
        data: StockDetails.physically_damaged_container,
      },
      {
        header: "qr bar code damaged containers:",
        data: StockDetails.qr_barcode_damaged_container,
      },
      {
        header: "new containers to get:",
        data: StockDetails.new_containers_to_get,
      },
      { header: "ongoing containers:", data: StockDetails.ongoing_containers },
      { header: "delivery containes:", data: StockDetails.delivery_containers },
    ]);
  }, []);
  return (
    <Box>
      {StockDetails !== undefined &&
        StockDetails !== null &&
        StockDetails?.map((data) => {
          return (
            <Card
              elevation={10}
              sx={{
                mb: 4,
                //   "&:hover": {
                //     boxShadow: "0px 0px 5px 5px rgb(100, 149, 237)",
                //   },
                "&:hover": {
                  transform: "scale(1.02)", // Enlarge the card on hover
                  // boxShadow: theme.shadows[10], // Change shadow on hover
                  boxShadow: "10px 20px #888888",
                },
                marginTop: "20px",
                borderRadius: "20px",
                border: "1.5px solid  #888888",
                ml: "30px",
                mr: "30px",
                boxShadow: "10px 20px #888888",
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Container Material
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.container_material}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Container Capacity
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.container_capacity}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Empty Container
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.empty_containers}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Filled Container
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.filled_containers}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Physically Damaged Containers
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.physically_damaged_containers}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Qr/BarCode Damage Containers
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.qr_bar_code_damaged_containers}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      New Containers to Get
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.new_containers_to_get}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Ongoing Containers
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.ongoing_containers}
                  </Grid>
                  <Grid item md={3} sm={12} xs={6}>
                    <Typography
                      sx={{
                        color: "blue",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Delivery Containers
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={12} xs={6} sx={{ textAlign: "left" }}>
                    : {data.delivery_containers}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
    </Box>
  );
}

export default DistributorStockMain;
