import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";

function CustomSelect({
  name,
  placeHolder,
  input = [],
  handleChange,
  getNameAndValueOnChange,
  defaultValue,
  variant = "standard",
  sx,
  field,
}) {
  const handleOnChange = (event) => {
    getNameAndValueOnChange(name, event.target.value);
    field?.onChange && field.onChange(name, event.target.value);
  };
  return (
    <Box>
      <FormControl sx={{ width: "25ch", ...sx }} variant={variant} size="small">
        <InputLabel id="demo-select-small-label">
          {placeHolder || name}
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          name={placeHolder || name}
          value={defaultValue}
          label={placeHolder}
          onChange={getNameAndValueOnChange ? handleOnChange : handleChange}
        >
          {input?.map(({ value, menuItem }, index) => {
            return <MenuItem value={value}>{menuItem}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default CustomSelect;
