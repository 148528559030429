import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import IOTHomePage from "./IOTHomePage";
import RecycleHomePage from "./RecycleHomePage";
import { useTheme } from "@emotion/react";
import { tokens } from "../../AppMeta/theme";
import CustomTab from "../../Components/CustomComp/CustomTab/CustomTab";

function Home() {
  //hooks
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ padding: "1rem" }}>
      <CustomTab
        bgColor="#d4e2ea"
        defaultValue="recycle"
        input={[
          {
            value: "recycle",
            label: "Recycle",
            component: <RecycleHomePage />,
          },
          {
            value: "iot",
            label: "IOT",
            component: <IOTHomePage />,
          },
        ]}
      />
    </Box>
  );
}

export default Home;
