import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import LineChart from "./LineChart";
import HomeDataCard from "./HomeDataCard";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBottleWater } from "@fortawesome/free-solid-svg-icons";
import GaugeChart from "./GaugeChart";
import HomeOrderCards from "./HomeTwoLayerCards";
import CustomDatePicker from "./CustomDatePicker";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";
function IOTGraph() {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex", mt: "15px", gap: "10px" }}>
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              // searchAndUpdateAPI: (searchText) => {
              //   setFacilitySearch(searchText);
              // },
              // input: rolesList,
              name: "role_1",
              label: "Search Facility",
            }}
            // handleInputChange={((name, selectedOption), {})}
          />
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              // searchAndUpdateAPI: (searchText) => {
              //   setWfsSearch(searchText);
              // },
              // input: rolesList,
              name: "role_1",
              label: "Search Wfs",
            }}
            // handleInputChange={((name, selectedOption), {})}
          />
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              // searchAndUpdateAPI: (searchText) => {
              //   setWfsSearch(searchText);
              // },
              // input: rolesList,
              name: "role_1",
              label: "Search Device ID",
            }}
            // handleInputChange={((name, selectedOption), {})}
          />
        </Box>
        <CustomDatePicker
          handleChange={(date) => {
            console.log("---date---", date);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        <Box
          component={Paper}
          elevation={15}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            padding: "2rem",
          }}
        >
          <Box component={Paper} elevation={15}>
            <LineChart />
          </Box>
          <Box
            component={Paper}
            elevation={15}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",

              height: "300px",
              paddingX: "2rem",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, textAlign: "left" }}
            >
              TDS
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <GaugeChart value={250} valueMax={500} fillColor={"#a3dc9a"} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default IOTGraph;
