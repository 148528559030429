import actionTypes from "../actionTypes";

export const wfsApis = {
  get_wfs_list: {
    api_name: "/PSCM/wfs",
    https_method: "GET",
    action_type: actionTypes.GET_WFS_LIST,
    description: "",
  },
  add_wfs: {
    api_name: "/PSCM/wfs/create",
    https_method: "POST",
    action_type: actionTypes.ADD_WFS,
    description: "",
  },
  update_wfs: {
    api_name: "/PSCM/wfs/update",
    https_method: "PUT",
    action_type: actionTypes.UPDATE_WFS,
    description: "",
  },
  get_lines: {
    api_name: "PSCM/getLineIdandIotDeviceNumber",
    https_method: "GET",
    action_type: actionTypes.GET_LINES_WFS,
    description: "",
  },
  upload_water_quality: {
    api_name: "/PSCM/upload/water_quality/report",
    https_method: "PUT",
    action_type: actionTypes.UPLOAD_WATER_QUALITY,
    description: "",
  },
};
