import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";

function CustomRadio({ label, input, onChange, section, defaultValue }) {
  const [radioValue, setRadioValue] = useState(
    defaultValue !== "" ? defaultValue : input[0].value
  );
  const handleChange = (event) => {
    onChange(event.target.name, event.target.value, section);
    setRadioValue(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={radioValue}
        onChange={handleChange}
      >
        {input?.map((radioObj) => {
          return (
            <FormControlLabel
              name={radioObj.name}
              value={radioObj.value}
              control={<Radio />}
              label={radioObj.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default CustomRadio;
