/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Custom input field which is used all over the order
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import appMeta from "../../AppMeta/appMeta";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomInputTextField = (props) => {
  // Default values for isValid and setIsValid
  const [fieldInput, setFieldInput] = React.useState(props.value);
  const [hint, setHint] = React.useState(false);
  const [hintText, setHintText] = React.useState("Incorrect entry.");
  const [textFieldType, setTextFieldType] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);

  const [isValidDefault, setIsValidDefault] = React.useState(true);

  const setDataForVariables = (state) => {
    // console.log(state);
    setIsValidDefault(state);
    if (props.isHintRequired) setHint(!state);
  };

  const handleChange = (event) => {
    const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegEx = /^[6-9][0-9]{9}$/;
    const pinCodeRegEx = /^[1-9][0-9]{5}$/;
    const numberRegEx = /^[0-9]*$/;
    const passwordRegEx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const isiRegEx = /^\d{7}$/;
    const fssaiRegEx = /^\d{14}$/;
    const aadharRegEx = /^\d{12}$/;
    const gstRegEx = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
    const panRegEx = /^[A-Z]{5}\d{4}[A-Z]{1}$/;

    const data = event.target.value + "";
    const name = event.target.name;
    if (props.type === appMeta.textFieldTypes.PHONE && data.length < 11) {
      // props.setValue(data);
      setFieldInput(data);
      if (phoneRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid phone number");
        setDataForVariables(false);
      }
    } else if (
      props.type === appMeta.textFieldTypes.PINCODE &&
      data.length <= 6
    ) {
      setFieldInput(data);
      if (pinCodeRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid PINCODE");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.EMAIL) {
      // props.setValue(data);
      setFieldInput(data);
      if (emailRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid email");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.NUMBER) {
      // props.setValue(data);
      setFieldInput(data);
      if (numberRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid number");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.PASSWORD) {
      // Max Limit Exceedsc
      setFieldInput(data);
      if (passwordRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText(
          "Password should have Upper, Lower, Number, special character"
        );
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.PERCENTAGE) {
      if (parseInt(data) <= 100 || data === "") {
        setFieldInput(data);
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Percent should be less than 100");
        console.log(false, data);
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.ISI) {
      // props.setValue(data);
      setFieldInput(data);
      if (isiRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid 7 digit ISI number");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.FSSAI) {
      // props.setValue(data);
      setFieldInput(data);
      if (fssaiRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid 14 digit FSSAI number");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.AADHAR) {
      // props.setValue(data);
      setFieldInput(data);
      if (aadharRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid 12 digit Aadhar number");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.GST) {
      // props.setValue(data);
      setFieldInput(data);
      if (gstRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid GST number");
        setDataForVariables(false);
      }
    } else if (props.type === appMeta.textFieldTypes.PAN) {
      // props.setValue(data);
      setFieldInput(data);
      if (panRegEx.test(data)) {
        props.onChange(name, data, props.section);
        setDataForVariables(true);
      } else {
        setHintText("Enter valid PAN number");
        setDataForVariables(false);
      }
    } else if (
      props.type !== appMeta.textFieldTypes.PINCODE &&
      props.type !== appMeta.textFieldTypes.PHONE &&
      props.type !== appMeta.textFieldTypes.NUMBER &&
      props.type !== appMeta.textFieldTypes.EMAIL
    ) {
      // props.setValue(data);
      setFieldInput(data);
      props.onChange(name, data, props.section);
      setDataForVariables(true);
    }
  };

  useEffect(() => {
    if (props.type === appMeta.textFieldTypes.DATE) {
      setTextFieldType("date");
    }
    if (props.type === appMeta.textFieldTypes.PASSWORD) {
      setTextFieldType("password");
    }
  }, []);
  useEffect(() => {
    if (props.error) {
      setHint(true);
      setHintText("Please fill all mandatory fields");
    } else {
      setHint(false);
      setHintText("");
    }
  }, [props.error]);

  return (
    <div className="card flex justify-content-center">
      <TextField
        size="small"
        value={fieldInput}
        name={props.name}
        variant="standard"
        sx={{
          minWidth:
            appMeta.textFieldTypes.PASSWORD === props.type ? "20ch" : "25ch",
          // "& input[type=date]::-webkit-datetime-edit-text": {
          //   WebkitAppearance: "none",
          //   display: "none",
          // },
          // "& input[type=date]::-webkit-datetime-edit-month-field": {
          //   WebkitAppearance: "none",
          //   display: "none",
          // },
          // "& input[type=date]::-webkit-datetime-edit-day-field": {
          //   WebkitAppearance: "none",
          //   display: "none",
          // },
          // "& input[type=date]::-webkit-datetime-edit-year-field": {
          //   WebkitAppearance: "none",
          //   display: "none",
          // },
        }}
        onChange={handleChange}
        onPaste={handleChange}
        error={!isValidDefault || props.error} // Use default value for isValid
        label={props.placeholder}
        helperText={hint ? hintText : ""}
        inputProps={{
          maxLength:
            props.type === "phone" ? 10 : props.type === "pincode" ? 6 : 35,
        }}
        disabled={props.disabled}
        required={props.isRequired}
        type={textFieldType}
        InputProps={{
          endAdornment: appMeta.textFieldTypes.PASSWORD === props.type && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => {
                  setShowPassword(!showPassword);
                  setTextFieldType(showPassword ? "password" : "");
                }}
                sx={{
                  width: "2ch",
                }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: appMeta.textFieldTypes.PHONE === props.type && (
            <InputAdornment position="start">{props.phoneCode}</InputAdornment>
          ),
        }}
      />
      {/* <Typography sx={{ color: "red" }}>{props.error && hintText}</Typography> */}
    </div>
  );
};

export default CustomInputTextField;
