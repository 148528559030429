/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Table template component. props for custom table includes
 *      tableData={userDetailsList}
        tableHead={["Full Name", "Roll Name", "Phone", "Organisation"]}
        tableDataKey={["fullname", "role_name", "phone", "org_name", 
        {   
            rowClickEnable:true,
            component: (props) =>{<Input />}}]
        }
        isCollapsable={true}
         collapseTableHead={["Name", "Capacity", "Material"]}
          collapseTableDataKey={[
            "product_details.name",
            "product_details.capacity",
            "product_details.material",
          ]}
          isCollapseViewVertical={true}
        filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          {
            type: "select",
            value: "is_active",
            label: "Status",
            input: [
              { value: true, menuItem: "Active" },
              { value: false, menuItem: "In active" },
            ],
          },,
        ]}
          handleFilterClose ---- Function returns filterdata as key value
        sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose ------ Function returns sortData as key value
        addButton={true}
        handleOnClickAddButton={() => setAddUserDialog(true)}
        isCardViewEnable = {true}
        cardHeight="200px"
        cardTitle={{ label: "Batch ID", key: "batch_id" }}
        cardSubTitle={{ label: "Container", key: "container_capacity" }}
         cardInput={(row) => [
                    {
                      data: [
                        { label: "Line ID", value: row.line_id },
                        { label: "Sim Number", value: row.sim_number },
                      ],
                    },
                    {
                      data: [
                        {
                          label: "Manufacture Date",
                          value: () => moment(row.mft_date).format("DD-MM-YYYY"),
                        },
                      ],
                    },
                  ]}
          cardButton={(row)=>[
           {
                          startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                          label: "Link",
                          handleClick: handleLinking,
                        },
          { func:(row)=>{} }, 
        ]}
         isFormViewEnable={true}
        formSearchOptionKey="wfs_name"
        formSearchLabel="Enter WFS"
        formInput={(row)=>[
          { label: "Wfs Name", value: "wfs_name" },
          { label: "Wfs Name", value: (row)=>{} }, 
          ]}
          formButton={(row)=>[{
            label: "Edit",
            color: "blue",
            handleClick: (row) => {
              handleWfsRowClick(null, row);
            },
          },
          ]}

 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */

import {
  Box,
  IconButton,
  Paper,
  TablePagination,
  Typography,
  Collapse,
  MenuItem,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "./CustomFilter";
import CustomAddButton from "./CustomAddButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import CustomSelect from "./CustomSelect";
import CustomCard from "./CustomCard";
import CustomListView from "./CustomListView";
import CustomAutoComplete from "./CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";

function CustomTable({
  tableHead = [],
  tableDataKey = [],
  tableData = [],
  isCollapsable,
  collapseTableHead,
  collapseTableDataKey,
  isCollapseViewVertical,
  tableRowClickFunction,
  pagination = true,
  filter = false,
  filterSelectOption,
  handleFilterClose,
  sort = false,
  sortSelectOption,
  handleSortClose,
  addButton = false,
  handleOnClickAddButton,
  setPageNumber,
  setPageSize,
  addButtonLabel,
  isCardViewEnable = false,
  cardHeight = "180px",
  cardTitle,
  cardSubTitle,
  cardInput,
  cardButton,
  isFormViewEnable = false,
  formSearchOptionKey,
  formSearchOptionKey2,
  formSearchOutputKey,
  formSearchLabel,
  formInput,
  formButton,
  tableView,
  defaultView = "table",
}) {
  //declaration for PageNumbers
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openRows, setOpenRows] = useState([]);
  const [view, setView] = useState(defaultView);
  const [search, setSearch] = useState();
  const [formSelectedObject, setFormSelectedObject] = useState();

  //functions for Pagination

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    // setPageSize(rowsPerPage);
    setPageSize(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    // setPageSize(event.target.value);
  };

  const handleToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows.includes(index);
      if (isOpen) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const getValueFormNestedObject = (key, e) => {
    let keys = key?.split(".");
    return keys?.reduce((obj, key) => {
      if (
        typeof obj?.[key] === "object" &&
        obj?.[key] !== null &&
        !Array.isArray(obj?.[key])
      ) {
        return obj?.[key];
      }
      return obj?.[key];
    }, e);
  };

  useEffect(() => {
    tableView && setView(tableView);
  }, [tableView]);

  useEffect(() => {
    view === "form" && setFormSelectedObject(tableData[0]);
  }, [view]);

  useEffect(() => {
    search &&
      handleFilterClose({ value: formSearchOptionKey, searchText: search });
  }, [search]);

  return (
    <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isCardViewEnable === true || isFormViewEnable === true ? (
          <CustomSelect
            name={"Select View"}
            input={[
              { value: "table", menuItem: "Table" },
              {
                ...(isCardViewEnable && { value: "card", menuItem: "Cards" }),
              },
              {
                ...(isFormViewEnable && { value: "form", menuItem: "Form" }),
              },
            ]}
            defaultValue={view}
            handleChange={(e) => {
              setView(e.target.value);
            }}
            sx={{ width: "100px", mt: "9px" }}
          />
        ) : null}
        {filter && (
          <CustomFilter
            name="filter"
            type="filter"
            startIcon={<FilterAltIcon />}
            selectOption={filterSelectOption}
            handleFilterClose={handleFilterClose}
          />
        )}

        {sort && (
          <CustomFilter
            name="Sort"
            type="sort"
            startIcon={<SwapVertIcon />}
            selectOption={sortSelectOption}
            handleFilterClose={handleSortClose}
          />
        )}
        {addButton && (
          <CustomAddButton
            handleOnClickButton={handleOnClickAddButton}
            label={addButtonLabel}
          />
        )}
      </Box>

      {view === "table" && (
        <TableContainer
          component={Paper}
          elevation={3}
          sx={appStyle.general.table.tableContainer}
        >
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {isCollapsable && <TableCell></TableCell>}
                {tableHead?.map((e, i) => {
                  return (
                    <TableCell
                      key={i}
                      sx={appStyle.general.table.headderRowCell}
                    >
                      {e}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData !== undefined &&
              tableData !== null &&
              tableData.length > 0 ? (
                <>
                  {(rowsPerPage > 0
                    ? tableData?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : tableData
                  ).map((e, i) => {
                    return (
                      <>
                        <TableRow
                          key={i}
                          sx={{
                            "&:hover": tableRowClickFunction && {
                              backgroundColor: "#95c1e785",
                              transform: "scale(1.02)",
                              height: "100px",
                              // width: "calc(100% + 20px)",
                            },
                          }}
                        >
                          {isCollapsable && (
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleToggle(e)}
                              >
                                {openRows.includes(e) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          )}
                          {tableDataKey?.map((data, index) => {
                            if (typeof data === "string") {
                              let keys = data?.split(".");
                              //Take value from nested object
                              const value = keys?.reduce((obj, key) => {
                                if (
                                  typeof obj?.[key] === "object" &&
                                  obj?.[key] !== null &&
                                  !Array.isArray(obj?.[key])
                                ) {
                                  return obj?.[key];
                                }
                                return obj?.[key];
                              }, e);
                              return (
                                <TableCell
                                  key={`${i}${index}`}
                                  onClick={() => {
                                    tableRowClickFunction &&
                                      tableRowClickFunction(i, e);
                                  }}
                                  sx={{
                                    ...appStyle.general.table.bodyRowCell,
                                    cursor: tableRowClickFunction && "pointer",
                                  }}
                                >
                                  {value}
                                </TableCell>
                              );
                            } else if (
                              typeof data === "object" &&
                              typeof data.component === "function"
                            ) {
                              return (
                                <TableCell
                                  size="small"
                                  key={`${i}${index}`}
                                  sx={{
                                    ...appStyle.general.table.bodyRowCell,
                                    cursor: data.rowClickEnable && "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      data.rowClickEnable &&
                                      tableRowClickFunction
                                    ) {
                                      tableRowClickFunction(i, e);
                                    }
                                  }}
                                >
                                  {data.component({
                                    disabled: !e[data.enableKey],
                                    row: e,
                                    index: i,
                                  })}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={tableHead.length}
                          ></TableCell>
                        </TableRow>
                        {isCollapsable && (
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={8}
                            >
                              <Collapse
                                in={openRows.includes(e)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  {isCollapseViewVertical ? (
                                    <TableBody>
                                      {collapseTableDataKey?.map(
                                        (data, index) => {
                                          if (typeof data === "string") {
                                            let keys = data?.split(".");
                                            //Take value from nested object
                                            const value = keys?.reduce(
                                              (obj, key) => {
                                                if (
                                                  typeof obj?.[key] ===
                                                    "object" &&
                                                  obj?.[key] !== null &&
                                                  !Array.isArray(obj?.[key])
                                                ) {
                                                  return obj?.[key];
                                                }
                                                return obj?.[key];
                                              },
                                              e
                                            );
                                            return (
                                              <TableRow>
                                                <TableCell>
                                                  {collapseTableHead[index]}
                                                </TableCell>
                                                <TableCell
                                                  key={`${i}${index}`}
                                                  // onClick={() => {
                                                  //   tableRowClickFunction &&
                                                  //     tableRowClickFunction(i, e);
                                                  // }}
                                                  sx={{
                                                    ...appStyle.general.table
                                                      .bodyRowCell,
                                                    // cursor:
                                                    //   tableRowClickFunction &&
                                                    //   "pointer",
                                                  }}
                                                >
                                                  {value}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          } else if (
                                            typeof data === "object" &&
                                            typeof data.component === "function"
                                          ) {
                                            return (
                                              <TableCell
                                                key={`${i}${index}`}
                                                sx={{
                                                  ...appStyle.general.table
                                                    .bodyRowCell,
                                                  cursor:
                                                    data.rowClickEnable &&
                                                    "pointer",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    data.rowClickEnable &&
                                                    tableRowClickFunction
                                                  ) {
                                                    tableRowClickFunction(i, e);
                                                  }
                                                }}
                                              >
                                                {data.component({
                                                  disabled: !e[data.enableKey],
                                                  row: e,
                                                  index: i,
                                                })}
                                              </TableCell>
                                            );
                                          }
                                        }
                                      )}
                                    </TableBody>
                                  ) : (
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          {collapseTableHead?.map((e, i) => {
                                            return (
                                              <TableCell
                                                key={i}
                                                sx={
                                                  appStyle.general.table
                                                    .headderRowCell
                                                }
                                              >
                                                {e}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {collapseTableDataKey?.map(
                                          (data, index) => {
                                            if (typeof data === "string") {
                                              let keys = data?.split(".");
                                              //Take value from nested object
                                              const value = keys?.reduce(
                                                (obj, key) => {
                                                  if (
                                                    typeof obj?.[key] ===
                                                      "object" &&
                                                    obj?.[key] !== null &&
                                                    !Array.isArray(obj?.[key])
                                                  ) {
                                                    return obj?.[key];
                                                  }
                                                  return obj?.[key];
                                                },
                                                e
                                              );
                                              return (
                                                <TableCell
                                                  key={`${i}${index}`}
                                                  // onClick={() => {
                                                  //   tableRowClickFunction &&
                                                  //     tableRowClickFunction(i, e);
                                                  // }}
                                                  sx={{
                                                    ...appStyle.general.table
                                                      .bodyRowCell,
                                                    // cursor:
                                                    //   tableRowClickFunction &&
                                                    //   "pointer",
                                                  }}
                                                >
                                                  {value}
                                                </TableCell>
                                              );
                                            } else if (
                                              typeof data === "object" &&
                                              typeof data.component ===
                                                "function"
                                            ) {
                                              return (
                                                <TableCell
                                                  key={`${i}${index}`}
                                                  sx={{
                                                    ...appStyle.general.table
                                                      .bodyRowCell,
                                                    cursor:
                                                      data.rowClickEnable &&
                                                      "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      data.rowClickEnable &&
                                                      tableRowClickFunction
                                                    ) {
                                                      tableRowClickFunction(
                                                        i,
                                                        e
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {data.component({
                                                    disabled:
                                                      !e[data.enableKey],
                                                    row: e,
                                                    index: i,
                                                  })}
                                                </TableCell>
                                              );
                                            }
                                          }
                                        )}
                                      </TableBody>
                                    </Table>
                                  )}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  <Typography textAlign={"center"}>NO DATA FOUND</Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* cards */}
      {view === "card" && (
        <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {tableData.map((data) => {
            return (
              <CustomCard
                height={cardHeight}
                titleKey={cardTitle?.label}
                title={getValueFormNestedObject(cardTitle?.key, data)}
                subTitleKey={cardSubTitle?.label}
                subTitle={getValueFormNestedObject(cardSubTitle?.key, data)}
                // headerCustomFunctions={[
                //   {
                //     description: "round white board",
                //     func: (row) => {
                //       return <Box>Hi</Box>;
                //     },
                //   },
                // ]}
                input={cardInput && cardInput(data)}
                buttons={cardButton}
                row={data}
              />
            );
          })}
        </Box>
      )}

      {view === "form" && (
        <Box component={Paper} elevation={10} sx={{ padding: "1rem" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CustomAutoComplete
              field={{
                inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                optionKey: formSearchOptionKey,
                optionKey2: formSearchOptionKey2,
                outputKey: formSearchOutputKey,
                searchAndUpdateAPI: (searchText) => {
                  setSearch(searchText);
                },
                input: tableData,
                name: "tableAutoComplete",
                label: formSearchLabel,
              }}
              handleInputChange={(name, selectedOption, obj) => {
                setFormSelectedObject(obj);
              }}
            />
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <CustomListView
              data={formSelectedObject}
              formInput={formInput}
              getValueFormNestedObject={getValueFormNestedObject}
              buttons={formButton}
            />
          </Box>
        </Box>
      )}
      {pagination && view !== "form" && (
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default CustomTable;
