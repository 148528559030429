import React, { createContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Button,
  Box,
  useTheme,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Chip,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import { batch, useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import CustomDialogMoreDetailsWaterFilling from "../../Components/CustomComp/CustomDialogMoreDetailsWaterFilling";
import { EditDialogBatch } from "./EditDialogBatch";

import {
  GetCreateBatch,
  PutRollbackBatch,
  StartBatch,
  StopBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import { CancelDialogBatch } from "./CancelDialogBatch";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomScannerDialog from "../../Components/CustomComp/CustomScannerDialog";
import { ScannerDialog } from "./ScannerDialog";
import { tokens } from "../../AppMeta/theme";
import BadgeIcon from "@mui/icons-material/Badge";
import appStyle from "../../AppMeta/appStyle";
import appFonts from "../../AppMeta/appFonts";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomButton from "../../Components/CustomComp/CustomButton";
import ScanningDialog from "../../Components/CustomComp/ScanningDialog";
import {
  BorderColor,
  Call,
  Cancel,
  Close,
  ContentCopy,
  DepartureBoardOutlined,
  LocalShippingOutlined,
  Place,
  QrCodeScanner,
  Rotate90DegreesCcw,
  Start,
  Stop,
} from "@mui/icons-material";
import moment from "moment/moment";
import CustomCard from "../../Components/QrCodeScanner/CustomCard";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { QRApis } from "../../Redux/Reducers/QRApis";

export const UserContext = createContext();

export default function BatchDetailsTable({
  batchList,
  setDrawOpenProp,
  filterLabel,
  setFilterLabel,
  filterSearchText,
  setFilterSearchText,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  view,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const batchDetails = useSelector((state) => state.batchData);
  console.log(wfsDetails);
  // console.log("---batchList---", batchList);

  //1.useState
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [batchData, setBatchData] = React.useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [openStartConfirmationDialog, setOpenStartConfirmationDialog] =
    React.useState(false);
  const [openRollbackConfirmationDialog, setOpenRollbackConfirmationDialog] =
    React.useState(false);
  const [openStopConfirmationDialog, setOpenStopConfirmationDialog] =
    React.useState(false);
  const [openLinkDialog, setOpenLinkDialog] = React.useState(false);
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  // Scanning new
  const isBatch = true;
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  const [ownerDetails, setOwnerDetails] = React.useState([]);
  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedBatchId, setSelectedBatchId] = React.useState("");
  // const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // const [page, setPage] = React.useState(0);
  // const [filterLabel, setFilterLabel] = React.useState("device_id");
  // const [filterSearchText, setFilterSearchText] = React.useState("");
  //2.useEffect Hook
  React.useEffect(() => {
    if (currentScanned?.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
    // const current_scanned_stored = JSON.parse(localStorage.getItem("current_scanned_codes")) || []
  }, [currentScanned]);
  React.useEffect(() => {
    // dispatch(GetCreateBatch()).then(() => {
    //   console.log("Batch Data");
    //   console.log(batchDetails);
    // });
    dispatch(
      GetCreateBatch({
        ignore_app_loading_spinner: true,
        page_number: page + 1,
        page_size: rowsPerPage,
        ...((filterSearchText !== "" && {
          [filterLabel]: filterSearchText,
        }) || { sort_created_at: "DESC" }),
      })
    );
  }, [page, rowsPerPage, filterSearchText, filterLabel]);

  const fetchBatch = () => {
    dispatch(
      GetCreateBatch({
        page_number: page + 1,
        page_size: rowsPerPage,
        ...((filterSearchText !== "" && {
          [filterLabel]: filterSearchText,
        }) || { sort_created_at: "DESC" }),
      })
    );
  };

  React.useEffect(() => {
    console.log("batchDetails01");
    console.log(batchDetails);
    setBatchData(batchDetails?.batchData);
  }, [batchDetails]);

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      console.log("setRequestObject", requestObject);
      console.log(requestObject?.codes?.length);
      if (requestObject?.codes?.length) {
        dispatch(
          HandleApiActions({
            ...QRApis.scan_batch_qr,
            params: {
              batch: requestObject.order_id,
              codes: requestObject.codes,
            },
            show_toast: true,
          })
        );
        // setTimeout(() => {
        //   fetchBatch();
        // }, 1000);
        // setOpenProductCapColorDialog(true);
      }
      setTimeout(() => {
        // dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
        // dispatch(GetCreateBatch({ sort_created_at: "DESC" }));

        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        fetchBatch();
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);

  //Pagination for table
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleClose = (data) => {
    console.log("dataaasdd", data);
    setEditDialogOpen(false);
    setCancelDialogOpen(false);
    //setCurrentItem(null); // Clear the current item when dialog is closed
  };
  const handleEditClick = (index, details) => {
    console.log("Details");
    console.log(details);
    setEditDialogOpen(true);
    setCurrentItem(details);
  };

  const handleCancelClick = (details) => {
    console.log("Cancel", details);
    console.log(cancelDialogOpen);
    setCancelDialogOpen(true);
    setCurrentItem(details);
  };

  const handleShowMore = (details) => {
    console.log("details");
    console.log(details);
    setOpenCustomDialog(true);
    setMoreDetails(details);
  };
  const handleStartBatch = (batch) => {
    console.log("batchh");
    console.log(batch);
    setSelectedBatchId(batch.batch_id);
    setOpenStartConfirmationDialog(true);
  };

  const handleStopBatch = (batch) => {
    console.log("-----batchh------", batch);
    setSelectedBatchId(batch.batch_id);
    setOpenStopConfirmationDialog(true);
  };
  const handleRollback = (batchData) => {
    console.log("-----batchData------", batchData);
    setSelectedBatchId(batchData.batch_id);
    setOpenRollbackConfirmationDialog(true);
  };

  const handleRollbackConfirmDialog = () => {
    dispatch(PutRollbackBatch({ batch: selectedBatchId }));
    setOpenRollbackConfirmationDialog(false);
    setTimeout(() => {
      fetchBatch();
    }, 1500);
  };
  const handleCloseRollbackbatch = () => {
    setOpenRollbackConfirmationDialog(false);
  };
  const handleLinking = (data) => {
    // setOpenLinkDialog(true);
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.quantity - data?.linked_container_qty);
    setScanOrderId(data?.batch_id);
  };

  const handleBatchDetailFilter = ({ value, searchText }) => {
    console.log(value, searchText);
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };
  const handleBatchDetailSort = ({ value, searchText }) => {
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  const handleClosestartbatch = () => {
    setOpenStartConfirmationDialog(false);
  };

  const handleStartConfirmDialog = () => {
    // setOpenConfirmationDialog(false);

    const reqObj = {
      batch_id: selectedBatchId,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(StartBatch(reqObj));
    setOpenStartConfirmationDialog(false);
    setTimeout(() => {
      dispatch(GetCreateBatch({ sort_created_at: "DESC" }));
    }, 1000);
  };
  const handleStopConfirmDialog = () => {
    console.log("------setIsConfirmed--------");
    const reqObj = {
      batch_id: selectedBatchId,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(StopBatch(reqObj));
    setOpenStopConfirmationDialog(false);
    setTimeout(() => {
      dispatch(GetCreateBatch({ sort_created_at: "DESC" }));
    }, 1000);
    // setOpenConfirmationDialog(false);
  };
  const handleClosestopbatch = () => {
    setOpenStopConfirmationDialog(false);
  };

  const handleRestDialogClose = () => {
    setOpenConfirmationDialog(false);
  };
  const handleRestConfirmDialog = () => {
    localStorage.removeItem("current_scanned_codes");
    setCurrentScanned({});
    setScannedCount(0);
    setOpenConfirmationDialog(false);
  };

  return (
    <Box>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomFilter
          name={"Filter"}
          selectOption={[{ value: "batch_id", label: "Batch ID" }]}
          type={"filter"}
          startIcon={<FilterAltIcon />}
          handleFilterClose={handleBatchDetailFilter}
        />
        <CustomFilter
          name={"Sort"}
          selectOption={[{ value: "created_at_sort", label: "Created at" }]}
          type={"sort"}
          startIcon={<SwapVertIcon />}
          handleFilterClose={handleBatchDetailSort}
        />
        <CustomButton
          label={"Create Batch"}
          handleClick={() => {
            setDrawOpenProp(true);
          }}
        />
      </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {/* {batchData?.map((data) => {
          return (
            <CustomCard
              row={data}
              // titleKey="Product Name"
              subTitle={`${data.container_capacity} - ${data.container_material}`}
              titleKey="Batch ID"
              title={data.batch_id}
              input={[
                {
                  data: [
                    { label: "Products In batch", value: data.quantity },
                    {
                      label: "Status",
                      value: () => {
                        return (
                          <Typography
                            sx={{
                              fontSize: "13px",
                              textAlign: "start",
                              fontWeight: 600,
                              color:
                                data?.is_batch_started === true &&
                                data?.is_batch_stopped === true &&
                                data?.linked_container_qty === 0
                                  ? "red"
                                  : "#494848",
                            }}
                          >
                            {data?.is_batch_started === true &&
                            data?.is_batch_stopped === true &&
                            data?.linked_container_qty !== 0
                              ? "Completed"
                              : data?.is_batch_started === true &&
                                data?.is_batch_stopped === false
                              ? "Ongoing"
                              : data?.is_batch_started === false &&
                                data?.is_batch_stopped === false
                              ? "Yet to start"
                              : data?.is_batch_started === true &&
                                data?.is_batch_stopped === true &&
                                data?.linked_container_qty === 0
                              ? "Cancelled"
                              : "Started"}
                          </Typography>
                        );
                      },
                    },
                  ],
                },
                {
                  data: [
                    {
                      label: "Linked Product Qty ",
                      value: data.linked_container_qty,
                    },
                    {
                      label: "Created at",
                      value: moment(data.created_at).format("DD-MM-YYYY"),
                    },
                  ],
                },
              ]}
              buttons={[
                data.is_batch_started === false &&
                data.is_batch_stopped === false &&
                data?.linked_container_qty !== data?.quantity &&
                data.quantity !== 0
                  ? {
                      startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                      label: "Link",
                      handleClick: handleLinking,
                    }
                  : data?.linked_container_qty === data?.quantity &&
                    data.quantity !== 0 &&
                    data.is_batch_started === false &&
                    data.is_batch_stopped === false
                  ? {
                      startIcon: <Start style={{ fontSize: "18px" }} />,
                      label: "Start",
                      handleClick: handleStartBatch,
                    }
                  : data?.linked_container_qty === data?.quantity &&
                    data.is_batch_started === true &&
                    data.is_batch_stopped === false
                  ? {
                      startIcon: <Stop style={{ fontSize: "18px" }} />,
                      label: "Stop",
                      handleClick: handleStopBatch,
                    }
                  : data?.linked_container_qty === data?.quantity &&
                    data.is_batch_started === true &&
                    data.is_batch_stopped === false
                  ? {
                      label: "Edit",
                      handleClick: handleEditClick,
                    }
                  : undefined,
                data?.is_batch_started !== true &&
                data?.is_batch_stopped !== true &&
                data?.linked_container_qty === 0
                  ? {
                      startIcon: <Cancel style={{ fontSize: "18px" }} />,
                      label: "Cancel",
                      handleClick: handleCancelClick,
                      color: "error",
                    }
                  : undefined,
              ]}
            />
          );
        })} */}
        {/* {batchData.map((data) => {
          return (
            <Paper
              sx={{
                marginTop: "10px",
                width: { xs: 300, md: 330 },
                borderRadius: "10px",
                height: "190px",
              }}
              spacing={2}
              elevation={10}
            >
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "5px" }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography sx={{ fontWeight: 800, fontSize: "17px" }}>
                    Batch Id :
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "17px" }}>
                    {data.batch_id}
                  </Typography>
                </Box>
                {data?.is_batch_started === true &&
                data?.is_batch_stopped === true &&
                data?.linked_container_qty === 0 ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleCancelClick(data)}
                    color="error"
                    size="small"
                    sx={{
                      fontSize: "8px",

                      minWidth: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Close style={{ fontSize: "14px" }} /> Cancel
                  </Button>
                )}
              </Stack>
              <Divider orientation="horizantal" flexItem />
              <Box>
                <Box sx={{ display: "flex", gap: "10px", margin: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "start",
                      fontWeight: 800,
                    }}
                  >
                    Product Name :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#494848",
                      textAlign: "start",
                      fontWeight: 600,
                    }}
                  >
                    {data.container_capacity} - {data.container_material}{" "}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    margin: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        textAlign: "start",
                        fontWeight: 800,
                      }}
                    >
                      Products In batch :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#494848",
                        textAlign: "start",
                        fontWeight: 600,
                      }}
                    >
                      {data.quantity}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        textAlign: "start",
                        fontWeight: 800,
                      }}
                    >
                      Status :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textAlign: "start",
                        fontWeight: 600,
                        color:
                          data?.is_batch_started === true &&
                          data?.is_batch_stopped === true &&
                          data?.linked_container_qty === 0
                            ? "red"
                            : "#494848",
                      }}
                    >
                      {data?.is_batch_started === true &&
                      data?.is_batch_stopped === true &&
                      data?.linked_container_qty !== 0
                        ? "Completed"
                        : data?.is_batch_started === true &&
                          data?.is_batch_stopped === false
                        ? "Ongoing"
                        : data?.is_batch_started === false &&
                          data?.is_batch_stopped === false
                        ? "Yet to start"
                        : data?.is_batch_started === true &&
                          data?.is_batch_stopped === true &&
                          data?.linked_container_qty === 0
                        ? "Cancelled"
                        : "Started"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "start",
                      fontWeight: 800,
                    }}
                  >
                    Linked Product Qty :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#494848",
                      textAlign: "start",
                      fontWeight: 600,
                    }}
                  >
                    {data.linked_container_qty}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "start",
                      fontWeight: 800,
                    }}
                  >
                    Created at :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#494848",
                      textAlign: "start",
                      fontWeight: 600,
                    }}
                  >
                    {moment(data.created_at).format("DD-MM-YYYY")}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 0,
                  m: 0,
                }}
              >
                {data.is_batch_started === false &&
                data.is_batch_stopped === false &&
                data?.linked_container_qty !== data?.quantity ? (
                  <CustomButton
                    size="small"
                    startIcon={<QrCodeScanner />}
                    label="Link"
                    handleClick={() => handleLinking(data)}
                  />
                ) : data?.linked_container_qty === data?.quantity &&
                  data.is_batch_started === false &&
                  data.is_batch_stopped === false ? (
                  <CustomButton
                    handleClick={() => handleStartBatch(data)}
                    label={"START"}
                  />
                ) : data?.linked_container_qty === data?.quantity &&
                  data.is_batch_started === true &&
                  data.is_batch_stopped === false ? (
                  <CustomButton
                    handleClick={() => handleStopBatch(data)}
                    // color="error"
                    label={"STOP"}
                  />
                ) : (
                  ""
                )}
                {data?.is_batch_started === false &&
                data?.is_batch_stopped === false ? (
                  <CustomButton
                    handleClick={() => handleEditClick(data)}
                    startIcon={<BorderColor />}
                    label="Edit"
                  />
                ) : (
                  ""
                )}
              </Box>
            </Paper>
          );
        })} */}
      </Box>

      <CustomTable
        tableHead={[
          "Batch ID",
          "Batch Quantity",
          "Batch Started",
          "Batch Stopped",
          "Capacity",
          "Material",
          "Link",
          "Batch Status",
          "More Details",
          "Cancel",
        ]}
        tableData={batchData}
        tableDataKey={[
          "batch_id",
          "quantity",
          {
            description: "Is Batch Started",
            rowClickEnable: true,
            component: ({ row }) => {
              return row.is_batch_started ? "Started" : "Not Started";
            },
          },
          {
            description: "Is Batch Stopped",
            rowClickEnable: true,
            component: ({ row }) => {
              return row.is_batch_stopped ? "Yes" : "No";
            },
          },
          "container_capacity",
          "container_material",
          {
            description: "Link",
            component: ({ row }) => {
              return (
                <Box>
                  {row?.linked_container_qty !== row?.quantity &&
                  row.is_batch_started === false &&
                  row.is_batch_stopped === false ? (
                    <CustomButton
                      handleClick={() => handleLinking(row)}
                      label={"LINK"}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              );
            },
          },
          {
            description: "Batch status",
            component: ({ row }) => {
              console.log("---Batch status---", row);
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  {row.is_batch_started === false && (
                    <CustomButton
                      handleClick={() => handleStartBatch(row)}
                      label={"START"}
                    />
                  )}

                  {row.is_batch_stopped === false && (
                    <CustomButton
                      handleClick={() => handleStopBatch(row)}
                      color="error"
                      label={"STOP"}
                    />
                  )}
                </Box>
              );
            },
          },
          {
            description: "More Details",
            component: ({ row }) => {
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  <CustomButton
                    handleClick={() => {
                      handleShowMore(row);
                    }}
                    label={"MORE"}
                  />
                </Box>
              );
            },
          },
          {
            description: "Cancel",
            component: ({ row }) => {
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  <CustomButton
                    handleClick={() => handleCancelClick(row)}
                    color="error"
                    label={"Cancel"}
                  />
                </Box>
              );
            },
          },
        ]}
        // filter={true}
        defaultView="card"
        filterSelectOption={[{ value: "batch_id", label: "Batch ID" }]}
        handleFilterClose={handleBatchDetailFilter}
        // sort={true}
        sortSelectOption={[{ value: "created_at", label: "Created at" }]}
        handleSortClose={handleBatchDetailSort}
        // addButton={true}
        handleOnClickAddButton={() => {
          setDrawOpenProp(true);
        }}
        tableRowClickFunction={(index, row) => {
          handleEditClick(0, row);
        }}
        pagination={false}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        cardHeight="200px"
        cardTitle={{ label: "Batch ID", key: "batch_id" }}
        cardSubTitle={{ label: "Container", key: "container_capacity" }}
        cardInput={(row) => [
          {
            data: [
              { label: "Products In batch", value: row.quantity },
              {
                label: "Status",
                value: () => {
                  return (
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textAlign: "start",
                        fontWeight: 600,
                        color:
                          row?.is_batch_started === true &&
                          row?.is_batch_stopped === true &&
                          row?.linked_container_qty === 0
                            ? "red"
                            : "#494848",
                      }}
                    >
                      {row?.is_batch_started === true &&
                      row?.is_batch_stopped === true &&
                      row?.linked_container_qty !== 0
                        ? "Completed"
                        : row?.is_batch_started === true &&
                          row?.is_batch_stopped === false
                        ? "Ongoing"
                        : row?.is_batch_started === false &&
                          row?.is_batch_stopped === false
                        ? "Yet to start"
                        : row?.is_batch_started === true &&
                          row?.is_batch_stopped === true &&
                          row?.linked_container_qty === 0
                        ? "Cancelled"
                        : "Started"}
                    </Typography>
                  );
                },
              },
            ],
          },
          {
            data: [
              {
                label: "Linked Product Qty ",
                value: row.linked_container_qty,
              },
              {
                label: "Created at",
                value: moment(row.created_at).format("DD-MM-YYYY"),
              },
            ],
          },
        ]}
        cardButton={(data) => [
          data?.linked_container_qty === data?.quantity &&
          data.quantity !== 0 &&
          data.is_batch_started === false &&
          data.is_batch_stopped === false
            ? {
                startIcon: <Rotate90DegreesCcw style={{ fontSize: "18px" }} />,
                label: "rollback",
                handleClick: handleRollback,
              }
            : undefined,
          data.is_batch_started === false &&
          data.is_batch_stopped === false &&
          data?.linked_container_qty !== data?.quantity &&
          data.quantity !== 0
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "Link",
                handleClick: handleLinking,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.quantity !== 0 &&
              data.is_batch_started === false &&
              data.is_batch_stopped === false
            ? {
                startIcon: <Start style={{ fontSize: "18px" }} />,
                label: "Start",
                handleClick: handleStartBatch,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                startIcon: <Stop style={{ fontSize: "18px" }} />,
                label: "Stop",
                handleClick: handleStopBatch,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                label: "Edit",
                handleClick: handleEditClick,
              }
            : undefined,
          data?.is_batch_started !== true &&
          data?.is_batch_stopped !== true &&
          data?.linked_container_qty === 0
            ? {
                startIcon: <Cancel style={{ fontSize: "18px" }} />,
                label: "Cancel",
                handleClick: handleCancelClick,
                color: "error",
              }
            : undefined,
        ]}
        formSearchOptionKey="batch_id"
        formSearchLabel="Enter Batch ID"
        formInput={(row) => [
          { label: "Batch ID", value: "batch_id" },
          { label: "Products In batch", value: "quantity" },
          {
            label: "Status",
            value: (row) => {
              return (
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "start",
                    fontWeight: 600,
                    color:
                      row?.is_batch_started === true &&
                      row?.is_batch_stopped === true &&
                      row?.linked_container_qty === 0
                        ? "red"
                        : "#494848",
                  }}
                >
                  {row?.is_batch_started === true &&
                  row?.is_batch_stopped === true &&
                  row?.linked_container_qty !== 0
                    ? "Completed"
                    : row?.is_batch_started === true &&
                      row?.is_batch_stopped === false
                    ? "Ongoing"
                    : row?.is_batch_started === false &&
                      row?.is_batch_stopped === false
                    ? "Yet to start"
                    : row?.is_batch_started === true &&
                      row?.is_batch_stopped === true &&
                      row?.linked_container_qty === 0
                    ? "Cancelled"
                    : "Started"}
                </Typography>
              );
            },
          },
          {
            label: "Linked Product Qty ",
            value: "linked_container_qty",
          },
          {
            label: "Created at",
            value: () => moment(row.created_at).format("DD-MM-YYYY"),
          },
        ]}
        formButton={(data) => [
          data.is_batch_started === false &&
          data.is_batch_stopped === false &&
          data?.linked_container_qty !== data?.quantity &&
          data.quantity !== 0
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "Link",
                handleClick: handleLinking,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.quantity !== 0 &&
              data.is_batch_started === false &&
              data.is_batch_stopped === false
            ? [
                {
                  startIcon: <Start style={{ fontSize: "18px" }} />,
                  label: "rollback",
                  handleClick: handleStartBatch,
                },
                {
                  startIcon: <Start style={{ fontSize: "18px" }} />,
                  label: "Start",
                  handleClick: handleStartBatch,
                },
              ]
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                startIcon: <Stop style={{ fontSize: "18px" }} />,
                label: "Stop",
                handleClick: handleStopBatch,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                label: "Edit",
                handleClick: handleEditClick,
              }
            : undefined,
          data?.is_batch_started !== true &&
          data?.is_batch_stopped !== true &&
          data?.linked_container_qty === 0
            ? {
                startIcon: <Cancel style={{ fontSize: "18px" }} />,
                label: "Cancel",
                handleClick: handleCancelClick,
                color: "error",
              }
            : undefined,
        ]}
        tableView={view}
      />
      <Paper />

      {openCustomDialog && (
        <CustomDialogMoreDetailsWaterFilling
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title="More details"
          moreDetails={moreDetails}
        />
      )}
      {editDialogOpen && (
        <EditDialogBatch
          open={editDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          onClose={handleClose}
          item={currentItem}
        />
      )}
      {cancelDialogOpen && (
        <CancelDialogBatch
          open={cancelDialogOpen}
          onClose={handleClose}
          setCancelDialogOpen={setCancelDialogOpen}
          item={currentItem}
        />
      )}
      {openStartConfirmationDialog && (
        <CustomConfirmationDialog
          open={openStartConfirmationDialog}
          setOpenConfirmationDialog={setOpenStartConfirmationDialog}
          dialogContent={"Are you sure want to START batch?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleClosestartbatch}
          onConfirm={handleStartConfirmDialog}
        />
      )}
      {openRollbackConfirmationDialog && (
        <CustomConfirmationDialog
          open={openRollbackConfirmationDialog}
          setOpenConfirmationDialog={setOpenRollbackConfirmationDialog}
          dialogContent={"Are you sure want to Rollback Scanned Products?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleCloseRollbackbatch}
          onConfirm={handleRollbackConfirmDialog}
        />
      )}

      {openStopConfirmationDialog && (
        <CustomConfirmationDialog
          open={openStopConfirmationDialog}
          setOpenConfirmationDialog={setOpenStopConfirmationDialog}
          dialogContent={"Are you sure want to STOP batch?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleClosestopbatch}
          onConfirm={handleStopConfirmDialog}
        />
      )}
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          dialogContent={"Are you sure you want to link from the beginning?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleRestDialogClose}
          onConfirm={handleRestConfirmDialog}
        />
      )}
      {/* {openLinkDialog && (
        <ScannerDialog
          open={openLinkDialog}
          setOpenLinkDialog={setOpenLinkDialog}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          validData={validData}
          invalidData={invalidData}
          setInvalidData={setInvalidData}
          setValidData={setValidData}
        />
      )} */}
      <ScanningDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={currentScanned}
        setCurrentScanned={setCurrentScanned}
        scanQtyMax={scanQtyMax}
        scanOrderId={scanOrderId}
        scannedCount={scannedCount}
        setScannedCount={setScannedCount}
        setRequestObject={setRequestObject}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        isBatch={isBatch}
        validateExpired={validateExpired}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
    </Box>
  );
}
