import React from "react";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { Box } from "@mui/material";
import CustomTable from "../../../Components/CustomComp/CustomTable";

function WfsStockDetail({ setOpenStockDialog, stockDetail }) {
  console.log("--stockDetail--", stockDetail);
  return (
    <Box>
      <CustomTable
        tableHead={[
          "Capacity",
          "Material",
          "Filled container",
          "Empty container",
          "Physically damaged",
          "QR damaged",
        ]}
        tableDataKey={[
          "container_capacity",
          "container_material",
          "filled_containers",
          "empty_containers",
          "physically_damaged_containers",
          "qr_bar_code_damaged_containers",
        ]}
        tableData={stockDetail}
        pagination={false}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomButton
          handleClick={() => {
            setOpenStockDialog(false);
          }}
          label={"Close"}
        />
      </Box>
    </Box>
  );
}

export default WfsStockDetail;
