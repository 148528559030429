import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

import {
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { AddLinesFacility } from "../../../Redux/Actions/addFacilityWFSFillingAction";
import { all } from "axios";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../../Components/Utils/CustomFormUtils";
import appMeta from "../../../AppMeta/appMeta";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextFieldForForm";

export function AddLinesFacilty({ allFacilities }) {
  const dispatch = useDispatch();
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const [allWfs, setAllWfs] = React.useState("");

  //1.useState Hooks
  const [selectFacility, setSelectFacility] = React.useState("");
  const [searchFacility, setSearchFacility] = React.useState("");
  // const [selectedWfs, setSelectedWfs] = React.useState("");
  const [searchWfs, setSearchWfs] = React.useState("");

  //3.Functions
  const handleClickAddLines = async (data) => {
    console.log("dataaaaa", data);
    const reqObj = {
      facility_id: parseInt(data?.facility),
      wfs_id: parseInt(data?.wfs),
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(AddLinesFacility(reqObj));
    // console.log("---response---", response.data);
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          ...(searchFacility !== "" && { org_name: searchFacility }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [searchFacility]);

  React.useEffect(() => {
    if (profileRole !== "1000" || selectFacility !== "") {
      searchAndUpdateWfs();
    }
  }, [searchWfs, selectFacility]);

  React.useEffect(() => {
    console.log("----wfsDetails.wfsLineId----", wfsList);
    if (profileRole === "1000") {
      selectFacility !== "" && setAllWfs(wfsList);
    } else {
      setAllWfs(wfsList);
    }
  }, [wfsList]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Paper elevation={23}>
          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <CustomForm
              hideCloseButton={true}
              configConfirmation={{
                isConfirmationRequired: true,
                dialogContent: "Are you sure you want to create new line?",
              }}
              data={[
                {
                  title: "Create new line to WFS",
                  data: [
                    {
                      ...(profileRole === "1000" && {
                        inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                        optionKey: "org_name",
                        outputKey: "id",
                        onChange: (name, value) => {
                          setSelectFacility(value);
                        },
                        searchAndUpdateAPI: (searchText) => {
                          setSearchFacility(searchText);
                        },
                        input: allFacilities || [],
                        name: "facility",
                        label: "Enter Facility Name",
                      }),
                    },
                    {
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "wfs_name",
                      outputKey: "wfs_id",
                      // onChange: (name, value) => {
                      //   console.log("---Value--", value);
                      //   setSelectedWfs(value);
                      // },
                      searchAndUpdateAPI: (searchText) => {
                        setSearchWfs(searchText);
                      },
                      input: allWfs || [],
                      name: "wfs",
                      label: "Enter WFS Name",
                    },
                  ],
                },
              ]}
              handleSubmit={(formData) => {
                handleClickAddLines(formData);
              }}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
