import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CustomAutoComplete from "../../../Components/CustomComp/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";

function SelectOrganization({ getId }) {
  //hooks
  const dispatch = useDispatch();
  const organisationDetails = useSelector((state) => state.organizationData);
  const profileRole = useSelector((state) => state.profileData).profile.role_id;

  //state
  const [selectFacility, setSelectFacility] = React.useState("");
  const [searchFacility, setSearchFacility] = React.useState("");
  const [allFacility, setAllFacility] = React.useState(
    organisationDetails.organizationList
  );

  //functions
  const searchAndUpdateFacility = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          ...(searchFacility !== "" && { org_name: searchFacility }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  //useEffect
  useEffect(() => {
    searchFacility !== "" && searchAndUpdateFacility();
  }, [searchFacility]);

  useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  useEffect(() => {
    searchAndUpdateFacility();
  }, []);

  return (
    <Box>
      <CustomAutoComplete
        field={{
          optionKey: "org_name",
          outputKey: "id",
          onChange: (name, value) => {
            setSelectFacility(value);
            getId(value);
          },
          searchAndUpdateAPI: (searchText) => {
            setSearchFacility(searchText);
          },
          input: allFacility || [],
          name: "facility",
          label: "Enter Facility Name",
        }}
      />
    </Box>
  );
}

export default SelectOrganization;
