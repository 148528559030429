import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GenerateComp from "./GenerateComp";
import CreateBatchComp from "./CreateBatchComp";

export default function GenerateQRBarcode({
  pageNumber,
  pageSize,
  createdAtSort,
  isQr,
  qrBarcodeId,
  orderId,
  tabValue,
  setOpenScannerDialog,
}) {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GenerateComp
          pageNumber={pageNumber}
          pageSize={pageSize}
          createdAtSort={createdAtSort}
          isQr={isQr}
          qrBarcodeId={qrBarcodeId}
          orderId={orderId}
          tabValue={tabValue}
          setOpenScannerDialog={setOpenScannerDialog}
        />
      </Box>
    </Box>
  );
}
