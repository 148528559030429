import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import DistributorUpdateProfileCompany from "./DistributorUpdateProfileCompany";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";

function DistributorCompanyOnboarding() {
  const dispatch = useDispatch();
  const distributorList = useSelector(
    (state) => state.userManagementReducer.distributor_List
  );
  const [openupdatecompanyprofile, setOpenupdatecompanyprofile] =
    React.useState(false);
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_List,
        // params: reqObj,
        show_toast: false,
      })
    );
  }, []);
  const handleOpenDrawer = () => {
    setOpenupdatecompanyprofile(true);
  };
  const handleSubmitDistributorProfile = (props) => {
    console.log("--props-----", props);

    dispatch(
      HandleApiActions({
        ...userManagementApis.add_distributor_profile,
        params: props,
        show_toast: true,
      })
    );
    setOpenupdatecompanyprofile(false);
    setTimeout(() => {
      dispatch(
        HandleApiActions({
          ...userManagementApis.get_distributor_List,
          // params: reqObj,
          show_toast: false,
        })
      );
    }, 1500);
  };
  const handleCloseDistributorProfile = () => {
    setOpenupdatecompanyprofile(false);
  };
  return (
    <Box>
      <CustomTable
        tableHead={[
          "Distributor Name",
          "Company Name",
          "Company Type",
          "City",
          "state",
          "Country",
          "Created On",
          "Status",
        ]}
        tableData={distributorList}
        tableDataKey={[
          "fullname",
          "name",
          "container_material",
          "city",
          "state",

          {
            component: function (props) {
              return (
                <Typography sx={{ fontSize: "12px" }}>
                  {/* {orderStatus(props.row.order_status)} */}
                </Typography>
              );
            },
          },

          "created_at",
          "updated_by",
          // {
          //   rowClickEnable: true,
          //   component: (props) => {
          //     console.log("propss", props);
          //     return moment(props?.row?.created_at).format("DD-MM-YYYY");
          //   },
          // },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "order_id", label: "order_id" },
          { value: "status", label: "status" },
        ]}
        // handleFilterClose={handleWfsFilterClose}
        sort={true}
        sortSelectOption={[
          { value: "created_at_sort", label: "Created at" },
          { value: "wfs_name_sort", label: "WFS name" },
          { value: "facility_name_sort", label: "Facility name" },
        ]}
        // handleSortClose={handleWfsSortClose}
        addButton={true}
        handleOnClickAddButton={handleOpenDrawer}
        // tableRowClickFunction={(index, props) => handleRowClick(props)}
        // setPageNumber={setPageNumber}
        // setPageSize={setPageSize}
      />
      <DistributorUpdateProfileCompany
        openupdatecompanyprofile={openupdatecompanyprofile}
        handleSubmit={handleSubmitDistributorProfile}
        handleClose={handleCloseDistributorProfile}
      />
    </Box>
  );
}

export default DistributorCompanyOnboarding;
