import { Reducer } from "redux";
import actionTypes from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  providedAccessData: [],
  providedAccessDataTable: [],
};

const recycleOrderReducer = (state = initialState, action) => {
  // console.log("action");
  // console.log(action);
  switch (action.type) {
    case actionTypes.GET_DAMAGED_EXPIRED:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        providedAccessData: action.payload.data,
      };

    case actionTypes.CREATE_RECYCLE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionTypes.GET_RECYCLE_DAMAGE_TABLE:
      console.log("action", action.payload.data);
      return {
        error: action.payload.error,
        msg: action.payload.msg,
        providedAccessDataTable: action.payload.data,
      };
    case actionTypes.UPDATE_RECYCLE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default recycleOrderReducer;
