import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateManufacturingOrders,
  GetManufacuringOrders,
  MftCompleteOrder,
  MftOrdersApproval,
} from "../../../Redux/Actions/manufacturingOrdersAction";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { UploadFile } from "../../../Redux/Actions/fileHandlingActions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styled } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import MftOrderDetailsDialog from "./MftOrderDetailsDialog";
import {
  GenerateQR,
  GetGeneratedQR,
} from "../../../Redux/Actions/GenerateQrAction";
import RegenerateQrDialog from "./RegenerateQrDialog";
import { appColors } from "../../../AppMeta/appColors";
import QrBarcodeDetailsDialog from "./QrBarcodeDetailsDialog";
import CustomDialogMoreDetails from "../../../Components/CustomComp/CustomDialogMoreDetails";
import { toast } from "react-toastify";
import RecreateOrderDialog from "./RecreateOrderDialog";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetailsDialog from "./OrderDetailsDialog";
import EditOrderDialog from "./EditOrderDialog";
import { GetManuFacturer } from "../../../Redux/Actions/ManufacturersAction";
import { GetProductSku } from "../../../Redux/Actions/ProductComponentsAction";
import appStyle from "../../../AppMeta/appStyle";
import { tokens } from "../../../AppMeta/theme";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import OrderCards from "./OrderCards";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import CustomSelect from "../../../Components/CustomComp/CustomSelect";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// Sample data from your API response

const useStyles = makeStyles((theme) => ({
  smallButton: {
    padding: "5px 7px", // Customize padding to make it smaller
    fontSize: "0.60rem",
    // Reduce font size to make it smaller
  },
}));
export default function AllMftOrders({
  value,
  setValue,
  setMftName,
  setMftEmail,
  setMftPhone,
  setMftAreaName,
  setMftPincode,
  setNoOfItems,
  setProduct,
  setTotalPrice,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  lastPageNumber,
  setOrderIdSearch,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const mftOrderDetails = useSelector((state) => state.mftOrdersData);
  const productDetails = useSelector((state) => state.productComponentsData);

  const filesData = useSelector((state) => state.filesData);

  const dispatch = useDispatch();
  const [isPoApproved, setIsPoApproved] = React.useState(false);
  const [isPrApproved, setIsPrApproved] = React.useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = React.useState(false);
  const [prFile, setPrFile] = React.useState("");
  const [uploadPrBtn, setUploadPrBtn] = React.useState(false);
  const [uploadedFileName, setUploadedFileName] = React.useState("");
  const [selectedOrderData, setSelectedOrderData] = React.useState(null);
  const [openMftOrderDialog, setOpenMftOrderDialog] = React.useState(false);

  const [openRegenerateQrDialog, setOpenRegenerateQrDialog] =
    React.useState(false);

  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const [generatedCodesList, setGeneratedCodesList] = React.useState([]);

  const [moreDetails, setMoreDetails] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [recreateOrderDialog, setRecreateOrderDialog] = React.useState(false);
  const [isProductAvailable, setIsProductAvailable] = React.useState(true);
  const [editOrderDialog, setEditOrderDialog] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState({});
  const [allProducts, setAllProducts] = React.useState([]);

  const prInputRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(GetProductSku());
  }, []);
  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      setAllProducts(productDetails?.products);
    }
  }, [productDetails]);
  React.useEffect(() => {
    dispatch(GetManuFacturer());
  }, []);
  React.useEffect(() => {
    console.log("mftOrderDetails");
    console.log(mftOrderDetails);
    mftOrderDetails?.mftOrders?.data?.map((order) => {
      if (order.order_status === "PO_APPROVED") {
        setIsPoApproved(true);
      } else if (order.order_status === "PR_APPROVED") {
        setIsPoApproved(true);
        setIsPrApproved(true);
      } else if (order.order_status === "COMPLETED") {
        setIsPoApproved(true);
        setIsPrApproved(true);
        setIsOrderCompleted(true);
      }
    });
  }, [mftOrderDetails]);

  // Extract the actual data from the API response
  const rows = mftOrderDetails?.mftOrders?.data;
  console.log("---rows---", rows);
  const pageNumberForward = () => {
    if (mftOrderDetails?.mftOrders?.data.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };
  const handleUploadPR = () => {
    prInputRef.current.click();
  };
  const handleChangePRUpload = (event) => {
    console.log("Selected PR file:", event.target.files[0]);
    setPrFile(event.target.files[0]);
    setUploadPrBtn(true);
  };

  React.useEffect(() => {
    console.log("Upload PR button useEffect");
    if (uploadPrBtn === true) {
      console.log("Hitting Upload API");
      dispatch(UploadFile(prFile));
      setUploadPrBtn(false);
    }
  }, [uploadPrBtn]);

  React.useEffect(() => {
    console.log("Files Data");
    console.log(filesData);
    if (filesData.error === false) {
      setUploadedFileName(filesData.fileName);
    }
  }, [filesData]);

  const handleGenerateQr = (orderId) => {
    console.log(`orderId ${orderId}`);
    const reqObj = {
      order_id: orderId,
    };
    // dispatch(GenerateQR(reqObj));
    setTimeout(() => {
      dispatch(GetManufacuringOrders());
    }, 1000);
  };

  const handleRegenerateQr = (orderId) => {
    setOpenRegenerateQrDialog(true);
  };

  const handleClickShowMore = (details) => {
    setMoreDetails(details);
    // setOpenCustomDialog(true);
    setOpenOrderDetailsDialog(true);
  };
  const handleClickEditOrder = (details) => {
    console.log(details);
    setDataToEdit(details);
    setEditOrderDialog(true);
  };
  const handleRecreateOrder = (row) => {
    console.log("row recreate");
    console.log(row);
    setSelectedRow(row);
    setMoreDetails(row.mft_details);
    if (row.porduct_details.is_available) {
      setIsProductAvailable(true);
      setRecreateOrderDialog(true);
    } else {
      setIsProductAvailable(false);
      setRecreateOrderDialog(true);
    }
  };

  const handleChangeOrderId = (event) => {
    setOrderIdSearch(event.target.value);
    console.log(event.target.value);
  };

  const getColor = (orderType) => {
    switch (orderType) {
      case "400":
        return "#11f583"; // Processing Partner
      case "100":
        return "green"; // Purchase Product
      default:
        return "blue"; // Default color
    }
  };
  return (
    <Box>
      {/* <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Paper>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Order ID"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleChangeOrderId}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              // onClick={handleSearchOrderID}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      </Toolbar> */}
      {/* <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CustomSelect
            defaultValue={pageSize}
            name={"Page size"}
            sx={{ width: "10ch" }}
            handleChange={(event) => {
              console.log(event.target.value);
              setPageSize(event.target.value);
            }}
            input={[
              { menuItem: 25, value: 25 },
              { menuItem: 100, value: 100 },
              { menuItem: 200, value: 200 },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "10px", fontWeight: "bold" }}>
            Page number:
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstPageIcon
              sx={{
                color: pageNumber - 1 >= 0 ? "blue" : "gray",
                fontSize: "24px",
              }}
              onClick={() => {
                if (pageNumber - 1 >= 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            />
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {pageNumber + 1}
            </Typography>
            <LastPageIcon
              sx={{
                color:
                  mftOrderDetails?.mftOrders?.data?.length === pageSize
                    ? "blue"
                    : "gray",
                fontSize: "24px",
              }}
              onClick={() => {
                if (rows?.length === pageSize) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </Box>
        </Box>
      </Box> */}
      {/* <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {rows?.map((row) =>
          row.components.map((component) => (
            <OrderCards
              row={row}
              bgColor={"#074276"}
              orderStatusColor={
                row.order_status === "COMPLETED"
                  ? "green"
                  : row.order_status === "PO_APPROVED"
                  ? "orange"
                  : "#074276"
              }
              product={component.name}
              quantity={component.quantity}
              orderID={row.order_id}
              orderType={
                component.order_type === "400"
                  ? "Processing Partner"
                  : "Purchase Product"
              }
              manufacturer={row.mft_details.details.name}
              orderStatus={row.order_status}
              date={row.created_at}
              more={handleClickShowMore}
              edit={handleClickEditOrder}
            />
          ))
        )}
      </Box> */}

      <CustomTable
        filter={true}
        filterSelectOption={[
          { value: "order_id", label: "Order ID" },
          {
            type: "select",
            value: "order_type",
            label: "Order Type",
            input: [
              { value: 100, menuItem: "Purchase Product" },
              { value: 200, menuItem: "Processing Partner" },
            ],
          },
        ]}
        sort={true}
        sortSelectOption={[{ value: "created_at_sort", label: "Created at" }]}
        tableHead={[
          "Order ID",
          "Order Type",
          "Manufacturer",
          "Order Status",
          "More Details",
          "Edit Order",
        ]}
        tableData={rows}
        tableDataKey={[
          "order_id",
          {
            rowClickEnable: true,
            component: (props) => {
              return props.row.order_type === "400"
                ? "Processing Partner"
                : "Purchase Product";
            },
          },
          "mft_details.details.name",
          "order_status",
          {
            component: (props) => {
              return (
                <CustomButton
                  label="More"
                  handleClick={() => handleClickShowMore(props.row)}
                />
              );
            },
          },
          {
            component: (props) => {
              return (
                <CustomButton
                  label="Edit"
                  disabled={true}
                  handleClick={() => handleClickEditOrder(props.row)}
                />
              );
            },
          },
        ]}
        isCardViewEnable={true}
        cardTitle={{ label: "Order ID", key: "order_id" }}
        cardSubTitle={{ label: "Mft Name", key: "mft_details.details.name" }}
        cardInput={(row) => {
          console.log("---row---", row);
          return [
            {
              data: [
                {
                  label: "Order status",
                  value: row.order_status,
                },
              ],
            },
            {
              data: [
                {
                  label: "Order Type",
                  value:
                    row.order_type === "400"
                      ? "Processing Partner"
                      : "Purchase Product",
                },
              ],
            },
          ];
        }}
        cardButton={(row) => [
          {
            label: "Edit",
            startIcon: <EditNoteIcon />,
            handleClick: handleClickEditOrder,
          },
          {
            label: "More",
            startIcon: <MoreHorizIcon />,
            handleClick: handleClickShowMore,
          },
        ]}
      />
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Order ID
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Order Type
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Manufacturer
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Order Status
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                More Details
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Edit Order
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.order_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  if (event.target.tagName !== "BUTTON") {
                    setSelectedOrderData(row);
                    // setOpenMftOrderDialog(true);
                  }
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={appStyle.general.table.bodyRowCell}
                >
                  {row.order_id}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: getColor(row.order_type) }}
                >
                  {row.order_type === "400"
                    ? "Processing Partner"
                    : "Purchase Product"}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={appStyle.general.table.bodyRowCell}
                >
                  {row.mft_details.details.name}
                </TableCell>

                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {row.order_status}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="outlined"
                    size="small"
                    // onClick={() => handleClickShowMore(row.mft_details)}
                    onClick={() => handleClickShowMore(row)}
                  >
                    Show More
                  </Button>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    // onClick={() => handleClickShowMore(row.mft_details)}
                    onClick={() => handleClickEditOrder(row)}
                  >
                    Edit Order
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor:
              appStyle.general.colors(colors).pageNumberBackground,
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}
      {editOrderDialog && (
        <>
          <EditOrderDialog
            editOrderDialog={editOrderDialog}
            setEditOrderDialog={setEditOrderDialog}
            dataToEdit={dataToEdit}
            allProducts={allProducts}
          />
        </>
      )}
      {openMftOrderDialog && (
        <>
          <MftOrderDetailsDialog
            openMftOrderDialog={openMftOrderDialog}
            setOpenMftOrderDialog={setOpenMftOrderDialog}
            selectedOrderData={selectedOrderData}
          />
        </>
      )}

      {openRegenerateQrDialog && (
        <>
          <RegenerateQrDialog
            openRegenerateQrDialog={openRegenerateQrDialog}
            setOpenRegenerateQrDialog={setOpenRegenerateQrDialog}
            generatedCodesList={generatedCodesList}
          />
        </>
      )}

      {openQrDetailsDialog && (
        <QrBarcodeDetailsDialog
          openQrDetailsDialog={openQrDetailsDialog}
          setOpenQrDetailsDialog={setOpenQrDetailsDialog}
        />
      )}

      {openCustomDialog && (
        <CustomDialogMoreDetails
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title={"Manufacurer Details"}
          moreDetails={moreDetails}
        />
      )}

      {openOrderDetailsDialog && (
        <OrderDetailsDialog
          openOrderDetailsDialog={openOrderDetailsDialog}
          setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
          moreDetails={moreDetails}
          setMoreDetails={setMoreDetails}
        />
      )}
      {recreateOrderDialog && (
        <>
          <RecreateOrderDialog
            recreateOrderDialog={recreateOrderDialog}
            setRecreateOrderDialog={setRecreateOrderDialog}
            value={value}
            setValue={setValue}
            moreDetails={moreDetails}
            selectedRow={selectedRow}
            setMftName={setMftName}
            setMftEmail={setMftEmail}
            setMftPhone={setMftPhone}
            setMftAreaName={setMftAreaName}
            setMftPincode={setMftPincode}
            isProductAvailable={isProductAvailable}
            setNoOfItems={setNoOfItems}
            setProduct={setProduct}
            setTotalPrice={setTotalPrice}
          />
        </>
      )}
    </Box>
  );
}
