import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useEffect, useState } from "react";

function CustomCheckBox({
  input,
  onChange,
  name,
  defaultValue,
  selectAllPreviousCheckBox = false,
}) {
  const [valueArray, setValueArray] = useState([]);
  const [defaultArray, setDefaultArray] = useState(
    Object.keys(defaultValue).filter((key) => defaultValue[key])
  );
  console.log("defaultValue", defaultValue);
  //function
  const handleChange = async (event) => {
    let updatedArray = [];
    const currentIndex = input.findIndex(
      (ele) => ele.value === event.target.value
    );
    if (selectAllPreviousCheckBox && event.target.checked) {
      updatedArray = input.slice(0, currentIndex + 1).map((ele) => ele.value);
    } else if (event.target.checked) {
      updatedArray = [...valueArray, event.target.value];
    } else if (selectAllPreviousCheckBox && !event.target.checked) {
      updatedArray = input.slice(0, currentIndex + 1).map((ele) => ele.value);
    } else {
      updatedArray = valueArray.filter((value) => value !== event.target.value);
    }

    setValueArray(updatedArray);
    onChange(name, updatedArray);
  };

  //UseEffect
  useEffect(() => {
    if (Array.isArray(defaultArray)) {
      setValueArray(defaultArray);
    }
  }, [defaultArray]);
  return (
    <FormGroup>
      {input.map((ele, index) => (
        <FormControlLabel
          key={ele.value}
          control={
            <Checkbox
              value={ele.value}
              checked={valueArray.includes(ele.value)}
              onChange={handleChange}
            />
          }
          label={ele.menuItem}
        />
      ))}
    </FormGroup>
  );
}

export default CustomCheckBox;
