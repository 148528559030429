import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";

function SingleQrBarcodeDetailsDialog(props) {
  const [containerDetails, setContainerDetails] = React.useState([]);
  const [qualityDetails, setQualityDetails] = React.useState([]);
  const [wfsDetails, setWfsDetails] = React.useState([]);
  const [ownerDetails, setOwnerDetails] = React.useState([]);
  console.log("--containerDetails--", containerDetails);
  React.useEffect(() => {
    const data = [
      {
        key: "Capacity",
        value: "20_Liter",
      },
      {
        key: "Material",
        value: "PET",
      },
      {
        key: "Life Span",
        value: 29,
      },
    ];
    setContainerDetails(data);
    const QualityDetails = [
      { key: "TDS", value: 123 },
      { key: "PH", value: 123 },
      { key: "Mft.date", value: 123 },
      { key: "Exp.date", value: 123 },
      { key: "No.of times can used", value: 123 },
      { key: "Container Status", value: 123 },
      { key: "Can Type", value: 123 },
      { key: "Order Id", value: 123 },
    ];
    setQualityDetails(QualityDetails);
    const wfsDetails = [
      { key: "Filling Station", value: "wfs2" },
      { key: "Status", value: "Available" },
      { key: "Owner name", value: "Milind Ingale" },
      { key: "Phone number", value: 7769059525 },
      { key: "Pincode", value: 603103 },
      { key: "Batch ID", value: "WFSB00719" },
      { key: "Updated By", value: 123 },
    ];
    setWfsDetails(wfsDetails);
    const OwnerDetails = [
      { key: "Owner Type", value: "Customer" },
      { key: "Name", value: "vinay" },
      { key: "Email", value: "MilindIngale@gmail.com" },
      { key: "Phone number", value: 7769059525 },
      { key: "Status", value: "Active" },
    ];
    setOwnerDetails(OwnerDetails);
  }, []);

  const handleCloseDialog = () => {
    props.handleCloseDialog();
  };
  return (
    <Dialog
      open={props.openSingleQrBarcodeDialog}
      // sx={{ padding: 0 }}
      fullScreen
    >
      <DialogContent>
        <Box>
          <AppBar sx={{ background: "linear-gradient(#02173a, #0b48a7)" }}>
            <Toolbar>
              <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Qr/Barcode Details
              </Typography>
              <CustomButton
                label="close"
                color="error"
                handleClick={handleCloseDialog}
              />
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            mt: "60px",
          }}
        >
          <Box sx={{ border: "1px solid black", p: "20px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 800,
                fontSize: "18px",
                textDecoration: "underline",
              }}
            >
              Container Details
            </Typography>

            {containerDetails?.map((data, index) => {
              console.log("data", data);
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 600,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ border: "1px solid black", p: "20px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 800,
                fontSize: "18px",
                textDecoration: "underline",
              }}
            >
              Quality Details
            </Typography>

            {qualityDetails?.map((data, index) => {
              console.log("data", data);
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 600,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ border: "1px solid black", p: "20px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 800,
                fontSize: "18px",
                textDecoration: "underline",
              }}
            >
              Wfs Details
            </Typography>

            {wfsDetails?.map((data, index) => {
              console.log("data", data);
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 600,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ border: "1px solid black", p: "20px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 800,
                fontSize: "18px",
                textDecoration: "underline",
              }}
            >
              Owner Details
            </Typography>

            {ownerDetails?.map((data, index) => {
              console.log("data", data);
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "14", md: "16px" },
                      fontWeight: 600,
                      // marginRight: "15px",
                      textAlign: "left",
                      m: 1,
                    }}
                  >
                    {data.key}:
                  </Typography>

                  <Typography
                    sx={{
                      // color: "white",
                      fontSize: { xs: "12", md: "14px" },
                      fontWeight: 400,
                      textAlign: "right",
                      m: 1,
                    }}
                  >
                    {data.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default SingleQrBarcodeDetailsDialog;
