import {
  AppBar,
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { tokens } from "../../AppMeta/theme";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { buttons } from "../../AppMeta/appColors";
import { sideBarData } from "./sideBarData";
// import useNavigate from "react-router-dom";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import profileActions, { GetProfile } from "../../Redux/Actions/profile";
import useScreenSize from "../../Hooks/CustomHooks/useScreenSize";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  console.log("colors", colors);
  const handleNavigator = () => {
    history.push(to);
    setSelected(title);
  };

  return (
    <MenuItem
      active={selected === title}
      // style={{
      //   color: appStyle.general.colors(colors).sideBarMenuColor,
      //   background:
      //     selected === title &&
      //     appStyle.general.colors(colors).sideMenuBarActive,
      // }}
      rootStyles={{
        backgroundColor:
          selected === title
            ? appStyle.general.colors(colors).sideMenuBarActive
            : appStyle.general.colors(colors).sideBarMenuColor,
        "&:hover": {
          color: "black",
        },
      }}
      onClick={handleNavigator}
      icon={icon}
    >
      <Typography sx={appStyle.general.sideNavBar.menuText}>{title}</Typography>
    </MenuItem>
  );
};

function SideNavigation({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const testColors = tokens(theme);
  const [selected, setSelected] = React.useState("Home");

  const [dashboard, setDashboard] = React.useState([]);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuOptions, setMenuOptions] = React.useState([]);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const history = useHistory();
  const authenticationDetails = useSelector(
    (state) => state.authenticationData
  );
  const profileDetails = useSelector((state) => state.profileData);

  // React.useEffect(() => {
  //   dispatch(GetProfile());
  // }, []);
  // const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  // React.useEffect(() => {
  //   setMenuOptions(() =>
  //     profileDetails?.profile?.role_details?.access_to_pages
  //       ? profileDetails.profile.role_details.access_to_pages
  //       : []
  //   );
  // }, [profileDetails]);
  React.useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);
  React.useEffect(() => {
    console.log("profileDetailssssss", profileDetails);
    setMenuOptions(() =>
      profileDetails?.access_to_pages ? profileDetails?.access_to_pages : []
    );
  }, [profileDetails, sideBarData]);

  // React.useEffect(() => {
  //   setMenuOptions(() =>
  //     authenticationDetails?.menu_options
  //       ? authenticationDetails?.menu_options
  //       : []
  //   );
  // }, [authenticationDetails]);

  //1.useState
  const [open, setOpen] = React.useState(true);

  //3.Funtions
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickToPage = (to) => {
    setIsVisible(false);
    // navigate(to);
    history.push(to);
    // setOpen(!open);
  };
  ////////////////////////
  console.log("sideBarData", sideBarData);
  console.log("menuOptions", menuOptions);
  React.useEffect(() => {
    console.log("profileData");
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
  }, [profileData]);

  // React.useEffect(() => {
  //   // console.log("isFacility", isFacility);
  //   // console.log("domain", domain);
  //   // console.log("orgFacilityName", orgFacilityName);
  //   // if (domain === "BOOKWATER.COM") {
  //   //   setDashboard(sideBarData.bookWaterDashboard);
  //   // } else if (isFacility === true) {
  //   //   setDashboard(sideBarData.facilityDashBoard);
  //   // } else {
  //   //   setDashboard(sideBarData.organisationDashboard);
  //   // }
  //   console.log(
  //     "sideBarData.bookWaterDashboard",
  //     sideBarData.bookWaterDashboard
  //   );
  //   setDashboard(sideBarData.bookWaterDashboard);
  // }, [isFacility, domain, orgFacilityName]);
  // React.useEffect(() => {
  //   // console.log("isFacility", isFacility);
  //   // console.log("domain", domain);
  //   // console.log("orgFacilityName", orgFacilityName);
  //   // if (domain === "BOOKWATER.COM") {
  //   //   setDashboard(sideBarData.bookWaterDashboard);
  //   // } else if (isFacility === true) {
  //   //   setDashboard(sideBarData.facilityDashBoard);
  //   // } else {
  //   //   setDashboard(sideBarData.organisationDashboard);
  //   // }
  //   setDashboard(sideBarData.bookWaterDashboard);
  // }, []);

  React.useEffect(() => {
    console.log("dashboard");
    console.log(dashboard);
  }, [dashboard]);

  React.useEffect(() => {});

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    setIsCollapsed(false);
  }, [isVisible, isMobile]);

  return (
    <Box>
      <Collapse orientation="horizontal" in={isVisible || !isMobile}>
        <Sidebar
          collapsed={isCollapsed}
          backgroundColor={`${colors.primary[500]} !important`}
          rootStyles={{
            padding: "0 0.5rem",
            width: "17rem",
            height: "90vh",
            overflow: "hidden",
            "&::-webkit-scrollbar": {
              width: "5px",
              display: "none",
            },
          }}
        >
          <Menu
            iconShape="square"
            icon={"transparent !important"}
            rootStyles={{
              height: "100vh",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                display: "none",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                display: "none",
              },

              paddingBottom: "40px",
            }}
          >
            {!isMobile && (
              <MenuItem
                className="custom-menu-item"
                rootStyles={{
                  display: "flex",
                  justifyContent: isCollapsed ? "" : "space-between",
                  margin: 0,
                  height: "3rem",
                  border: "1px solid #eff1f4",
                  position: "sticky",
                  // "&:hover": {
                  //   backgroundColor: "red",
                  //   color: "white",
                  // },
                }}
              >
                {isCollapsed ? (
                  <Box onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ margin: "11px" }}>
                      <Typography
                        color="white"
                        sx={{
                          // color: "#0a3f93",
                          fontWeight: 800,
                          fontSize: "13px",
                        }}
                      >
                        POWERED BY BOOKWATER
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: "30px",
                        marginTop: "10px",
                        "&:hover": {
                          color: "#0a3f93",
                        },
                      }}
                      onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                      <ArrowBackIosNewIcon />
                    </Box>
                  </Box>
                )}
              </MenuItem>
            )}

            <Box
            // sx={{
            //   overflowY: "scroll",
            //   "&::-webkit-scrollbar": {
            //     width: "5px",
            //     borderRadius: "3px",
            //   },
            //   "&::-webkit-scrollbar-thumb": {
            //     borderRadius: "10px",
            //   },
            //   "&::-webkit-scrollbar-track": {
            //     borderRadius: "10px",
            //     display: "none",
            //   },
            //   height: "95vh",
            //   paddingBottom: "40px",
            // }}
            >
              <List
                component="nav"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {sideBarData?.map((data, index) => {
                  const titleSide = data.title.toUpperCase();
                  if (
                    menuOptions.find((item) => item.toUpperCase() == titleSide)
                  ) {
                    return (
                      <ListItemButton
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "start",
                          overflow: "hidden",
                          background:
                            window.location.pathname === data.path &&
                            "linear-gradient(#02173a, #0b48a7)",
                          // theme.palette.mode === "dark"
                          //   ? "linear-gradient(#1f78ff, #0b48a7)"
                          //   : "linear-gradient(#02173a, #0b48a7)"
                          // : "",
                          "&:hover": {
                            backgroundColor: buttons.greenHover,
                            color: "white",
                            transform: "scale(1.11)",
                            // height: "70px",
                            // width: "calc(100% + 20px)",
                          },
                          padding: "0.25rem 0.5rem", // Adjust padding
                          margin: "0.25rem 0.5rem", // Remove spacing between buttons
                          borderRadius: "1rem",
                          height: "2.5rem",
                          minHeight: "2.5rem",
                          minWidth: "2rem",
                        }}
                        onClick={() => handleClickToPage(data.path)}
                      >
                        <ListItemIcon
                          size="large"
                          sx={{
                            height: "1.5rem", // Increased size
                            width: "1.5rem", // Increased size

                            fontSize: "24px",
                            marginTop: "4px",
                            color:
                              window.location.pathname === data.path
                                ? "white"
                                : colors.primary[500],
                          }}
                        >
                          {React.cloneElement(data.icon, {
                            style: {
                              fontSize: "24px",

                              color:
                                theme.palette.mode === "dark"
                                  ? "D9D9D9"
                                  : window.location.pathname === data.path
                                  ? "white"
                                  : "black",
                            },
                          })}
                        </ListItemIcon>
                        {!isCollapsed && (
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "15px", // Increased font size
                              fontWeight: "bold", // Optional for bold text
                              color:
                                window.location.pathname === data.path
                                  ? "white"
                                  : "inherit",
                            }}
                            sx={{
                              fontWeight: 800,
                              color:
                                window.location.pathname === data.path
                                  ? "white"
                                  : "inherit",
                            }}
                            primary={data.title}
                          />
                        )}
                      </ListItemButton>
                    );
                  } else {
                    return null;
                  }
                })}
              </List>
              <Box sx={{ marginBottom: "40px" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: "20px",
                  }}
                >
                  version : {process.env.REACT_APP_PAAS_WEB_VERSION}
                </Typography>
              </Box>
            </Box>
          </Menu>
        </Sidebar>
      </Collapse>

      {/* )} */}
    </Box>
  );
}

export default SideNavigation;
