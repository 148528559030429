import { Box, styled, Tab, Typography } from "@mui/material";

const StyledTab = styled(Tab)(({ selected, themeColors }) => ({
  textTransform: "none",
  fontSize: "16px",
  fontWeight: selected ? "bold" : "bold",
  backgroundColor: selected ? themeColors.basic.card_color : "transparent",
  borderBottom: selected ? "none" : "1px solid transparent",
  borderTopLeftRadius: "1rem",
  borderTopRightRadius: "1rem",
  color: selected ? themeColors.basic.secondary_text : "#007BFF",
  "&:hover": {
    textDecoration: !selected && "underline",
  },
  "&.Mui-disabled": {
    color: "#aaa",
    cursor: "default",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export { StyledTab, TabPanel, a11yProps };
