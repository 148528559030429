import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import Html5QrcodePlugin1 from "./Html5QrcodePlugin";
import GeneratedQRBarcodeList from "./GereratedQRBarcodeList";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import CustomForm from "../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../Components/Utils/CustomFormUtils";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomComp/CustomButton";
import {
  GenerateQR,
  GenerateQRWithOutMftOrderId,
  GetGeneratedQR,
  RegenerateQr,
} from "../../Redux/Actions/GenerateQrAction";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import userManagementApi from "../../Redux/Actions/userManagement";
import { toast } from "react-toastify";
import Linking from "../CodesManagement/Linking";
import { makeStyles } from "@mui/styles";
import { ArrowBack, NavigateNext } from "@mui/icons-material";

function GenerateComp({ tabValue, setOpenScannerDialog }) {
  const [generate, setGenerate] = React.useState("GenerateQrCode");
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productData);
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const organisationDetails = useSelector((state) => state.organizationData);
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const wfsDetails = useSelector((state) => state.wfsDetails);
  // const wfsList = useSelector((state) => state.wfsDetails.wfsList);
  const WfsData = useSelector((state) => state.batchData);
  const [allFacility, setAllFacility] = React.useState(
    organisationDetails.organizationList
  );
  const [capacity, setCapacity] = React.useState("");
  const [allWfs, setAllWfs] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [manufacturerorderId, setManufacturerorderId] = React.useState("");
  const [qrbqrCodeQty, setQrbqrCodeQty] = React.useState("");

  const [searchWfs, setSearchWfs] = React.useState("");
  const [searchFacility, setSearchFacility] = React.useState("");
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [productName, setProductName] = React.useState({});
  const [quantity, setQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState("");
  const [manufacturerName, setManufacturerName] = React.useState("");
  const [selectFacility, setSelectFacility] = React.useState("");
  const [manufacturerAddress, setManufacturerAddress] = React.useState("");
  const [wfsValue, setWfsValue] = React.useState("");
  const [openToLink, setOpenToLink] = React.useState(false);

  // const [value, setValue] = React.useState("one");

  React.useEffect(() => {
    searchFacility !== "" && searchAndUpdateFacility();
  }, [searchFacility]);

  React.useEffect(() => {
    console.log("---wfsList---", wfsDetails.wfsLinesList.lineIds);
    if (profileRole !== "1000" || selectFacility !== "" || searchWfs !== "") {
      setAllWfs(wfsDetails.wfsList.data);
    } else if (profileRole === "1000") {
      setAllWfs("");
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    if (profileRole !== "1000" || selectFacility !== "") {
      searchAndUpdateWfs();
    }
  }, [searchWfs, selectFacility]);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  const [isQrSwitch, setIsQrSwitch] = React.useState(true);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const handleChangeGenerateQRBarcode = (event) => {
  //   setGenerate(event.target.value);
  // };
  const useStyles = makeStyles((theme) => ({
    stepper: {
      // Adjust the width of the connector line here
      "& .MuiStepConnector-line": {
        border: "1px solid black", // Change this value to your desired width
      },
    },
    stepLabel: {
      // Adjust the font size of the step label here
      fontSize: "20px", // Change this value to your desired text size
      "& .MuiStepLabel-root:hover": {
        cursor: "pointer",
        color: "blue",
      },
    },
  }));
  const classes = useStyles();
  const handleChangeCapacity = (event) => {
    setCapacity(event.target.value);
  };

  const handleChangeMaterial = (value) => {
    console.log("value", value);
    // setMaterial(value);
    const material = value;
    let duplicateElement =
      value !== undefined
        ? productDetails?.finalProduct?.find(
            (data) => data.product_details.id === material
          )
        : {};

    if (Object.keys(duplicateElement).length > 0) {
      console.log("duplicateElement", {
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
      setProductName({
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
    }
  };
  const searchAndUpdateFacility = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApi.get_all_organization,
        params: {
          call: 1,
          ...(searchFacility !== "" && { org_name: searchFacility }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateWfs = () => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    console.log(decodedText);
    // if (decodedResult.result.format.formatName === "QR_CODE") {
    //   setScannedQrCode(decodedText);
    //   console.log(scanCount);
    // }
    // if (decodedResult.result.format.formatName === "CODE_128") {
    //   setScannedBarCode(decodedText);
    //   console.log(scanCount);
    // }
    // setDecodedResults((prev) => [...prev, decodedResult]);
  };

  const handleGenerateQrbarcode = ({
    pageNumber,
    pageSize,
    createdAtSort,
    isQr,
    qrBarcodeId,
    orderId,
  }) => {
    console.log(productName);
    // const reqObj = {
    //   batch_id: batchId,
    //   container_capacity: productName.capacity,
    //   container_material: productName.material,
    //   mft_address: manufacturerAddress,
    //   mft_name: manufacturerName,
    //   quantity: parseInt(quantity),
    //   // is_url: isQrSwitch,
    // };
    if (generate === "GenerateQrCode" || generate === "GenerateBarCode") {
      const reqObj = {
        // order_id: manufacturerorderId,
        quantity: qrbqrCodeQty,
        product_material: productName.material,
        product_capacity: productName.capacity,
        // is_url: isQrSwitch,
        // wfs_id: selectedWfs,
        batch_id: batchId,
        org_id: selectFacility,
      };
      if (
        reqObj.quantity !== "" &&
        reqObj.product_material !== "" &&
        reqObj.product_capacity !== "" &&
        reqObj.batch_id !== ""
        // reqObj.org_id !== ""
      ) {
        // dispatch(GenerateQR(reqObj));
        dispatch(GenerateQRWithOutMftOrderId(reqObj));
        setTimeout(() => {
          dispatch(
            GetGeneratedQR({
              // page_number: pageNumber,
              // page_size: pageSize,
              created_at_sort: "DESC",
              is_qr: true,
              // qr_barcode_Id: true,
              // order_id: orderId,
            })
          );
          setManufacturerorderId("");
          setQrbqrCodeQty("");
          setWfsValue("");
          setBatchId("");
          setProductName({
            capacity: "",
            material: "",
          });
        }, 1000);
      } else {
        toast.error("Please fill the all data");
      }
    } else if (generate === "Re-GenerateQrCode") {
      console.log("Re-GenerateBarCode");
      const reqObj = {
        is_qr: true,
        quantity: parseInt(quantity),
        org_id: selectFacility,
      };
      console.log("reqObj", reqObj);
      dispatch(RegenerateQr(reqObj));
      setTimeout(() => {
        setMaterial("");
        setManufacturerAddress("");
        setManufacturerName("");
        setBatchId("");
        setQuantity("");
      }, 1000);
    } else if (generate === "Re-GenerateBarCode") {
      const reqObj = {
        is_qr: false,
        quantity: parseInt(quantity),
        org_id: selectFacility,
      };
      console.log("reqObj", reqObj);
      dispatch(RegenerateQr(reqObj));
      setTimeout(() => {
        setMaterial("");
        setManufacturerAddress("");
        setManufacturerName("");
        setBatchId("");
        setQuantity("");
      }, 1000);
    }
  };
  useEffect(() => {
    console.log(generate);
  }, [generate]);

  const handleIsUrlSwitch = (event) => {
    setIsQrSwitch(event.target.checked);
  };

  const orderids = [
    { order_id: "1234567" },
    { order_id: "8765434" },
    { order_id: "765444567" },
  ];

  const handleChangeWfs = (value) => {
    console.log("value", value);
    setWfsValue(value);
  };

  const getProductsWfs = async () => {
    dispatch(GetFinalProductSKU({ ignore_app_loading_spinner: true }));
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          page_number: 1,
          page_size: 500,
          ignore_app_loading_spinner: true,
        },

        show_toast: false,
      })
    );
    searchAndUpdateFacility();
  };

  React.useEffect(() => {
    if (tabValue === "Generate-Qr/Barcode") {
      getProductsWfs();
    }
  }, [tabValue]);
  const steps = ["Generate Qr/Barcode", "Link Qr/Barcode Or Qr Code"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickLabel = (index) => {
    // console.log("------activeStep---------", activeStep);
    // console.log("------index---------", index);
    if (index === 0) {
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
  };

  return (
    <Box
      component={Paper}
      elevation={10}
      sx={{
        padding: "10px",
        borderRadius: "15px",
        width: "100%",
        m: "0px 10px 0px 10px",
      }}
    >
      {" "}
      <>
        {" "}
        {/* <Box>
          <Button
            variant="contained"
            onClick={() => setOpenToLink(!openToLink)}
          >
            Link QR Barcodes
          </Button>
          <Dialog open={openToLink}>
            {openToLink && (
              <Html5QrcodePlugin1
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            )}
            <Button
              variant="outlined"
              onClick={() => setOpenToLink(!openToLink)}
            >
              Close
            </Button>
          </Dialog>
        </Box> */}
        {/* <Box sx={{ padding: "20px" }}>
          <GeneratedQRBarcodeList />
        </Box> */}
      </>
      <Box sx={{ width: "100%", mt: "20px", mb: "10px" }}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={label}
                {...stepProps}
                onClick={() => handleClickLabel(index)}
              >
                <StepLabel {...labelProps} className={classes.stepLabel}>
                  {label}{" "}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 ? (
          <Box
            component={Paper}
            elevation={10}
            sx={{ mt: "20px", pt: "10px", borderRadius: "15px" }}
          >
            {" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 600,
                fontSize: "17px",
                mb: "10px",
              }}
            >
              Generate Qr/Barcode
            </Typography>
            <Divider />
            <Box
              sx={{
                minWidth: 220,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: "10px",
                mt: "10px",
              }}
            >
              <CustomSelect
                name="Select option"
                input={[
                  {
                    value: "GenerateQrCode",
                    menuItem: "Generate QR & Barcode Codes",
                  },
                  // { value: "GenerateBarCode", menuItem: "Generate Bar Code" },
                  {
                    value: "Re-GenerateQrCode",
                    menuItem: "Re-generate QR Code",
                  },
                  {
                    value: "Re-GenerateBarCode",
                    menuItem: " Re-generate Bar Code",
                  },
                ]}
                handleChange={(e) => {
                  setGenerate(e.target.value);
                }}
                defaultValue={generate}
                variant="standard"
                sx={{ width: "11rem" }}
              />
              {/* </FormControl> */}
            </Box>
            <Divider sx={{ border: "1.5px solid #f0f3f7" }} />
            {generate === "GenerateQrCode" || generate === "GenerateBarCode" ? (
              <>
                <Box
                  sx={{
                    mb: "30px",
                    mt: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    gap: { xs: "10px" },
                    flexWrap: "wrap",
                  }}
                >
                  <CustomAutoComplete
                    field={{
                      optionKey: "product_details.capacity",
                      optionKey2: "product_details.material",
                      outputKey: "product_details.id",
                      searchAndUpdateAPI: (searchText) => {},
                      onChange: (name, value) => {
                        handleChangeMaterial(value);
                      },
                      input: productDetails?.finalProduct || [],
                      name: "product_details.id",
                      label: "Select Product",
                    }}
                  />

                  {/* <CustomInputTextField
                placeholder="Enter Manufacturer Order Id"
                value={manufacturerorderId}
                setValue={setManufacturerorderId}
                type={appMeta.textFieldTypes.NUMBER}
                isHintRequired={true}
              /> */}
                  <CustomInputTextField
                    placeholder="Enter Quantity"
                    value={qrbqrCodeQty}
                    setValue={setQrbqrCodeQty}
                    type={appMeta.textFieldTypes.NUMBER}
                    isHintRequired={true}
                  />
                  <CustomAutoComplete
                    field={{
                      optionKey: "org_name",
                      outputKey: "id",
                      onChange: (name, value) => {
                        setSelectFacility(value);
                      },
                      searchAndUpdateAPI: (searchText) => {
                        setSearchFacility(searchText);
                      },
                      input: allFacility || [],
                      name: "facility",
                      label: "Enter Facility Name",
                    }}
                  />
                  {/* <CustomAutoComplete
                field={{
                  optionKey: "wfs_name",
                  outputKey: "wfs_id",
                  onChange: (name, value) => {
                    console.log("---Value--", value);
                    setSelectedWfs(value);
                  },
                  searchAndUpdateAPI: (searchText) => {
                    setSearchWfs(searchText);
                  },
                  input: allWfs || [],
                  name: "wfs",
                  label: "Enter WFS Name",
                }}
              /> */}
                  <CustomInputTextField
                    placeholder="Enter the batch Id"
                    value={batchId}
                    setValue={setBatchId}
                    type={appMeta.textFieldTypes.NUMBER}
                    isHintRequired={true}
                  />
                  {/* <Box>
                <Typography>Is URL :</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AntSwitch
                    checked={isQrSwitch}
                    onChange={handleIsUrlSwitch}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography
                    sx={{
                      color: isQrSwitch === true ? "#30A2F3" : "black",
                    }}
                  >
                    {isQrSwitch === true ? "Enable" : "Disable"}
                  </Typography>
                </Stack>
              </Box> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    //   flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "30px",
                  }}
                >
                  {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          > */}

                  {/* <CustomInputTextField
            placeholder="Enter the quantity"
            value={quantity}
            setValue={setQuantity}
            type={appMeta.textFieldTypes.NUMBER}
            isHintRequired={true}
          /> */}

                  {/* <CustomInputTextField
            placeholder="Enter the batch Id"
            value={batchId}
            setValue={setBatchId}
            type={appMeta.textFieldTypes.NUMBER}
            isHintRequired={true}
          />

          <CustomInputTextField
            placeholder="Manufacturer name"
            value={manufacturerName}
            setValue={setManufacturerName}
            type={appMeta.textFieldTypes.TEXT}
            // isHintRequired={true}
          />

          <CustomInputTextField
            placeholder="Manufacturer Address"
            value={manufacturerAddress}
            setValue={setManufacturerAddress}
            type={appMeta.textFieldTypes.TEXT}
            // isHintRequired={true}
          /> */}
                  {/* </Box> */}
                  {/* ) : generate === "GenerateBarCode" ||
            generate === "Re-GenerateQrCode" ||
            generate === "Re-GenerateBarCode" ? (
            <>
              <CustomInputTextField
                placeholder="Enter the quantity"
                value={quantity}
                setValue={setQuantity}
                type={appMeta.textFieldTypes.NUMBER}
                isHintRequired={true}
              />
            </>
          ) : (
            ""
          )} */}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: { md: "10px", xs: "15px" },
                  flexWrap: "wrap",
                }}
              >
                {/* <Box>
              <Typography>Is URL :</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={isQrSwitch}
                  onChange={handleIsUrlSwitch}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography
                  sx={{
                    color: isQrSwitch === true ? "#30A2F3" : "black",
                  }}
                >
                  {isQrSwitch === true ? "Enable" : "Disable"}
                </Typography>
              </Stack>
            </Box> */}
                <CustomAutoComplete
                  field={{
                    optionKey: "org_name",
                    outputKey: "id",
                    onChange: (name, value) => {
                      setSelectFacility(value);
                    },
                    searchAndUpdateAPI: (searchText) => {
                      setSearchFacility(searchText);
                    },
                    input: allFacility || [],
                    name: "facility",
                    label: "Enter Facility Name",
                  }}
                />
                <CustomInputTextField
                  placeholder="Enter the quantity"
                  value={quantity}
                  setValue={setQuantity}
                  type={appMeta.textFieldTypes.NUMBER}
                  isHintRequired={true}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CustomButton
                label="generate"
                handleClick={handleGenerateQrbarcode}
              />
            </Box>
          </Box>
        ) : (
          <Linking
            setOpenScannerDialog={setOpenScannerDialog}
            activeStep={activeStep}
          />
        )}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {/* <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button> */}
              <CustomButton
                color="inherit"
                label="back"
                startIcon={<ArrowBack />}
                disabled={activeStep === 0}
                handleClick={handleBack}
              />
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button disabled color="inherit" onClick={handleSkip}></Button>
              )}
              <CustomButton
                handleClick={handleNext}
                startIcon={<NavigateNext />}
                disabled={activeStep === steps.length - 1}
                label={activeStep === steps.length - 1 ? "Finished" : "Next"}
              />
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}

export default GenerateComp;
