import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import DistriburoePlaceOrder from "./DistriburoePlaceOrder";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment/moment";

function DistributorBookNowMain() {
  const dispatch = useDispatch();
  const listOfEmpptyordesr = useSelector(
    (state) => state.userManagementReducer.distributor_wfs_orders_List
  );

  const [opendistributorPlaceOrder, setOpendistributorPlaceOrder] =
    React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [pageNumber, setPageNumber] = React.useState(1);

  React.useEffect(() => {
    hitgetordersApi();
  }, [pageSize, pageNumber]);
  const hitgetordersApi = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_wfs_orders,
        show_toast: false,
      })
    );
  };

  const handleOpenPlaceOrderDrawer = () => {
    setOpendistributorPlaceOrder(true);
  };
  const handleSubmitDrawer = (reqObj) => {
    console.log("reqObj", reqObj);
    if (
      reqObj.wfs_id !== "" &&
      reqObj.container_master_id !== "" &&
      reqObj.order_quantity !== ""
    ) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.create_order_distributor_to_wfs,
          params: reqObj,
          show_toast: true,
        })
      ).then(() => {
        hitgetordersApi();
      });
      setOpendistributorPlaceOrder(false);
    } else {
      toast.error("Please Send Mandatory Field");
    }
  };
  const handleCloseDrawer = () => {
    setOpendistributorPlaceOrder(false);
  };
  return (
    <Box>
      {" "}
      <CustomTable
        tableHead={[
          "Order Id",
          "Product",
          "Order Qty",
          "Return Qty",
          "Requested To",
          "Created On",
          "Status",
        ]}
        tableData={listOfEmpptyordesr}
        tableDataKey={[
          "ec_order_id",
          {
            component: (props) => {
              return (
                <Typography>
                  {props.row.container_capacity}-{props.row.container_material}
                </Typography>
              );
            },
          },
          "order_quantity",
          "return_quantity",
          "wfs_name",
          {
            rowClickEnable: true,
            component: (props) => {
              console.log("propss", props);
              return moment(props?.row?.created_at).format("DD-MM-YYYY");
            },
          },
          {
            component: function (props) {
              return (
                <Typography sx={{ fontSize: "12px" }}>
                  {props.row.ec_order_status}
                </Typography>
              );
            },
          },
          // "WFS_NAME",
          // "Wfs_Incharge_Name",
          // "Wfs_Incharge_Phone",
          // "created_at",
          // "updated_by",
          // {
          //   rowClickEnable: true,
          //   component: (props) => {
          //     console.log("propss", props);
          //     return moment(props?.row?.created_at).format("DD-MM-YYYY");
          //   },
          // },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "order_id", label: "order_id" },
          { value: "status", label: "status" },
        ]}
        // handleFilterClose={handleWfsFilterClose}
        sort={true}
        sortSelectOption={[
          { value: "created_at_sort", label: "Created at" },
          { value: "wfs_name_sort", label: "WFS name" },
          { value: "facility_name_sort", label: "Facility name" },
        ]}
        // handleSortClose={handleWfsSortClose}
        addButton={true}
        handleOnClickAddButton={handleOpenPlaceOrderDrawer}
        // tableRowClickFunction={(index, props) => handleRowClick(props)}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
      <DistriburoePlaceOrder
        opendistributorPlaceOrder={opendistributorPlaceOrder}
        handleSubmitDrawer={handleSubmitDrawer}
        handleCloseDrawer={handleCloseDrawer}
      />
    </Box>
  );
}

export default DistributorBookNowMain;
