/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import * as Material from "@mui/material";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
// import { AddingNewPincodes } from "../../Pages/Redux/Actions/pincodeAndServiceAreaAction";
import { toast } from "react-toastify";
import {
  AddPincodeAreas,
  AddProductsToLocalities,
} from "../../Redux/Actions/pincodeAreasAction";
import { ConstructionOutlined } from "@mui/icons-material";
import appStyle from "../../AppMeta/appStyle";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
// import {
//   AddPincodeSeriviceAreaSku,
//   DeleteProductFromLocality,
//   UpdatePincodePriceSku,
// } from "../../Pages/Redux/Actions/skuPincodeAction";
// import ConformationDialog from "./conformationDialog";

function AddUpdatePicodeAreaSku({
  openPincodeDialog,
  setOpenPicodeDialog,
  updatePincodeData,
  setupdatePincodeData,
  setDrawOpenProp,
  allProducts,

  setDrawerOpen,
}) {
  // const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  let initialPicodeData = {
    pincode: "",
    country: "",
    country_code: "IN",
    phone_code: "+91",
    district: "",
    state: "",
    locality: "",
    products: [],
  };
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [pincodeData, setPincodeData] = useState(initialPicodeData);
  const [serviceArea, setServiceArea] = React.useState("");
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [productList, setProductList] = React.useState({
    product_id: "",
    booking_type: "",
    name: "",
    refill: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 100,
      refund_amount: 0,
    },
    one_time_sale: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 0,
      refund_amount: 0,
    },
  });
  const [deleteProduct, setDeleteProduct] = React.useState({
    locality_id: "",
    products: [],
  });
  console.log("-----updatePincodeData-----", updatePincodeData);
  const updateFlag = Object.keys(updatePincodeData)?.length > 0;

  const dispatch = useDispatch();
  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: "100",
    },
    {
      label: "WATER DROPS",
      value: "200",
    },
    {
      label: "None",
      value: "0",
    },
  ];

  //useEffect for setting up initial data in state
  console.log("----UpdateFlag---", updateFlag);
  React.useEffect(() => {
    if (updateFlag) {
      setPincodeData((prev) => ({
        products: updatePincodeData.products,
        locality_ids: [updatePincodeData.id],
      }));
    }
  }, [updatePincodeData, updateFlag]);

  // handle change function for adding pincode details
  const handelChangePicodeData = (event) => {
    const { name, value } = event.target;
    let pinCodeRegex = /^[1-9][0-9]{5}$/;
    if (name === "pincode" && !pinCodeRegex.test(value)) {
      setErrors(true);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    } else {
      setErrors(false);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    }
  };

  //UseEffect for validation
  React.useEffect(() => {
    if (pincodeData.country?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.district?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.state?.trim().length < 3) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [pincodeData]);

  // handle change function for adding locality
  const handleAddServiceArea = () => {
    if (serviceArea?.trim() !== "") {
      setPincodeData((prev) => ({
        ...prev,
        locality: [...prev.locality, serviceArea],
      }));
      setServiceArea("");
    } else {
    }
  };

  // remove area from locality array
  const removeAreaFrArr = (idx) => {
    const newLocality = [...pincodeData.locality];
    newLocality.splice(idx, 1);
    setPincodeData((prev) => ({
      ...prev,
      locality: newLocality,
    }));
  };

  // function for closing dialog
  const handelClose = () => {
    setOpenPicodeDialog(false);
    setPincodeData(initialPicodeData);
    // setDrawOpenProp(false);
    setDrawerOpen(false);
    setupdatePincodeData({});
  };

  //function for handling product selection change
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = allProducts.find(
      (data) => data?.product_details?.name === event.target.value
    );
    console.log(selectedDetails);
    if (selectedDetails) {
      setProductList((prev) => ({
        ...prev,
        name: selectedDetails?.product_details?.name,
        product_id: selectedDetails?.product_details?.id,
        booking_type: selectedDetails?.product_details?.booking_type,
        refill: {
          ...prev.refill,
          mrp: selectedDetails?.product_details?.refill?.mrp,
          sp: selectedDetails?.product_details?.refill?.sp,
          deposit: selectedDetails?.product_details?.refill?.deposit,
          refund_type: selectedDetails?.product_details?.refill?.refund_type,
          refund_amount:
            selectedDetails?.product_details?.refill?.refund_amount,
        },
        one_time_sale: {
          ...prev.one_time_sale,
          mrp: selectedDetails?.product_details?.one_time_sale?.mrp,
          sp: selectedDetails?.product_details?.one_time_sale?.sp,
          deposit: selectedDetails?.product_details?.one_time_sale?.deposit,
          refund_type:
            selectedDetails?.product_details?.one_time_sale?.refund_type,
          refund_amount:
            selectedDetails?.product_details?.one_time_sale?.refund_amount,
        },
      }));
    }
  };

  //function for handling add product detaisls
  const handelAddProduct = () => {
    if (Object.keys(productList).length > 0) {
      let duplicateElement = pincodeData.products.filter(
        (data) => data.product_id === productList.product_id
      );
      if (duplicateElement.length === 0) {
        setPincodeData((prev) => ({
          ...prev,
          products: [...prev.products, productList],
        }));
        setProductList({});
      }
    } else {
    }
  };

  //function for handling product change
  const handleProductDetailsChange = (event, index, saleType, field) => {
    const newValue =
      event.target.value.trim() !== "" ? parseFloat(event.target.value) : 0;
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [saleType]: {
                ...product[saleType],
                [field]: newValue,
              },
            }
          : product
      ),
    }));
  };

  //function for handling product Booking type change
  const handleProductBookingTypeChange = (value, index, field) => {
    const newValue = value;
    console.log(newValue);
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [field]: newValue,
            }
          : product
      ),
    }));
  };

  //useEffect for closing dialog after sucessfull adding of pincode
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.addPincodeError) {
  //     handelClose();
  //     setPincodeData(initialPicodeData);
  //   }
  // }, [skuPincodeDetails.addPincodeError]);

  // console.log(skuPincodeDetails);
  //function for updating data
  const handelUpdateData = () => {
    // console.log(pincodeData);
    if (!updateFlag) {
      // dispatch(AddPincodeSeriviceAreaSku(pincodeData));
      dispatch(AddPincodeAreas(pincodeData));
      setOpenPicodeDialog(false);
    } else {
      UpdatePincodePrice(pincodeData);
    }
  };

  //function for updating pincode price detaisl
  const UpdatePincodePrice = () => {
    dispatch(AddProductsToLocalities(pincodeData));
    setOpenPicodeDialog(false);
  };

  //useEffect for hitting api
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.updatePincodeError) {
  //     handelClose();
  //     setPincodeData(initialPicodeData);
  //     setupdatePincodeData({});
  //   }
  // }, [skuPincodeDetails.updatePincodeError]);

  //function for opening conformation dialog
  const handelDeleteProduct = (value, idx) => {
    console.log(value, idx);
    if (updateFlag) {
      setConfirmDialog(true);
      setDeleteProduct((prev) => ({
        locality_id: updatePincodeData.id,
        products: [value.product_id],
      }));
    } else {
      const newProductList = [...pincodeData.products];
      newProductList.splice(idx, 1);
      setPincodeData((prev) => ({
        ...prev,
        products: newProductList,
      }));
    }
  };

  //function for deleting product from locality
  const handelConformDelete = () => {
    // dispatch(DeleteProductFromLocality(deleteProduct));
    setConfirmDialog(false);
  };

  //useEffect for hitting api
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.deleteProductError) {
  //     handelClose();
  //   }
  // }, [skuPincodeDetails.deleteProductError]);

  console.log(confirmDialog);
  return (
    <Box>
      {/* <CustomForm
            data={[
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Pincode",
                defaultvalue: "",
                name: "pincode",
                textFieldType: appMeta.textFieldTypes.PINCODE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "District",
                defaultvalue: "",
                name: "district",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "State",
                defaultvalue: "",
                name: "state",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Country",
                defaultvalue: "",
                name: "country",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Country code",
                defaultvalue: "",
                name: "country_code",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Service area",
                defaultvalue: "",
                name: "serviceArea",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.SELECT,
                placeHolder: "Product",
                defaultvalue: "",
                name: "pincode",
                isHintRequired: true,
                isRequired: true,
              },
            ]}
            handleSubmit={handelUpdateData}
          /> */}
      <Card>
        <CardContent>
          {!updateFlag ? (
            <>
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="number"
                      label="Pincodes*"
                      name="pincode"
                      size="small"
                      value={pincodeData.pincode}
                      inputProps={{
                        maxLength: 6,
                      }}
                      onChange={handelChangePicodeData}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      label="District*"
                      name="district"
                      size="small"
                      value={pincodeData.district}
                      onChange={handelChangePicodeData}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      name="state"
                      label="State*"
                      size="small"
                      value={pincodeData.state}
                      onChange={handelChangePicodeData}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      label="Country*"
                      name="country"
                      size="small"
                      value={pincodeData.country}
                      onChange={handelChangePicodeData}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      id="outlined-read-only-input"
                      name="country_code"
                      label="Country_code*"
                      size="small"
                      value={pincodeData.country_code}
                      onChange={handelChangePicodeData}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      id="outlined-read-only-input"
                      name="locality"
                      label="locality*"
                      size="small"
                      value={pincodeData.locality}
                      onChange={handelChangePicodeData}
                      InputProps={{
                        readOnly: false,
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    container
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: "1rem" }}
                  >
                    <TextField
                      type="text"
                      label="Service Area*"
                      name="locality"
                      size="small"
                      value={serviceArea}
                      onChange={(e) => setServiceArea(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contained"
                              size="small"
                              onClick={handleAddServiceArea}
                              sx={appStyle.general.button.blueButton}
                            >
                              Add
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid> */}
                </Grid>
              </Stack>
              {/* <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                {pincodeData.locality.map((val, idx) => (
                  <Grid item key={idx}>
                    <Box
                      sx={{
                        padding: "5px",
                        // backgroundColor: "#C9EEFF",
                        display: "flex",
                        borderRadius: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "sans-serif",
                        }}
                      >
                        {val.length > 15 ? val.slice(0, 15) + "...." : val}
                      </Typography>
                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          removeAreaFrArr(idx);
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid> */}
            </>
          ) : (
            <Typography>LOCALITY:{updatePincodeData?.locality}</Typography>
          )}
          {/* <Typography>LOCALITY:{updatePincodeData?.locality}</Typography> */}
          <Grid
            container
            spacing={2}
            sx={{ marginTop: ".5rem" }}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
              <InputLabel id="demo-select-small">Product*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={productList.name}
                label="Capacity"
                onChange={handleChangeSelectedProduct}
              >
                {allProducts?.map((data) => (
                  <MenuItem
                    value={data?.product_details?.name}
                    key={data?.product_details?.id}
                  >
                    <div>
                      <span>
                        {" "}
                        <strong>{data?.product_details?.name}</strong>
                      </span>
                      <br />
                      <small>
                        Capacity:
                        {data?.product_details?.capacity}/
                      </small>
                      <small>
                        Material:
                        {data?.product_details?.material}
                      </small>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handelAddProduct}
              variant="contained"
              size="small"
              sx={appStyle.general.button.blueButton}
            >
              Add
            </Button>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
            {pincodeData.products.map((val, idx) => (
              <Grid item xs={12} key={idx}>
                <Material.Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 1}
                      value={val.booking_type}
                      label="Refill"
                      onChange={() =>
                        handleProductBookingTypeChange(1, idx, "booking_type")
                      }
                    />
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 2}
                      value={val.booking_type}
                      label="One time sale"
                      onChange={() =>
                        handleProductBookingTypeChange(2, idx, "booking_type")
                      }
                    />
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 3}
                      value={val.booking_type}
                      label="Both"
                      onChange={() =>
                        handleProductBookingTypeChange(3, idx, "booking_type")
                      }
                    />
                    <>
                      <DeleteIcon
                        sx={{ color: "red" }}
                        onClick={() => handelDeleteProduct(val, idx)}
                      />
                    </>
                  </Box>
                </Material.Grid>
                <Box
                  sx={{
                    border: ".5px solid gray",
                    borderRadius: "10px",
                    padding: ".5rem",
                  }}
                >
                  {val.booking_type == 2 || val.booking_type == 3 ? (
                    <>
                      <Typography>
                        {val.name}
                        {val.name !== undefined ? "/" : null} {val.capacity}
                        {val.material !== undefined ? "/" : null}
                        {val.material}/One Time Sale
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          marginTop: "1rem",
                        }}
                      >
                        <TextField
                          type="number"
                          label="Deposit"
                          name="deposit"
                          size="small"
                          value={val.one_time_sale.deposit}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "deposit"
                            )
                          }
                        />
                        <TextField
                          type="number"
                          label="MRP"
                          name="mrp"
                          size="small"
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "mrp"
                            )
                          }
                          value={val.one_time_sale.mrp}
                        />
                        <Material.TextField
                          id="outlined-select-currency-native"
                          select
                          sx={{
                            width: { sm: "70%", xs: "100%" },
                          }}
                          size="small"
                          label="Refund Type"
                          defaultValue={val.one_time_sale.refund_type}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "refund_type"
                            )
                          }
                        >
                          {refundTypes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Material.TextField>
                        <TextField
                          type="number"
                          label="Refund Amount"
                          name="refund_amount"
                          size="small"
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "refund_amount"
                            )
                          }
                          value={val.one_time_sale.refund_amount}
                        />
                        <TextField
                          type="text"
                          label="Special Price"
                          name="sp"
                          size="small"
                          value={val.one_time_sale.sp}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "sp"
                            )
                          }
                        />
                      </Box>
                    </>
                  ) : null}
                  {val.booking_type == 1 || val.booking_type == 3 ? (
                    <>
                      <Typography>
                        {" "}
                        {val.name}
                        {val.name !== undefined ? "/" : null} {val.capacity}
                        {val.material !== undefined ? "/" : null}
                        {val.material}/Refill Sale
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,

                          marginTop: "1rem",
                        }}
                      >
                        <TextField
                          type="number"
                          label="Deposit"
                          name="deposit"
                          size="small"
                          value={val.refill.deposit}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "deposit"
                            )
                          }
                        />
                        <TextField
                          type="number"
                          label="MRP"
                          name="mrp"
                          size="small"
                          value={val.refill.mrp}
                          onChange={(e) =>
                            handleProductDetailsChange(e, idx, "refill", "mrp")
                          }
                        />
                        <Material.TextField
                          id="outlined-select-currency-native"
                          select
                          sx={{
                            width: { sm: "70%", xs: "100%" },
                          }}
                          size="small"
                          label="Refund Type"
                          defaultValue={val.refill.refund_type}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "refund_type"
                            )
                          }
                        >
                          {refundTypes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Material.TextField>
                        <TextField
                          type="number"
                          label="Refund Amount"
                          name="refund_amount"
                          size="small"
                          value={val.refill.refund_amount}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "refund_amount"
                            )
                          }
                        />
                        <TextField
                          type="text"
                          label="Special Price"
                          name="country"
                          size="small"
                          value={val.refill.sp}
                          onChange={(e) =>
                            handleProductDetailsChange(e, idx, "refill", "sp")
                          }
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        {/* <ConformationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              dialogMsg="Are you sure to delete Product from Locality"
              handelProceed={handelConformDelete}
            /> */}
      </Card>
      <Button onClick={handelClose} sx={appStyle.general.button.redButton}>
        Close
      </Button>
      <Button
        disabled={errors}
        onClick={handelUpdateData}
        variant="contained"
        size="small"
        sx={appStyle.general.button.blueButton}
      >
        Update
      </Button>
      <Dialog open={openPincodeDialog} onClose={handelClose}>
        <DialogContent>
          <DialogTitle>Add/Update Pincode</DialogTitle>
          <Typography>LOCALITY:{updatePincodeData?.locality}</Typography>
          <Grid
            container
            spacing={2}
            sx={{ marginTop: ".5rem" }}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
              <InputLabel id="demo-select-small">Product*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={productList.name}
                label="Capacity"
                onChange={handleChangeSelectedProduct}
              >
                {allProducts?.map((data) => (
                  <MenuItem
                    value={data?.product_details?.name}
                    key={data?.product_details?.id}
                  >
                    <div>
                      <span>
                        {" "}
                        <strong>{data?.product_details?.name}</strong>
                      </span>
                      <br />
                      <small>
                        Capacity:
                        {data?.product_details?.capacity}/
                      </small>
                      <small>
                        Material:
                        {data?.product_details?.material}
                      </small>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handelAddProduct}
              variant="contained"
              size="small"
              sx={appStyle.general.button.blueButton}
            >
              Add
            </Button>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
            {pincodeData.products.map((val, idx) => (
              <Grid item xs={12} key={idx}>
                <Material.Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 1}
                      value={val.booking_type}
                      label="Refill"
                      onChange={() =>
                        handleProductBookingTypeChange(1, idx, "booking_type")
                      }
                    />
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 2}
                      value={val.booking_type}
                      label="One time sale"
                      onChange={() =>
                        handleProductBookingTypeChange(2, idx, "booking_type")
                      }
                    />
                    <Material.FormControlLabel
                      control={<Radio />}
                      checked={val.booking_type == 3}
                      value={val.booking_type}
                      label="Both"
                      onChange={() =>
                        handleProductBookingTypeChange(3, idx, "booking_type")
                      }
                    />
                    <>
                      <DeleteIcon
                        sx={{ color: "red" }}
                        onClick={() => handelDeleteProduct(val, idx)}
                      />
                    </>
                  </Box>
                </Material.Grid>
                <Box
                  sx={{
                    border: ".5px solid gray",
                    borderRadius: "10px",
                    padding: ".5rem",
                  }}
                >
                  {val.booking_type == 2 || val.booking_type == 3 ? (
                    <>
                      <Typography>
                        {val.name}
                        {val.name !== undefined ? "/" : null} {val.capacity}
                        {val.material !== undefined ? "/" : null}
                        {val.material}/One Time Sale
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          marginTop: "1rem",
                        }}
                      >
                        <TextField
                          type="number"
                          label="Deposit"
                          name="deposit"
                          size="small"
                          value={val.one_time_sale.deposit}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "deposit"
                            )
                          }
                        />
                        <TextField
                          type="number"
                          label="MRP"
                          name="mrp"
                          size="small"
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "mrp"
                            )
                          }
                          value={val.one_time_sale.mrp}
                        />
                        <Material.TextField
                          id="outlined-select-currency-native"
                          select
                          sx={{
                            width: { sm: "70%", xs: "100%" },
                          }}
                          size="small"
                          label="Refund Type"
                          defaultValue={val.one_time_sale.refund_type}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "refund_type"
                            )
                          }
                        >
                          {refundTypes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Material.TextField>
                        <TextField
                          type="number"
                          label="Refund Amount"
                          name="refund_amount"
                          size="small"
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "refund_amount"
                            )
                          }
                          value={val.one_time_sale.refund_amount}
                        />
                        <TextField
                          type="text"
                          label="Special Price"
                          name="sp"
                          size="small"
                          value={val.one_time_sale.sp}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "one_time_sale",
                              "sp"
                            )
                          }
                        />
                      </Box>
                    </>
                  ) : null}
                  {val.booking_type == 1 || val.booking_type == 3 ? (
                    <>
                      <Typography>
                        {" "}
                        {val.name}
                        {val.name !== undefined ? "/" : null} {val.capacity}
                        {val.material !== undefined ? "/" : null}
                        {val.material}/Refill Sale
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,

                          marginTop: "1rem",
                        }}
                      >
                        <TextField
                          type="number"
                          label="Deposit"
                          name="deposit"
                          size="small"
                          value={val.refill.deposit}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "deposit"
                            )
                          }
                        />
                        <TextField
                          type="number"
                          label="MRP"
                          name="mrp"
                          size="small"
                          value={val.refill.mrp}
                          onChange={(e) =>
                            handleProductDetailsChange(e, idx, "refill", "mrp")
                          }
                        />
                        <Material.TextField
                          id="outlined-select-currency-native"
                          select
                          sx={{
                            width: { sm: "70%", xs: "100%" },
                          }}
                          size="small"
                          label="Refund Type"
                          defaultValue={val.refill.refund_type}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "refund_type"
                            )
                          }
                        >
                          {refundTypes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Material.TextField>
                        <TextField
                          type="number"
                          label="Refund Amount"
                          name="refund_amount"
                          size="small"
                          value={val.refill.refund_amount}
                          onChange={(e) =>
                            handleProductDetailsChange(
                              e,
                              idx,
                              "refill",
                              "refund_amount"
                            )
                          }
                        />
                        <TextField
                          type="text"
                          label="Special Price"
                          name="country"
                          size="small"
                          value={val.refill.sp}
                          onChange={(e) =>
                            handleProductDetailsChange(e, idx, "refill", "sp")
                          }
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {" "}
          <Button onClick={handelClose} sx={appStyle.general.button.redButton}>
            Close
          </Button>
          <Button
            disabled={errors}
            onClick={handelUpdateData}
            variant="contained"
            size="small"
            sx={appStyle.general.button.blueButton}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUpdatePicodeAreaSku;
