/*
<CustomTab
        bgColor="#d4e2ea"
        defaultValue="recycle"
        input={[
          {
            value: "recycle",
            label: "Recycle",
            component: <RecycleHomePage />,
          },
          {
            value: "iot",
            label: "IOT",
            component: <IOTGraph />,
          },
        ]}
      />

*/

import React from "react";
import { a11yProps, StyledTab, TabPanel } from "./TabStyle";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../AppMeta/theme";

function CustomTab({
  input,
  bgColor = "#c1d8f7",
  defaultValue,
  setValue,
  value,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [value, setValue] = React.useState(defaultValue);
  return (
    <Box sx={{ padding: "5px" }}>
      <Box
        sx={{
          // width: "100%",
          backgroundColor: bgColor,
          p: 2,
          borderRadius: "1rem",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            display: "flex",
            background: bgColor,
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
            borderBottom: "0px solid transparent",
            // scrollButtons="auto",

            "@media (max-width: 600px)": {
              flexWrap: "nowrap", // Prevent wrapping and ensure tabs remain in a row
            },
          }}
        >
          {input.map((tab) => (
            <StyledTab
              label={tab.label}
              {...a11yProps(tab.value)}
              selected={value === tab.value}
              themeColors={colors}
              onClick={() => setValue(tab.value)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                display: "flex",
                "@media (max-width: 600px)": {
                  flexWrap: "nowrap", // Prevent wrapping and ensure tabs remain in a row
                },
              }}
            />
          ))}
        </Box>

        {/* Tab Panels */}
        <Box sx={{ background: colors.basic.card_color }}>
          {input.map((tab) => (
            <TabPanel value={value} index={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CustomTab;
