import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomAccordion from "../../../Components/CustomComp/CustomAccordion";
import QRConfiguration from "./QRConfiguration";
import WaterDetailConfiguration from "./WaterDetailConfiguration";
import RewardsConfiguration from "./RewardsConfiguration";
import ScanningLevelConfiguration from "./ScanningLevelConfiguration";
import SelectOrganization from "./SelectOrganization";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { masterConfigApi } from "../../../Redux/Reducers/masterConfigApi";

function MasterConfigurationForm() {
  //hookss
  const dispatch = useDispatch();

  //state
  const [masterConfigObject, setMasterConfigObject] = useState(null);

  //function
  const handleMasterSettingClick = async () => {
    const res = await dispatch(
      HandleApiActions({
        ...masterConfigApi.update_master_setting,
        params: masterConfigObject,
        show_toast: true,
      })
    );

    if (res.data) {
      handleGetFacilitySetting();
    }
  };

  const handleGetFacilitySetting = async (org_id) => {
    const res = await dispatch(
      HandleApiActions({
        ...masterConfigApi.get_master_setting,
        params: { org_id: 6 },
        show_toast: true,
      })
    );
    setMasterConfigObject(res.data);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "sticky",
          top: 5,
          zIndex: 1000,
        }}
      >
        {masterConfigObject !== null && (
          <CustomButton
            size="large"
            label="Save"
            startIcon={<SaveIcon />}
            handleClick={handleMasterSettingClick}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SelectOrganization
            getId={(org_id) => {
              handleGetFacilitySetting(6);
            }}
          />
        </Box>

        {masterConfigObject !== null && (
          <CustomAccordion
            title={"QR"}
            body={
              <QRConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Scanning level"}
            body={
              <ScanningLevelConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Water quality details"}
            body={
              <WaterDetailConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
        {masterConfigObject !== null && (
          <CustomAccordion
            title={"Rewards"}
            body={
              <RewardsConfiguration
                masterConfigObject={masterConfigObject}
                setMasterConfigObject={setMasterConfigObject}
              />
            }
          />
        )}
      </Box>
    </Box>
  );
}

export default MasterConfigurationForm;
