/*
<CustomFileUploadButton
                            field={{ name: "poFile", placeHolder: "Upload PO" }}
                            // hideDownloadButton={true}
                            formData={{
                              poFile: poUrl,
                            }}
                            errors={{
                              poFile: false,
                            }}
                            handleInputChange={(name, value) => {
                              // setPoUrl(value);
                              console.log("--file--", name, value);
                              handleChangePoUpload(value);
                            }}
                            // uploadButton={<UploadIcon />}
                          />
*/

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import profileActions from "../../Redux/Actions/profile";
import DownloadIcon from "@mui/icons-material/Download";

function CustomFileUploadButton({
  handleInputChange,
  field,
  formData,
  errors,
  hideUploadButton,
  hideDownloadButton,
  downloadIcon,
  iconColor = "black",
  uploadButton,
}) {
  //hooks
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profileData);

  //state
  const [hintText, setHintText] = useState(
    formData[field?.name] !== "" ? formData[field?.name] : ""
  );

  const handelUploadFile = async (event) => {
    const file = event.target.files[0];

    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
      if (allowedExtensions.includes(fileExtension)) {
        // Upload the Profile Picture => Get the file name
        let res = await dispatch(
          HandleApiActions({
            ...profileActions.upload_profile_pic,
            show_toast: true,
            file: file,
            is_file: true,
          })
        );

        handleInputChange(event.target.name, res.file_name);
        setHintText(file.name);
        //  Update the profile with the profile picture
      } else {
        toast.dark("Please select a valid image file", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  const getFile = async (fileName) => {
    console.log(fileName);
    let token = localStorage.getItem("token");
    if (token === "" || token === null) {
      token = sessionStorage.getItem("token");
    }
    console.log("Token", token);
    const url = process.env.REACT_APP_BASE_URL;
    let fileType;
    if (fileName !== "") {
      fileType = fileName.split(".")[1];
    } else {
      fileType = "png";
    }
    if (token !== "") {
      await fetch(`${url}/PSCM/file/${fileName}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileType}`);
          document.body.appendChild(link);
          link.click();
          console.log("getFilesAction" + link.href);
          toast.success("Successfully Downloaded", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.log("getFiles Action Error : " + error);
        });
    }
  };

  const handleDownloadFile = async () => {
    getFile(formData[field?.name]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box>
        {!hideUploadButton && (
          <Button component="label">
            <input
              id={field?.name}
              accept="image/*"
              type="file"
              onChange={handelUploadFile}
              placeholder={field?.placeholder}
              name={field?.name}
              style={{ display: "none" }}
            />
            {uploadButton === undefined ? (
              <label
                htmlFor={field?.name}
                style={{
                  display: "inline-block",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  backgroundColor:
                    formData[field?.name] !== ""
                      ? "green"
                      : errors[field?.name]
                      ? "red"
                      : "blue",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s ease",
                  width: "20ch",
                }}
              >
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <UploadFileIcon />
                  {field?.placeHolder}
                </Box>
              </label>
            ) : (
              <Box>{uploadButton}</Box>
            )}
          </Button>
        )}
        {formData[field?.name] !== "" && !hideDownloadButton && (
          <Button onClick={handleDownloadFile} sx={{ color: iconColor }}>
            {downloadIcon !== undefined ? downloadIcon : <DownloadIcon />}
          </Button>
        )}
      </Box>
      {formData[field?.name] !== "" && !hideUploadButton && (
        <Typography
          sx={{
            color: "black",
            fontSize: "10px",
          }}
        >
          {hintText}
        </Typography>
      )}
    </Box>
  );
}

export default CustomFileUploadButton;
