import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CodesListTable from "./CodesListTable";
import { useDispatch, useSelector } from "react-redux";
import { GetGeneratedQR } from "../../Redux/Actions/GenerateQrAction";
import CustomButton from "../../Components/CustomComp/CustomButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Linking from "./Linking";
import Scanner from "../../Components/QrCodeScanner/Scanner";
import CustomScannerDialog from "../../Components/CustomComp/CustomScannerDialog";
import appStyle from "../../AppMeta/appStyle";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import appData from "../../AppMeta/appData";
import GenerateQRBarcode from "../Home/GenerateQRBarcode";
import CustomTab from "../../Components/CustomComp/CustomTab/CustomTab";

function CodesManagement() {
  const dispatch = useDispatch();
  const generatedCodesDetails = useSelector((state) => state.generateQrData);
  const linkingData = useSelector((state) => state.linkingData);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [valuenewTab, setValuenewTab] = React.useState("Generate-Qr/Barcode");
  console.log("----valuenewTab--------", valuenewTab);
  const [createdAtSort, setCreatedAtSort] = React.useState("DESC");
  const [isQr, setisQr] = React.useState(true);
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [orderId, setOrderId] = React.useState("");

  const [generatedCodesList, setGeneratedCodesList] = React.useState([]);
  const [value, setValue] = React.useState("Generate-Qr/Barcode");
  console.log("value", value);
  const [openScannerDialog, setOpenScannerDialog] = React.useState(false);

  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes")) || []
  );

  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   dispatch(
  //     GetGeneratedQR({
  //       page_number: pageNumber,
  //       page_size: pageSize,
  //       created_at_sort: createdAtSort,
  //       is_qr: isQr,
  //       qr_barcode_Id: qrBarcodeId,
  //       order_id: orderId,
  //     })
  //   );
  // }, [pageNumber, pageSize, createdAtSort, isQr]);

  React.useEffect(() => {
    console.log(`generatedCodesDetails`);
    if (generatedCodesDetails.error === false) {
      setLastPageNumber(
        generatedCodesDetails.generatedCodesList.last_page_number
      );
      setGeneratedCodesList(generatedCodesDetails?.generatedCodesList?.data);
    }
  }, [generatedCodesDetails]);

  React.useEffect(() => {
    console.log("current Scanned");
    console.log(currentScanned);
    if (currentScanned.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
    const current_scanned_stored =
      JSON.parse(localStorage.getItem("current_scanned_codes")) || [];
    console.log("current_scanned_stored");
    console.log(current_scanned_stored);
  }, [currentScanned]);

  React.useEffect(() => {
    console.log("Linking data");
    console.log(linkingData);
    if (linkingData.validateData) {
      setValidData(linkingData?.validateData?.valid_data);
      setInvalidData(linkingData?.validateData?.invalid_data);
      // setting the valid_data to the local storage
      localStorage.setItem(
        "valid_data",
        JSON.stringify(linkingData?.validateData?.valid_data) || []
      );
    }
  }, [linkingData]);

  const [validDataToApi, setValidDataToApi] = React.useState([]);

  React.useEffect(() => {
    console.log("ValidData");
    console.log(validData);
    const transformedArray = validData?.map((item) => ({
      id1: item.qrcode,
      id2: item.barcode,
    }));
    setValidDataToApi(transformedArray);
  }, [validData]);

  React.useEffect(() => {
    console.log("validDataToApi");
    console.log(validDataToApi);
  }, [validDataToApi]);

  return (
    <Box>
      {/* <DisplayTitle>QR/Barcodes Management</DisplayTitle> */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "10px",
          // margin: "10p",

          bgcolor: "red",
        }}
      > */}
      {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="QR/Barcodes" sx={appStyle.general.tab.secondLevel} />
          <Tab
            label="Linking QR/Barcodes"
            sx={appStyle.general.tab.secondLevel}
          />
        </Tabs> */}
      {/* <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            sx={appStyle.general.tab.firstLevel}
            value={"Generate-Qr/Barcode"}
            label="Generate Qr/Barcode & Linking"
          />
          <Tab
            sx={appStyle.general.tab.firstLevel}
            value={"Scan-Qr/Barcode"}
            label="scan Qr/Barcode"
          />
        </Tabs> */}
      <CustomTab
        bgColor="#d0d3d4"
        setValue={setValuenewTab}
        value={valuenewTab}
        input={[
          {
            value: "Generate-Qr/Barcode",
            label: "Generate Qr/Barcode & Linking",
            component: (
              <GenerateQRBarcode
                pageNumber={pageNumber}
                pageSize={pageSize}
                createdAtSort={createdAtSort}
                isQr={isQr}
                qrBarcodeId={qrBarcodeId}
                orderId={orderId}
                tabValue={value}
                setOpenScannerDialog={setOpenScannerDialog}
              />
            ),
          },
          {
            value: "Scan-get-qr/barcode",
            label: "scan Qr/Barcode",
            component: (
              <Linking
                setOpenScannerDialog={setOpenScannerDialog}
                tabvalue={value}
                valuenewTab={valuenewTab}
              />
            ),
          },
        ]}
      />
      {/* </Box> */}
      {value === "Scan-Qr/Barcode" && (
        <Linking setOpenScannerDialog={setOpenScannerDialog} tabvalue={value} />
      )}
      {/* {value === "Generate-Qr/Barcode" && (
        <GenerateQRBarcode
          pageNumber={pageNumber}
          pageSize={pageSize}
          createdAtSort={createdAtSort}
          isQr={isQr}
          qrBarcodeId={qrBarcodeId}
          orderId={orderId}
          tabValue={value}
          setOpenScannerDialog={setOpenScannerDialog}
        />
      )} */}
      {value === "Link-Qr/Barcode" && (
        <Linking setOpenScannerDialog={setOpenScannerDialog} tabvalue={value} />
      )}
      <Typography
        variant="h5"
        sx={{
          mt: 3,
          fontWeight: 800,
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        List Of Created Qr/Barcodes
      </Typography>
      <CodesListTable
        generatedCodesList={generatedCodesList}
        pageNumber={pageNumber}
        pageSize={pageSize}
        lastPageNumber={lastPageNumber}
        createdAtSort={createdAtSort}
        isQr={isQr}
        qrBarcodeId={qrBarcodeId}
        orderId={orderId}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setCreatedAtSort={setCreatedAtSort}
        setisQr={setisQr}
        setQrBarcodeId={setQrBarcodeId}
        setOrderId={setOrderId}
      />

      {openScannerDialog && (
        <CustomScannerDialog
          openScannerDialog={openScannerDialog}
          setOpenScannerDialog={setOpenScannerDialog}
          setCurrentScanned={setCurrentScanned}
          currentScanned={currentScanned}
          validData={validData}
          setValidData={setValidData}
          invalidData={invalidData}
          setInvalidData={setInvalidData}
          validDataToApi={validDataToApi}
          setValidDataToApi={setValidDataToApi}
        />
      )}
    </Box>
  );
}

export default CodesManagement;
