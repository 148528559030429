import {
  Box,
  Typography,
  Chip,
  TableCell,
  TextField,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
import moment from "moment/moment";
import userManagementApis from "../../../Redux/Actions/userManagement";
import { GetPincodeAreas } from "../../../Redux/Actions/pincodeAreasAction";
import CustomAutoComplete from "../../../Components/CustomComp/CustomAutoComplete";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { Add, Delete, Upload } from "@mui/icons-material";
import CustomFileUploadButton from "../../../Components/CustomComp/CustomFileUploadButton";

function AddWfsForm({
  setOpenAddWfsDialog,
  getWfs,
  profileRole,
  selectedRowForEdit,
}) {
  console.log("---selectedRowForEdit--", selectedRowForEdit);
  //hooks
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profileData);
  const organizationDetail = useSelector((state) => state.organizationData);
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const organizationList = organizationDetail.organizationList;

  //state
  const [singlePincode, setSinglePincode] = React.useState("");
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);
  const [searchPincode, setSearchPincode] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [formSelectedObject, setFormSelectedObject] = React.useState({});
  const [fileName, setFileName] = React.useState("");
  const [storeFileName, setStoreFileName] = React.useState("");
  console.log("-----fileName-----", fileName);
  const [storePincodesForChips, setStorePincodesForChips] = React.useState([]);
  const [formSelectedStateOption, setFormSelectedStateOption] =
    React.useState("");
  const [incharageDetails, setIncharageDetails] = React.useState({});
  console.log("---incharageDetails----", incharageDetails);

  // React.useEffect(() => {
  //   if (fileName) {
  //     setRows((prevRows) => [...prevRows, { document_image: fileName }]);
  //     // setStoreFileName(fileName);
  //   }
  // }, [fileName]);

  React.useEffect(() => {
    if (
      selectedRowForEdit?.wfs_incharge_details !== undefined &&
      selectedRowForEdit?.wfs_incharge_details !== undefined &&
      selectedRowForEdit?.wfs_incharge_details.length > 0
    ) {
      setIncharageDetails(selectedRowForEdit?.wfs_incharge_details[0]);
    }
  }, [selectedRowForEdit]);
  //function
  const handleAddWfsSubmit = async (formData) => {
    console.log("---handleAddWfsSubmit---", formData, storePincodesForChips);
    let payLoad = {
      wfs_name: formData.companyName,
      facility_id: parseInt(formData.facility) || undefined,
      address: {
        district: formData.village,
        pincode: formData.pincode,
        state: formData.state,
        street: formData.street,
        village: formData.district,
        // country: formData.timeZone?.split("-")[2],
        door_no: formData.doorNumber,
        country: formSelectedObject.country_code,
        state: formSelectedStateOption,
      },
      wfs_incharge: {
        name: formData.wfsInchargeName,
        phone_number: formData.wfsInchargePhone,
        // aadhaar_number: formData.wfsInchargeAadhar,
        email: formData.wfsInchargeEmail,
        phone_code: formData.timeZone?.split("-")[1],
      },
      wfs_files: rows,
      is_active: true,
      updated_by: profileDetails.profile.user_id,
      updated_at: moment().format("YYYY-MM-DD"),
      created_at: moment().format("YYYY-MM-DD"),
      is_deleted: false,
      delivering_pincodes: storePincodesForChips.map((obj) => obj.id),
    };

    console.log("---payLoad---", payLoad);

    const res = await dispatch(
      HandleApiActions({
        ...wfsApis.add_wfs,
        params: payLoad,
        show_toast: true,
      })
    );
    console.log("---add_wfs---", res);
    getWfs({ ignore_app_loading_spinner: true });
    if (res.error === false) {
      setOpenAddWfsDialog(false);
    }
  };

  const handleChangePincode = (value) => {
    setSearchPincode(value);
  };

  const handleAddPincodes = () => {
    console.log("---handleAddPincodes---", searchPincode);

    allPincodesAndAreas.map((obj) => {
      if (obj.id === searchPincode) {
        setStorePincodesForChips((prev) => [
          ...prev,
          { pincode: obj.pincode, id: obj.id },
        ]);
      }
    });
  };

  const handleDeleteDeliveryPincode = (index) => {
    console.log(
      "---handleDeleteDeliveryPincode--",
      index,
      storePincodesForChips
    );

    setStorePincodesForChips((prev) =>
      prev.filter((obj) => {
        if (obj.id !== storePincodesForChips[index].id) {
          return true;
        } else return false;
      })
    );
    console.log("--deletedDeliveryPincode-", storePincodesForChips);
  };

  //useEffects
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_country_codes,
        show_toast: false,
        params: {
          ignore_app_loading_spinner: true,
        },
      })
    );
    dispatch(GetPincodeAreas());
  }, []);

  const searchAndUpdateFacility = (searchText) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          ...(searchText !== "" && { org_name: searchText }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    if (
      selectedRowForEdit.wfs_files !== undefined &&
      selectedRowForEdit.wfs_files !== null &&
      selectedRowForEdit.wfs_files.length > 0
    ) {
      setRows(selectedRowForEdit.wfs_files);
    }
  }, [selectedRowForEdit]);

  useEffect(() => {
    searchAndUpdateFacility();
  }, []);

  // Function to handle input changes
  const handleInputChangedocument = (index, field, value) => {
    let row = rows[index];
    if (row) {
      rows[index] = {
        ...row,
        // document_image: fileName,
        [field]: value,
      };
    } else {
      rows.push({
        // document_image: fileName,
        [field]: value,
      });
    }
    // const updatedRows = rows.map((row, i) =>
    //   i === index
    //     ? {
    //         ...row,
    //         country_code: formSelectedObject.country_code,
    //         [field]: value,
    //       }
    //     : row
    // );
    setRows([...rows]);

    // Automatically add a new row when editing the last row
    // if (
    //   index === rows.length - 1 &&
    //   (field === "tax_name" || field === "charges_perc") &&
    //   value !== ""
    // ) {
    //   setRows([...updatedRows]);
    // }
  };

  const handleRemoveDocument = (index) => {
    setRows((prevState) => {
      const updatedDetails = prevState.filter((_, i) => i !== index);
      return updatedDetails;
    });
  };

  // const handleChangePoUpload = (index, file) => {
  //   console.log("---fileName--file------", file, rows);
  //   const fileName = file; // Get the file name

  //   // Update the state with the file, document name, and document number
  //   setRows((prevState) => {
  //     const updatedDetails = [
  //       ...prevState,
  //       {
  //         ...prevState[index],
  //         document_image: fileName, // Store the file name in document_image
  //       },
  //     ];
  //     // updatedDetails[index] = ;
  //     return updatedDetails;
  //   });
  // };
  // const handleChangePoUpload = (index, file) => {
  //   console.log("---fileName--file------", file, rows);
  //   const fileName = file; // Get the file name

  //   setRows((prevState) => {
  //     console.log("---prevState----", prevState);
  //     const updatedDetails = prevState.map((row, i) => {
  //       console.log("----index--------", index);
  //       console.log("----i--------", i);
  //       if (i === index) {
  //         // If the current row is the one being updated, set the document_image
  //         return {
  //           ...row,
  //           document_image: fileName, // Update the document_image for the current row
  //         };
  //       }

  // Make sure document_image is always initialized for other rows (empty string if not uploaded)
  // return {
  //   ...row,
  //   document_image: fileName || "", // Keep existing document_image or set empty if undefined
  // };
  // });

  //     return updatedDetails; // Return the updated state
  //   });
  // };

  return (
    <CustomForm
      data={[
        {
          ...(profileRole === "1000" && {
            data: [
              {
                inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                optionKey: "org_name",
                outputKey: "id",
                searchAndUpdateAPI: searchAndUpdateFacility,
                input: organizationList || [],
                name: "facility",
                label: "Enter Facility Name*",
                isRequired: true,
              },
            ],
          }),
        },
        {
          title: "WFS details",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              defaultValue: selectedRowForEdit.wfs_name,
              placeHolder: "Company Name",
              // defaultValue: "",
              name: "companyName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              component: () => {
                return (
                  <CustomAutoComplete
                    field={{
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "country",
                      outputKey: "country_code",
                      defaultValue: parseInt(
                        selectedRowForEdit.address?.country_code
                      ),
                      searchAndUpdateAPI: (searchText) => {
                        // setSearchCountry(searchText);
                      },
                      input: countryCodes,
                      name: "country_code",
                      label: "Select Country",
                    }}
                    handleInputChange={(name, selectedOption, obj) => {
                      setFormSelectedObject(obj);
                    }}
                  />
                );
              },
            },
            {
              component: () => {
                return (
                  <CustomAutoComplete
                    field={{
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "state_name",
                      outputKey: "state_code",
                      searchAndUpdateAPI: (searchText) => {
                        // setSearchState(searchText);
                      },
                      input: formSelectedObject?.state,

                      name: "state_code",
                      label: "Select State",
                    }}
                    handleInputChange={(name, selectedOption) => {
                      setFormSelectedStateOption(selectedOption);
                    }}
                  />
                );
              },
            },
          ],
        },
        {
          title: "Submit WFS ID Documents",
          data: [
            {
              component: () => {
                return (
                  // <Box
                  //   sx={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     ml: "260px",
                  //   }}
                  // >
                  <TableContainer component={Paper}>
                    <Table sx={{ border: "1px solid black" }}>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>Check</TableCell> */}
                          <TableCell
                            sx={{
                              fontWeight: 800,
                              border: "1px solid black",
                            }}
                          >
                            Document Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 800,
                              border: "1px solid black",
                            }}
                          >
                            Document Number
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 800,
                              border: "1px solid black",
                            }}
                          >
                            Upload Document
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 800,
                              border: "1px solid black",
                            }}
                          >
                            Remove
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[...rows, { document_name: "" }].map((row, index) => (
                          <TableRow key={index}>
                            {/* <TableCell>
                              <Checkbox
                                checked={row.checked}
                                onChange={() =>
                                  handleCheckboxChange(index)
                                }
                              />
                            </TableCell> */}
                            <TableCell sx={{ border: "1px solid black" }}>
                              <TextField
                                autoFocus={true}
                                value={row.key || row.document_name}
                                onChange={(e) =>
                                  handleInputChangedocument(
                                    index,
                                    "document_name",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                size="small"
                              />
                            </TableCell>
                            <TableCell sx={{ border: "1px solid black" }}>
                              <TextField
                                value={row.value || row.document_number}
                                onChange={(e) =>
                                  handleInputChangedocument(
                                    index,
                                    "document_number",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                size="small"
                              />
                            </TableCell>
                            <TableCell sx={{ border: "1px solid black" }}>
                              <CustomFileUploadButton
                                field={{
                                  name: index || row.document_image,
                                  placeHolder: <Upload />,
                                }}
                                // hideDownloadButton={true}
                                formData={{
                                  poFile: "",
                                }}
                                errors={{
                                  poFile: false,
                                }}
                                handleInputChange={(name, value) => {
                                  // setPoUrl(value);
                                  console.log("--file--", name, value);
                                  // setFileName(value);
                                  handleInputChangedocument(
                                    index,
                                    "document_image",
                                    value
                                  );
                                }}
                                // uploadButton={<UploadIcon />}
                              />
                            </TableCell>
                            <TableCell sx={{ border: "1px solid black" }}>
                              <Delete onClick={handleRemoveDocument} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  // </Box>
                );
              },
            },
          ],
        },
        {
          title: "WFS incharge details",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Name",
              defaultValue:
                incharageDetails !== undefined &&
                incharageDetails !== null &&
                incharageDetails?.fullname,
              name: "wfsInchargeName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.SELECT,
              placeHolder: "Time Zone",
              name: "timeZone",
              input: countryCodes?.map((data) => {
                return {
                  value: `${data.time_zone}-${data.phone_code}-${data.country_code}`,
                  menuItem: `${data.phone_code}-${data.time_zone}`,
                };
              }),
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Phone",
              defaultValue: incharageDetails?.phone,
              name: "wfsInchargePhone",
              textFieldType: appMeta.textFieldTypes.PHONE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Email",
              defaultValue: incharageDetails?.email,
              name: "wfsInchargeEmail",
              textFieldType: appMeta.textFieldTypes.EMAIL,
              isHintRequired: true,
              isRequired: true,
            },
          ],
        },
        {
          title: "Address",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Door Number",
              defaultValue: selectedRowForEdit?.address?.door_no,
              name: "doorNumber",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Street",
              defaultValue: selectedRowForEdit?.address?.street,
              name: "street",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Village",
              defaultValue: selectedRowForEdit?.address?.village,
              name: "village",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "District",
              defaultValue: selectedRowForEdit?.address?.district,
              name: "district",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "State",
              defaultValue: selectedRowForEdit?.address?.state,
              name: "state",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            // {
            //   inputType: appMeta.inputFieldTypes.INPUT,
            //   placeHolder: "Pincode",
            //   defaultValue: "",
            //   name: "pincode",
            //   textFieldType: appMeta.textFieldTypes.TEXT,
            //   isHintRequired: true,
            //   isRequired: true,
            // },
            {
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "pincode",
              outputKey: "id",
              defaultValue: selectedRowForEdit?.address?.pincode,
              searchAndUpdateAPI: (searchText) => {
                setSinglePincode(searchText);
              },
              input: allPincodesAndAreas || [],
              name: "pincode",
              label: "Enter Pincode ",
            },
            // {
            //   inputType: appMeta.inputFieldTypes.INPUT,
            //   placeHolder: "Country",
            //   defaultValue: "",
            //   name: "country",
            //   textFieldType: appMeta.textFieldTypes.TEXT,
            //   isHintRequired: true,
            //   isRequired: true,
            // },
          ],
        },
        {
          data: [
            {
              component: (formData, setFormData) => {
                // let arr = [];
                // let text = "";
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          mt: "20px",
                        }}
                      >
                        ASSIGN PINCODES TO WFS
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        // ml: "70px",
                      }}
                    >
                      <CustomAutoComplete
                        field={{
                          optionKey: "pincode",
                          outputKey: "id",
                          searchAndUpdateAPI: (searchText) => {
                            setSearchPincode(searchText);
                          },
                          input: allPincodesAndAreas || [],
                          onChange: (name, value) => {
                            handleChangePincode(value);
                          },
                          name: "id",
                          label: "Enter Pincode",
                        }}
                      />
                      <CustomButton
                        label="add"
                        startIcon={<Add />}
                        handleClick={handleAddPincodes}
                      />
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                      {storePincodesForChips?.map((pincode, index) => (
                        <Chip
                          label={pincode.pincode}
                          onDelete={() => {
                            handleDeleteDeliveryPincode(index);
                          }}
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </Box>
                  // <Box sx={{ height: "100px" }}>
                  //   <TextField
                  //     onChange={(e) => {
                  //       text = e.target.value;
                  //     }}
                  //   />
                  //   <CustomButton
                  //     handleClick={() => {
                  //       arr.push(text);
                  //       setFormData({
                  //         ...formData,
                  //         test: arr,
                  //       });
                  //     }}
                  //     label={"Click"}
                  //   />
                  // </Box>
                );
              },
            },
          ],
        },
        // {
        //   title: "Document Upload",
        //   numberOfColumn: 2,
        //   data: [
        //     {
        //       inputType: appMeta.inputFieldTypes.FILE,
        //       name: "fssaiFile",
        //       placeHolder: "FSSAI",
        //       // isRequired: true,
        //     },
        //     {
        //       inputType: appMeta.inputFieldTypes.FILE,
        //       name: "isiFile",
        //       placeHolder: "ISI",
        //       // isRequired: true,
        //     },
        //     {
        //       inputType: appMeta.inputFieldTypes.FILE,
        //       name: "gstinFile",
        //       placeHolder: "GSTIN",
        //       // isRequired: true,
        //     },
        //     {
        //       inputType: appMeta.inputFieldTypes.FILE,
        //       name: "companyPanFile",
        //       placeHolder: "Company PAN",
        //       // isRequired: true,
        //     },
        //     {
        //       inputType: appMeta.inputFieldTypes.FILE,
        //       name: "aadharFile",
        //       placeHolder: "Aadhar",
        //       // isRequired: true,
        //     },
        //   ],
        // },
      ]}
      handleClose={() => {
        setOpenAddWfsDialog(false);
      }}
      handleSubmit={handleAddWfsSubmit}
    />
  );
}

export default AddWfsForm;
