import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import { appColors } from "../../../AppMeta/appColors";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckProcessingPartnerPO,
  CheckPurchaseOrder,
  GeneratePurchaseOrder,
  GeneratePurchaseOrderProcessingPartner,
  PurchaseOrderAcceptReject,
  PurchaseOrderUpdate,
} from "../../../Redux/Actions/purchaseProductsAction";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import {
  UploadFile,
  getFiles,
} from "../../../Redux/Actions/fileHandlingActions";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import {
  ConfirmProcessingPartnerOrder,
  GetManufacuringOrders,
  MftCompleteOrder,
} from "../../../Redux/Actions/manufacturingOrdersAction";
import {
  GenerateQR,
  GenerateQRWithOutMftOrderId,
} from "../../../Redux/Actions/GenerateQrAction";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CustomFileUploadButton from "../../../Components/CustomComp/CustomFileUploadButton";
import CustomDrawer from "../../../Components/CustomComp/CustomDrawer";
// import { UploadFile } from "@mui/icons-material";

// const orderStatus = {
//   INITIATED: 100,
//   PO_APPROVED: 200,
//   PO_PROCESSING: 201,
//   PO_REJECTED: 202,
//   PR_APPROVED: 300,
//   PR_PROCESSING: 301,
//   PR_REJECTED: 303,
//   CANCELLED: 400,
//   COMPLETED: 500,
//   CONFIRMED: 600,
// };

export default function OrderDetailsDialog({
  openOrderDetailsDialog,
  setOpenOrderDetailsDialog,
  moreDetails,
  setMoreDetails,
}) {
  console.log("more details", moreDetails);
  const dispatch = useDispatch();
  const filesDetails = useSelector((state) => state.filesData);

  const {
    components,
    mft_details,
    price,
    order_status,
    created_at,
    updated_at,
    final_product,
  } = moreDetails;

  const handleClose = () => {
    setOpenOrderDetailsDialog(false);
  };
  const [selectedManufacturerDetails, setSelectedManufacturerDetails] =
    React.useState([]);

  const [generatePO, setGeneratePO] = React.useState(false);
  const [downloadPODraft, setDownloadPODraft] = React.useState(false);

  const [downloadSpinner, setDownloadSpinner] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const [poUrl, setPoUrl] = React.useState(moreDetails.po_file);
  const [poFile, setPoFile] = React.useState("");
  const [poUploadBtn, setPoUploadBtn] = React.useState(false);
  const [poFileFlag, setPoFileFlag] = React.useState(false);

  const [rawMaterials, setRawMaterials] = React.useState([]);
  React.useEffect(() => {
    if (downloadPODraft) {
      if (moreDetails.order_type !== "400") {
        const reqObj = {
          order_type: parseInt(moreDetails.order_type),
          orders: selectedManufacturerDetails,
        };
        console.log(reqObj);
        dispatch(CheckPurchaseOrder(reqObj));
      } else if (moreDetails.order_type === "400") {
        const reqObj = {
          order_type: 400,
          mft_id: moreDetails.mft_details.id,
          order: {
            final_product: final_product[0].product_details[0].id,
            quantity_outcome: final_product[0].quantity_outcome,
            processing_fee: final_product[0].processing_fee,
            raw_materials: rawMaterials,
          },
        };
        console.log(reqObj);
        dispatch(CheckProcessingPartnerPO(reqObj));
      }

      setDownloadSpinner(true);
      setDownloadPODraft(false);
    }
  }, [downloadPODraft]);

  React.useEffect(() => {
    console.log("rawMaterials");
    console.log(rawMaterials);
  }, [rawMaterials]);

  React.useEffect(() => {
    if (downloadSpinner) {
      setTimeout(() => {
        setDownloadSpinner(false);
      }, 2000);
    }
  }, [downloadSpinner]);
  const handleDownloadPODraft = () => {
    const product_details = components.map(({ id, quantity, price }) => ({
      id,
      quantity,
      price,
    }));
    setSelectedManufacturerDetails([
      { mft_id: mft_details.id, product_details },
    ]);

    const rawMaterialsArr = components.map(({ id, quantity }) => ({
      id,
      quantity,
    }));
    setRawMaterials(rawMaterialsArr);

    setDownloadPODraft(true);
  };

  const handleClickGeneratePO = () => {
    // Extracting the required fields (id, quantity, and price) from components
    const product_details = components.map(({ id, quantity, price }) => ({
      id,
      quantity,
      price,
    }));

    // Store the productDetails for the selected manufacturer
    setSelectedManufacturerDetails([
      { mft_id: mft_details.id, product_details },
    ]);
    setGeneratePO(true);
    setOpenConfirmationDialog(true);
    // Perform additional logic as needed
    // console.log("Checking purchase order for manufacturer:", manufacturer);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      setGeneratePO(true);
      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed]);
  React.useEffect(() => {
    console.log("selectedManufacturerDetails");
    console.log(selectedManufacturerDetails);
  }, [selectedManufacturerDetails]);

  React.useEffect(() => {
    if (generatePO) {
      const reqObj = {
        order_type: 100,
        orders: selectedManufacturerDetails,
      };
      console.log(reqObj);
      setGeneratePO(false);
      if (moreDetails.order_type !== "400") {
        dispatch(GeneratePurchaseOrder(moreDetails.order_id));
      } else if (moreDetails.order_type === "400") {
        dispatch(GeneratePurchaseOrderProcessingPartner(moreDetails.order_id));
      }
      setDownloadSpinner(true);
    }
  }, [generatePO]);

  const poInputRef = React.useRef(null);
  const handleUploadPoClick = () => {
    poInputRef.current.click();
  };

  // React.useEffect(() => {
  //   console.log(`poUrl ${poUrl}`);
  //   if (poUrl !== "") {
  //     const reqObj = {
  //       order_id: moreDetails.order_id,
  //       po_file: poUrl,
  //     };
  //     dispatch(PurchaseOrderUpdate(reqObj));
  //     setTimeout(() => {
  //       dispatch(GetManufacuringOrders());
  //     }, 1000);
  //   }
  // }, [poUrl]);

  // React.useEffect(() => {}, [poFile]);
  const handleChangePoUpload = (value) => {
    console.log("Selected GST file:", value);
    setPoUrl(value);
    setPoFile(value);
    const reqObj = {
      order_id: moreDetails.order_id,
      po_file: value,
    };
    dispatch(PurchaseOrderUpdate(reqObj));
    setTimeout(() => {
      dispatch(GetManufacuringOrders());
    }, 1000);

    setPoUploadBtn(true);
  };

  // React.useEffect(() => {
  //   if (poUploadBtn) {
  //     console.log("po file upload");
  //     setPoUrl("4h6ARTp3F.pdf");
  //     dispatch(UploadFile(poFile));
  //     setPoUploadBtn(false);
  //     setPoFileFlag(true);
  //   }
  // }, [poFile]);

  React.useEffect(() => {
    console.log("filesDetails");
    console.log(filesDetails);
    if (poFileFlag) {
      console.log("setting po url");
      console.log(filesDetails.fileName);
      setPoUrl(filesDetails.fileName);
      setPoFileFlag(false);
    }
  }, [filesDetails]);

  const handleCompleteOrder = () => {
    const reqObj = {
      order_id: moreDetails.order_id,
      is_complete: true,
    };
    dispatch(MftCompleteOrder(reqObj));
    setTimeout(() => {
      dispatch(GetManufacuringOrders());
    }, 1000);
    setOpenOrderDetailsDialog(false);
  };

  const handleCancelOrder = () => {
    const reqObj = {
      order_id: moreDetails.order_id,
      is_complete: false,
    };
    dispatch(MftCompleteOrder(reqObj));
    setOpenOrderDetailsDialog(false);
  };

  const handleConfirmOrder = () => {
    const reqObj = {
      order_id: moreDetails.order_id,
      is_confirmed: true,
    };
    dispatch(ConfirmProcessingPartnerOrder(reqObj));
    setOpenOrderDetailsDialog(false);
  };

  const handlePoAcceptReject = (buttonName) => {
    if (buttonName === "reject") {
      const reqObj = {
        order_id: moreDetails.order_id,
        is_approved: false,
        reason: "po is not proper",
      };
      dispatch(PurchaseOrderAcceptReject(reqObj));
    } else if (buttonName === "accept") {
      const reqObj = {
        order_id: moreDetails.order_id,
        is_approved: true,
        reason: "Po is proper",
      };
      dispatch(PurchaseOrderAcceptReject(reqObj));
      setTimeout(() => {
        setTimeout(() => {
          dispatch(GetManufacuringOrders());
        }, 1000);
      }, 1000);
    }
  };

  const handleGenerateQrBarcode = () => {
    const reqObj = {
      order_id: moreDetails.order_id,
    };
    dispatch(GenerateQRWithOutMftOrderId(reqObj));
  };

  return (
    <React.Fragment>
      {/* <Dialog
        open={openOrderDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent> */}
      <CustomDrawer
        open={openOrderDetailsDialog}
        setOpen={setOpenOrderDetailsDialog}
        title="Order Details"
      >
        <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Order Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Order ID:</strong> {moreDetails.order_id}
              </Typography>
              <Typography variant="body1">
                <strong>Order Status:</strong> {order_status}
              </Typography>
              <Typography variant="body1">
                <strong>Order Price:</strong> {price}
              </Typography>
              <Typography variant="body1">
                <strong>Order Created At:</strong> {created_at}
              </Typography>
              <Typography variant="body1">
                <strong>Order Updated At:</strong> {updated_at}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Manufacturer Name:</strong> {mft_details.details.name}
              </Typography>
              <Typography variant="body1">
                <strong>Owner Name:</strong>{" "}
                {mft_details.details.user_details.owner_name}
              </Typography>
              <Typography variant="body1">
                <strong>Owner Phone:</strong>{" "}
                {mft_details.details.user_details.owner_phone}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong>{" "}
                {`${mft_details.details.address.door_number}, ${mft_details.details.address.building_name}, ${mft_details.details.address.area_name}, ${mft_details.details.address.city}, ${mft_details.details.address.state}, ${mft_details.details.address.pincode}`}
              </Typography>
            </Grid>

            {moreDetails.order_type !== "400" && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Ordered Products
                </Typography>
                <CustomTable
                  pagination={false}
                  tableData={components}
                  tableHead={[
                    "Product ID",
                    "Name",
                    "Quantity",
                    "CGST %",
                    "SGST %",
                    "Total GST",
                    "Total Price",
                    // moreDetails.order_type === "300" && "Action",
                  ]}
                  tableDataKey={[
                    "id",
                    "name",
                    "quantity",
                    moreDetails.order_type === "300"
                      ? "cgst_perc"
                      : "details.tax_details.gst.CGST",
                    moreDetails.order_type === "300"
                      ? "sgst_perc"
                      : "details.tax_details.gst.SGST",
                    "GST",
                    "component_total",
                    // {
                    //   ...(moreDetails.order_type === "300" && {
                    //     component: (props) => {
                    //       return (
                    //         <CustomButton
                    //           label="Generate QR/Barcode"
                    //           handleClick={handleGenerateQrBarcode}
                    //           disabled={!props.row.is_qr}
                    //         />
                    //       );
                    //     },
                    //   }),
                    // },
                  ]}
                />
                {/* <TableContainer>
                    <Table>
                      <TableHead sx={{ backgroundColor: appColors.secondary }}>
                        <TableRow>
                          <TableCell>Product ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>CGST %</TableCell>
                          <TableCell>SGST %</TableCell>
                          <TableCell>Total GST</TableCell>
                          <TableCell>Total Price</TableCell>
                          {moreDetails.order_type === "300" && (
                            <TableCell>Action</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {components.map((component) => (
                          <TableRow key={component.id}>
                            <TableCell>{component.id}</TableCell>
                            <TableCell>{component.name}</TableCell>
                            <TableCell>{component.quantity}</TableCell>
                            <TableCell>
                              {moreDetails.order_type === "300"
                                ? component.cgst_perc
                                : component.details?.tax_details?.gst?.CGST}
                            </TableCell>
                            <TableCell>
                              {moreDetails.order_type === "300"
                                ? component.sgst_perc
                                : component.details?.tax_details?.gst?.SGST}
                            </TableCell>
                            <TableCell>{component.GST}</TableCell>
                            <TableCell>{component.component_total}</TableCell>
                            <TableCell>
                              <Button
                                disabled={!component.is_qr}
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={handleGenerateQrBarcode}
                              >
                                Generate QR/Barcode
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
              </Grid>
            )}

            {moreDetails.order_type === "400" && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Raw materials required for processing
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: appColors.secondary }}>
                      <TableRow>
                        <TableCell>Raw Material Name</TableCell>
                        <TableCell align="right">Units</TableCell>
                        <TableCell align="right">CGST</TableCell>
                        <TableCell align="right">SGST</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {components.map((component) => (
                        <TableRow key={component.id}>
                          <TableCell component="th" scope="row">
                            {component.raw_materials[0].name}
                          </TableCell>
                          <TableCell align="right">
                            {component.raw_materials[0].units}
                          </TableCell>
                          <TableCell align="right">
                            {
                              component.raw_materials[0].details.tax_details.gst
                                .CGST
                            }
                            %
                          </TableCell>
                          <TableCell align="right">
                            {
                              component.raw_materials[0].details.tax_details.gst
                                .SGST
                            }
                            %
                          </TableCell>
                          <TableCell align="right">
                            {component.quantity}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {moreDetails.order_type === "400" && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Final Product required
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Name</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">CGST (%)</TableCell>
                        <TableCell align="right">SGST (%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {final_product[0]?.product_details?.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell component="th" scope="row">
                            {product.name}
                          </TableCell>
                          <TableCell align="right">{product.price}</TableCell>
                          <TableCell align="right">
                            {product.details.tax_details.gst.CGST}
                          </TableCell>
                          <TableCell align="right">
                            {product.details.tax_details.gst.SGST}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {moreDetails.order_status !== "COMPLETED" && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDownloadPODraft}
                  >
                    Download PO draft <DownloadRoundedIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickGeneratePO}
                  >
                    Generate final PO
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Paper elevation={23} sx={{ padding: "10px" }}>
                    <Box>
                      <Typography sx={{ textAlign: "center" }}>
                        Upload PO
                      </Typography>
                      <Tooltip title="Upload PO File" position="top">
                        {/* <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleUploadPoClick}
                              edge="end"
                            >
                              <UploadRoundedIcon />
                            </IconButton>
                            {poUrl !== "" && (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => dispatch(getFiles(poUrl))}
                                edge="end"
                              >
                                <GetAppRoundedIcon />
                              </IconButton>
                            )}
                          </Box> */}
                        <CustomFileUploadButton
                          field={{ name: "poFile", placeHolder: "Upload PO" }}
                          // hideDownloadButton={true}
                          formData={{
                            poFile: poUrl,
                          }}
                          errors={{
                            poFile: false,
                          }}
                          handleInputChange={(name, value) => {
                            // setPoUrl(value);
                            console.log("--file--", name, value);
                            handleChangePoUpload(value);
                          }}
                          // uploadButton={<UploadIcon />}
                        />
                      </Tooltip>
                      {/* {poUrl !== "" && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Chip label="Upload Success" color="success" />
                          </Box>
                        )} */}
                      {/* <input
                          type="file"
                          ref={poInputRef}
                          style={{ display: "none" }}
                          onChange={handleChangePoUpload}
                        /> */}
                    </Box>
                    {poUrl && moreDetails.order_status !== "PO_APPROVED" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => handlePoAcceptReject("reject")}
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          onClick={() => handlePoAcceptReject("accept")}
                        >
                          Accept
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  {downloadSpinner && <CircularProgress />}
                </Box>

                {moreDetails.order_type === "400" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={handleConfirmOrder}
                    >
                      Confirm for Processing
                    </Button>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleCancelOrder}
                  >
                    Cancel Order
                  </Button>
                  {moreDetails.poFile !== "" && (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={handleCompleteOrder}
                    >
                      Complete Order
                    </Button>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              color="error"
              handleClick={handleClose}
              label="Close"
            />
          </Box>
        </Paper>
      </CustomDrawer>
      {/* </DialogContent>
        <DialogActions>
          <CustomButton color="error" handleClick={handleClose} label="Close" />
        </DialogActions>
      </Dialog> */}
      <CustomConfirmationDialog
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        openConfirmationDialog={openConfirmationDialog}
        description={
          "After generating PO this order cannot be edited. Make sure the PO is proper by downloading the PO draft"
        }
        setIsConfirmed={setIsConfirmed}
      />
    </React.Fragment>
  );
}
