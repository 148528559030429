import { Close, QrCodeScanner } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import RecycleScanningDialog from "./RecycleScanningDialog";
import { useDispatch, useSelector } from "react-redux";
import appConstants from "../../AppMeta/appConstants";

import moment from "moment";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

function RecycleSingleOrderDialog(props) {
  console.log(
    "---recycleSingleOrderDetails---",
    props.openRecycleSingleOrderDialog
  );
  console.log("appConstants", appConstants);
  const recycleordersdetails = useSelector(
    (state) => state.recycleordersdetails
  );
  const orderDetails = props.recycleSingleOrderDetails;
  const isScanningCompleted =
    orderDetails &&
    (orderDetails?.physical_damage || 0) ===
      (orderDetails?.scanned_physical_damage || 0) &&
    (orderDetails?.expired || 0) === (orderDetails?.scanned_expired || 0) &&
    (orderDetails?.qr_barcode_damage || 0) ===
      (orderDetails?.scanned_qr_barcode_damage || 0);

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   dispatch(
  //     GetRequestedRecycleOrderList({
  //       order_id: props.recycleSingleOrderDetails?.order_id,
  //     })
  //   );
  // }, []);

  const [openRecyclescanningDialog, setOpenRecyclescanningDialog] =
    React.useState(false);
  const styless = {
    AdminStyleHeading: {
      display: "flex",
      alignItems: "center",
      padding: "1px",
      gap: "3px",
      color: "#0a83c4",
      justifyContent: "space-between",
      ml: 7,
    },
  };
  const span = {
    spanStyle: {
      color: "black",
      fontWeight: "normal",
      marginRight: "30px",
    },
  };
  const orderStatus = (order_status) => {
    switch (parseInt(order_status)) {
      case appConstants.rcy_order_status.rcy_ORDER_CONFIRMED:
        return "REQUESTED";
      case appConstants.rcy_order_status.rcy_ORDER_CANCELLED:
        return "REJECTED";
      case appConstants.rcy_order_status.rcy_ORDER_COMPLETED:
        return "COMPLETED";
      default:
        return "";
    }
  };
  const handleOpenRecycleScanningDialog = () => {
    setOpenRecyclescanningDialog(true);
  };
  const handleCloseRecycleScanningDialog = () => {
    setOpenRecyclescanningDialog(false);
  };
  const handleCloseRecycleSingleDialog = () => {
    props.handleCloseRecycleSingleDialog();
  };
  const handleAcceptOrReject = (data) => {
    props.handleAcceptOrReject({
      data: data,
      order_id: props.recycleSingleOrderDetails.order_id,
    });
  };
  return (
    <>
      <CustomDrawer
        open={props.openRecycleSingleOrderDialog}
        title="Scanning process"
      >
        {/* <DialogContent> */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <Button
            variant="contained"
            color="error"
            onClick={handleCloseRecycleSingleDialog}
          >
            <Close />
          </Button> */}
          <CustomButton
            color="error"
            handleClick={handleCloseRecycleSingleDialog}
            endIcon={<Close />}
          />
        </Box>
        <Box>
          <Box sx={styless.AdminStyleHeading}>
            <Typography
              sx={{
                margin: 0.5,
                fontWeight: 800,
                fontSize: { xs: "1em", md: "16px" },
              }}
            >
              Order ID
              <span style={span.spanStyle}>
                &nbsp;&nbsp;&nbsp;
                {props.recycleSingleOrderDetails?.order_id}
              </span>
            </Typography>
          </Box>
          <Box sx={styless.AdminStyleHeading}>
            <Typography
              sx={{
                margin: 0.5,
                fontWeight: 800,
                fontSize: { xs: "1em", md: "16px" },
              }}
            >
              Product
              <span style={span.spanStyle}>
                &nbsp;&nbsp;&nbsp;
                {props.recycleSingleOrderDetails?.container_capacity +
                  "-" +
                  props.recycleSingleOrderDetails?.container_material}
              </span>
            </Typography>
          </Box>
          {props.recycleSingleOrderDetails?.physically_damaged_quantity ? (
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 0.5,
                  fontWeight: 800,
                  fontSize: { xs: "1em", md: "16px" },
                }}
              >
                Physical Damaged Qty
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;&nbsp;{" "}
                  {props.recycleSingleOrderDetails?.physically_damaged_quantity}
                </span>
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          {props.recycleSingleOrderDetails?.expired_quantity ? (
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 0.5,
                  fontWeight: 800,
                  fontSize: { xs: "1em", md: "16px" },
                }}
              >
                Expired Qty
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;{" "}
                  {props.recycleSingleOrderDetails?.expired_quantity}
                </span>
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {parseInt(props.recycleSingleOrderDetails?.qr_barcode_damaged_qty) ? (
            <Box sx={styless.AdminStyleHeading}>
              <Typography
                sx={{
                  margin: 0.5,
                  fontWeight: 800,
                  fontSize: { xs: "1em", md: "16px" },
                }}
              >
                Qr/Barcode Damaged Qty
                <span style={span.spanStyle}>
                  &nbsp;&nbsp;{" "}
                  {parseInt(
                    props.recycleSingleOrderDetails?.qr_barcode_damaged_qty
                  )}
                </span>
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          <Box sx={styless.AdminStyleHeading}>
            <Typography
              sx={{
                margin: 0.5,
                fontWeight: 800,
                fontSize: { xs: "1em", md: "16px" },
              }}
            >
              Status
              <span style={span.spanStyle}>
                &nbsp;&nbsp;&nbsp;
                {orderStatus(props.recycleSingleOrderDetails?.order_status)}
              </span>
            </Typography>
          </Box>
          <Box sx={styless.AdminStyleHeading}>
            <Typography
              sx={{
                margin: 0.5,
                fontWeight: 800,
                fontSize: { xs: "1em", md: "16px" },
              }}
            >
              Created On
              <span style={span.spanStyle}>
                &nbsp;&nbsp;&nbsp;
                {moment(props.recycleSingleOrderDetails?.created_at).format(
                  "DD-MM-YYYY"
                )}
              </span>
            </Typography>
          </Box>
          <Box sx={styless.AdminStyleHeading}>
            <Typography
              sx={{
                margin: 0.5,
                fontWeight: 800,
                fontSize: { xs: "1em", md: "16px" },
              }}
            >
              Updated By
              <span style={span.spanStyle}>
                &nbsp;&nbsp;&nbsp;
                {props.recycleSingleOrderDetails?.updated_user?.name}
              </span>
            </Typography>
          </Box>
        </Box>
        {parseInt(props.recycleSingleOrderDetails?.order_status) ===
        appConstants.rcy_order_status.rcy_ORDER_CONFIRMED ? (
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            {/* <Button
                variant="contained"
                color="success"
                onClick={() => handleAcceptOrReject("ACCEPT")}
              >
                Accept
              </Button> */}
            <CustomButton
              label="Complete"
              handleClick={() => handleAcceptOrReject("ACCEPT")}
            />
            <CustomButton
              label="Reject"
              color="error"
              handleClick={() => handleAcceptOrReject("REJECT")}
            />
            {/* <Button
                variant="contained"
                color="error"
                onClick={() => handleAcceptOrReject("REJECT")}
              >
                Reject
              </Button> */}
          </Box>
        ) : (
          ""
        )}
        {/* {!isScanningCompleted ? ( */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
          <Button variant="contained" onClick={handleOpenRecycleScanningDialog}>
            <QrCodeScanner />
            Scan
          </Button>
        </Box> */}
        {/* ) : (
          ""
        )} */}
        {/* </DialogContent> */}
        {openRecyclescanningDialog && (
          <RecycleScanningDialog
            singleOrderData={props.recycleSingleOrderDetails}
            setOpenRecyclescanningDialog={setOpenRecyclescanningDialog}
            // qrType={props.recycleSingleOrderDetails.type}
            qrType={true}
            openRecyclescanningDialog={openRecyclescanningDialog}
            handleCloseRecycleScanningDialog={handleCloseRecycleScanningDialog}
          />
        )}
      </CustomDrawer>
    </>
  );
}

export default RecycleSingleOrderDialog;
