import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import Html5QrcodePlugin1 from "../Home/Html5QrcodePlugin";
import QrBarcodeLinkingDialog from "./QrBarcodeLinkingDialog";
import ResultContainerPlugin1 from "./ResultContainerPlugin1";
import { LinkQrBarcodes } from "../../Redux/Actions/linkingQrBarcodesAction";
import { useDispatch, useSelector } from "react-redux";
import SingleQrBarcodeDetailsDialog from "./SingleQrBarcodeDetailsDialog";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { wfsApis } from "../../Redux/Actions/wfsAction";
import { toast } from "react-toastify";

function Linking({ setOpenScannerDialog, tabvalue, activeStep, valuenewTab }) {
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const inQrBarcodeAdmin = true;
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;
  const dispatch = useDispatch();
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [allWfs, setAllWfs] = React.useState(wfsDetails.wfsLineId);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  console.log("scannedQrCodeDetails", scannedQrCodeDetails);
  const [linkQrScannedId, setLinkQrScannedId] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openSingleQrBarcodeDialog, setOpenSingleQrBarcodeDialog] =
    React.useState(false);
  const [scanCount, setScanCount] = React.useState(0);
  const [link, setLink] = React.useState(false);
  // const [alignment, setAlignment] = React.useState('Scan');
  const [scanQrFlag, setScanQrFlag] = React.useState(false);
  const [linkQrFlag, setLinkQrFlag] = React.useState(false);
  const [GenerateFlag, setGenerateFlag] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [unMountFlag, setUnMountFlag] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");
  const [wfsValue, setWfsValue] = React.useState("");
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [linkToggle, setLinkToggle] = React.useState("linkQr");
  const [searchWfs, setSearchWfs] = React.useState("");

  // React.useEffect(() => {
  //   dispatch(
  //     HandleApiActions({
  //       ...wfsApis.get_wfs_list,
  //       // params: {
  //       //   page_number: 1,
  //       //   page_size: 500,
  //       // },
  //       show_toast: false,
  //     })
  //   );
  // }, []);
  React.useEffect(() => {
    if (link === true) {
      const reqObj = {
        qrcode_id: scannedQrCode,
        barcode_id: scannedBarCode,
        // wfs_id: wfsValue,
        wfs_id: wfsValue,
      };
      console.log("---reqObj----", reqObj);
      if (
        reqObj.barcode_id !== "" &&
        reqObj.qrcode_id !== "" &&
        reqObj.wfs_id !== ""
      ) {
        dispatch(LinkQrBarcodes(reqObj));
      } else {
        toast.error("Link With Proper Data");
      }
      setScannedQrCode("");
      setScannedBarCode("");
      setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      setLink(false);
      setLinkQrBarcodeBtn(false);
    }
  }, [link]);
  React.useEffect(() => {
    if (linkToggle === "linkQr" && linkQrScannedId) {
      console.log(linkQrScannedId);
      const reqObj = {
        qrcode_id: linkQrScannedId,
        // wfs_id: parseInt(wfsValue),
        wfs_id: parseInt(wfsValue),
      };
      console.log("reqObj1", reqObj);
      if (reqObj.qrcode_id !== "" && reqObj.wfs_id !== "") {
        dispatch(LinkQrBarcodes(reqObj));
      } else {
        toast.error("Link With Proper Data");
      }
      setLinkQrScannedId("");
      setScannedQrCode("");
      setScannedBarCode("");
      setScanCount(0);
      setDecodedResults([]);
    }
  }, [linkToggle, linkQrScannedId]);
  React.useEffect(() => {
    if (scannedQrCodeDetails !== "") {
      // dispatch(GetDetailsFromQrcode(scannedQrCodeDetails));
      console.log("getScannedd", scannedQrCodeDetails);
      dispatch(LinkQrBarcodes(scannedQrCodeDetails));
      setOpenSingleQrBarcodeDialog(true);
      setScanQrCodeDetailBtn(false);
      setScannedQrCodeDetails("");
    }
  }, [scannedQrCodeDetails]);

  React.useEffect(() => {
    console.log(linkToggle);
    console.log(scannedQrCode);
    console.log(`scannedQrCode ${scannedQrCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedQrCode !== "" &&
      scannedQrCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    console.log(`scannedBarCode ${scannedBarCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedBarCode !== "" &&
      scannedBarCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode, linkToggle]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      console.log("--- qrBarcodeIdNew === qrBarcodeId ---");
      console.log(qrBarcodeIdNew);
      console.log(qrBarcodeId);
      if (valuenewTab === "Scan-get-qr/barcode") {
        setScanQrCodeDetailBtn(true);
        setUnMountFlag(true);
        setScannedQrCodeDetails(qrBarcodeId);
        console.log("---Scan-Qr/Barcode---");
        setDecodedResults([]);
      } else if (activeStep === 1) {
        console.log("==== Data Tab2 ====");
        console.log(qrBarcodeId);
        setScannedQrCode(decodedResults[0]);
        setLinkQrScannedId(qrBarcodeId);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
        setScannedBarCode(qrBarcodeId);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleLinking = () => {
    setOpenScannerDialog(true);
  };

  const handleChangeLink = (event) => {
    setLinkQrScannedId("");
    setScannedQrCode("");
    setScannedBarCode("");
    setScanCount(0);
    setDecodedResults([]);
    setLinkToggle(event.target.value);
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setLinkQrScannedId(decodedText);

      setScannedQrCode(decodedText);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setLinkQrScannedId(decodedText);

      setScannedBarCode(decodedText);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
    // setScanQrFlag(false);
    // setScanOption("scanner");
  };
  const handleSelectScanOption = (event) => {
    console.log("-- handleSelectScanOption --");
    console.log(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      setScanQrCodeDetailBtn(true);
      setScanQrFlag(true);
      setUnMountFlag(true);
    } else {
      setScanQrCodeDetailBtn(false);
      setScanQrFlag(false);
      setUnMountFlag(false);
    }
  };

  const handleQrBarcodeIdFromScanner = (event) => {
    console.log(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        console.log("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        console.log("--- data is set ---");
      }
    }, 500);
  };

  const onNewScanResultScanGetQrCode = (decodedText, decodedResult) => {
    console.log(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);

      // dispatch(GetDetailsFromQrcode(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
    setScanQrFlag(false);
    setScanOption("scanner");
  };

  const handleCloseDialog = () => {
    setOpenSingleQrBarcodeDialog(false);
  };
  const handleChangeWfs = (value) => {
    console.log("value", value);
    setWfsValue(value);
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          // ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    searchAndUpdateWfs();
  }, [searchWfs]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        borderRadius: "15px",
        m: "15px 10px 0px 10px",
      }}
      component={Paper}
      elevation={15}
    >
      <Box sx={{ pb: "20px" }}>
        {/* <CustomButton
          label="Start Linking"
          variant="contained"
          handleClick={handleLinking}
        /> */}
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: 600,
            fontSize: "17px",
            mb: "10px",
          }}
        >
          Link Qr Code Or Qr&Barcode
        </Typography>
        {/* <Divider sx={{ width: "100%" }} /> */}
        {activeStep === 1 ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <CustomAutoComplete
              field={{
                optionKey: "wfs_name",
                outputKey: "wfs_id",
                searchAndUpdateAPI: (searchText) => {
                  setSearchWfs(searchText);
                },
                input: wfsList || [],
                onChange: (name, value) => {
                  handleChangeWfs(value);
                },
                name: "wfs_id",
                label: "Enter WFS",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              value={linkToggle}
              exclusive
              onChange={(event) => handleChangeLink(event)}
              aria-label="Platform"
            >
              <ToggleButton value="linkQr">Link Qr Code</ToggleButton>
              <ToggleButton value="linkQrBarcode">
                Link Qr & Barcode
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ) : (
          ""
        )}
        {valuenewTab === "Scan-get-qr/barcode" ? (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              textDecoration: "underline",
            }}
          >
            Scan and get Qr/Barcode details
          </Typography>
        ) : (
          ""
        )}
        <FormControl>
          <FormLabel>Select Scanning Option</FormLabel>
          <RadioGroup
            defaultValue="scanner"
            value={scanOption}
            name="radio-buttons-group"
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={handleSelectScanOption}
          >
            <FormControlLabel
              value="scanner"
              control={<Radio />}
              label="Scanner Device"
            />
            <FormControlLabel
              value="camera"
              control={<Radio />}
              label="Camera"
            />
          </RadioGroup>
        </FormControl>
        {scanOption === "camera" && valuenewTab === "Scan-get-qr/barcode" ? (
          <>
            {" "}
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              scanQrFlag={scanQrFlag}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResultScanGetQrCode}
            />
          </>
        ) : (
          ""
        )}
        {scanOption === "camera" && activeStep === 1 ? (
          <>
            {" "}
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              scanQrFlag={scanQrFlag}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </>
        ) : (
          ""
        )}
        <ResultContainerPlugin1
          results={decodedResults}
          setScanCount={setScanCount}
          scanType={scanOption}
        />
        {scanOption === "scanner" &&
        (valuenewTab === "Scan-get-qr/barcode" || activeStep === 1) ? (
          <Box>
            <TextField
              onChange={handleQrBarcodeIdFromScanner}
              label="QR/Barcode:"
              value={qrBarcodeId}
              size="small"
              sx={{
                height: "10px",
                width: "200px",
                marginBotton: "30px",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>

      {/* <Box>
        <CustomButton label="Re-link" variant="contained" />
      </Box> */}
      <Box>
        <QrBarcodeLinkingDialog
          setLinkingDialogOpen={setLinkingDialogOpen}
          linkingDialogOpen={linkingDialogOpen}
          scannedQrCode={scannedQrCode}
          scannedBarCode={scannedBarCode}
          setLink={setLink}
          scanCount={scanCount}
          setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
          inQrBarcodeAdmin={inQrBarcodeAdmin}
        />
      </Box>
      {openSingleQrBarcodeDialog && (
        <SingleQrBarcodeDetailsDialog
          openSingleQrBarcodeDialog={openSingleQrBarcodeDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </Box>
  );
}

export default Linking;
