import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Cancel,
  QrCodeScanner,
  Rotate90DegreesCcw,
  Start,
  Stop,
} from "@mui/icons-material";
import appStyle from "../../../AppMeta/appStyle";
import appMeta from "../../../AppMeta/appMeta";
import DeliveriesEnterOtpDialog from "../../Distributor/DeliveriesEnterOtpDialog";
import ScanningDialog from "../../../Components/CustomComp/ScanningDialog";
import { CancelDialogBatch } from "../CancelDialogBatch";

function FillingStationOrdersMain() {
  const dispatch = useDispatch();
  const listOfEmpptyordesr = useSelector(
    (state) => state.userManagementReducer.distributor_wfs_orders_List
  );
  const [pageSize, setPageSize] = React.useState(20);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [orderData, setOrderData] = React.useState({});
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  const [productData, setProductData] = React.useState({});
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  React.useEffect(() => {
    hitOrderApiApi();
  }, [pageSize, pageNumber]);

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      console.log("setRequestObject", requestObject);
      console.log(requestObject?.codes?.length);
      if (requestObject?.codes?.length) {
        console.log("----productData-----", productData);
        if (
          productData.is_inscan_completed === false &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_in_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        } else if (
          productData.is_inscan_completed === true &&
          productData.is_outscan_completed === false
        ) {
          dispatch(
            HandleApiActions({
              ...userManagementApis.put_out_scan,
              params: {
                order_id: requestObject.order_id,
                qr_codes: requestObject.codes,
              },
              show_toast: true,
            })
          );
        }
        // setTimeout(() => {
        //   fetchBatch();
        // }, 1000);
        // setOpenProductCapColorDialog(true);
      }
      setTimeout(() => {
        // dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
        // dispatch(GetCreateBatch({ sort_created_at: "DESC" }));

        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        hitOrderApiApi();
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);

  const hitOrderApiApi = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_wfs_orders,
        show_toast: false,
      })
    );
  };

  // const handleCompleteOrder = (props) => {
  //   console.log("--props---", props);
  //   dispatch(
  //     HandleApiActions({
  //       ...userManagementApis.complete_wfs_order,
  //       params: { order_id: props },
  //       show_toast: true,
  //     })
  //   );
  //   setTimeout(() => {
  //     hitOrderApiApi();
  //   }, 2000);
  // };

  const handleOpenEnterOtpDialog = (props) => {
    console.log("----orderData-props----", props);
    setOrderData(props);
    setOpenEnterOtpDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenEnterOtpDialog(false);
  };
  const handleSubmitOtp = () => {
    console.log("----orderData-----", orderData);
    dispatch(
      HandleApiActions({
        ...userManagementApis.put_verify_otp_order,
        params: {
          otp: enterOtpTextfied,
          order_id: orderData?.ec_order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      hitOrderApiApi();
      setOpenEnterOtpDialog(false);
    }, 1500);
  };

  const handleinscanopen = (data) => {
    // setOpenLinkDialog(true);
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.return_quantity - data?.inscaned_quantity);
    setProductData(data);
  };
  const handleoutscanopen = (data) => {
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.quantity - data?.outscaned_quantity);
    setProductData(data);
  };

  const handleCompleteOrder = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.post_complete_consumer_order,
        params: {
          order_id: orderData?.order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      hitOrderApiApi();
    }, 1500);
  };
  const handleClose = (data) => {
    console.log("dataaasdd", data);
    setCancelDialogOpen(false);
    //setCurrentItem(null); // Clear the current item when dialog is closed
  };
  const handleCancelClick = (details) => {
    console.log("Cancel", details);
    console.log(cancelDialogOpen);
    setCancelDialogOpen(true);
    setCurrentItem(details);
  };
  return (
    <Box>
      {/* <CustomTable
        tableHead={[
          "Order Id",
          "Product",
          "Order Qty",
          "Return Qty",
          "Distributor Name",
          "Distributor Email",
          "Distributor Phone",
          "Created On",
          "Status",
          "Actions",
        ]}
        tableData={listOfEmpptyordesr}
        tableDataKey={[
          "ec_order_id",
          {
            component: (props) => {
              return (
                <Typography>
                  {props.row.container_capacity}-{props.row.container_material}
                </Typography>
              );
            },
          },
          "order_quantity",
          "return_quantity",
          "distributor_name",
          "distributor_email",
          "distributor_phone",
          {
            rowClickEnable: true,
            component: (props) => {
              console.log("propss", props);
              return moment(props?.row?.created_at).format("DD-MM-YYYY");
            },
          },
          {
            component: function (props) {
              return (
                <Typography sx={{ fontSize: "12px" }}>
                  {props.row.ec_order_status === 100
                    ? "ACCEPTED"
                    : props.row.ec_order_status === 200
                    ? "REJECTED"
                    : props.row.ec_order_status === 300
                    ? "COMPLETED"
                    : ""}
                </Typography>
              );
            },
          },
          {
            component: (props) => {
              return (
                <Box>
                  {props?.row?.ec_order_status === 100 ? (
                    <CustomButton
                      label="complete"
                      handleClick={() =>
                        handleCompleteOrder(props?.row?.ec_order_id)
                      }
                    />
                  ) : (
                    ""
                  )}
                </Box>
              );
            },
          },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "order_id", label: "order_id" },
          { value: "status", label: "status" },
        ]}
        // handleFilterClose={handleWfsFilterClose}
        sort={true}
        sortSelectOption={[
          { value: "created_at_sort", label: "Created at" },
          { value: "wfs_name_sort", label: "WFS name" },
          { value: "facility_name_sort", label: "Facility name" },
        ]}
        // handleSortClose={handleWfsSortClose}
        // addButton={true}
        // handleOnClickAddButton={handleOpenPlaceOrderDrawer}
        // tableRowClickFunction={(index, props) => handleRowClick(props)}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      /> */}
      <CustomTable
        tableHead={[
          "Order ID",
          "Product",
          "Order Quantity",
          "Return Quantity",
          "Distributor Name",
          "Distributor Email",
          "Distributor Phone",
          "Created On",
          "Status",
          "Actions",
        ]}
        tableData={listOfEmpptyordesr}
        tableDataKey={[
          "ec_order_id",
          {
            component: (props) => {
              return (
                <Typography>
                  {props.row.container_capacity}-{props.row.container_material}
                </Typography>
              );
            },
          },
          "order_quantity",
          "return_quantity",
          "distributor_name",
          "distributor_email",
          "distributor_phone",
          {
            rowClickEnable: true,
            component: (props) => {
              console.log("propss", props);
              return moment(props?.row?.created_at).format("DD-MM-YYYY");
            },
          },
          {
            component: function (props) {
              return (
                <Typography sx={{ fontSize: "12px" }}>
                  {props.row.ec_order_status === 100
                    ? "ACCEPTED"
                    : props.row.ec_order_status === 200
                    ? "REJECTED"
                    : props.row.ec_order_status === 300
                    ? "COMPLETED"
                    : ""}
                </Typography>
              );
            },
          },
          {
            component: (props) => {
              return (
                <Box>
                  {props?.row?.ec_order_status === 100 ? (
                    <CustomButton
                      label="complete"
                      handleClick={() =>
                        handleCompleteOrder(props?.row?.ec_order_id)
                      }
                    />
                  ) : (
                    ""
                  )}
                </Box>
              );
            },
          },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "order_id", label: "order_id" },
          { value: "status", label: "status" },
        ]}
        isCardViewEnable={true}
        defaultView="card"
        // handleFilterClose={handleBatchDetailFilter}
        sort={true}
        sortSelectOption={[{ value: "created_at", label: "Created at" }]}
        // handleSortClose={handleBatchDetailSort}
        // addButton={true}
        // handleOnClickAddButton={() => {
        //   setDrawOpenProp(true);
        // }}
        // tableRowClickFunction={(index, row) => {
        //   handleEditClick(0, row);
        // }}
        pagination={true}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        cardHeight="230px"
        cardTitle={{ label: "Batch ID", key: "ec_order_id" }}
        cardSubTitle={{ label: "Container", key: "name" }}
        cardInput={(row) => [
          {
            data: [
              { label: "Order Qty", value: row.order_quantity },
              { label: "Return Qty", value: row.return_quantity },
            ],
          },
          {
            data: [
              {
                label: "Status",
                value: () => {
                  return (
                    <Typography sx={{ fontSize: "12px" }}>
                      {row.ec_order_status === 100
                        ? "ACCEPTED"
                        : row.ec_order_status === 200
                        ? "REJECTED"
                        : row.ec_order_status === 300
                        ? "COMPLETED"
                        : ""}
                    </Typography>
                  );
                },
              },
              { label: "Distributor Name", value: row.distributor_name },
              // {
              //   label: "Created at",
              //   value: moment(row.created_at).format("DD-MM-YYYY"),
              // },
            ],
          },
          {
            data: [
              { label: "Distributor Phone", value: row.distributor_phone },
            ],
          },
          {
            data: [
              { label: "Distributor Email", value: row.distributor_email },
            ],
          },
          {
            data: [
              {
                label: "Created at",
                value: moment(row.created_at).format("DD-MM-YYYY"),
              },
            ],
          },
        ]}
        cardButton={(data) => [
          data?.linked_container_qty === data?.quantity &&
            data.quantity !== 0 &&
            data.is_batch_started === false &&
            data.is_batch_stopped === false && {
              startIcon: <Rotate90DegreesCcw style={{ fontSize: "18px" }} />,
              label: "rollback",
              // handleClick: handleRollback,
            },
          data.ec_order_status === appMeta.ecOrderStatus.confirmed
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "Enter OTP",
                handleClick: handleOpenEnterOtpDialog,
              }
            : data.is_inscan_completed === false &&
              data.is_outscan_completed === false
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "IN-SCAN",
                handleClick: handleinscanopen,
              }
            : data.is_inscan_completed === true &&
              data.is_outscan_completed === false
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "OUT-SCAN",
                handleClick: handleoutscanopen,
              }
            : data.is_inscan_completed === true &&
              data.is_outscan_completed === true
            ? {
                startIcon: <Stop style={{ fontSize: "18px" }} />,
                label: "Complete Order",
                handleClick: handleCompleteOrder,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                label: "Edit",
                // handleClick: handleEditClick,
              }
            : undefined,
          data?.ec_order_status === appMeta.ecOrderStatus.otpVerfied
            ? {
                startIcon: <Cancel style={{ fontSize: "18px" }} />,
                label: "Cancel",
                handleClick: handleCancelClick,
                color: "error",
              }
            : undefined,
        ]}
        formSearchOptionKey="ec_order_id"
        formSearchLabel="Enter Order ID"
        formInput={(row) => [
          { label: "Order ID", value: "ec_order_id" },
          { label: "Order Quantity", value: "order_quantity" },
          {
            label: "Status",
            value: (row) => {
              return (
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "start",
                    fontWeight: 600,
                    color:
                      row?.is_batch_started === true &&
                      row?.is_batch_stopped === true &&
                      row?.linked_container_qty === 0
                        ? "red"
                        : "#494848",
                  }}
                >
                  {row?.is_batch_started === true &&
                  row?.is_batch_stopped === true &&
                  row?.linked_container_qty !== 0
                    ? "Completed"
                    : row?.is_batch_started === true &&
                      row?.is_batch_stopped === false
                    ? "Ongoing"
                    : row?.is_batch_started === false &&
                      row?.is_batch_stopped === false
                    ? "Yet to start"
                    : row?.is_batch_started === true &&
                      row?.is_batch_stopped === true &&
                      row?.linked_container_qty === 0
                    ? "Cancelled"
                    : "Started"}
                </Typography>
              );
            },
          },
          {
            label: "Linked Product Qty ",
            value: "linked_container_qty",
          },
          {
            label: "Created at",
            value: () => moment(row.created_at).format("DD-MM-YYYY"),
          },
        ]}
        formButton={(data) => [
          data.is_batch_started === false &&
          data.is_batch_stopped === false &&
          data?.linked_container_qty !== data?.quantity &&
          data.quantity !== 0
            ? {
                startIcon: <QrCodeScanner style={{ fontSize: "18px" }} />,
                label: "Link",
                // handleClick: handleLinking,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.quantity !== 0 &&
              data.is_batch_started === false &&
              data.is_batch_stopped === false
            ? [
                {
                  startIcon: <Start style={{ fontSize: "18px" }} />,
                  label: "rollback",
                  // handleClick: handleStartBatch,
                },
                {
                  startIcon: <Start style={{ fontSize: "18px" }} />,
                  label: "Start",
                  // handleClick: handleStartBatch,
                },
              ]
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                startIcon: <Stop style={{ fontSize: "18px" }} />,
                label: "Stop",
                // handleClick: handleStopBatch,
              }
            : data?.linked_container_qty === data?.quantity &&
              data.is_batch_started === true &&
              data.is_batch_stopped === false
            ? {
                label: "Edit",
                // handleClick: handleEditClick,
              }
            : undefined,
          data?.is_batch_started !== true &&
          data?.is_batch_stopped !== true &&
          data?.linked_container_qty === 0
            ? {
                startIcon: <Cancel style={{ fontSize: "18px" }} />,
                label: "Cancel",
                // handleClick: handleCancelClick,
                color: "error",
              }
            : undefined,
        ]}
        // tableView={view}
      />
      {cancelDialogOpen && (
        <CancelDialogBatch
          open={cancelDialogOpen}
          onClose={handleClose}
          setCancelDialogOpen={setCancelDialogOpen}
          item={currentItem}
        />
      )}

      <DeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleSubmitOtp}
        handleClose={handleCloseDialog}
      />
      <ScanningDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={currentScanned}
        setCurrentScanned={setCurrentScanned}
        scanQtyMax={scanQtyMax}
        scanOrderId={orderData?.order_id}
        scannedCount={scannedCount}
        setScannedCount={setScannedCount}
        setRequestObject={setRequestObject}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        isBatch={true}
        validateExpired={validateExpired}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
    </Box>
  );
}

export default FillingStationOrdersMain;
