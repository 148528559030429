/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Add users main component to show user details and edit details.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Dialog,
  Menu,
  Chip,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Typography,
  Divider,
  Paper,
  DialogActions,
  DialogContent,
  Autocomplete,
  Drawer,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { useDispatch, useSelector } from "react-redux";
import {
  AddUserRolesDomain,
  GetUserRolesDomainDetails,
} from "../../../Redux/Actions/addUsersDomainRolesAction";
import UserDetailsTable from "./UserDetailsTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { GetOrganizationList } from "../../../Redux/Actions/organizationAction";
import appStyle from "../../../AppMeta/appStyle";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import EditUserDetailsDialog from "./EditUserDetailsDialog";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomSelect from "../../../Components/CustomComp/CustomSelect";
import appMeta from "../../../AppMeta/appMeta";
import CustomPhoneTextField from "../../../Components/CustomComp/CustomPhoneTextField";
import appFonts from "../../../AppMeta/appFonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CustomDialog from "../../../Components/CustomComp/CustomDialog";
import EditUserRoleDialog from "./EditUserRoleDialog";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import CustomAutoComplete from "../../../Components/CustomComp/CustomAutoComplete";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    gap: "10px",
    maxWidth: "400px",
    margin: "0 auto",
  },
  // button: {
  //   alignSelf: "flex-end",
  // },
}));

const AddUsers = ({ drawOpenProp, setDrawOpenProp }) => {
  const dispatch = useDispatch();
  const rolesData = useSelector(
    (state) => state.userManagementReducer.rolesList
  );
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const countryCodes = useSelector(
    (state) => state.userManagementReducer.countryCodes
  );

  const addUserRolesDomainDetails = useSelector(
    (state) => state.addUserRolesDomainDetails
  );

  const organisationDetails = useSelector((state) => state.organizationData);
  const classes = useStyles();
  // const [formValues, setFormValues] = useState({
  //   roleName: "",
  //   organizationName: "",
  //   fullName: "",
  //   email: "",
  //   phone: "",
  // });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [organizationSearch, setOrganizationSearch] = useState("");
  const [roleSearch, setRoleSearch] = useState("");

  // Required variabled declared here
  const [addUserDialog, setAddUserDialog] = React.useState(drawOpenProp);
  const [userDetailsList, setUserDetailsList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortLabel, setSortLabel] = React.useState();
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [filterLabel, setFilterLabel] = React.useState("role_name");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [allFacility, setAllFacility] = React.useState([]);
  // const [organizationListForSearch, setOrganizationListForSerach] =
  //   useState(allFacility);
  const [organizationName, setOrganizationName] = React.useState("");
  const [organisationName, setOrganisationName] = React.useState("");
  const [organisationId, setOrganisationId] = React.useState("");

  // For checking the validation for textfield variables
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPhone, setInvalidPhone] = React.useState(false);

  //add user form variables
  const [rolesList, setRolesList] = React.useState([]);
  const [lastMore, setLastMore] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [showPassword, setShowPassword] = React.useState(false);

  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [isValidPassword, setIsValidPassword] = React.useState(false);

  //states for edit Dialoug
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openEditUserRoleDialog, setOpenEditUserRoleDialog] =
    React.useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const getUsers = () =>
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_user_list,
        params: {
          page_number: pageNumber,
          page_size: pageSize,
          [sortLabel]: ascOrDesc,
          // sort_order: ascOrDesc,
          ...(filterSearchText !== "" && { [filterLabel]: filterSearchText }),
        },
        show_toast: false,
      })
    );

  const deleteUser = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.delete_user,
        params: {
          user_id: selectedRow.user_id,
        },
        show_toast: true,
      })
    );
    setOpenDeleteConfirmationDialog(false);
    setTimeout(() => {
      getUsers();
    }, 1000);
  };

  // const handleOrganisationSearch = (event) => {
  //   const value = event.target.value;
  //   setOrganizationSearch(value);
  //   dispatch(GetOrganizationList({ page_number: "1", page_size: "25" }));
  // };

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [organizationSearch]);

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          ...(roleSearch !== "" && { role_name: roleSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
    setRolesList(rolesData);
  }, [roleSearch]);

  // React.useEffect(() => {
  //   console.log("-----rolesdata--------", rolesData);
  //   if (rolesData.error === false) {
  //     if (rolesData?.rolesList?.data?.length > 0) {
  //       console.log(rolesData.rolesList.data);
  //       // setRolesList(rolesData.rolesList.data);
  //       setLastMore(rolesData.rolesList.last_page_number);
  //       setRolesList((prevRolesList) =>
  //         prevRolesList
  //           ? [...prevRolesList, ...rolesData.rolesList.data]
  //           : rolesData.rolesList.data
  //       );
  //     }
  //   }
  // }, [rolesData]);

  // Hitting the GET Roles API to show all the role for the user to select
  React.useEffect(() => {
    // dispatch(
    //   GetUserRolesDomainDetails(
    //     pageNumber,
    //     pageSize,
    //     sortLabel,
    //     ascOrDesc,
    //     filterLabel,
    //     filterSearchText
    //   )
    // );
    getUsers();
  }, [
    pageNumber,
    pageSize,
    sortLabel,
    ascOrDesc,
    filterLabel,
    filterSearchText,
  ]);

  // To access the roles reducer store data
  React.useEffect(() => {
    if (addUserRolesDomainDetails.error === false) {
      setLastPageNumber(addUserRolesDomainDetails.userDetails.last_page_number);
      if (addUserRolesDomainDetails?.userDetails?.data?.length > 0) {
        setUserDetailsList(addUserRolesDomainDetails.userDetails.data);
      }
    }
  }, [addUserRolesDomainDetails]);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    searchAndUpdateFacility();
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_country_codes,
        show_toast: false,
      })
    );

    // dispatch(
    //   HandleApiActions({
    //     ...userManagementApis.get_roles_list,
    //     params: {
    //       ...(roleSearch !== "" && { role_name: roleSearch }),
    //       ignore_app_loading_spinner: true,
    //     },
    //     show_toast: false,
    //   })
    // );
  }, []);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails, organizationSearch]);

  React.useEffect(() => {
    console.log("allFacility");
    console.log(allFacility);
    allFacility.map((data) => {
      setOrganisationName(data.org_name);
      setOrganisationId(data.id);
    });
  }, [organisationName, organisationId]);

  React.useEffect(() => {
    console.log("----Drawer opened----");
    console.log(drawOpenProp);
    setAddUserDialog(drawOpenProp);
  }, [drawOpenProp]);

  // Checking the validation for email and phone number
  // const isValidEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Common handle change function for textfields for adding users
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    // Email validation check
    if (name === "email") {
      if (!isValidEmail(value)) {
        console.log("Invalid email");
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
    }
    // Phone number validation check
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        console.log("Invalid phone Number");
        setInvalidPhone(true);
      } else {
        setInvalidPhone(false);
      }
    }
    // Setting the formvalues
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [name]: value,
    // }));
  };

  // This function will hit the Add User API with Request object required
  const handleSubmit = (formData) => {
    console.log("---formData---", formData.timeZone?.split("-")[1]);
    // e.preventDefault();
    // Handle form submission here
    // console.log("organizationName", organizationName);
    // console.log(formValues);
    const reqObj = {
      role_name: formData?.role,
      fullname: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      org_id: parseInt(formData?.organization),
      region: formData.timeZone?.split("-")[0],
      phone_code: formData.timeZone?.split("-")[1],
    };

    console.log("---reqObj---", reqObj);

    dispatch(AddUserRolesDomain(reqObj));
    setAddUserDialog(false);
    getUsers();

    ///reset form data
    setSelectedRole();
    setOrganisationName("");
    setFullName("");
    setEmail("");
    setTimeZone("");
    setPhone("");
    setSelectedPhoneCode("");
    setPassword("");
    // setTimeout(() => {
    //   dispatch(
    //     GetUserRolesDomainDetails(
    //       pageNumber,
    //       pageSize,
    //       sortLabel,
    //       ascOrDesc,
    //       filterLabel,
    //       filterSearchText
    //     )
    //   );
    // }, 1000);
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  const handleRowClick = (index) => {
    setOpenEditDialog(true);
    setSelectedRow(addUserRolesDomainDetails.userDetails[index]);
    // setDrawOpenProp(true);
    // console.log(index);
  };

  const handleButtonFun = () => {
    setAddUserDialog(true);
  };

  const handleAddUserFilterClose = (filterData) => {
    setFilterLabel(filterData.value);
    setFilterSearchText(filterData.searchText);
    // getUsers();
  };

  const handleAddUserSortClose = (sortData) => {
    setSortLabel(sortData.value);
    setAscOrDesc(sortData.searchText);
    // getUsers();
  };

  //  Jsx starts here..
  return (
    <Box
    // sx={{
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   gap: "20px",
    // }}
    >
      {/* <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          variant="contained"
          color="secondary"
          label="Add new user"
          sx={appStyle.general.button.addButton}
          onClick={() => setAddUserDialog(true)}
          endIcon={<PersonAddRoundedIcon />}
        >
          Add new user
        </Button>
      </Box> */}
      <Drawer
        open={addUserDialog}
        anchor="right"
        PaperProps={{
          sx: {
            width: 250, // Set the width here
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
        // onClose={() => {
        //   setAddUserDialog(false);
        //   setDrawOpenProp(false);
        // }}
        sx={{ borderRadius: "30px" }}
      >
        <Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: "10px",
            }}
            variant={appFonts.cardTitle}
          >
            Add User
          </Typography>
        </Box>
        <Divider
          component={Paper}
          elevation={5}
          sx={{ width: "100%", border: "1px solid black" }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <CustomForm
            handleClose={() => setAddUserDialog(false)}
            handleSubmit={handleSubmit}
            configConfirmation={{
              isConfirmationRequired: true,
              dialogContent: "Are you sure you want to update changes?",
            }}
            data={[
              {
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                    optionKey: "role_name",
                    optionKey2: "department",
                    outputKey: "role_name",
                    searchAndUpdateAPI: (searchText) => {
                      setRoleSearch(searchText);
                    },
                    input: rolesList,
                    name: "role",
                    label: "Enter Role",
                  },
                  {
                    ...(profileRole === "1000" && {
                      inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                      optionKey: "org_name",
                      outputKey: "id",
                      searchAndUpdateAPI: (searchText) => {
                        setOrganizationSearch(searchText);
                      },
                      input: allFacility,
                      name: "organization",
                      label: "Enter Facility",
                    }),
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Full name",
                    defaultValue: "",
                    name: "fullName",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Email",
                    defaultValue: "",
                    name: "email",
                    textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.SELECT,
                    placeHolder: "Time Zone",
                    name: "timeZone",
                    input: countryCodes?.map((data) => {
                      return {
                        value: `${data.time_zone}-${data.phone_code}`,
                        menuItem: data.time_zone,
                      };
                    }),
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Phone",
                    defaultValue: "",
                    name: "phone",
                    textFieldType: appMeta.textFieldTypes.NUMBER,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ],
              },
            ]}
          />
          {/* <Autocomplete
              id="country-select-demo"
              options={rolesList || []}
              autoHighlight
              getOptionLabel={(option) => {
                return option?.role_name;
              }}
              onChange={(_, selectedOption) => {
                setSelectedRole(selectedOption);
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                return (
                  <Box
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    {`${option?.role_name}${
                      option?.department && "/" + option?.department
                    }`}
                  </Box>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth={false}
                    label="Enter Role"
                    variant="standard"
                    onChange={(e) => {
                      setRoleSearch(e.target.value);
                    }}
                    value={roleSearch}
                    sx={{
                      minWidth: "25ch",
                    }}
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                      },
                    }}
                  />
                );
              }}
            /> */}

          {/* <Autocomplete
              id="country-select-demo"
              options={allFacility || []}
              autoHighlight
              getOptionLabel={(option) => {
                return option.org_name;
              }}
              onChange={(_, selectedOption) => {
                setOrganizationName(selectedOption);
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                return (
                  <Box
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    {`${option.org_name}`}
                  </Box>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth={false}
                    label="Enter Organization"
                    variant="standard"
                    onChange={(e) => {
                      setOrganizationSearch(e.target.value);
                    }}
                    value={organizationSearch}
                    sx={{
                      minWidth: "25ch",
                    }}
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                      },
                    }}
                  />
                );
              }}
            /> */}

          {/* <CustomInputTextField
              placeholder="Full Name"
              size="large"
              value={fullName}
              setValue={setFullName}
              type={appMeta.textFieldTypes.TEXT}
              isRequired={true}
            /> */}

          {/* <CustomInputTextField
              placeholder="email"
              size="large"
              value={email}
              setValue={setEmail}
              type={appMeta.textFieldTypes.EMAIL}
              isValid={isValidEmail}
              setIsValid={setIsValidEmail}
              isHintRequired={true}
              isRequired={true}
            /> */}

          {/* <CustomSelect
              name="Time Zone*"
              input={countryCodes?.map((data) => {
                return {
                  value: `${data.time_zone}-${data.phone_code}`,
                  menuItem: data.time_zone,
                };
              })}
              handleChange={(e) => {
                let [country, phoneCode] = e?.target?.value?.split("-");
                setTimeZone(country);
                setSelectedPhoneCode(phoneCode);
              }}
              defaultValue={`${timeZone}-${selectedPhoneCode}`}
              variant="standard"
              sx={{ width: "11rem" }}
            /> */}

          {/* <CustomInputTextField
              placeholder="Phone"
              size="large"
              value={phone}
              setValue={setPhone}
              type={appMeta.textFieldTypes.PHONE}
              isValid={isValidPhone}
              setIsValid={setIsValidPhone}
              phoneCode={selectedPhoneCode}
              isHintRequired={true}
              isRequired={true}
            /> */}

          {/* <CustomInputTextField
              placeholder="Password"
              size="large"
              value={password}
              setValue={setPassword}
              isValid={isValidPassword}
              setIsValid={setIsValidPassword}
              type={appMeta.textFieldTypes.PASSWORD}
              isHintRequired={true}
              isRequired={true}
            /> */}
        </Box>
        {/* 
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={invalidEmail || invalidPhone}
            sx={{
              ...appStyle.general.button.blueButton,
              borderRadius: "20rem",
            }}
          >
            Submit
          </Button>

          <CustomButton
            handleOnClick={() => setAddUserDialog(false)}
            variant="text"
            color="error"
            btnName="Close"
          />
        </DialogActions> */}
      </Drawer>
      {/* User list table component called here */}
      {/* <UserDetailsTable
        userDetailsList={userDetailsList}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ascOrDesc={ascOrDesc}
        isActive={isActive}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setAscOrDesc={setAscOrDesc}
        setIsActive={setIsActive}
        setAddUserDialog={setAddUserDialog}
        lastPageNumber={lastPageNumber}
      /> */}
      <CustomTable
        tableData={addUserRolesDomainDetails.userDetails}
        tableHead={["Full Name", "Role Name", "Phone", "Department", "Delete"]}
        tableDataKey={[
          {
            rowClickEnable: true,
            component: (props) => {
              return (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: "blue",
                    }}
                  >
                    {props?.row?.fullname}
                  </Typography>
                </Box>
                // edit
              );
            },
          },
          // "role_details.role_name",
          {
            // rowClickEnable: true,
            component: (props) => {
              return (
                <Box
                  onClick={() => {
                    setOpenEditUserRoleDialog(true);
                    setSelectedRow(props.row);
                  }}
                  sx={{ cursor: "pointer", display: "flex", gap: "5px" }}
                >
                  <Typography variant="h6">
                    {props?.row?.role_details?.role_name}
                  </Typography>
                  <Box sx={{ marginTop: "-3px" }}>
                    <BorderColorIcon style={{ fontSize: "1rem" }} />
                  </Box>
                </Box>
              );
            },
          },
          "phone",
          "role_details.department",
          {
            // rowClickEnable: true,
            component: (props) => {
              return (
                <Box>
                  <CustomButton
                    label={"Delete"}
                    handleClick={() => {
                      setSelectedRow(props.row);
                      setOpenDeleteConfirmationDialog(true);
                    }}
                  />
                </Box>
              );
            },
          },
        ]}
        filter={true}
        filterSelectOption={[
          { label: "Roll Name", value: "role_name" },
          { label: "Full Name", value: "fullname" },
          {
            type: "select",
            value: "is_active",
            label: "Status",
            input: [
              { value: true, menuItem: "Active" },
              { value: false, menuItem: "In active" },
            ],
          },
        ]}
        handleFilterClose={handleAddUserFilterClose}
        sort={true}
        sortSelectOption={[
          { label: "Created at", value: "created_at_sort" },
          { label: "Full name", value: "fullname_sort" },
          { label: "Role name", value: "role_name_sort" },
        ]}
        handleSortClose={handleAddUserSortClose}
        addButton={true}
        handleOnClickAddButton={handleButtonFun}
        tableRowClickFunction={handleRowClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
      <EditUserDetailsDialog
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        selectedRow={selectedRow}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ascOrDesc={ascOrDesc}
        getUsers={getUsers}
        rolesList={rolesList}
        setRoleSearch={setRoleSearch}
        // isActiveGet={isActiveGet}
      />

      {/* Edit user Role */}
      <CustomDialog
        open={openEditUserRoleDialog}
        onClose={() => {
          setOpenEditUserRoleDialog(false);
        }}
      >
        <EditUserRoleDialog
          setOpenEditUserRoleDialog={setOpenEditUserRoleDialog}
          rolesList={rolesList}
          selectedRow={selectedRow}
          setRoleSearch={setRoleSearch}
          getUsers={getUsers}
        />
      </CustomDialog>
      <CustomConfirmationDialog
        open={openDeleteConfirmationDialog}
        dialogContent="Are you sure you want to delete user?"
        onConfirm={() => {
          deleteUser();
        }}
        onClose={() => {
          setOpenDeleteConfirmationDialog(false);
        }}
      />
    </Box>
  );
};

export default AddUsers;
