/**
 * @author Bookwater Tech
 * @description Custom hook use Axios
 * @copyright Bookwater tech pvt ltd
 */

import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { startLoading, stopLoading } from "../Redux/Actions/SpinnerAction";
import { store } from "../Redux/store";

// const baseURL = "https://link.bookwater.com";
const baseURL = "https://paasstage.bookwater.com";

// const baseURL = "http://localhost:6007";
let show_spinner = true;
const useAxios = () => {
  let authTokens = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : localStorage.getItem("token");

  let storageType = localStorage.getItem("token") ? true : false; //true -> Local Storage
  if (!authTokens) {
    storageType = false;
    authTokens = sessionStorage.getItem("token");
  }
  //   console.log("authTokens: " + authTokens);

  //Create the Axios Instance
  //Note: Every API (GET/PUT/POST) must have Auth Token So Make it as Default.
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens}` },
  });
  //It will Execute Before Main Axios for Token verification & Refresh
  axiosInstance.interceptors.request.use(
    async (req) => {
      console.log(typeof req);
      show_spinner = true;
      try {
        // if the key exist then this API doesn't wanto to show spinner
        if (req.params?.ignore_app_loading_spinner === true) {
          show_spinner = false;
          delete req.params.ignore_app_loading_spinner;
        }
      } catch (error) {
        console.error("Invalid URL:", error);
      }
      if (show_spinner) {
        store.dispatch(startLoading());
      }

      const user = jwt_decode(authTokens);
      // console.log(
      //   "axiosInstance.interceptors.request User: " + JSON.stringify(user)
      // );
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

      if (!isExpired) return req;

      //If Token Expiered, refresh the Token
      console.log(authTokens);
      const token = authTokens;

      const response = await axios.post(`${baseURL}/PSCM/login/keepalive`, {
        headers: { Authorization: `Bearer ${token}` },
        token,
      });

      // For local backend API testing purpose
      // const response = await axios.post(
      //   `https://paasstage.bookwater.com/PSCM/login/keepalive`,
      //   {
      //     headers: { Authorization: `Bearer ${token}` },
      //     token,
      //   }
      // );
      //

      if (response.status === 200) {
        console.log("Success Response in Refresh Token");
        if (storageType) {
          localStorage.setItem("token", response.data.token);
          sessionStorage.setItem("token", response.data.token);
        } else {
          sessionStorage.setItem("token", response.data.token);
        }
        authTokens = response.data.teken;
      } else {
        console.log("Error Response in Refresh Token");
        console.log("End Response: " + JSON.stringify(response));
      }
      req.headers.Authorization = `Bearer ${authTokens}`;
      return req;
    },
    (error) => {
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return response;
    },
    (error) => {
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
export default useAxios;
