import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { appColors } from "../../AppMeta/appColors";
import { useDispatch } from "react-redux";
import {
  CheckPurchaseOrder,
  PlaceOrder,
} from "../../Redux/Actions/purchaseProductsAction";
import { useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomButton from "../../Components/CustomComp/CustomButton";

const PriceBreakUp = ({
  orderValidation,
  selectedData,
  productType,
  setActiveStep,
}) => {
  const isMultipleManufacturer = orderValidation.length > 1;

  console.log("is multiple manufacturer", isMultipleManufacturer);
  const dispatch = useDispatch();
  const history = useHistory(); // Get the history object

  const [selectedManufacturerDetails, setSelectedManufacturerDetails] =
    useState([]);

  const [checkPo, setCheckPo] = React.useState(false);
  const [downloadSpinner, setDownloadSpinner] = React.useState(false);

  React.useEffect(() => {
    if (downloadSpinner) {
      setTimeout(() => {
        setDownloadSpinner(false);
      }, 2000);
    }
  }, [downloadSpinner]);
  React.useEffect(() => {
    console.log(selectedManufacturerDetails);
  }, [selectedManufacturerDetails]);

  React.useEffect(() => {
    if (checkPo) {
      const reqObj = {
        order_type: parseInt(productType),
        orders: selectedManufacturerDetails,
      };
      dispatch(CheckPurchaseOrder(reqObj));
      setDownloadSpinner(true);
      setCheckPo(false);
    }
  }, [checkPo]);
  const handleCheckPurchaseOrder = (manufacturer) => {
    console.log(manufacturer);
    // Extracting the required fields (id, quantity, and price) from components
    const product_details = manufacturer.components.map(
      ({ id, quantity, price }) => ({ id, quantity, price })
    );

    // Store the productDetails for the selected manufacturer
    setSelectedManufacturerDetails([
      { mft_id: manufacturer.mft_id, product_details },
    ]);
    setCheckPo(true);
    // Perform additional logic as needed
    console.log("Checking purchase order for manufacturer:", manufacturer);
  };

  const handlePlaceOrder = async () => {
    // Logic for placing the order
    console.log(
      "Placing order for selected manufacturer's details:",
      selectedManufacturerDetails
    );
    const reqObj = {
      order_type: 300,
      orders: selectedManufacturerDetails,
    };
    const response = await dispatch(PlaceOrder(reqObj));
    console.log("---response---", response);
    if (response.status === 200) {
      history.push("/dashboard/bookwater-order-management");
    }
    // if (isMultipleManufacturer === false) {
    //   history.push("/dashboard/orders");
    // }
  };

  React.useEffect(() => {
    orderValidation.map((manufacturer) => {
      const product_details = manufacturer.components.map(
        ({ id, quantity, price }) => ({ id, quantity, price })
      );

      setSelectedManufacturerDetails([
        { mft_id: manufacturer.mft_id, product_details },
      ]);
    });
  }, [orderValidation]);

  return (
    <Paper elevation={23}>
      {orderValidation.map((manufacturer) => (
        <div key={manufacturer.mft_id}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "10px",
              color: "white",
            }}
          >
            Manufacturer Name : {manufacturer.mft_name}
          </Typography>
          <CustomTable
            pagination={false}
            tableData={manufacturer.components}
            tableHead={[
              "Product ID",
              "Quantity",
              "Price/Quantity",
              "Total GST",
              "Grand Total",
            ]}
            tableDataKey={[
              "id",
              "quantity",
              "price",
              "GST",
              {
                component: (props) => {
                  return manufacturer.order_total;
                },
              },
            ]}
          />

          {/* <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: appColors.secondary }}>
                <TableRow>
                  <TableCell>Product ID</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price/Quantity</TableCell>
                  <TableCell>Total GST</TableCell>
                  <TableCell>Grand Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manufacturer.components.map((component) => (
                  <TableRow key={component.id}>
                    <TableCell>{component.id}</TableCell>
                    <TableCell>{component.quantity}</TableCell>
                    <TableCell>{component.price}</TableCell>
                    <TableCell>{component.GST}</TableCell>
                    <TableCell>{manufacturer.order_total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box sx={{ padding: "10px" }}>
              <CustomButton
                label={"Back"}
                handleClick={() => setActiveStep(0)}
              />
              {/* <CustomButton
                label={"Check Order"}
                handleClick={() => handleCheckPurchaseOrder(manufacturer)}
              /> */}
              <CustomButton
                label={"Place order"}
                handleClick={handlePlaceOrder}
              />
              {/* <Button
                variant="contained"
                onClick={() => handleCheckPurchaseOrder(manufacturer)}
                size="small"
              >
                Check Purchase order
              </Button> */}
            </Box>
            {/* <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                onClick={handlePlaceOrder}
                size="small"
              >
                Place Order
              </Button>
            </Box> */}
          </Box>
        </div>
      ))}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {downloadSpinner && <CircularProgress />}
      </Box>
    </Paper>
  );
};

export default PriceBreakUp;
