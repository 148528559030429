import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

function HomeOrderCards({ value, subTitle, icon }) {
  return (
    <Box
      sx={{
        height: "125px",
        width: "250px",
        background: "#d4e2ea",
        borderRadius: "1rem",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "125px",
          width: "125px",
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          paddingTop: "1rem",
          paddingLeft: "1rem",
          color: "#0a4299",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          {value}
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>{subTitle}</Typography>
      </Box>
      <Box
        sx={{
          height: "125px",
          width: "130px",
          background: "#A3BCCB",
          borderRadius: "50%",
          borderTopRightRadius: "1rem",
          borderBottomRightRadius: "1rem",
          alignSelf: "flex-end",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "#0a4299", fontSize: "26px" }}>
          <FontAwesomeIcon icon={icon} />
        </Typography>
      </Box>
    </Box>
  );
}

export default HomeOrderCards;
