import { Box, Stack } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function CustomQuantityIncrementDecrementChange({
  handelMinusQuantity,
  handelAddQuantity,
  handelQuantityChange,
  inputValue,
  disabled,
}) {
  return (
    <Stack direction="row">
      <Box
      // sx={{
      //   // backgroundColor: "#068FFF",
      //   height: "30px",
      //   width: "50px",
      //   display: "flex",
      //   justifyContent: "space-around",
      //   // borderRadius: "0.3rem 0 0 .3rem",
      //   color: "#fff",
      // }}
      >
        <CustomButton
          handleClick={handelMinusQuantity}
          endIcon={<RemoveIcon />}
          style={{ height: "30px" }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#F0FFFF",
          height: "30px",
          width: "2.8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "13px",
          mt: "10px",
          borderBottom: "2px solid black",
        }}
      >
        {" "}
        <input
          type="number"
          value={inputValue}
          onChange={handelQuantityChange}
          style={{
            wordWrap: "normal",
            border: 0,
            fontSize: "18px",
            minWidth: "40px",
            textAlign: "center",
            width: "100%",
            outline: "none",
          }}
          disabled={false}
        />
      </Box>
      <Box
      // sx={{
      //   // backgroundColor: "#068FFF",
      //   height: "30px",
      //   width: "50px",
      //   display: "flex",
      //   justifyContent: "space-around",
      //   borderRadius: "0 0.3rem .3rem 0",
      //   color: "#fff",
      // }}
      >
        <CustomButton
          handleClick={handelAddQuantity}
          endIcon={<AddIcon />}
          style={{ height: "30px" }}
        />
      </Box>
    </Stack>
  );
}

export default CustomQuantityIncrementDecrementChange;
