/**
 * @author Pravesh
 * @description All Meta/Constats Constants which are mentioned for Local
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 */

const appMeta = {
  textFieldTypes: {
    PINCODE: 100,
    FULL_NAME: 200,
    EMAIL: 300,
    PASSWORD: "password",
    SERVICE_AREA: 500,
    PHONE: 600,
    EMAIL_PHONE: 700,
    TEXT: "text",
    NUMBER: 900,
    PERCENTAGE: 1000,
    DATE: "date",
    ISI: "isi",
    FSSAI: "fssai",
    AADHAR: "aadhar",
    GST: "gst",
    PAN: "pan",
  },
  ecOrderStatus: {
    confirmed: 100,
    otpVerfied: 201,
    completed: 400,
    cancelled: 500,
  },
  inputFieldTypes: {
    INPUT: "input",
    SELECT: "select",
    RADIO: "radio",
    FILE: "file",
    SWITCH: "switch",
    DATE: "date",
    AUTO_COMPLETE: "autoComplete",
    CHECKBOX: "checkBox",
  },
};

export default appMeta;
