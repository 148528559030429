import React from "react";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import CustomQuantityIncrementDecrementChange from "../../Components/CustomComp/CustomQuantityIncrementDecrementChange";
import { Box, Divider, Typography } from "@mui/material";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { wfsApis } from "../../Redux/Actions/wfsAction";

function PlaceRecycleOrder(props) {
  console.log("propsss", props);

  //hooks
  const dispatch = useDispatch();
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;

  //states
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    physical_damaged_qty: 0,
    qrBarcode_damaged_qty: 0,
    expired_qty: 0,
  });
  const [productName, setProductName] = React.useState({});
  console.log("productName", productName);
  const productDetails = useSelector((state) => state.productData);
  const organisationDetails = useSelector((state) => state.organizationData);

  const [allFacilities, setAllFacilities] = React.useState(
    organisationDetails.organizationList
  );
  const [organizationSearch, setOrganizationSearch] = React.useState("");
  const [selectFacility, setSelectFacility] = React.useState("");
  const [allWfs, setAllWfs] = React.useState(wfsList);
  const [searchWfs, setSearchWfs] = React.useState("");
  const [selectedWfs, setSelectedWfs] = React.useState("");
  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);
  console.log("orderParams", orderParams);
  const handelQuantityphysicalChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      physical_damaged_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.physical_damaged_qty),
    }));
  };

  const handelQuantityqrbarcodeChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      qrBarcode_damaged_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.qrBarcode_damaged_qty),
    }));
  };
  const handelQuantityExpiredChange = (value) => {
    setOrderParams((prev) => ({
      ...prev,
      expired_qty:
        value >= 0 && value <= 99999
          ? parseInt(value)
          : parseInt(prev.expired_qty),
    }));
  };

  const handleChangeMaterial = (value) => {
    console.log("value", value);

    const material = value;
    let duplicateElement = productDetails?.finalProduct?.find(
      (data) => data.product_details.id === material
    );

    if (Object.keys(duplicateElement).length > 0) {
      console.log("duplicateElement", {
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
      setProductName({
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
    }
  };

  const handleCloseDrawer = () => {
    props.handleCloseDrawer();
  };
  const handleSubmitDrawer = () => {
    props.handleSubmitDrawer({
      container_material: productName.material,
      container_capacity: productName.capacity,
      physically_damaged_quantity: orderParams.physical_damaged_qty,
      expired_quantity: orderParams.qrBarcode_damaged_qty,
      qr_barcode_damaged_qty: orderParams.qrBarcode_damaged_qty,
      wfs_id: selectedWfs,
      facility_id: selectFacility,
    });
  };

  const searchAndUpdateFacility = async () => {
    await dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          ...(organizationSearch !== "" && { org_name: organizationSearch }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateWfs = async () => {
    await dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    searchAndUpdateFacility();
    console.log("organisation");
    console.log(organisationDetails);
  }, []);

  React.useEffect(() => {
    searchAndUpdateFacility();
  }, [organizationSearch]);

  React.useEffect(() => {
    console.log("organisationDetails");
    console.log(organisationDetails);
    setAllFacilities(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    console.log("selectFacility");
    console.log(selectFacility);
    if (selectFacility !== "") {
      searchAndUpdateWfs();
    }
  }, [selectFacility]);

  React.useEffect(() => {
    if (profileRole === "1000" && selectFacility !== "") {
      searchAndUpdateWfs();
    } else if (profileRole !== "1000" && searchWfs !== "") {
      searchAndUpdateWfs();
    }
  }, [searchWfs]);

  React.useEffect(() => {
    setAllWfs(wfsList);
  }, [wfsList]);

  return (
    <CustomDrawer
      open={props.drawOpenProp}
      // setOpen={props.setOpenDialog}
      title={"Create Recycle Order"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          m: 2,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {profileRole === "1000" && (
          <CustomAutoComplete
            field={{
              optionKey: "org_name",
              outputKey: "id",
              onChange: (name, value) => {
                setSelectFacility(value);
              },
              searchAndUpdateAPI: (searchText) => {
                setOrganizationSearch(searchText);
              },
              input: allFacilities || [],
              name: "facility",
              label: "Enter Facility Name",
            }}
            handleInputChange={(name, selectedOption) => {}}
          />
        )}
        <CustomAutoComplete
          field={{
            optionKey: "wfs_name",
            outputKey: "wfs_id",
            onChange: (name, value) => {
              console.log("---Value--", value);
              setSelectedWfs(value);
            },
            searchAndUpdateAPI: (searchText) => {
              setSearchWfs(searchText);
            },
            input: allWfs || [],
            name: "wfs",
            label: "Enter WFS Name",
          }}
          handleInputChange={(name, selectedOption) => {}}
        />
        <CustomAutoComplete
          field={{
            optionKey: "product_details.capacity",
            optionKey2: "product_details.material",
            outputKey: "product_details.id",
            searchAndUpdateAPI: (searchText) => {},
            onChange: (name, value) => {
              handleChangeMaterial(value);
            },
            input: productDetails?.finalProduct || [],
            name: "product_details.id",
            label: "Select Product",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "5px",
          // marginTop: "1rem",
          ml: "0px",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: "17px", pl: "10px" }}>
          &bull; Physical Damaged Quantity&nbsp;&nbsp;
        </Typography>
        <CustomQuantityIncrementDecrementChange
          handelAddQuantity={() =>
            handelQuantityphysicalChange(
              parseInt(orderParams.physical_damaged_qty) + 1
            )
          }
          handelMinusQuantity={() =>
            handelQuantityphysicalChange(
              parseInt(orderParams.physical_damaged_qty) - 1
            )
          }
          handelQuantityChange={(event) =>
            handelQuantityphysicalChange(event.target.value)
          }
          inputValue={orderParams.physical_damaged_qty}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // gap: "10px",
          // marginTop: "1rem",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: "17px", pl: "10px" }}>
          &bull; Qr/Barcode Damaged Quantity&nbsp;&nbsp;
        </Typography>
        <CustomQuantityIncrementDecrementChange
          handelAddQuantity={() =>
            handelQuantityqrbarcodeChange(
              parseInt(orderParams.qrBarcode_damaged_qty) + 1
            )
          }
          handelMinusQuantity={() =>
            handelQuantityqrbarcodeChange(
              parseInt(orderParams.qrBarcode_damaged_qty) - 1
            )
          }
          handelQuantityChange={(event) =>
            handelQuantityqrbarcodeChange(event.target.value)
          }
          inputValue={orderParams.qrBarcode_damaged_qty}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "center",
          // gap: "10px",
          // marginTop: "1rem",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: "17px", pl: "10px" }}>
          &bull; Expired Quantity&nbsp;&nbsp;
        </Typography>
        <CustomQuantityIncrementDecrementChange
          handelAddQuantity={() =>
            handelQuantityExpiredChange(parseInt(orderParams.expired_qty) + 1)
          }
          handelMinusQuantity={() =>
            handelQuantityExpiredChange(parseInt(orderParams.expired_qty) - 1)
          }
          handelQuantityChange={(event) =>
            handelQuantityExpiredChange(event.target.value)
          }
          inputValue={orderParams.expired_qty}
        />
      </Box>
      <Divider sx={{ border: "1px solid black" }} />
      <Box sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
        <CustomButton
          label="close"
          color="error"
          handleClick={handleCloseDrawer}
        />
        <CustomButton label="submit" handleClick={handleSubmitDrawer} />
      </Box>
    </CustomDrawer>
  );
}

export default PlaceRecycleOrder;
