import { Box, InputLabel, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomButton from "../../Components/CustomComp/CustomButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PlaceRecycleOrder from "./PlaceRecycleOrder";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";
import CustomTable from "../../Components/CustomComp/CustomTable";
import moment from "moment";
import {
  CreateRecycleOrder,
  GetRecycleOrderTable,
} from "../../Redux/Actions/recycleActions";
import { useDispatch, useSelector } from "react-redux";
import CustomCard from "../../Components/QrCodeScanner/CustomCard";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
function RecycleOrdersMain() {
  const dispatch = useDispatch();
  const recycleData = useSelector((state) => state.recycleData);
  console.log("recycleData", recycleData);
  const [drawOpenProp, setDrawOpenProp] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [filterLabel, setFilterLabel] = React.useState("device_id");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    dispatch(
      GetRecycleOrderTable({ page_number: pageNumber + 1, page_size: pageSize })
    );
  }, []);

  const handleOpenDrawer = () => {
    console.log("-------drawer-------");
    setDrawOpenProp(true);
  };
  const handleCloseDrawer = () => {
    setDrawOpenProp(false);
  };
  const handleSubmitDrawer = (props) => {
    const reqObj = props;

    console.log(reqObj);
    dispatch(CreateRecycleOrder(reqObj));
    setTimeout(() => {
      dispatch(GetRecycleOrderTable());
    }, 1000);
    setDrawOpenProp(false);
  };

  const handleRecycleOrderFilter = ({ value, searchText }) => {
    console.log(value, searchText);
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  const handleRecycleOrderSort = ({ value, searchText }) => {
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };
  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: isMobile ? "center" : "flex-end",
            flexWrap: "wrap",
          }}
        >
          {/* Page Size */}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
        
            <CustomSelect
              defaultValue={pageSize}
              name={"Page size"}
              sx={{ width: "10ch" }}
              handleChange={(event) => {
                console.log(event.target.value);
                setPageSize(event.target.value);
              }}
              input={[
                { menuItem: 25, value: 25 },
                { menuItem: 100, value: 100 },
                { menuItem: 200, value: 200 },
              ]}
            />
          </Box> */}
          {/* Page Number */}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              // justifyContent: "center",
              // background: "red",
            }}
          >
            <InputLabel sx={{ fontSize: "10px", fontWeight: "bold" }}>
              Page number:
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FirstPageIcon
                sx={{
                  color: pageNumber - 1 > 0 ? "blue" : "gray",
                  fontSize: "24px",
                }}
                onClick={() => {
                  if (pageNumber - 1 >= 0) {
                    setPageNumber(() => pageNumber - 1);
                  }
                }}
              />
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {pageNumber + 1}
              </Typography>
              <LastPageIcon
                sx={{
                  color:
                    recycleData?.batchData?.length === pageSize
                      ? "blue"
                      : "gray",
                  fontSize: "24px",
                }}
                onClick={() => {
                  if (recycleData?.batchData?.length === pageSize) {
                    setPageNumber(() => pageNumber + 1);
                  }
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomFilter
              name={"Filter"}
              selectOption={[{ value: "batch_id", label: "Batch ID" }]}
              type={"filter"}
              startIcon={<FilterAltIcon />}
              handleFilterClose={handleRecycleOrderFilter}
            />
            <CustomFilter
              name={"Sort"}
              selectOption={[{ value: "created_at_sort", label: "Created at" }]}
              type={"sort"}
              startIcon={<SwapVertIcon />}
              handleFilterClose={handleRecycleOrderSort}
            />
            <CustomButton
              startIcon={<AddIcon />}
              label={"Place Order"}
              handleClick={() => {
                setDrawOpenProp(true);
              }}
            />
          </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        {/* {recycleData.providedAccessDataTable?.map((data) => {
          return (
            <CustomCard
              // titleKey="Product Name"
              subTitle={`${data.container_capacity} - ${data.container_material}`}
              titleKey="Order ID"
              title={data.order_id}
              input={[
                {
                  data: [
                    {
                      label: "Physical Damaged Qty",
                      value: data.physically_damaged_quantity,
                    },
                    {
                      label: "Status",
                      value: () => {
                        return (
                          <Typography
                            sx={{
                              fontSize: "13px",
                              textAlign: "start",
                              fontWeight: 600,
                              color:
                                data?.order_status === 100 ? "red" : "#494848",
                            }}
                          >
                            {parseInt(data?.order_status) === 100
                              ? "Requested"
                              : parseInt(data?.order_status) === 200
                              ? "Completed"
                              : parseInt(data?.order_status) === 300
                              ? "Cancelled"
                              : ""}
                          </Typography>
                        );
                      },
                    },
                  ],
                },
                {
                  data: [
                    {
                      label: "Qr Barcode Damaged Qty ",
                      value: data.linked_container_qty,
                    },
                    {
                      label: "Created at",
                      value: moment(data.created_at).format("DD-MM-YYYY"),
                    },
                  ],
                },
                {
                  data: [
                    {
                      label: "Expired Qty ",
                      value: data.expired_quantity,
                    },
                  ],
                },
              ]}
              // buttons={[
              //   data.is_batch_started === false &&
              //   data.is_batch_stopped === false &&
              //   data?.linked_container_qty !== data?.quantity
              //     ? {
              //         label: "Link",
              //         handleClick: handleLinking,
              //       }
              //     : data?.linked_container_qty === data?.quantity &&
              //       data.is_batch_started === false &&
              //       data.is_batch_stopped === false
              //     ? {
              //         label: "Start",
              //         handleClick: handleStartBatch,
              //       }
              //     : data?.linked_container_qty === data?.quantity &&
              //       data.is_batch_started === true &&
              //       data.is_batch_stopped === false
              //     ? {
              //         label: "Edit",
              //         handleClick: handleEditClick,
              //       }
              //     : undefined,
              // ]}
            />
          );
        })} */}
        {/* <CustomDrawer open={drawOpenProp} setDrawOpenProp={setDrawOpenProp} /> */}

        <CustomTable
          tableHead={[
            "Order ID",
            "Capacity",
            "Physical Damage Qty",
            "Qr Barcode Damaged Qty",
            "Expired Qty",
            "Status",
            "Created at",
          ]}
          tableData={recycleData.providedAccessDataTable}
          tableDataKey={[
            "order_id",
            "container_capacity",
            "physically_damaged_quantity",
            "qr_barcode_damaged_qty",
            "expired_quantity",
            {
              component: (row) => {
                console.log("---row---", row);
                let status =
                  parseInt(row?.row?.order_status) === 100
                    ? "Requested"
                    : parseInt(row?.row?.order_status) === 200
                    ? "Completed"
                    : parseInt(row?.row?.order_status) === 300
                    ? "Cancelled"
                    : "";
                return status;
              },
            },
            {
              component: (row) =>
                moment(row?.row?.created_at).format("DD-MM-YYYY"),
            },
          ]}
          filter={true}
          filterSelectOption={[{ value: "batch_id", label: "Batch ID" }]}
          handleFilterClose={handleRecycleOrderFilter}
          sort={true}
          sortSelectOption={[{ value: "created_at_sort", label: "Created at" }]}
          addButton={true}
          addButtonLabel={"Place Order"}
          handleOnClickAddButton={() => {
            setDrawOpenProp(true);
          }}
          handleSortClose={handleRecycleOrderSort}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          isCardViewEnable={true}
          cardTitle={{ label: "Order ID", key: "order_id" }}
          cardSubTitle={{ label: "Capacity", key: "container_capacity" }}
          cardInput={(row) => [
            {
              data: [
                {
                  label: "Physical Damage Qty",
                  value: row.physically_damaged_quantity,
                },
              ],
            },
            {
              data: [
                {
                  label: "Qr Barcode Damaged Qty",
                  value: row.qr_barcode_damaged_qty,
                },
              ],
            },
            {
              data: [
                {
                  label: "Expired Qty",
                  value: row.expired_quantity,
                },
                {
                  label: "Status",
                  value: () =>
                    parseInt(row?.order_status) === 100
                      ? "Requested"
                      : parseInt(row?.order_status) === 200
                      ? "Completed"
                      : parseInt(row?.order_status) === 300
                      ? "Cancelled"
                      : "",
                },
              ],
            },
          ]}
          isFormViewEnable={true}
          formSearchOptionKey="order_id"
          formSearchLabel="Enter Order ID"
          formInput={(row) => [
            {
              label: "Physical Damage Qty",
              value: "physically_damaged_quantity",
            },
            {
              label: "Qr Barcode Damaged Qty",
              value: "qr_barcode_damaged_qty",
            },
            {
              label: "Expired Qty",
              value: "expired_quantity",
            },
            {
              label: "Status",
              value: () =>
                parseInt(row?.order_status) === 100
                  ? "Requested"
                  : parseInt(row?.order_status) === 200
                  ? "Completed"
                  : parseInt(row?.order_status) === 300
                  ? "Cancelled"
                  : "",
            },
          ]}
        />
        <PlaceRecycleOrder
          drawOpenProp={drawOpenProp}
          setDrawOpenProp={setDrawOpenProp}
          handleSubmitDrawer={handleSubmitDrawer}
          handleCloseDrawer={handleCloseDrawer}
        />
      </Box>
    </Box>
  );
}

export default RecycleOrdersMain;
