import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";

function CustomListView({
  data,
  formInput,
  getValueFormNestedObject,
  buttons,
}) {
  return (
    <>
      {data && (
        <Box>
          <Box>
            {typeof formInput === "function"
              ? formInput(data)?.map((ele) => {
                  return (
                    <Box sx={{ display: "flex", gap: "0.25rem" }}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {ele.label}
                      </Typography>
                      <Typography>:</Typography>
                      <Typography>
                        {typeof ele.value === "function"
                          ? ele.value(data)
                          : getValueFormNestedObject(ele.value, data)}
                      </Typography>
                    </Box>
                  );
                })
              : formInput?.map((ele) => {
                  return (
                    <Box sx={{ display: "flex", gap: "0.25rem" }}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {ele.label}
                      </Typography>
                      <Typography>:</Typography>
                      <Typography>
                        {typeof ele.value === "function"
                          ? ele.value(data)
                          : getValueFormNestedObject(ele.value, data)}
                      </Typography>
                    </Box>
                  );
                })}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "flex-end",
            }}
          >
            {typeof buttons === "function"
              ? buttons(data)?.map((button) => {
                  return button?.func !== undefined
                    ? button.func(data)
                    : button !== undefined && (
                        <CustomButton
                          label={button.label}
                          color={button.color ? button.color : "blue"}
                          handleClick={() => {
                            button.handleClick(data);
                          }}
                        />
                      );
                })
              : buttons?.map((button) => {
                  return button?.func !== undefined
                    ? button.func(data)
                    : button !== undefined && (
                        <CustomButton
                          label={button.label}
                          color={button.color ? button.color : "blue"}
                          handleClick={() => {
                            button.handleClick(data);
                          }}
                        />
                      );
                })}
          </Box>
        </Box>
      )}
    </>
  );
}

export default CustomListView;
