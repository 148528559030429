import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Typography,
  TableContainer,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { appColors } from "../../AppMeta/appColors";
import AddUpdatePicodeAreaSku from "./AddUpdatePicodeSku";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPincodeAreas,
  RemoveLocality,
} from "../../Redux/Actions/pincodeAreasAction";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

const PincodesAndAres = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({});
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const productData = useSelector((state) => state.productData);

  const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [currentRow, setCurrentRow] = React.useState({});
  const [drawOpenProp, setDrawOpenProp] = React.useState(false);

  React.useEffect(() => {
    dispatch(GetFinalProductSKU({ ignore_app_loading_spinner: true }));
    dispatch(GetPincodeAreas({ ignore_app_loading_spinner: true }));
  }, []);

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
    setAllProducts(productData?.finalProduct);
  }, [productData]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [isDrawerOpen, setDrawerOpen] = React.useState(drawOpenProp);

  const [localityId, setLocalityId] = React.useState("");
  //   const [currentRow, setCurrentRow] = React.useState({});

  //   const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  React.useEffect(() => {
    console.log("currentRow");
    console.log(currentRow);
    // currentRow.products.map((data) => {
    //   console.log(data);
    // });
  }, [currentRow]);
  const handleToggle = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  const handleEditClick = (row) => {
    console.log("---row---", row);
    setCurrentRow(row);
    // setEditDialogOpen(true);
    setDrawerOpen(true);
  };
  const handleBookingTypeChange = (event) => {
    setCurrentRow({ ...currentRow, booking_type: Number(event.target.value) });
  };

  const handleClose = () => {
    setEditDialogOpen(false);
  };

  const handleProductChange = (event, index, fieldName) => {
    // Clone the currentRow to avoid direct state mutation
    console.log(event.target.value);
    let newRow = { ...currentRow };
    // Ensure the products array is also cloned to avoid nested state mutation
    let products = [...newRow.products];

    // Update the specific field of the product at the given index
    products[index] = { ...products[index], [fieldName]: event.target.value };

    // Update the newRow object with the modified products array
    newRow.products = products;

    // Update the state with the modified newRow object
    setCurrentRow(newRow);
  };

  const handlePincodeFilterClose = () => {};
  const handlePincodeSortClose = () => {};
  const handleAddPincode = () => {
    setDrawerOpen(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      const reqObj = {
        locality_ids: [localityId],
      };
      dispatch(RemoveLocality(reqObj));
      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <CustomTable
        tableHead={["", "Pincode", "Locality", "District", "State", "Country"]}
        tableDataKey={[
          "",
          "pincode",
          "locality",
          "district",
          "state_name",
          "country",
        ]}
        tableData={allPincodesAndAreas}
        tableRowClickFunction={(index, row) => handleEditClick(row)}
        pagination={true}
        filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
        handleFilterClose={handlePincodeFilterClose}
        sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose={handlePincodeSortClose}
        addButton={true}
        handleOnClickAddButton={handleAddPincode}
        // setPageNumber
        // setPageSize
      />
      {/* <TableContainer
        sx={{
          borderRadius: "10px",
        }}
        component={Paper}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell} />
              <TableCell sx={appStyle.general.table.headderRowCell}>
                S.no
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Pincode
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Locality
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                District
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                State
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Country
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {allPincodesAndAreas?.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleToggle(row.id)}
                    >
                      {open[row.id] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    sx={appStyle.general.table.bodyRowCell}
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.pincode}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.locality}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.district}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.state_name}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.country}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => handleEditClick(row)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setLocalityId(row.id);
                          setOpenConfirmationDialog(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                    sx={appStyle.general.table.bodyRowCell}
                  >
                    <Collapse in={open[row.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Products
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Product ID</TableCell>
                              <TableCell>Material</TableCell>
                              <TableCell>Capacity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.products.map((productRow) => (
                              <TableRow key={productRow.product_id}>
                                <TableCell component="th" scope="row">
                                  {productRow.product_id}
                                </TableCell>
                                <TableCell>{productRow.material}</TableCell>
                                <TableCell>{productRow.capacity}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <CustomDrawer open={isDrawerOpen} title={"Add pincodes and areas"}>
        <AddUpdatePicodeAreaSku
          openPincodeDialog={openPincodeDialog}
          setOpenPicodeDialog={setOpenPicodeDialog}
          updatePincodeData={currentRow}
          setupdatePincodeData={setCurrentRow}
          allPincodesAndAreas={allPincodesAndAreas}
          allProducts={allProducts}
          setDrawerOpen={setDrawerOpen}
        />
      </CustomDrawer>
      <CustomConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        description={"Are you sure that you want to delete?"}
        setIsConfirmed={setIsConfirmed}
      />
    </>
  );
};

export default PincodesAndAres;
