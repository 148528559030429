import appMeta from "../../AppMeta/appMeta";
import CustomAutoComplete from "../CustomComp/CustomAutoComplete";
import CustomCheckBox from "../CustomComp/CustomCheckBox";
import CustomFileUploadButton from "../CustomComp/CustomFileUploadButton";
import CustomInputTextFieldForForm from "../CustomComp/CustomInputTextFieldForForm";
import CustomRadio from "../CustomComp/CustomRadio";
import CustomSelect from "../CustomComp/CustomSelect";
import CustomSwitch from "../CustomComp/CustomSwitch";

const getNamesFromOneLevelNestedObject = (data) => {
  return data?.reduce((obj, section) => {
    if (section.data !== undefined) {
      section.data.forEach((field) => {
        field.component === undefined &&
          (obj[field.name] =
            field.defaultValue !== undefined ? String(field.defaultValue) : "");
      });
    } else {
      obj[section.name] = section?.defaultValue || "";
    }
    return obj;
  }, {});
};

const getErrorsFromOneLevelNestedObject = (data) => {
  return data?.reduce((obj, section) => {
    if (section.data !== undefined) {
      section.data.forEach((field) => {
        field.component === undefined && (obj[field.name] = false);
      });
    } else {
      obj[section.name] = false;
    }
    return obj;
  }, {});
};

const getIsRequiredFromOneLevelNestedObject = (data) => {
  return data?.reduce((obj, section) => {
    if (section.data !== undefined) {
      section.data.forEach((field) => {
        field.component === undefined &&
          (obj[field.name] =
            field.isRequired !== undefined ? field.isRequired : false);
      });
    } else {
      obj[section.name] = section.isRequired;
    }
    return obj;
  }, {});
};

const getInputFieldBasedOnType = (
  ele,
  formData,
  errors,
  handleInputChange,
  section
) => {
  // console.log("---section---", section);
  if (ele.inputType === appMeta.inputFieldTypes.INPUT) {
    return (
      <CustomInputTextFieldForForm
        key={ele.name}
        placeholder={ele.placeHolder}
        name={ele.name}
        value={formData[ele.name]}
        size="large"
        error={errors[ele.name]}
        onChange={handleInputChange}
        isRequired={ele.isRequired}
        type={ele.textFieldType}
        isHintRequired={ele.isHintRequired}
        helperText={errors[ele.name]}
        disabled={ele.disabled}
        section={section}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.SELECT) {
    return (
      <CustomSelect
        field={ele}
        key={ele.name}
        name={ele.name}
        placeHolder={ele.placeHolder}
        defaultValue={formData[ele.name]}
        input={ele.input}
        getNameAndValueOnChange={handleInputChange}
        options={ele.options || []} // Assuming options for the select
        error={!!errors[ele.name]}
        helperText={errors[ele.name]} // Show field-specific error message
        section={section}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.SWITCH) {
    return (
      <CustomSwitch
        checked={formData[ele.name]}
        name={ele.name}
        onChange={handleInputChange}
        label={ele.placeHolder}
        section={section}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.RADIO) {
    return (
      <CustomRadio
        label={ele.placeHolder}
        input={ele.input}
        section={section}
        onChange={handleInputChange}
        defaultValue={formData[ele.name]}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.FILE) {
    return (
      <CustomFileUploadButton
        handleInputChange={handleInputChange}
        field={ele}
        formData={formData}
        errors={errors}
        section={section}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.AUTO_COMPLETE) {
    return (
      <CustomAutoComplete
        field={ele}
        handleInputChange={handleInputChange}
        errors={errors}
        section={section}
      />
    );
  } else if (ele.inputType === appMeta.inputFieldTypes.CHECKBOX) {
    <CustomCheckBox />;
  } else return null;
};

const getInputForSelectFromArray = (array, fieldForMenu, fieldForValue) => {
  return array?.map((ele) => {
    let menuKeys = fieldForMenu?.split(".");
    const menuItem = menuKeys?.reduce((obj, key) => {
      if (
        typeof obj?.[key] === "object" &&
        obj?.[key] !== null &&
        !Array.isArray(obj?.[key])
      ) {
        return obj?.[key];
      }
      return obj?.[key];
    }, ele);
    const valueKeys = fieldForValue?.split(".");
    const value = valueKeys?.reduce((obj, key) => {
      if (
        typeof obj?.[key] === "object" &&
        obj?.[key] !== null &&
        !Array.isArray(obj?.[key])
      ) {
        return obj?.[key];
      }
      return obj?.[key];
    }, ele);
    return {
      menuItem: menuItem,
      value: value,
    };
  });
};

export {
  getNamesFromOneLevelNestedObject,
  getErrorsFromOneLevelNestedObject,
  getIsRequiredFromOneLevelNestedObject,
  getInputFieldBasedOnType,
  getInputForSelectFromArray,
};
