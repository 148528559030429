/*
 * @author : Janarthanan
 * @Date : 6/11/24
 * @description Reusable Custom Auto component.
 * @Params
 * @Demo
 * <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              searchAndUpdateAPI: (searchText) => {
                setRoleSearch(searchText);
              },
              input: rolesList,
              name: "role_1",
              label: "Enter Role",
            }}
              handleInputChange={(name, selectedOption), {}}
          />
 
 */

import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

function CustomAutoComplete({ field, handleInputChange, errors }) {
  const [selectedData, setSelectedData] = useState();
  const [searchText, setSearchText] = useState(field.defaultValue);

  //function
  const getValueFormNestedObject = (key, e) => {
    let keys = key?.split(".");
    return keys?.reduce((obj, key) => {
      if (
        typeof obj?.[key] === "object" &&
        obj?.[key] !== null &&
        !Array.isArray(obj?.[key])
      ) {
        return obj?.[key];
      }
      return obj?.[key];
    }, e);
  };
  useEffect(() => {
    console.log("---AutoComplete input---", field);
  }, [searchText]);
  return (
    <Box>
      <Autocomplete
        id="country-select-demo"
        options={field.input || []}
        autoHighlight
        getOptionLabel={(option) => {
          return getValueFormNestedObject(field.optionKey, option);
        }}
        onChange={(_, selectedOption) => {
          setSelectedData(
            getValueFormNestedObject(field.optionKey, selectedOption)
          );
          field.onChange !== undefined &&
            field.onChange(
              field.name,
              getValueFormNestedObject(field.outputKey, selectedOption)
            );
          handleInputChange !== undefined &&
            handleInputChange(
              field.name,
              getValueFormNestedObject(field.outputKey, selectedOption),
              selectedOption
            );
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          return (
            <Box
              key={key}
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              {`${getValueFormNestedObject(field.optionKey, option)}${
                field.optionKey2 !== undefined
                  ? "/" + getValueFormNestedObject(field.optionKey2, option)
                  : ""
              }`}
            </Box>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth={false}
              label={field.label}
              variant="standard"
              onChange={(e) => {
                setSearchText(e.target.value);
                field.searchAndUpdateAPI(e.target.value);
              }}
              error={errors !== undefined ? errors[field.name] : false}
              value={searchText}
              sx={{
                minWidth: "25ch",
              }}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                },
              }}
            />
          );
        }}
      />
    </Box>
  );
}

export default CustomAutoComplete;
