import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { BorderColor } from "@mui/icons-material";
import CustomButton from "../../Components/CustomComp/CustomButton";

function AllConsumerOrders() {
  const dispatch = useDispatch();
  const listOfOrdersList = useSelector(
    (state) => state.userManagementReducer.distributor_consumer_orders
  );
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  console.log("---listOfOrdersList----", listOfOrdersList);
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_consumer_orders,
        // params: reqObj,
        show_toast: false,
      })
    );
  }, []);
  return (
    <Box>
      {" "}
      <CustomTable
        tableData={listOfOrdersList}
        tableHead={[
          "Order ID",
          "Contact Name",
          "Contact Phone",
          "Distributor Name",
        ]}
        tableDataKey={[
          {
            rowClickEnable: true,
            component: (props) => {
              return (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: "blue",
                    }}
                  >
                    {props?.row?.order_id}
                  </Typography>
                </Box>
                // edit
              );
            },
          },
          // "role_details.role_name",
          {
            // rowClickEnable: true,
            component: (props) => {
              return (
                <Box
                  //   onClick={() => {
                  //     setOpenEditUserRoleDialog(true);
                  //     setSelectedRow(props.row);
                  //   }}
                  sx={{ cursor: "pointer", display: "flex", gap: "5px" }}
                >
                  <Typography variant="h6">
                    {props?.row?.contact_name}
                  </Typography>
                </Box>
              );
            },
          },
          "contact_phone",
          "distributor_name",
          //   {
          //     // rowClickEnable: true,
          //     component: (props) => {
          //       return (
          //         <Box>
          //           <CustomButton
          //             label={""}
          //             // handleClick={() => {
          //             //   setSelectedRow(props.row);
          //             //   setOpenDeleteConfirmationDialog(true);
          //             // }}
          //           />
          //         </Box>
          //       );
          //     },
          //   },
        ]}
        filter={true}
        filterSelectOption={[
          { label: "Roll Name", value: "role_name" },
          { label: "Full Name", value: "fullname" },
          {
            type: "select",
            value: "is_active",
            label: "Status",
            input: [
              { value: true, menuItem: "Active" },
              { value: false, menuItem: "In active" },
            ],
          },
        ]}
        // handleFilterClose={handleAddUserFilterClose}
        sort={true}
        sortSelectOption={[
          { label: "Created at", value: "created_at_sort" },
          { label: "Full name", value: "fullname_sort" },
          { label: "Role name", value: "role_name_sort" },
        ]}
        // handleSortClose={handleAddUserSortClose}
        addButton={true}
        // handleOnClickAddButton={handleButtonFun}
        // tableRowClickFunction={handleRowClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
    </Box>
  );
}

export default AllConsumerOrders;
