import { Call, Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  DialogContentText,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import DeliveriesEnterOtpDialog from "./DeliveriesEnterOtpDialog";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import userManagementApis from "../../Redux/Actions/userManagement";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function DistributorOrdersMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const listOfOrders = useSelector(
    (state) => state.userManagementReducer.distributor_consumer_orders
  );
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [orderData, setOrderData] = React.useState({});

  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_distributor_consumer_orders,
        // params: reqObj,
        show_toast: false,
      })
    );
  }, []);

  const handleOpenCompleteOrder = (props) => {
    console.log("----orderData-props----", props);
    setOrderData(props);
    setOpenEnterOtpDialog(true);
  };
  const handleSubmitOtp = () => {
    console.log("----orderData-----", orderData);
    dispatch(
      HandleApiActions({
        ...userManagementApis.put_verify_otp_order,
        params: {
          otp: enterOtpTextfied,
          order_id: orderData?.order_id,
        },
        show_toast: true,
      })
    );
    setTimeout(() => {
      dispatch(
        HandleApiActions({
          ...userManagementApis.get_distributor_consumer_orders,
          // params: reqObj,
          show_toast: false,
        })
      );
      setOpenEnterOtpDialog(false);
    }, 1500);
  };
  const handleCloseDialog = () => {
    setOpenEnterOtpDialog(false);
  };

  const handlePhone = (phone_number) => {
    console.log("--phone_number----", phone_number);
    const telUrl = `tel:${phone_number}`;
    window.location.href = telUrl;
  };
  const handleLocation = (props) => {
    console.log("----props-------", props);
    if (
      props.data?.latitude !== undefined &&
      props.data?.latitude !== null &&
      props.data?.longitude !== undefined &&
      props.data?.longitude !== null &&
      parseFloat(props.data?.latitude) > 0 &&
      parseFloat(props.data?.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props?.data?.latitude},${props?.data?.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handleopenSingleOrder = (data) => {
    const url = `/dashboard/single_order?order_id=${data?.order_id}`;
    history.push(url);
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}
    >
      {listOfOrders?.map((data) => {
        return (
          <Paper
            sx={{
              width: { xs: 300, md: 300 },
              m: "10px",
              borderRadius: "20px",
              "&:hover": {
                transform: "scale(1.04)", // Enlarge the card on hover
                // boxShadow: theme.shadows[10], // Change shadow on hover
                boxShadow: "5px 3px #074276",
              },
            }}
            onClick={() => handleopenSingleOrder(data)}
            elevation={10}
          >
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: "7px",
                bgcolor: "#074276;",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              // divider={<Divider orientation="vertical" flexItem />}
            >
              <Chip
                label={`Order ID : ${data.order_id}`}
                // onClick={handleViewDetails}
                // sx={{
                //   bgcolor:
                //     props.data.payment_type === 300
                //       ? "#6d147d"
                //       : props.data.packaged_drinking_water.order_type === 700
                //       ? "#EE4190"
                //       : props.data.packaged_drinking_water.is_subscribed_order ===
                //         true
                //       ? "#3BC2C0"
                //       : "#924EEF",
                //   color: "white",
                // }}
                sx={{ bgcolor: "white", fontWeight: 800, fontSize: "14px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <Call
                  onClick={() => handlePhone(data?.contact_phone)}
                  sx={{
                    fontSize: "25px",
                    bgcolor: "white",
                    borderRadius: "5px",
                  }}
                />
                <Place
                  onClick={() => handleLocation(data)}
                  color="primary"
                  sx={{
                    fontSize: "25px",
                    marginLeft: "15px",
                    color: "red",
                    bgcolor: "white",
                    borderRadius: "5px",
                    "&:hover": { cursor: "pointer" },
                  }}
                />
              </Box>
            </Stack>
            <Box sx={{ p: "0px 10px 0px 10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // padding: "5px 5px 0px 5px",
                  borderBottom: "2px dashed gray",
                }}
              >
                <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                  Contact Name :
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {data.contact_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // padding: "5px 5px 0px 5px",
                  borderBottom: "2px dashed gray",
                }}
              >
                <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                  Contact Number :
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {data.contact_phone}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // padding: "5px 5px 0px 5px",
                  borderBottom: "2px dashed gray",
                }}
              >
                <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                  status :
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {parseInt(data.order_status) === 200
                    ? "NOT STARTED"
                    : parseInt(data.order_status) === 201
                    ? "STARTED"
                    : parseInt(data.order_status) === 400
                    ? "CANCELLED"
                    : "COMPLETED"}
                </Typography>
              </Box>
              <Box sx={{ borderBottom: "2px dashed gray" }}>
                <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                  Contact Address :
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.address_line1}
                  </Typography>
                  ,
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.address_line2}
                  </Typography>
                  ,
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.address_line3}
                  </Typography>
                  ,
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.city}
                  </Typography>
                  ,
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.country}
                  </Typography>{" "}
                  -
                  <Typography
                    sx={{ fontWeight: 600, display: "flex", flexWrap: "wrap" }}
                  >
                    {data.pincode}
                  </Typography>
                </Box>
              </Box>
              {/* <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 800, fontSize: "15px" }}>
                    Products
                  </Typography>
                  <Typography sx={{ fontWeight: 800, fontSize: "15px" }}>
                    Quantity
                  </Typography>
                </Box>
                {data?.products?.map((data) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <Typography sx={{ fontWeight: 600 }}>
                        {data.capacity + "-" + data.material}
                      </Typography>
                      <Typography sx={{ fontWeight: 600, mr: "20px" }}>
                        {data.quantity}
                      </Typography>
                    </Box>
                  );
                })}
              </Box> */}
            </Box>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                label="Enter Otp"
                handleClick={() => handleOpenCompleteOrder(data)}
              />
            </Box> */}
          </Paper>
        );
      })}

      <DeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleSubmitOtp}
        handleClose={handleCloseDialog}
      />
    </Box>
  );
}

export default DistributorOrdersMain;
