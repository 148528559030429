import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField.jsx";
import appMeta from "../../../AppMeta/appMeta.js";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadFile,
  getFiles,
} from "../../../Redux/Actions/fileHandlingActions";
import {
  CreateManufacturingOrders,
  GetManufacuringOrders,
} from "../../../Redux/Actions/manufacturingOrdersAction";
import { toast } from "react-toastify";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AllMftOrders from "./AllMftOrders";
import AddProducts from "../../Products/AddProductsList";
import Slide from "@mui/material/Slide";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog.jsx";
import appStyle from "../../../AppMeta/appStyle";
import DisplayTitle from "../../../Components/CustomComp/DisplayTitle.jsx";
import CustomSelect from "../../../Components/CustomComp/CustomSelect.jsx";
import appData from "../../../AppMeta/appData.js";

const steps = [
  "Enter manufacturer details",
  "Number of cans required",
  "Choose payment method",
  // "Order details",
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function BookingToManufacturer() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  // mft details variable
  const dispatch = useDispatch();
  const filesData = useSelector((state) => state.filesData);
  const productDetails = useSelector((state) => state.productData);
  const mftOrdersDetails = useSelector((state) => state.mftOrdersData);
  const [renderFlag, setRenderFlag] = React.useState(true);
  const [productList, setProductList] = React.useState([]);
  const [mftName, setMftName] = React.useState("");

  const [mftEmail, setMftEmail] = React.useState("");
  const [isMftEmailValid, setIsMftEmailValid] = React.useState(true);

  const [mftPhone, setMftPhone] = React.useState("");
  const [isMftPhoneValid, setIsMftPhoneValid] = React.useState(true);

  const [mftAreaName, setMftAreaName] = React.useState("");

  const [mftPinode, setMftPincode] = React.useState("");
  const [isMftPincodeValid, setIsMftPincodeValid] = React.useState(true);

  const [poFile, setPoFile] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");

  const [noOfItems, setNoOfItems] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);

  const [uploadedFileName, setUploadedFileName] = React.useState("");

  const [value, setValue] = React.useState(0);

  // Pagination and filter variables

  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [lastPageNumber, setLastPageNumber] = React.useState(0);
  const [orderIdSearch, setOrderIdSearch] = React.useState("");
  const [mftNameSearch, setMftNameSearch] = React.useState("");

  const [placeOrderFlag, setPlaceOrderFlag] = React.useState(false);

  const [openAddProductDialog, setOpenAddProductDialog] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [confirmationDescription, setConfirmationDescription] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  React.useEffect(() => {
    if (isConfirmed) {
      console.log("Hit add product API");
    }
  }, [isConfirmed]);
  React.useEffect(() => {
    // setPageNumber(0);
    dispatch(GetManufacuringOrders(pageNumber + 1, pageSize));
  }, [pageNumber, pageSize]);
  // React.useEffect(() => {
  //   dispatch(GetManufacuringOrders(pageNumber + 1, pageSize));
  // }, [pageSize]);
  // const handleChange = (event, newValue) => {
  //   console.log(newValue);
  //   setValue(newValue);
  // };
  const handleChangeProduct = (event) => {
    console.log(event.target.value);
    setProduct(event.target.value);
  };

  // React.useEffect(() => {
  //   console.log(renderFlag);
  //   if (renderFlag === true) {
  //     dispatch(GetManufacuringOrders());
  //     setRenderFlag(false);
  //   }
  // }, [renderFlag]);

  React.useEffect(() => {}, []);
  React.useEffect(() => {
    console.log("Files Data");
    console.log(filesData);
    if (filesData.error === false) {
      setUploadedFileName(filesData.fileName);
    }
  }, [filesData]);

  const clearData = () => {
    setActiveStep(0);
    setMftAreaName("");
    setMftName("");
    setMftEmail("");
    setMftPhone("");
    setMftPincode("");
    setNoOfItems(0);
    setProduct("");
    setProductPrice("");
    setTotalPrice(0);
    setUploadedFileName("");
  };

  React.useEffect(() => {
    console.log("mftOrdersDetails");
    console.log(mftOrdersDetails);
    if (mftOrdersDetails.error === false) {
      setLastPageNumber(mftOrdersDetails.mftOrders.last_page_number);
      if (mftOrdersDetails?.mftOrderId?.length) {
        // setPlaceOrderFlag(false);
        console.log("mftOrderId");
        console.log(mftOrdersDetails.mftOrderId);
        setValue(0);
        clearData();
      }
    }
  }, [mftOrdersDetails]);

  React.useEffect(() => {
    console.log(`activeStep ${activeStep}`);
  }, [activeStep]);

  // On changing the product accordingly the price will set
  React.useEffect(() => {
    console.log(`product ${product}`);
    console.log(productDetails);
    if (productDetails.error === false) {
      productDetails?.products?.data?.map((productObj) => {
        if (productObj.product_id === product) {
          console.log(productObj.product_id);
          console.log(product);
          setProductPrice(productObj.price);
        }
      });
    }
  }, [product]);

  // Checking weather the product price is setting
  // Checking
  React.useEffect(() => {
    console.log(`productPrice ${productPrice}`);
  }, [productPrice]);

  const poInputRef = React.useRef(null);

  const handleUploadPO = () => {
    poInputRef.current.click();
  };

  // storing the product details in an array
  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      setProductList(productDetails?.products?.data);
    }
  }, [productDetails]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (activeStep === 0) {
      console.log("Zero");
      if (
        // mftName !== "" &&
        // mftEmail !== "" &&
        // mftPhone !== "" &&
        // mftAreaName !== "" &&
        // mftPinode !== ""
        true
      ) {
        console.log("Mft details are filled");
        setActiveStep(newActiveStep);
      } else {
        toast.error("Please fill required manufacturing details", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (activeStep === 1) {
      console.log("One");
      if (product === "") {
        toast.error("Please Select The Product", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (noOfItems === 0) {
        toast.error("Quantity Cannot Be Zero", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("Proceed to next step");
        setActiveStep(newActiveStep);
        // const reqObj = {
        //   mft_details: {
        //     phone: mftPhone,
        //     email: mftEmail,
        //     mft_name: mftName,
        //     pincode: mftPinode,
        //     area_name: mftAreaName,
        //   },
        //   po_file: uploadedFileName,
        //   quantity: noOfItems,
        //   product_id: product,
        //   price: totalPrice,
        // };
        // dispatch(CreateManufacturingOrders(reqObj));
      }
    } else if (activeStep === 2) {
      console.log("Two");
      if (uploadedFileName === "") {
        toast.error("Please Upload PO File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("Proceed to next step and hit create order API");
        setActiveStep(newActiveStep);
        const reqObj = {
          mft_details: {
            phone: mftPhone,
            email: mftEmail,
            mft_name: mftName,
            pincode: mftPinode,
            area_name: mftAreaName,
          },
          po_file: uploadedFileName,
          quantity: noOfItems,
          product_id: product,
          price: totalPrice,
        };
        console.log(reqObj);
        dispatch(CreateManufacturingOrders(reqObj));
        setTimeout(() => {
          dispatch(GetManufacuringOrders());
        }, 1000);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Order Id 1", 159, 6.0, 24, 4.0),
    createData("Order Id 2", 237, 9.0, 37, 4.3),
    createData("Order Id 3", 262, 16.0, 24, 6.0),
    createData("Order Id 4", 305, 3.7, 67, 4.3),
    createData("Order Id 5", 356, 16.0, 49, 3.9),
  ];

  const [uploadPoBtn, setUploadPoBtn] = React.useState(false);
  const handleChangePOUpload = (event) => {
    console.log("Selected PO file:", event.target.files[0]);
    setPoFile(event.target.files[0]);
    setUploadPoBtn(true);
  };

  React.useEffect(() => {
    console.log("Upload PO button useEffect");
    if (uploadPoBtn === true) {
      console.log("Hiiting Upload API");
      dispatch(UploadFile(poFile));
      setUploadPoBtn(false);
    }
  }, [uploadPoBtn]);

  const handlePlaceOrder = () => {
    console.log("Place order");
    const reqObj = {
      mft_details: {
        phone: mftPhone,
        email: mftEmail,
        mft_name: mftName,
        pincode: mftPinode,
        area_name: mftAreaName,
      },
      po_file: "sdfs.pdf",
      quantity: 1,
      product_id: 7,
      price: 10,
    };
    dispatch(CreateManufacturingOrders(reqObj));
  };

  //

  // const handleChangeNoOfItems = (event) => {
  //   setNoOfItems(event.target.value);
  // };

  const handleIncItems = () => {
    setNoOfItems(noOfItems + 1);
  };

  const handleDecrItems = () => {
    if (noOfItems > 0) {
      setNoOfItems(noOfItems - 1);
    }
  };

  React.useEffect(() => {
    console.log(`noOfItems ${noOfItems}`);
    setTotalPrice(noOfItems * productPrice);
  }, [noOfItems, productPrice]);

  const handleProductPriceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number or empty
    if (
      inputValue === "" ||
      (!isNaN(inputValue) && parseFloat(inputValue) >= 0)
    ) {
      setProductPrice(inputValue);
    }
  };

  const handleChangeNoOfItems = (event) => {
    const inputValue = event.target.value;
    // Check if the input is a valid number or empty
    if (
      inputValue === "" ||
      (!isNaN(inputValue) && parseFloat(inputValue) >= 0)
    ) {
      setNoOfItems(inputValue);
    }
  };

  const handleDelete = (chipToDelete) => {
    console.log(chipToDelete);
  };
  const [selectedManufacturer, setSelectedManufacturer] =
    React.useState("Manufacturer 1");
  const [manufacturerData, setManufacturerData] = React.useState([
    // Initial data
    {
      name: "Manufacturer 1",
      email: "manufacturer1@example.com",
      phone: "123-456-7890",
      area: "Area 1",
      pincode: "12345",
    },
    {
      name: "Manufacturer 2",
      email: "manufacturer2@example.com",
      phone: "987-654-3210",
      area: "Area 2",
      pincode: "67890",
    },
  ]);

  const handleAddProductDialog = () => {
    setOpenAddProductDialog(true);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
    setConfirmationDescription("Are you sure you want to add new product");
  };
  return (
    <Box sx={{ width: "100%", padding: "10px", marginTop: "10px" }}>
      {/* <DisplayTitle>Book Water Order Management</DisplayTitle> */}
      <Box sx={appStyle.general.tab.firstLevel}>
        {/* <CustomSelect
          name={"Bookwater Order Management"}
          input={appData.bookwaterOrderSelect}
          handleChange={handleChange}
          defaultValue={value}
        /> */}
        {/* <Toolbar>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            textColor="secondary"
            indicatorColor="secondary"
            sx={appStyle.general.tab.firstLevel}
          >
            <Tab
              label="Existing Product Orders"
              sx={appStyle.general.tab.secondLevel}
            />
            <Tab
              label="New Product Order"
              sx={appStyle.general.tab.secondLevel}
            />
          </Tabs>
        </Toolbar> */}
      </Box>
      {value === 1 && (
        <>
          <Box sx={{ padding: "10px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Booking new empty cans to Manufacturer1
            </Typography>
          </Box>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">
                  {/* <StepButton
              color="inherit"   
              onClick={() => {  
                console.log(index);
                if (index === 3) { 
                  handleStep(index); 
                }
              }} 
            > */}
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <>
                    {/* <Paper
                      elevation={23}
                      sx={{
                        padding: "10px",
                        borderRadius: "50px",
                        padding: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                      >
                        <CustomInputTextField
                          placeholder="Manufacturer name"
                          isRequired={true}
                          value={mftName}
                          setValue={setMftName}
                        />
                        <CustomInputTextField
                          placeholder="Manufacturer Email"
                          value={mftEmail}
                          setValue={setMftEmail}
                          isRequired={true}
                          isValid={isMftEmailValid}
                          setIsValid={setIsMftEmailValid}
                          type={appMeta.textFieldTypes.EMAIL}
                          isHintRequired={true}
                        />
                        <CustomInputTextField
                          placeholder="Manufacturer Phone"
                          value={mftPhone}
                          setValue={setMftPhone}
                          isValid={isMftPhoneValid}
                          setIsValid={setIsMftPhoneValid}
                          type={appMeta.textFieldTypes.PHONE}
                          isRequired={true}
                          isHintRequired={true}
                        />
                        <CustomInputTextField
                          placeholder="Manufacturer Area"
                          value={mftAreaName}
                          setValue={setMftAreaName}
                          isRequired={true}
                        />
                        <CustomInputTextField
                          placeholder="Manufacturer Pincode"
                          isRequired={true}
                          value={mftPinode}
                          setValue={setMftPincode}
                          isValid={isMftPincodeValid}
                          setIsValid={setIsMftPincodeValid}
                          isHintRequired={true}
                          type={appMeta.textFieldTypes.PINCODE}
                        />
                      </Box>
                    </Paper> */}
                    <Box sx={{ margin: "10px" }}>
                      <Select
                        value={selectedManufacturer}
                        onChange={(e) =>
                          setSelectedManufacturer(e.target.value)
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Select Manufacturer" }}
                      >
                        <MenuItem value="" disabled>
                          Select Manufacturer
                        </MenuItem>
                        {manufacturerData.map((manufacturer, index) => (
                          <MenuItem key={index} value={manufacturer.name}>
                            {manufacturer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <Paper
                      elevation={23}
                      sx={{
                        borderRadius: "50px",
                        padding: "10px",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ padding: "10px" }}>
                        <Button
                          variant="contained"
                          onClick={handleAddProductDialog}
                        >
                          Add Products
                        </Button>
                      </Box>
                      <Box sx={{ minWidth: 150, marginTop: "10px" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select product
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={product}
                            label="Select Product"
                            onChange={handleChangeProduct}
                          >
                            {productList?.map((product) => {
                              return (
                                <MenuItem value={product.product_id}>
                                  {product.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      {product !== "" && (
                        <Box>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            Required Quantity
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "20px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{ borderRadius: "100px" }}
                              onClick={handleDecrItems}
                            >
                              -
                            </Button>
                            <TextField
                              value={noOfItems}
                              label="Quantity"
                              variant="filled"
                              onChange={handleChangeNoOfItems}
                              disabled={true}
                            />
                            <Button
                              variant="outlined"
                              sx={{ borderRadius: "100px" }}
                              onClick={handleIncItems}
                            >
                              +
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "10px",
                              gap: "10px",
                            }}
                          >
                            <TextField
                              value={productPrice}
                              label="Product price"
                              variant="filled"
                              onChange={handleProductPriceChange}
                            />
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Total Price : {totalPrice}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Paper>
                  </>
                )}

                {activeStep === 2 && (
                  <>
                    <Paper
                      elevation={23}
                      sx={{ padding: "10px", borderRadius: "50px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                          Upload Purchase Order
                        </Typography>
                        <Tooltip title="Upload Purchase Order" position="top">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleUploadPO}
                            edge="end"
                          >
                            <UploadRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Hidden input element to handle file selection */}
                        <input
                          type="file"
                          ref={poInputRef}
                          style={{ display: "none" }}
                          onChange={handleChangePOUpload}
                        />
                        {uploadedFileName !== "" && (
                          <Box>
                            <Chip
                              label={`Upload Success (${uploadedFileName})`}
                              variant="outlined"
                              onClick={() => setUploadedFileName("")}
                              icon={<CloseRoundedIcon />}
                              color="success"
                            />
                          </Box>
                        )}

                        {/* <Button onClick={() => dispatch(getFiles("JSZkRqPfm.png"))}>
                      Download PO
                    </Button> */}
                        {/* <Button variant="contained" onClick={handlePlaceOrder}>
                      Place order
                    </Button> */}
                        {/* </FormControl> */}
                      </Box>
                    </Paper>
                  </>
                )}

                {activeStep === 3 && (
                  <Box sx={{ padding: "10px", marginTop: "10px" }}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Order Id</TableCell>
                            <TableCell align="right">Number of cans</TableCell>
                            <TableCell align="right">Ordered date</TableCell>
                            <TableCell align="right">Delivered date</TableCell>
                            <TableCell align="right">Total amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">
                                {row.calories}
                              </TableCell>
                              <TableCell align="right">{row.fat}</TableCell>
                              <TableCell align="right">{row.carbs}</TableCell>
                              <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext} sx={{ mr: 1 }}>
                    {activeStep === 2 ? "Place Order" : "Next"}
                  </Button>
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography
                        variant="caption"
                        sx={{ display: "inline-block" }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <></>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </div>
        </>
      )}

      {value === 0 && (
        <>
          <Box sx={{ padding: "10px" }}>
            <AllMftOrders
              value={value}
              setValue={setValue}
              setMftName={setMftName}
              setMftEmail={setMftEmail}
              setMftPhone={setMftPhone}
              setMftAreaName={setMftAreaName}
              setMftPincode={setMftPincode}
              setNoOfItems={setNoOfItems}
              setProduct={setProduct}
              setTotalPrice={setTotalPrice}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              lastPageNumber={lastPageNumber}
              setOrderIdSearch={setOrderIdSearch}
            />
          </Box>
        </>
      )}

      {openAddProductDialog ? (
        <>
          <Dialog
            open={openAddProductDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenAddProductDialog(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <AddProducts isConfirmed={isConfirmed} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddProductDialog(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleOpenConfirmationDialog}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        ""
      )}

      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={confirmationDescription}
          setIsConfirmed={setIsConfirmed}
        />
      )}
    </Box>
  );
}
