import React from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

function GaugeChart({ value, fillColor, valueMax }) {
  const settings = {
    width: 200,
    height: 200,
    value: value,
  };
  return (
    <Gauge
      {...settings}
      cornerRadius="50%"
      valueMax={valueMax}
      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 40,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: fillColor,
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.text.disabled,
        },
      })}
    />
  );
}

export default GaugeChart;
