import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Checkbox,
  Button,
  useTheme,
  FormControl,
  TablePagination,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { GetManuFacturer } from "../../Redux/Actions/ManufacturersAction";

const FinalProductsList = ({
  manufacturersList,
  selectedProduct,
  activeStep,
  hitValidateApi,
  setSelectedData,
  selectedData,
  allProducts,
  setActiveStep,
  addCartClickTracker,
}) => {
  const productDetails = useSelector((state) => state.productComponentsData);
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );
  const dispatch = useDispatch();
  const manufacturerDetails = useSelector((state) => state.manufacturerData);

  const skuFinalProducts = useSelector((state) => state.productData);

  const [productId, setProductId] = React.useState("");
  React.useEffect(() => {
    console.log("skuFinalProducts in FinalProductsList");
    console.log(skuFinalProducts);
  }, [skuFinalProducts]);

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
  }, [purchaseProductDetails]);

  React.useEffect(() => {
    //   if (value == tabValues.manufacturersSuppliers) {
    dispatch(GetManuFacturer());
    //   }
  }, []);

  React.useEffect(() => {
    console.log("---manufacturerDetails---", manufacturerDetails);
  }, [manufacturerDetails]);
  const [areas, setAreas] = useState(["Area 1", "Area 2", "Area 3"]);
  const [manufacturers, setManufacturers] = useState([
    "Manufacturer 1",
    "Manufacturer 2",
    "Manufacturer 3",
  ]);

  const [componentsForProducts, setComponentsForProducts] = React.useState([]);

  // const [quantities, setQuantities] = useState(
  //   Array(componentsForProducts?.length).fill("0")
  // );
  const [quantities, setQuantities] = useState(
    Array(allProducts?.length).fill("0")
  );

  const [prices, setPrices] = useState(Array(allProducts?.length).fill("0"));

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);

  const [selectedProducts, setSelectedProducts] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  React.useEffect(() => {
    console.log("productDetails");
    console.log(productDetails);
    // console.log(productDetails?.componentsForProduct?.data?.components);

    if (productDetails.error === false && selectedProduct !== "") {
      console.log(productDetails.componentsForProduct.data);

      // setComponentsForProducts(productDetails.componentsForProduct.data);
      const componentsArray =
        productDetails?.componentsForProduct?.data?.reduce(
          (componentsArray, product) => {
            if (product.components && Array.isArray(product.components)) {
              componentsArray.push(...product.components);
            }
            return componentsArray;
          },
          []
        );
      setComponentsForProducts(componentsArray);
    }
  }, [productDetails]);

  React.useEffect(() => {
    allProducts.map((item) => {
      console.log("Item");
      console.log(item);
    });
  }, []);

  React.useState(() => {
    console.log("SelectedProducts");
    console.log(selectedProducts);
  }, [selectedProducts]);

  React.useEffect(() => {
    console.log("allProducts");
    console.log(allProducts);
  }, [allProducts]);

  React.useEffect(() => {
    console.log("Manufacture list");

    console.log(manufacturersList);
  }, []);

  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(index)) {
      newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
    } else {
      newSelectedRows.push(index);
    }
    setSelectedRows(newSelectedRows);
  };

  React.useEffect(() => {
    skuFinalProducts.finalProduct.map((row) => {
      console.log("row");
      console.log(row);
      setProductId(row.product_details.id);
    });
  }, [productId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAreaChange = (event, index) => {
    const updatedData = [...componentsForProducts];
    updatedData[index].area = event.target.value;
    setAreas([...new Set([...areas, event.target.value])]);
  };

  const handleManufacturerChange = (event, index) => {
    const updatedData = [...skuFinalProducts.finalProduct];
    console.log(updatedData);
    updatedData[index].manufacturer = event.target.value;
    setManufacturers([...new Set([...manufacturers, event.target.value])]);
  };

  const handleQuantityChange = (event, index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = event.target.value;
    setQuantities(updatedQuantities);
  };

  const handlePriceChange = (event, index) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = event.target.value;
    setPrices(updatedPrices);
  };

  const handleQuantityIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = String(
      parseInt(updatedQuantities[index], 10) + 1
    );
    setQuantities(updatedQuantities);
  };

  const handleQuantityDecrement = (index) => {
    const updatedQuantities = [...quantities];
    if (parseInt(updatedQuantities[index], 10) > 0) {
      updatedQuantities[index] = String(
        parseInt(updatedQuantities[index], 10) - 1
      );
      setQuantities(updatedQuantities);
    }
  };

  React.useEffect(() => {
    console.log("hitValidateApi");
    console.log(hitValidateApi);
  }, [hitValidateApi]);

  const handleAddToCart = () => {
    setActiveStep(1);
    console.log("---selectedRows---", selectedRows);
    const selectedDataItems = selectedRows.map((index) => {
      return {
        id: skuFinalProducts.finalProduct[index].product_details.id,
        mft_id: skuFinalProducts.finalProduct[index].manufacturer,
        quantity: quantities[index],
        price: prices[index],
      };
    });
    console.log("selectedDataItems");
    console.log(selectedDataItems);
    // const groupedData = groupByProduct(selectedDataItems);
    const groupedData = groupByManufacturerId(selectedDataItems);
    // Do something with the grouped data, like adding it to the cart
    console.log(groupedData);
    setSelectedData(groupedData);
  };

  function groupByManufacturerId(inputArray) {
    const groupedByMftId = inputArray.reduce((accumulator, current) => {
      const mftId = current.mft_id;

      if (!accumulator[mftId]) {
        accumulator[mftId] = {
          mft_id: parseInt(mftId, 10),
          product_details: [],
        };
      }

      accumulator[mftId].product_details.push({
        id: parseInt(current.id, 10),
        quantity: parseInt(current.quantity, 10),
        price: parseFloat(current.price),
      });

      return accumulator;
    }, {});

    return Object.values(groupedByMftId);
  }

  React.useEffect(() => {
    if (selectedRows.length > 0) {
      handleAddToCart();
    }
  }, [addCartClickTracker]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <CustomTable
        tableHead={[
          "Select",
          "Final Product Name",
          "Required Quantity",
          "Price/Quantity",
          "Manufacturer",
        ]}
        tableDataKey={[
          {
            component: ({ row, index }) => {
              return (
                <Checkbox
                  checked={selectedRows.includes(index)}
                  onChange={() => handleRowSelect(index)}
                />
              );
            },
          },
          "product_details.name",
          {
            component: ({ row, index }) => {
              return (
                <TextField
                  size="small"
                  type="number"
                  value={quantities[index]}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(event) => handleQuantityChange(event, index)}
                />
              );
            },
          },
          {
            component: ({ row, index }) => {
              return (
                <TextField
                  type="number"
                  size="small"
                  value={prices[index]}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(event) => handlePriceChange(event, index)}
                />
              );
            },
          },
          {
            component: ({ row, index }) => {
              return (
                <FormControl fullWidth variant="filled">
                  <Select
                    value={row.manufacturer}
                    onChange={(event) => handleManufacturerChange(event, index)}
                  >
                    {manufacturersList?.map((manufacturer) => (
                      <MenuItem key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.details.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            },
          },
        ]}
        tableData={skuFinalProducts?.finalProduct}
      />
      {/* <TableContainer sx={appStyle.general.table.tableContainer}>
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Select
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Final Product Name
              </TableCell>

              <TableCell sx={appStyle.general.table.headderRowCell}>
                Required Quantity
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Price / Quantity
              </TableCell>

              <TableCell sx={appStyle.general.table.headderRowCell}>
                Manufacturer
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            <>
              <>
                {" "}
                {(rowsPerPage > 0
                  ? skuFinalProducts.finalProduct.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : skuFinalProducts.finalProduct
                ).map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <Checkbox
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelect(index)}
                        />
                      </TableCell>

                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {row.product_details.name}
                      </TableCell>

                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <TextField
                          size="small"
                          type="number"
                          value={quantities[index]}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                        />
                      </TableCell>

                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <TextField
                          type="number"
                          size="small"
                          value={prices[index]}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={(event) => handlePriceChange(event, index)}
                        />
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <FormControl fullWidth variant="filled">
                          <Select
                            value={row.manufacturer}
                            onChange={(event) =>
                              handleManufacturerChange(event, index)
                            }
                          >
                            {manufacturersList?.map((manufacturer) => (
                              <MenuItem
                                key={manufacturer.id}
                                value={manufacturer.id}
                              >
                                {manufacturer.details.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </>
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={appStyle.general.table.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={skuFinalProducts.finalProduct.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box> */}
      {/* <Box
        sx={{
          padding: "10px",
          position: "fixed",
          right: "1rem",
          bottom: "4rem",
          zIndex: 2000,
          ...appStyle.general.button.addButtonBox,
        }}
      >
        <Button
          startIcon={<ShoppingCartIcon />}
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleAddToCart}
          sx={appStyle.general.button.blueButton}
        >
          Add to Cart
        </Button>
      </Box> */}
      {/* <Box mt={2}>
        <Typography variant="h6">Selected Data</Typography>
        <pre>{JSON.stringify(selectedData, null, 2)}</pre>
      </Box> */}
    </Box>
  );
};

export default FinalProductsList;
