import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { useDispatch } from "react-redux";
import AllConsumerOrders from "./AllConsumerOrders";
import appStyle from "../../AppMeta/appStyle";

function AdminOrdersMain() {
  const dispatch = useDispatch();

  const [ordersTabvalue, setOrdersTabvalue] = React.useState("REGULAR");

  const handleChangeOrdersTab = (event, newValue) => {
    setOrdersTabvalue(newValue);
  };
  return (
    <Box>
      {" "}
      <Tabs
        variant="scrollable"
        allowScrollButtonsMobile
        value={ordersTabvalue}
        onChange={handleChangeOrdersTab}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          sx={appStyle.general.tab.firstLevel}
          value="REGULAR"
          label="Regular"
        />
        <Tab
          sx={appStyle.general.tab.firstLevel}
          value="EMPTYCANORDERS"
          label="WFS ORDERS"
        />
      </Tabs>
      {ordersTabvalue === "REGULAR" && (
        <Box>
          <AllConsumerOrders />
        </Box>
      )}
    </Box>
  );
}

export default AdminOrdersMain;
