import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Drawer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Container,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { appColors } from "../../AppMeta/appColors";
import SearchIcon from "@mui/icons-material/Search";
import {
  AddManuFacturerAction,
  GetManuFacturer,
  UpdateManufacturer,
} from "../../Redux/Actions/ManufacturersAction";
import { useDispatch } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { tab } from "@testing-library/user-event/dist/tab";
import FactoryIcon from "@mui/icons-material/Factory";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";

import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import { tabValues } from "../../AppMeta/appData";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

const ManufacturerTable = ({
  manufacturersList,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setAvailable,
  setCreatedAtSort,
  tabProps = tabValues.manufacturersSuppliers,
  drawOpenProp,
  setDrawOpenProp,
  view,
  setView,
}) => {
  console.log("manufacturersList", manufacturersList);

  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  // const [selectedManufacturer, setSelectedManufacturer] = useState(null);

  // State variables for Add Manufacturer Drawer

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    console.log("selectedManufacturer");
    console.log(selectedManufacturer);
  }, [selectedManufacturer]);

  const [name, setName] = useState("");
  const [doorNumber, setDoorNumber] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [gstIN, setGSTIN] = useState("");
  const [pan, setPAN] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [cgst, setCGST] = useState("");
  const [sgst, setSGST] = useState("");
  const [igst, setIGST] = useState("");
  const [hsn, setHSN] = useState("");

  const colors = tokens(theme.palette.mode);

  const [newManufacturer, setNewManufacturer] = useState({
    name: "",
    email: "",
    phone: "",
    area: "",
    pincode: "",
    buildingName: "",
    doorNumber: "",
  });
  const [openMoreDialog, setOpenMoreDialog] = useState(false);
  const [openAddManufacturerDrawer, setOpenAddManufacturerDrawer] =
    useState(false);

  const handleAddManufacturerDrawerClose = () => {
    setOpenAddManufacturerDrawer(false);
  };

  const handleAddManufacturer = (formData) => {
    const reqObj = {
      mft_type: tabProps === tabValues.manufacturersSuppliers ? 100 : 200,
      name: formData.name,
      address: {
        door_number: formData.doorNumber,
        building_name: formData.buildingName,
        area_name: formData.areaName,
        pincode: formData.pincode,
        state: formData.state,
        city: formData.city,
        district: formData.district,
      },
      tax_details: {
        GSTIN: formData.GSTIN,
        PAN: formData.pan,
        CGST: formData.CGST,
        SGST: formData.SGST,
        // IGST: formData.igst,
        HSN: formData.hsn,
      },
      user_details: {
        owner_name: formData.ownerName,
        owner_phone: formData.ownerPhone,
      },
      files: { GSTIN: "" },
    };

    console.log(reqObj);
    dispatch(AddManuFacturerAction(reqObj));
    setTimeout(() => {
      setOpenAddManufacturerDrawer(false);
      dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
  };

  const handleAddManufacturerClick = () => {
    setOpenAddManufacturerDrawer(true);
  };

  const handleMoreClick = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setOpenMoreDialog(true);
  };

  const handleMoreDialogClose = () => {
    setOpenMoreDialog(false);
    setSelectedManufacturer(null);
  };
  const handleEditClick = (manufacturer) => {
    setSelectedManufacturer("------Manufacturer------", manufacturer);
    setOpenDialogEdit(true);

    // Populate the state variables with the manufacturer details
    setName(manufacturer?.details?.name);
    setCity(manufacturer?.details?.address?.city);
    setDoorNumber(manufacturer?.details?.address?.door_number);
    setBuildingName(manufacturer.details?.address?.building_name);
    setAreaName(manufacturer?.details?.address?.area_name);
    setPincode(manufacturer?.details?.address?.pincode);
    setState(manufacturer?.details?.address?.state);
    setDistrict(manufacturer?.details?.address?.district);
    setGSTIN(manufacturer?.details?.tax_details?.GSTIN);
    setPAN(manufacturer?.details?.tax_details?.PAN);
    setOwnerName(manufacturer?.details?.user_details?.owner_name);
    setOwnerPhone(manufacturer?.details?.user_details?.owner_phone);
    setHSN(manufacturer?.details?.tax_details?.HSN);
  };

  const handleDialogEditClose = () => {
    setOpenDialogEdit(false);
    setSelectedManufacturer(null);
    // Reset state variables when closing the dialog
    setName("");
    setCity("");
    setDoorNumber("");
    setBuildingName("");
    setAreaName("");
    setPincode("");
    setState("");
    setDistrict("");
    setGSTIN("");
    setPAN("");
    setOwnerName("");
    setOwnerPhone("");
    setCGST("");
    setSGST("");
    setIGST("");
    setHSN("");
  };

  const handleSaveChanges = (formData) => {
    console.log("Saved Changes:");
    console.log({
      name,
      city,
      doorNumber,
      buildingName,
      areaName,
      pincode,
      state,
      district,
      gstIN,
      ownerName,
      ownerPhone,
    });
    const reqObj = {
      id: selectedManufacturer.id,
      name: formData.name,
      address: {
        city: formData.city,
        door_number: formData.doorNumber,
        building_name: formData.buildingName,
        area_name: formData.areaName,
        pincode: formData.pincode,
        state: formData.state,
        district: formData.district,
      },
      tax_details: {
        GSTIN: formData.GSTIN,
        PAN: formData.pan,
      },
      is_force_update: true,
      is_deleted: false,
    };
    dispatch(UpdateManufacturer(reqObj));
    setTimeout(() => {
      dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
    // Implement your logic to save changes here
    setOpenDialogEdit(false);
  };

  const handleDialogClose = () => {
    setOpenDialogAdd(false);
    setNewManufacturer({
      name: "",
      email: "",
      phone: "",
      area: "",
      pincode: "",
    });
  };
  const handleAddClick = () => {
    setOpenDialogAdd(true);
  };

  const handleRowClick = (manufacturer) => {
    console.log(manufacturer);
    setSelectedManufacturer(manufacturer);
  };

  const pageNumberForward = () => {
    if (manufacturersList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  const [manufacturerType, setManufacturerType] = React.useState(100);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeType = (event) => {
    setManufacturerType(event.target.value);
  };

  const handleAddManufacturerSubmit = (formData) => {
    console.log(formData);
  };

  const [tabValue, setTabValue] = React.useState(tabValues.purchaseProducts);
  // const handleChangeTab = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  React.useEffect(() => {
    setTabValue(tabProps);
  }, [tabProps]);

  React.useEffect(() => {
    setOpenAddManufacturerDrawer(drawOpenProp);
  }, [drawOpenProp]);

  return (
    <>
      <CustomDrawer
        open={openAddManufacturerDrawer}
        setOpen={setOpenAddManufacturerDrawer}
        title={
          tabProps === tabValues.manufacturersSuppliers
            ? "Add Manufacturers"
            : "Add Processing Partners"
        }
      >
        {/* <Drawer
        anchor="right"
        open={openAddManufacturerDrawer}
        onClose={handleAddManufacturerDrawerClose}
        PaperProps={{
          style: {
            width: isMobile ? "75%" : "500px",
            padding: "20px",
          },
        }}
      > */}

        <CustomForm
          data={[
            // {
            //   inputType: appMeta.inputFieldTypes.SELECT,
            //   placeHolder: "Manufacture Type",
            //   name: "manufactureType",
            //   input: [
            //     {
            //       menuItem: "Supplier/manufacturer",
            //       value: 100,
            //     },
            //     {
            //       menuItem: "Processing Partner",
            //       value: 200,
            //     },
            //   ],
            // },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Name",
              defaultValue: name,
              name: "name",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Door Number",
              defaultValue: doorNumber,
              name: "doorNumber",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Building Name",
              defaultValue: buildingName,
              name: "buildingName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Area Name",
              defaultValue: areaName,
              name: "areaName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Pincode",
              defaultValue: pincode,
              name: "pincode",
              textFieldType: appMeta.textFieldTypes.PINCODE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "State",
              defaultValue: state,
              name: "state",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "City",
              defaultValue: city,
              name: "city",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "District",
              defaultValue: district,
              name: "district",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "GSTIN",
              defaultValue: gstIN,
              name: "GSTIN",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "PAN",
              defaultValue: pan,
              name: "pan",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Owner Name",
              defaultValue: ownerName,
              name: "ownerName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Owner Phone",
              defaultValue: ownerPhone,
              name: "ownerPhone",
              textFieldType: appMeta.textFieldTypes.PHONE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "CGST(%)",
              defaultValue: cgst,
              name: "CGST",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "SGST(%)",
              defaultValue: sgst,
              name: "SGST",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "HSN",
              defaultValue: hsn,
              name: "hsn",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
          ]}
          handleSubmit={handleAddManufacturer}
          handleClose={() => {
            setOpenAddManufacturerDrawer(false);
            setDrawOpenProp(false);
          }}
        />
        {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Select Manufacturer type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={manufacturerType}
                onChange={handleChangeType}
              >
                <FormControlLabel
                  value={100}
                  control={<Radio />}
                  label="Supplier / Manufacturer"
                />
                <FormControlLabel
                  value={200}
                  control={<Radio />}
                  label="Processing Partner"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Door Number"
            fullWidth
            margin="normal"
            value={doorNumber}
            onChange={(e) => setDoorNumber(e.target.value)}
          />
          <TextField
            label="Building Name"
            fullWidth
            margin="normal"
            value={buildingName}
            onChange={(e) => setBuildingName(e.target.value)}
          />
          <TextField
            label="Area Name"
            fullWidth
            margin="normal"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
          />
          <TextField
            label="Pincode"
            fullWidth
            margin="normal"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
          <TextField
            label="State"
            fullWidth
            margin="normal"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <TextField
            label="City"
            fullWidth
            margin="normal"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            label="District"
            fullWidth
            margin="normal"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />
          <TextField
            label="GSTIN"
            fullWidth
            margin="normal"
            value={gstIN}
            onChange={(e) => setGSTIN(e.target.value)}
          />
          <TextField
            label="PAN"
            fullWidth
            margin="normal"
            value={pan}
            onChange={(e) => setPAN(e.target.value)}
          />
          <TextField
            label="Owner Name"
            fullWidth
            margin="normal"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
          <TextField
            label="Owner Phone"
            fullWidth
            margin="normal"
            value={ownerPhone}
            onChange={(e) => setOwnerPhone(e.target.value)}
          />
          <TextField
            label="CGST"
            fullWidth
            margin="normal"
            value={cgst}
            onChange={(e) => setCGST(e.target.value)}
          />
          <TextField
            label="SGST"
            fullWidth
            margin="normal"
            value={sgst}
            onChange={(e) => setSGST(e.target.value)}
          />
          <TextField
            label="HSN"
            fullWidth
            margin="normal"
            value={hsn}
            onChange={(e) => setHSN(e.target.value)}
          />
        
          <Button
            variant="contained"
            onClick={handleAddManufacturer}
            sx={appStyle.general.button.editButton}
          >
            Add
          </Button>*/}
      </CustomDrawer>

      <Box
        sx={{
          margin: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {/* Search bar components */}
        {/* <TextField
            label="Search by name"
            variant="outlined"
            margin="normal"
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon color="action" style={{ marginRight: "8px" }} />
              ),
            }}
          /> */}
        {/* <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberForward}
                // disabled={pageNumber < lastPageNumber ? false : true}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Page size</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={pageSize}
                  label="Page size"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                    // setSearchOrderFlag(true);
                  }}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
        {/* Add more search bar components if needed */}
      </Box>
      {/* <AppBar
        position="relative"
        sx={{
          backgroundColor:
            appStyle.general.colors(colors).secondarySkuTabBar.background,
          color: colors.primary[200],
          ...appStyle.sku.secondLevelTab.appBar,
        }}
      >
        <Toolbar>
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="SKU Management Tabs"
            sx={{
              color: colors.primary[400],
              indicatorColor:
                appStyle.general.colors(colors).secondarySkuTabBar
                  .indicatorColor,
            }}
          >
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Supplier / Manufacturers"
            />
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Processing partner"
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          variant="contained"
          color="secondary"
          sx={appStyle.general.button.addButton}
          startIcon={<FactoryIcon />}
          onClick={handleAddManufacturerClick}
        >
          Add Manufacturer
        </Button>
      </Box> */}

      <>
        {tabValue === tabValues.manufacturersSuppliers && (
          <>
            <SupplierManufacturer
              handleMoreClick={handleMoreClick}
              handleEditClick={handleEditClick}
              manufacturersList={manufacturersList}
              handleAddManufacturerClick={handleAddManufacturerClick}
              view={view}
              setView={setView}
            />
          </>
        )}

        {/* Table for processing partner */}

        {tabValue === tabValues.processingPartners && (
          <Box>
            {/* Processing paartner Table */}
            <CustomTable
              tableData={manufacturersList}
              tableHead={[
                "ID",
                "Name",
                // "Address",
                "Pincode",
                "State",
                "Details",
              ]}
              tableDataKey={[
                "id",
                "details.name",
                "details.address.pincode",
                "details.address.state",
                {
                  component: (row) => {
                    return (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleMoreClick(row)}
                        sx={appStyle.general.button.editButton}
                      >
                        More
                      </Button>
                    );
                  },
                },
              ]}
              tableRowClickFunction={(i, row) => {
                handleEditClick(row);
              }}
              pagination={true}
              // filter={true}
              filterSelectOption={[
                { value: "Roll Name", label: "role_name" },
                { value: "Full Name", label: "fullname" },
              ]}
              // handleFilterClose={}
              // sort={true}
              sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
              // handleSortClose={}
              // addButton={true}
              handleOnClickAddButton={handleAddManufacturerClick}
              setPageNumber
              setPageSize
            />
            {/* <TableContainer sx={appStyle.general.table.tableContainer}>
              <Table stickyHeader>
                <TableHead
                  sx={{
                    backgroundColor:
                      appStyle.general.colors(colors).table.tableHeadBackground,
                    color: colors.primary[300],
                    ...appStyle.general.table.tableHead,
                  }}
                >
                  <TableRow>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      ID
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Name
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Address
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Pincode
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      State
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Details
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: colors.primary[600] }}>
                  {manufacturersList?.map((manufacturer) => (
                    <TableRow
                      key={manufacturer.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer.id}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer.details.name}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {`${manufacturer?.details?.address?.door_number}, ${manufacturer?.details?.address?.building_name}, ${manufacturer?.details?.address?.area_name}, 
                  ${manufacturer?.details?.address?.district}`}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer?.details?.address?.pincode}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer?.details?.address?.state}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleMoreClick(manufacturer)}
                          sx={appStyle.general.button.editButton}
                        >
                          More
                        </Button>
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEditClick(manufacturer)}
                          sx={appStyle.general.button.editButton}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
            {/* <Box sx={appStyle.general.table.pagination}>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000]}
                component="div"
                count={dummyData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box> */}
          </Box>
        )}
      </>

      {/* {
    "id": 1,
    "name":"BKWTR",
    "address": {
        "city":"Chennai",
        "door_number":"2021A",
        "building_name":"Reheja Towers",
        "area_name": "hennai",
        "pincode": "600084",
        "state": "Tamil Nadu",
        "district": "Chennai"
    },
    "tax_details": {
        "GSTIN": "1234",
        "PAN":"FMIPM6114A"
    },
    "is_force_update":true,
    "is_deleted":false
} */}
      <CustomDrawer
        open={openDialogEdit}
        setOpen={setOpenDialogEdit}
        title={"Edit Manufacturer Details"}
      >
        {selectedManufacturer && (
          <CustomForm
            data={[
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Name",
                defaultValue: name,
                name: "name",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Door Number",
                defaultValue: doorNumber,
                name: "doorNumber",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Building Name",
                defaultValue: buildingName,
                name: "buildingName",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Area Name",
                defaultValue: areaName,
                name: "areaName",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Pincode",
                defaultValue: pincode,
                name: "pincode",
                textFieldType: appMeta.textFieldTypes.PINCODE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "State",
                defaultValue: state,
                name: "state",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "City",
                defaultValue: city,
                name: "city",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "District",
                defaultValue: district,
                name: "district",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "GSTIN",
                defaultValue: gstIN,
                name: "GSTIN",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "PAN",
                defaultValue: pan,
                name: "pan",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Owner Name",
                defaultValue: ownerName,
                name: "ownerName",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Owner Phone",
                defaultValue: ownerPhone,
                name: "ownerPhone",
                textFieldType: appMeta.textFieldTypes.PHONE,
                isHintRequired: true,
                isRequired: true,
              },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "CGST(%)",
              //   defaultValue: cgst,
              //   name: "CGST",
              //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "SGST(%)",
              //   defaultValue: sgst,
              //   name: "SGST",
              //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "HSN",
              //   defaultValue: hsn,
              //   name: "hsn",
              //   textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
            ]}
            handleSubmit={handleSaveChanges}
            handleClose={handleDialogEditClose}
          />
        )}
      </CustomDrawer>

      {/* <TextField
                label="Name"
                value={name}
                fullWidth
                margin="normal"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="City"
                value={city}
                fullWidth
                margin="normal"
                onChange={(e) => setCity(e.target.value)}
              />
              <TextField
                label="Door Number"
                value={doorNumber}
                fullWidth
                margin="normal"
                onChange={(e) => setDoorNumber(e.target.value)}
              />
              <TextField
                label="Building Name"
                value={buildingName}
                fullWidth
                margin="normal"
                onChange={(e) => setBuildingName(e.target.value)}
              />
              <TextField
                label="Area name"
                value={areaName}
                fullWidth
                margin="normal"
                onChange={(e) => setAreaName(e.target.value)}
              />
              <TextField
                label="Pincode"
                value={pincode}
                fullWidth
                margin="normal"
                onChange={(e) => setPincode(e.target.value)}
              />
              <TextField
                label="State"
                value={state}
                fullWidth
                margin="normal"
                onChange={(e) => setState(e.target.value)}
              />
              <TextField
                label="District"
                value={district}
                fullWidth
                margin="normal"
                onChange={(e) => setDistrict(e.target.value)}
              />
              <TextField
                label="GSTIN"
                value={gstIN}
                fullWidth
                margin="normal"
                onChange={(e) => setGSTIN(e.target.value)}
              />
              <TextField
                label="PAN"
                value={pan}
                fullWidth
                margin="normal"
                onChange={(e) => setPAN(e.target.value)}
              />
              <TextField
                label="Owner Name"
                value={ownerName}
                fullWidth
                margin="normal"
                onChange={(e) => setOwnerName(e.target.value)}
              />
              <TextField
                label="Owner Phone"
                value={ownerPhone}
                fullWidth
                margin="normal"
                onChange={(e) => setOwnerPhone(e.target.value)}
              /> */}

      {/* Add more text fields for other details */}

      {/* <DialogActions>
          <Button onClick={handleDialogEditClose}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions> */}

      {/* <Dialog open={openDialogAdd} onClose={handleDialogClose}>
        <DialogTitle>Add New Manufacturer</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Name"
              value={newManufacturer.name}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={newManufacturer.email}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              value={newManufacturer.phone}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Area"
              value={newManufacturer.area}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Pincode"
              value={newManufacturer.pincode}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openMoreDialog}
        onClose={handleMoreDialogClose}
        maxWidth="md"
      >
        <DialogTitle>More Details</DialogTitle>
        <DialogContent>
          {selectedManufacturer && (
            <Box>
              <Paper
                elevation={3}
                sx={{ padding: "16px", marginBottom: "16px" }}
              >
                <Typography variant="h6">Tax Details</Typography>
                <Typography>
                  GSTIN: {selectedManufacturer?.details?.tax_details?.GSTIN}
                </Typography>
                <Typography>
                  PAN: {selectedManufacturer?.details?.tax_details?.PAN}
                </Typography>
              </Paper>

              <Paper
                elevation={3}
                sx={{ padding: "16px", marginBottom: "16px" }}
              >
                <Typography variant="h6">User Details</Typography>
                <Typography>
                  Owner Name:{" "}
                  {selectedManufacturer?.details?.user_details?.owner_name}
                </Typography>
                <Typography>
                  Owner Phone:{" "}
                  {selectedManufacturer?.details?.user_details?.owner_phone}
                </Typography>
              </Paper>

              <Paper elevation={3} sx={{ padding: "16px" }}>
                <Typography variant="h6">Files</Typography>
                <Typography>
                  GST: {selectedManufacturer?.details?.files?.GST}
                </Typography>
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMoreDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManufacturerTable;

const SupplierManufacturer = ({
  manufacturersList,
  handleMoreClick,
  handleEditClick,
  handleAddManufacturerClick,
  view,
  setView,
}) => {
  console.log("---view---", view);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleManufacturerFilterClose = ({ value, searchText }) => {};
  const handleManufacturerSortClose = ({ value, searchText }) => {};

  return (
    <Box>
      {/* Manufacturer Table */}
      <CustomTable
        tableHead={["ID", "Name", "Pincode", "State", "Details"]}
        tableDataKey={[
          "id",
          "details.name",
          "details.address.pincode",
          "details.address.state",
          {
            type: "button",
            component: (row) => {
              return (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleMoreClick(row)}
                  sx={appStyle.general.button.editButton}
                  disabled
                >
                  More
                </Button>
              );
            },
          },
        ]}
        tableData={manufacturersList}
        tableRowClickFunction={(index, manufacturer) => {
          handleEditClick(manufacturer);
        }}
        pagination={true}
        // filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
        handleFilterClose={handleManufacturerFilterClose}
        // sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose={handleManufacturerSortClose}
        // addButton={true}
        handleOnClickAddButton={handleAddManufacturerClick}
        // setPageNumber={handleClickRow}
        // setPageSize={handleClickRow}
        tableView={view}
        cardTitle={{ label: "Name", key: "details.name" }}
        cardInput={(row) => [
          {
            data: [
              { label: "ID", value: row?.id },
              {
                label: "State",
                value: row?.details?.address?.state,
              },
            ],
          },
        ]}
        cardButton={(row) => [
          {
            label: "Edit",
            handleClick: (row) => {
              handleEditClick(row);
            },
          },
          {
            label: "More",

            handleClick: (row) => {
              handleMoreClick(row);
            },
          },
        ]}
        formSearchOptionKey="details.name"
        formSearchLabel="Enter Manufacturer"
        formInput={(row) => [
          { label: "ID", value: "id" },
          { label: "Name", value: "details.name" },
          { label: "Pincode", value: "details.address.pincode" },
          { label: "State", value: "details.address.state" },
        ]}
        formButton={(row) => [
          {
            label: "Edit",
            handleClick: (row) => {
              handleEditClick(row);
            },
          },
          {
            label: "More",

            handleClick: (row) => {
              handleMoreClick(row);
            },
          },
        ]}
      />

      {/* <TableContainer
        sx={appStyle.general.table.tableContainer}
        // component={Paper}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              ...appStyle.general.table.tableHead,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                IDs
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Name
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Address
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Pincode
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                State
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Details
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {(rowsPerPage > 0
              ? manufacturersList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : manufacturersList
            ).map((manufacturer) => (
              <TableRow
                key={manufacturer.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer.id}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer.details.name}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {`${manufacturer?.details?.address?.door_number}, ${manufacturer?.details?.address?.building_name}, ${manufacturer?.details?.address?.area_name}, 
            ${manufacturer?.details?.address?.district}`}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer?.details?.address?.pincode}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer?.details?.address?.state}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleMoreClick(manufacturer)}
                    sx={appStyle.general.button.editButton}
                    disabled
                  >
                    More
                  </Button>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleEditClick(manufacturer)}
                    sx={appStyle.general.button.editButton}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={appStyle.general.table.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={manufacturersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box> */}
    </Box>
  );
};
