/*
<CustomCard
          titleKey="Batch Id"
          title="WFSB00010"
          subTitleKey={"Product Name"}
          subTitle={"5 Litres - GLASS"}
          headerCustomFunctions={[
            {
              description: "round white board",
              func: (row) => {
                return (
                  <Box>
                    Hi
                  </Box>
                );
              },
            },
          ]}
          input={[
            {
              data: [
                { label: "Products In batch", value: 0 },
                {
                  label: "Status",
                  value: () => {
                    return "Hi";
                  },
                },
              ],
            },
            {
              data: [
                { label: "Linked Product Qty ", value: "0" },
                { label: "Created at", value: "29-11-2024" },
              ],
            },
          ]}
          buttons={[
            {
              label: "name",
              color: "blue",
              handleClick: (row) => {
                console.log("Clicked");
              },
            },
            {
              label: "more ",
              color: "blue",
              handleClick: (row) => {
                console.log("Clicked");
              },
            },
          ]}
        />
*/

import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import appFonts from "../../AppMeta/appFonts";
import CustomButton from "../CustomComp/CustomButton";

function CustomCard({
  bgColor = "#074276",
  color = "#074276",
  title,
  titleSize = "1rem",
  headerCustomFunctions,
  titleKey,
  subTitle,
  subTitleKey,
  input,
  buttons,
  row,
}) {
  console.log("---input---", buttons);
  return (
    <Box sx={{ width: "350px", padding: 0, margin: 0 }}>
      <Card
        sx={{
          borderRadius: "1rem",
          height: "180px",
          "&:hover": {
            transform: "scale(1.04)", // Enlarge the card on hover
            // boxShadow: theme.shadows[10], // Change shadow on hover
            boxShadow: "5px 3px #074276",
          },
        }}
      >
        <CardContent sx={{ padding: 0, margin: 0 }}>
          <Box sx={{ background: bgColor, height: "4rem" }}>
            <Box sx={{ background: "white", position: "relative" }}>
              {headerCustomFunctions?.map((foo) => foo.func(row))}
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  position: "absolute",
                  left: "1rem",
                  top: "1rem",
                }}
              >
                {titleKey !== undefined && (
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: titleSize,
                    }}
                  >{`${titleKey}  :`}</Typography>
                )}
                <Typography
                  sx={{
                    color: "white",

                    fontWeight: 600,
                    fontSize: titleSize,
                  }}
                >
                  {title}
                </Typography>
              </Box>
              {subTitle !== undefined && (
                <Box
                  sx={{
                    color: "white",
                    left: "1rem",
                    top: "2.5rem",

                    position: "absolute",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  {subTitleKey !== undefined && (
                    <Typography
                      sx={{ fontWeight: 600, fontSize: "13px" }}
                    >{`${subTitleKey}  :`}</Typography>
                  )}
                  <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>
                    {subTitle}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ padding: "0.5rem" }}>
            {input?.map((ele) => {
              return (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {ele.data.map((e) => {
                    return (
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography sx={{ fontWeight: 800, fontSize: "16px" }}>
                          {e.label}
                        </Typography>
                        :{" "}
                        {typeof e.value === "function" ? (
                          e.value(row)
                        ) : (
                          <Typography sx={{ fontSize: "17px" }}>
                            {e.value}
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "flex-end",
          }}
        >
          {buttons?.map((btn) => {
            return btn?.func !== undefined
              ? btn.func(row)
              : btn !== undefined && (
                  <CustomButton
                    startIcon={btn.startIcon}
                    label={btn.label}
                    color={btn.color ? btn.color : "blue"}
                    handleClick={() => {
                      console.log("--Clicked--", btn);
                      btn.handleClick(row);
                    }}
                  />
                );
          })}
        </CardActions>
      </Card>
    </Box>
  );
}

export default CustomCard;
