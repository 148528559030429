import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import {
  CreateRecycleOrder,
  GetDamagedExpired,
  GetRecycleOrderTable,
  UpdateRecycleDamaged,
} from "../../Redux/Actions/recycleActions";
import RecyclingIcon from "@mui/icons-material/Recycling";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";
import appFonts from "../../AppMeta/appFonts";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import RecycleScanningDialog from "./RecycleScanningDialog";
import RecycleSingleOrderDialog from "./RecycleSingleOrderDialog";
import appConstants from "../../AppMeta/appConstants";

function RecycleOrders() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();
  const damagedExpiredData = useSelector((state) => state.recycleData);
  const recycleData = useSelector((state) => state.recycleData);
  console.log("recycleData", recycleData);
  //useState Hooks
  const [products, setProducts] = React.useState([]);
  const [openRecyclescanningDialog, setOpenRecyclescanningDialog] =
    React.useState(false);
  const [singleOrderData, setSingleOrderData] = React.useState({});
  const [productId, setProductId] = React.useState("");
  const [damagedQuantity, setDamagedQuantity] = React.useState(0);
  const [expiredQuantity, setExpiredQuantity] = React.useState(0);
  const [recycleDamagedTable, setRecycleDamagedTable] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openRecycleSingleOrderDialog, setOpenRecycleSingleOrderDialog] =
    React.useState(false);
  const [isOpenPlaceRecycleOrder, setIsOpenRecycleOrder] =
    React.useState(false);
  const [isApprove, setIsApprove] = React.useState(false);

  React.useEffect(() => {
    console.log("GetProducts");
    console.log(products);
  }, [products]);

  React.useEffect(() => {
    const selectedProduct = products?.filter(
      (item) => item.product_details?.id === productId
    )?.[0];
    if (selectedProduct) {
      dispatch(
        GetDamagedExpired(
          selectedProduct.product_details.capacity,
          selectedProduct.product_details.material
        )
      );
    }
  }, [productId]);

  React.useEffect(() => {
    console.log("Damaged and Expired");
    console.log(damagedExpiredData);
    setExpiredQuantity(damagedExpiredData?.providedAccessData?.expiredTotal);
    setDamagedQuantity(
      damagedExpiredData?.providedAccessData?.physicallyDamagedTotal
    );
    setRecycleDamagedTable(damagedExpiredData?.providedAccessDataTable);
  }, [damagedExpiredData]);

  const handleClickRecycleOrder = () => {
    const selectedProduct = products?.filter(
      (item) => item.product_details?.id === productId
    )?.[0];
    if (selectedProduct) {
      const reqObj = {
        container_material: selectedProduct.product_details.material,
        container_capacity: selectedProduct.product_details.capacity,
        physically_damaged_quantity: damagedQuantity,
        expired_quantity: expiredQuantity,
      };
      console.log("ReqObj");
      console.log(reqObj);
      dispatch(CreateRecycleOrder(reqObj));
      setIsOpenRecycleOrder(false);
    }
  };

  const handleClickPlaceARecycleOrder = () => {
    setIsOpenRecycleOrder(!isOpenPlaceRecycleOrder);
  };

  const handleClickApprove = (order_id) => {
    const reqObj = {
      order_id: order_id,
      is_accepted: true,
    };
    console.log("reqObj");
    console.log(reqObj);
    setIsApprove(true);
    //dispatch();
  };
  React.useEffect(() => {
    dispatch(GetRecycleOrderTable());
  }, []);

  React.useEffect(() => {
    console.log("Get Table");
    console.log(recycleDamagedTable);
  });

  const handleRowClick = (data) => {
    console.log("-----handleRowClick--------", data);
    setSingleOrderData(data);
    setOpenRecycleSingleOrderDialog(true);
  };
  const handleCloseRecycleScanningDialog = () => {
    setOpenRecycleSingleOrderDialog(false);
  };

  const reccycleOrderdata = [
    {
      order_id: "BWRCP-77",
      product: "300 ML-GLASS",
      Physical_damaged_Qty: 2,
      Qr_Barcode_damaged_Qty: 1,
      Expired_Qty: "Expired Qty",
      Status: "PENDING",
      WFS_NAME: "BW",
      Wfs_Incharge_Name: "Vinay",
      Wfs_Incharge_Phone: 1234567,
      created_at: "11/22/2024",
      updated_by: "11/22/2024",
    },
    {
      order_id: "BWRCP-78",
      product: "300 ML-GLASS",
      Physical_damaged_Qty: 2,
      Qr_Barcode_damaged_Qty: 0,
      Expired_Qty: "Expired Qty",
      Status: "PENDING",
      WFS_NAME: "BW",
      Wfs_Incharge_Name: "Vinay",
      Wfs_Incharge_Phone: 1234567,
      created_at: "11/22/2024",
      updated_by: "11/22/2024",
    },
  ];

  const handleAcceptOrReject = (typeofbutton) => {
    console.log(typeofbutton);
    if (typeofbutton.data === "ACCEPT") {
      const reqObj = {
        order_id: typeofbutton.order_id,
        is_accepted: true,
      };
      dispatch(UpdateRecycleDamaged(reqObj)).then((data) => {
        console.log(data);
        if (data) {
          // dispatch(
          //   GetRequestedRecycleOrderList({
          //     order_id: typeofbutton.order_id,
          //   })
          // );
          dispatch(GetRecycleOrderTable());
        }
      });
      setOpenRecycleSingleOrderDialog(false);
    } else {
      const reqObj = {
        order_id: typeofbutton.order_id,
        is_accepted: false,
      };
      dispatch(UpdateRecycleDamaged(reqObj)).then((data) => {
        if (data) {
          dispatch(GetRecycleOrderTable());
        }
      });
      setOpenRecycleSingleOrderDialog(false);
    }
  };
  const handleCloseRecycleSingleDialog = () => {
    dispatch(GetRecycleOrderTable());
    setOpenRecycleSingleOrderDialog(false);
  };

  const orderStatus = (order_status) => {
    switch (parseInt(order_status)) {
      case appConstants.rcy_order_status.rcy_ORDER_CONFIRMED:
        return "REQUESTED";
      case appConstants.rcy_order_status.rcy_ORDER_CANCELLED:
        return "REJECTED";
      case appConstants.rcy_order_status.rcy_ORDER_COMPLETED:
        return "COMPLETED";
      default:
        return "";
    }
  };

  return (
    <Box m="10px">
      <Box display="flex" flexDirection="column">
        {/* <Header /> */}
        <CustomTable
          tableHead={[
            "Order Id",
            "Capacity",
            "Material",
            "Physical damaged Qty",
            "Qr/Barcode damaged Qty",
            "Expired Qty",
            "Status",
            "Wfs  Name",
            "Wfs Incharge Name",
            "Wfs Incharge Phine",
            "Created at",
            "Updated by",
          ]}
          tableData={recycleData.providedAccessDataTable}
          tableDataKey={[
            "order_id",
            "container_capacity",
            "container_material",
            "physically_damaged_quantity",
            "qr_barcode_damaged_qty",
            "expired_quantity",
            {
              component: function (props) {
                return (
                  <Typography sx={{ fontSize: "12px" }}>
                    {orderStatus(props.row.order_status)}
                  </Typography>
                );
              },
            },
            "WFS_NAME",
            "Wfs_Incharge_Name",
            "Wfs_Incharge_Phone",
            "created_at",
            "updated_by",
            // {
            //   rowClickEnable: true,
            //   component: (props) => {
            //     console.log("propss", props);
            //     return moment(props?.row?.created_at).format("DD-MM-YYYY");
            //   },
            // },
          ]}
          filter={true}
          filterSelectOption={[
            { value: "order_id", label: "order_id" },
            { value: "status", label: "status" },
          ]}
          // handleFilterClose={handleWfsFilterClose}
          sort={true}
          sortSelectOption={[
            { value: "created_at_sort", label: "Created at" },
            { value: "wfs_name_sort", label: "WFS name" },
            { value: "facility_name_sort", label: "Facility name" },
          ]}
          // handleSortClose={handleWfsSortClose}
          // addButton={true}
          // handleOnClickAddButton={handleOpenDrawer}
          tableRowClickFunction={(index, props) => handleRowClick(props)}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
      </Box>

      <RecycleSingleOrderDialog
        openRecycleSingleOrderDialog={openRecycleSingleOrderDialog}
        handleCloseRecycleScanningDialog={handleCloseRecycleScanningDialog}
        recycleSingleOrderDetails={singleOrderData}
        handleAcceptOrReject={handleAcceptOrReject}
        handleCloseRecycleSingleDialog={handleCloseRecycleSingleDialog}
      />
      {/* {openRecyclescanningDialog && (
        <RecycleScanningDialog
          singleOrderData={singleOrderData}
          setOpenRecyclescanningDialog={setOpenRecyclescanningDialog}
          // qrType={props.recycleSingleOrderDetails.type}
          qrType={true}
          openRecyclescanningDialog={openRecyclescanningDialog}
          handleCloseRecycleScanningDialog={handleCloseRecycleScanningDialog}
        />
      )} */}

      {isOpenPlaceRecycleOrder && (
        <PlaceARecycleOrder
          open={isOpenPlaceRecycleOrder}
          close={handleClickPlaceARecycleOrder}
          productId={productId}
          setProductId={setProductId}
          products={products}
          handleClickRecycleOrder={handleClickRecycleOrder}
          setExpiredQuantity={setExpiredQuantity}
          setDamagedQuantity={setDamagedQuantity}
          damagedQuantity={damagedQuantity}
          expiredQuantity={expiredQuantity}
        />
      )}
    </Box>
  );
}

export default RecycleOrders;

function PlaceARecycleOrder({
  open,
  productId,
  setProductId,
  products,
  damagedQuantity,
  expiredQuantity,
  handleClickRecycleOrder,
  setExpiredQuantity,
  setDamagedQuantity,
  close,
}) {
  const handleChangeDamaged = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue === "" ||
      (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 9)
    ) {
      setDamagedQuantity(inputValue);
    }
  };
  const handleChangeExpired = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue === "" ||
      (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 9)
    ) {
      setExpiredQuantity(inputValue);
    }
  };
  return (
    <Dialog open={open} onClose={close}>
      <Box p="10px" display="flex" boxShadow={3} justifyContent="space-between">
        <Typography fontWeight="bold" m="10px" sx={{ fontSize: "15px" }}>
          Place a Recycle Order
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="close">
          <CloseIcon onClick={close} />
        </IconButton>
      </Box>
      <Box p="10px">
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "royalblue",
          }}
        >
          Select Your Product Here!!!!
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box p="20px">
            <FormControl fullWidth sx={{ m: 1, maxWidth: 200, margin: "auto" }}>
              <InputLabel id="demo-simple-select-label">
                Select Products
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={productId}
                label="Select Products"
                onChange={(e) => {
                  setProductId(e.target.value);
                }}
              >
                {products?.map((row) => {
                  return (
                    <MenuItem
                      key={row.product_details.id}
                      value={row.product_details.id}
                    >
                      {row.product_details.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <TextField
            id="outlined-basic"
            label="Physically Damaged Quantity"
            variant="outlined"
            value={damagedQuantity}
            error={
              parseInt(damagedQuantity) < 0 || parseInt(damagedQuantity) > 9
            }
            helperText={
              parseInt(damagedQuantity) < 0 || parseInt(damagedQuantity) > 9
                ? "Value should be between 0 and 9"
                : ""
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChangeDamaged}
          ></TextField>

          <TextField
            sx={{ marginTop: "10px" }}
            id="outlined-basic"
            label="Expired Quantity"
            variant="outlined"
            value={expiredQuantity}
            error={
              parseInt(expiredQuantity) < 0 || parseInt(expiredQuantity) > 9
            }
            helperText={
              parseInt(expiredQuantity) < 0 || parseInt(expiredQuantity) > 9
                ? "Value should be between 0 and 9"
                : ""
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChangeExpired}
          />
        </Box>
      </Box>

      <Box display="flex" p="15px" justifyContent="center">
        <Button
          onClick={handleClickRecycleOrder}
          variant="contained"
          color="secondary"
          sx={appStyle.general.button.blueButton}
        >
          Create Recycle Order
        </Button>
      </Box>
    </Dialog>
  );
}
