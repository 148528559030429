import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomCheckBox from "../../../Components/CustomComp/CustomCheckBox";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CustomSelect from "../../../Components/CustomComp/CustomSelect";

function RewardsConfiguration({ masterConfigObject, setMasterConfigObject }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <CustomSelect
          name="rewards"
          placeHolder="Enable/Disable Rewards"
          defaultValue={masterConfigObject.rewards}
          input={[
            {
              menuItem: "Enable",
              value: true,
            },
            {
              menuItem: "Disable",
              value: false,
            },
          ]}
          // defaultValue={appData.httpMethodApiAddSelectOption
          getNameAndValueOnChange={(name, value) => {
            setMasterConfigObject({
              ...masterConfigObject,
              [name]: value,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export default RewardsConfiguration;
