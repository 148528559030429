import {
  Box,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Topbar from "./Topbar";
import SideNavigation from "./SideNavigation";
import { Switch } from "react-router-dom/cjs/react-router-dom";
import { FacilityOrderManagement } from "../Orders/OrganizationBooking/FacilityOrderManagement/FacilityOrderManagement";
import Sample from "../Sample";
import { OrganisationOrderManagement } from "../Orders/OrganizationBooking/OrganisationOrderManagement/OrganisationOrderManagement";
import Sku from "../StockKeepingUnit/Sku";
import Home from "../Home/Home";
import CodesManagement from "../CodesManagement/CodesManagement";
import StockManagement from "../StockManagement/StockManagement";
import CustomPriceForm from "../Settings/Settings";
import PurchaseProduct from "../Orders/PurchaseProduct/PurchaseProduct";
import AccessManagementTabs1 from "../AccessManagement/AccessManagementTabs1";
import AuthenticationPage from "../Authentication/Authentication";
import FillingStation from "../FillingStation/FillingStation";
import BookingToManufacturer from "../Orders/BookwaterBooking/BookingToManufacturer";
import RecycleOrder from "../RecycleOrders/RecycleOrders";
import MobileNew from "../Mobile/MobileNew";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Menu from "../Mobile/Menu";
import CheckOutMain from "../Orders/OrganizationBooking/OrganisationOrderManagement/CheckOutSku/CheckOutMain";
import OrganizationOrderList from "../OrganizationOrders/OrganizationOrderList";
import ApiDocumentsMain from "../ApiDocuments/ApiDocumentsMain";
import CustomLoaderDialog from "./CustomSpinner";
import { useSelector } from "react-redux";
import profileMain from "../Profile/ProfileConfig";
import ProfileConfig from "../Profile/ProfileConfig";
import DistributorStockMain from "../Distributor/DistributorStockMain";
import DistributorBookNowMain from "../Distributor/DistributorBookNowMain";
import FillingStationOrdersMain from "../FillingStation/FillingStationOrders/FillingStationOrdersMain";
import DistributorOrdersMain from "../Distributor/DistributorOrdersMain";
import MasterConfigurationForm from "../AccessManagement/AccessConfiguration/MasterConfigurationForm";
import AdminOrdersMain from "../AdminOrders/AdminOrdersMain";
import OnboardUsersMain from "../OnboardUsers/OnboardUsersMain";
import SingleOrderDetails from "../Distributor/SingleOrderDetails";

function Dashboard() {
  const [openProgressBar, setOpenProgressBar] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const spinnerState = useSelector((state) => state.spinnerReducer);

  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const history = useHistory();
  React.useEffect(() => {
    // customConsole(spinnerState);
    // customConsole(process.env.REACT_APP_ENV === "DEV");
    console.log("spinnerState", spinnerState.isLoading);
    if (spinnerState.isLoading) {
      setOpenProgressBar(true);
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 500);
    }
  }, [spinnerState]);

  return (
    <Box
      sx={{
        // background: "red",
        // width: "100vW",
        // height: "100vH",
        // maxWidth: "100vw",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Topbar isVisible={isVisible} setIsVisible={setIsVisible} />

      <div className="app">
        <SideNavigation isVisible={isVisible} setIsVisible={setIsVisible} />

        <Box
          sx={{
            width: "100%",
            // height: "100%",
            maxWidth: "100%",
            // maxHeight: "100%",
            overflow: "auto",
            paddingBottom: "40px",

            // width: "100vw",
            // maxWidth: "100vW",
            // marginY: "10px",
          }}
          className="container"
        >
          <Box
            className="content"
            sx={{
              maxHeight: "90vh",
              overflowY: "scroll",
            }}
          >
            <Switch>
              <Route
                path="/authentication"
                exact
                component={AuthenticationPage}
              />
              <Route path="/dashboard" exact component={Home} />
              <Route path="/dashboard/home" exact component={Home} />
              {/* <Route path="/dashboard/products" exact component={Products} /> */}
              <Route path="/dashboard/products" exact component={Sku} />
              {/* <Route path="/dashboard/products" exact component={PurchaseProduct} /> */}
              <Route
                path="/dashboard/user-management"
                exact
                component={AccessManagementTabs1}
              />
              <Route
                path="/dashboard/water-station"
                exact
                component={FillingStation}
              />
              <Route
                path="/dashboard/AdminAllOrders"
                exact
                component={AdminOrdersMain}
              />
              <Route
                path="/dashboard/bookwater-order-management"
                exact
                component={BookingToManufacturer}
              />
              <Route
                path="/dashboard/facility-order-management"
                exact
                component={FacilityOrderManagement}
              />
              <Route
                path="/dashboard/organisation-order-management"
                exact
                component={OrganisationOrderManagement}
              />
              <Route
                path="/dashboard/organization_orders_List"
                exact
                component={OrganizationOrderList}
              />
              <Route
                path="/dashboard/checkout"
                exact
                component={CheckOutMain}
              />
              <Route
                path="/dashboard/distributor"
                exact
                component={DistributorStockMain}
              />
              <Route
                path="/dashboard/codesManagement"
                exact
                component={CodesManagement}
              />
              <Route
                path="/dashboard/stock-management"
                exact
                component={StockManagement}
              />
              <Route
                path="/dashboard/settings"
                exact
                component={CustomPriceForm}
              />
              <Route path="/dashboard/sample" exact component={Sample} />
              <Route
                path="/dashboard/recycleOrders"
                exact
                component={RecycleOrder}
              />
              <Route
                path="/dashboard/api_documentation"
                exact
                component={ApiDocumentsMain}
              />
              <Route
                path="/dashboard/profile"
                exact
                component={ProfileConfig}
              />
              <Route
                path="/dashboard/Distributor_BookNow"
                exact
                component={DistributorBookNowMain}
              />
              <Route
                path="/dashboard/filling_station_orders"
                exact
                component={DistributorOrdersMain}
              />
              <Route
                path="/dashboard/single_order"
                exact
                component={SingleOrderDetails}
              />
              <Route
                path="/dashboard/consumer_orders"
                exact
                component={DistributorOrdersMain}
              />
              <Route
                path="/dashboard/configuration"
                exact
                component={MasterConfigurationForm}
              />
              <Route
                path="/dashboard/onboard_users"
                exact
                component={OnboardUsersMain}
              />
              <Route
                path="/dashboard/distributor_placed_orders"
                exact
                component={FillingStationOrdersMain}
              />
            </Switch>
          </Box>
        </Box>
        {openProgressBar && (
          <CustomLoaderDialog
            open={openProgressBar}
            handleClose={() => setOpenProgressBar(false)}
          />
        )}
      </div>
    </Box>
  );
}

export default Dashboard;
