import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import HomeDataCard from "./HomeDataCard";

import {
  faBottleWater,
  faRecycle,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import HomeTwoLayerCards from "./HomeTwoLayerCards";
import CustomDatePicker from "./CustomDatePicker";
import LineChartForIot from "./LineChart";
import GaugeChart from "./GaugeChart";
import CustomAutoComplete from "../../Components/CustomComp/CustomAutoComplete";
import appMeta from "../../AppMeta/appMeta";

function RecycleHomePage() {
  //hooks

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <Box sx={{ display: "flex", mt: "15px", gap: "10px" }}>
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              // searchAndUpdateAPI: (searchText) => {
              //   setFacilitySearch(searchText);
              // },
              // input: rolesList,
              name: "role_1",
              label: "Enter Facility",
            }}
            // handleInputChange={((name, selectedOption), {})}
          />
          <CustomAutoComplete
            field={{
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "role_name",
              optionKey2: "department",
              outputKey: "role_name",
              // searchAndUpdateAPI: (searchText) => {
              //   setWfsSearch(searchText);
              // },
              // input: rolesList,
              name: "role_1",
              label: "Enter Wfs",
            }}
            // handleInputChange={((name, selectedOption), {})}
          />
        </Box>
        <CustomDatePicker
          handleChange={(date) => {
            console.log("---date---", date);
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <HomeDataCard
            bgColor={"#d8fbe7"}
            icon={faRecycle}
            value={"10 Tones"}
            subTitle={"Plastic saved"}
          />
          <HomeDataCard
            bgColor={"#d8fbe7"}
            icon={faBottleWater}
            value={"2000"}
            subTitle={"Cans recycled"}
          />
          <HomeTwoLayerCards
            value={20}
            subTitle={"Destroyed"}
            icon={faStopwatch}
          />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component={Paper} elevation={15}>
            <LineChartForIot />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
}

export default RecycleHomePage;
