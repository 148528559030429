import actionTypes from "../actionTypes";

export const QRApis = {
  scan_batch_qr: {
    api_name: "/PSCM/facility/filling",
    https_method: "PUT",
    action_type: actionTypes.SCAN_BATCH_QR,
    description: "",
  },
  unauth_scan_batch_qr: {
    api_name: "/PSCM/facility/quality_report",
    https_method: "GET",
    action_type: actionTypes.UNAUTH_SCAN_BATCH_QR,
    description: "",
  },
};
