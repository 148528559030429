import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

function HomeDataCard({ bgColor, icon, value, subTitle }) {
  return (
    <Box
      sx={{
        background: bgColor,
        height: "125px",
        width: "250px",
        borderRadius: "2rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          color: "#0a4299",
          marginTop: "1rem",
          marginLeft: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "20px" }}>
          <FontAwesomeIcon icon={icon} />
        </Typography>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
            {value}
          </Typography>
          <Typography>{subTitle}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeDataCard;
