import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

function CustomDialog({ children, open, handleClose, handleSubmit, title }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box p="10px" display="flex" boxShadow={3} justifyContent="center">
        <Typography
          fontWeight="bold"
          m="10px"
          sx={{ fontSize: "15px", fontWeight: 800 }}
        >
          {title}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
        ></IconButton>
      </Box>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
