import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import appStyle from "../../AppMeta/appStyle";
import OrganizationDetails from "../AccessManagement/Organization/OrganizationDetails";
import WFSindex from "../FillingStation/WaterFillingStation/Wfsindex";
import IotDevicesMain from "../FillingStation/IotDevicesMain";
import DistributorCompanyOnboarding from "../Distributor/DistributorCompanyOnboarding";
import PincodesAndAres from "../StockKeepingUnit/PincodesAndAreas";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import { GetPincodeAreas } from "../../Redux/Actions/pincodeAreasAction";

function OnboardUsersMain() {
  const [onboardTabvalue, setOnboardTabvalue] = React.useState("FACILITY");

  const handleChangeOnboardTab = (event, newValue) => {
    setOnboardTabvalue(newValue);
  };
  return (
    <Box>
      <Tabs
        variant="scrollable"
        allowScrollButtonsMobile
        value={onboardTabvalue}
        onChange={handleChangeOnboardTab}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          sx={appStyle.general.tab.firstLevel}
          value="FACILITY"
          label="FACILITY"
        />
        <Tab sx={appStyle.general.tab.firstLevel} value="WFS" label="WFS" />
        <Tab sx={appStyle.general.tab.firstLevel} value="IOT" label="IOT" />
        <Tab
          sx={appStyle.general.tab.firstLevel}
          value="PINCODES"
          label="PINCODES"
        />
        <Tab
          sx={appStyle.general.tab.firstLevel}
          value="DISTRIBUTOR"
          label="DISTRIBUTOR"
        />
      </Tabs>
      {onboardTabvalue === "FACILITY" && (
        <Box>
          {" "}
          <OrganizationDetails
          // drawOpenProp={drawOpenProp}
          // setDrawOpenProp={setDrawOpenProp}
          />
        </Box>
      )}
      {onboardTabvalue === "WFS" && (
        <Box>
          {" "}
          <WFSindex />
        </Box>
      )}
      {onboardTabvalue === "IOT" && (
        <Box>
          {" "}
          <IotDevicesMain />
        </Box>
      )}
      {onboardTabvalue === "PINCODES" && (
        <Box>
          {" "}
          <PincodesAndAres />
        </Box>
      )}
      {onboardTabvalue === "DISTRIBUTOR" && <DistributorCompanyOnboarding />}
    </Box>
  );
}

export default OnboardUsersMain;
