import React from "react";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import {
  TextField,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  GetIotDevicesFacility,
  GetLinesFacility,
  UpdateLinesFacility,
} from "../../../Redux/Actions/addFacilityWFSFillingAction";
import { useDispatch, useSelector } from "react-redux";
import useScreenSize from "../../../Hooks/CustomHooks/useScreenSize";
import { GetIotDevices } from "../../../Redux/Actions/iotDeviceAction";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../../Components/Utils/CustomFormUtils";
import appMeta from "../../../AppMeta/appMeta";
import { GetIotDevicesList } from "../../../Redux/Actions/adddCreateBatchAction";
import userManagementApis from "../../../Redux/Actions/userManagement";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";

export function LinkIOTtoFacilityFillingLines() {
  const profileRole = useSelector((state) => state.profileData).profile.role_id;
  const addFillingLinesFacilities = useSelector(
    (state) => state.addFillingLinesFacilities
  );
  const organisationDetails = useSelector((state) => state.organizationData);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  // const wfsList = useSelector((state) => state.wfsDetails.wfsList);
  const WfsData = useSelector((state) => state.batchData);

  const [allFacility, setAllFacility] = React.useState(
    organisationDetails.organizationList
  );
  const [searchFacility, setSearchFacility] = React.useState("");
  const [allWfs, setAllWfs] = React.useState("");
  const [searchWfs, setSearchWfs] = React.useState("");
  const [selectedWfs, setSelectedWfs] = React.useState("");
  const [allLine, setAllLine] = React.useState("");
  const [searchLine, setSearchLine] = React.useState("");

  const [selectFacility, setSelectFacility] = React.useState("");
  // const [lineId, setLineId] = React.useState("");
  const [flagPrint, setFlagPrint] = React.useState(false);
  const [deviceNumber, setDeviceNumber] = React.useState("");

  const [lineIds, setLineIds] = React.useState([]);
  const [iotDevices, setIotDevices] = React.useState([]);
  const dispatch = useDispatch();

  // console.log("iotDevices", iotDevices);
  React.useEffect(() => {
    dispatch(
      GetIotDevicesList({
        page_number: 1,
        page_size: 500,
        ignore_app_loading_spinner: true,
      })
    );
  }, []);

  React.useEffect(() => {
    // console.log("addFillingLinesFacilities");
    // console.log(addFillingLinesFacilities);
    if (addFillingLinesFacilities.error === false) {
      console.log("---addFillingLinesFacilities---", addFillingLinesFacilities);
      setLineIds(addFillingLinesFacilities.lines.lineIds);
      setIotDevices(addFillingLinesFacilities.iotDevices);
      console.log("iotDevices", iotDevices);
    }
  }, [addFillingLinesFacilities]);

  // React.useEffect(() => {
  //   console.log("selectFacility");
  //   console.log(selectFacility);
  //   dispatch(GetIotDevicesFacility(selectFacility));
  //   if (selectFacility !== "") {
  //     dispatch(GetLinesFacility(selectFacility));
  //   }
  // }, [selectFacility]);

  const handleClickLink = (data) => {
    const reqObj = {
      facility_id: parseInt(data.facility),
      line_id: data.lineId,
      iot_device_number: data.device_id,
    };
    console.log("reqObj");
    console.log(reqObj);
    dispatch(UpdateLinesFacility(reqObj));
  };

  const searchAndUpdateFacility = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          call: 1,
          ...(searchFacility !== "" && { org_name: searchFacility }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateWfs = () => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          call: 1,
          ...(selectFacility !== "" && { facility_id: selectFacility }),
          ...(searchWfs !== "" && { wfs_name: searchWfs }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  const searchAndUpdateLines = () => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_lines,
        params: {
          call: 1,
          wfs_id: selectedWfs,
          // ...(searchLine !== "" && { org_name: searchLine }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    searchFacility !== "" && searchAndUpdateFacility();
  }, [searchFacility]);

  React.useEffect(() => {
    console.log("---wfsList---", wfsDetails.wfsLinesList.lineIds);
    if (profileRole !== "1000" || selectFacility !== "" || searchWfs !== "") {
      setAllWfs(wfsDetails.wfsList.data);
    } else if (profileRole === "1000") {
      setAllWfs("");
    }

    if (profileRole !== "1000" || selectedWfs !== "" || searchLine !== "") {
      setLineIds(wfsDetails.wfsLinesList.lineIds);
    } else if (profileRole === "1000") {
      setLineIds(wfsDetails.wfsLinesList.lineIds);
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    console.log("first");
    selectedWfs !== "" && searchAndUpdateLines();
  }, [selectedWfs]);

  React.useEffect(() => {
    if (profileRole !== "1000" || selectFacility !== "") {
      searchAndUpdateWfs();
    }
  }, [searchWfs, selectFacility]);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Paper elevation={23}>
            <Box sx={{ padding: "10px", minWidth: 150 }}>
              <CustomForm
                handleSubmit={(formData) => {
                  handleClickLink(formData);
                }}
                hideCloseButton={true}
                data={[
                  {
                    data: [
                      {
                        ...(profileRole === "1000" && {
                          inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                          optionKey: "org_name",
                          outputKey: "id",
                          onChange: (name, value) => {
                            setSelectFacility(value);
                          },
                          searchAndUpdateAPI: (searchText) => {
                            setSearchFacility(searchText);
                          },
                          input: allFacility || [],
                          name: "facility",
                          label: "Enter Facility Name",
                        }),
                      },
                      {
                        inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                        optionKey: "wfs_name",
                        outputKey: "wfs_id",
                        onChange: (name, value) => {
                          console.log("---Value--", value);
                          setSelectedWfs(value);
                        },
                        searchAndUpdateAPI: (searchText) => {
                          setSearchWfs(searchText);
                        },
                        input: allWfs || [],
                        name: "wfs",
                        label: "Enter WFS Name",
                      },
                      {
                        inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                        optionKey: "key",
                        outputKey: "value",
                        // onChange: (name, value) => {
                        //   console.log("---Value--", value);
                        //   setSelectedLine(value);
                        // },
                        searchAndUpdateAPI: (searchText) => {
                          setSearchLine(searchText);
                        },
                        input:
                          lineIds?.map((ele) => {
                            return { key: ele, value: ele };
                          }) || [],
                        name: "lineId",
                        label: "Select Line ID",
                      },
                      {
                        inputType: appMeta.inputFieldTypes.SELECT,
                        placeHolder: "Select Iot Device Id",
                        name: "device_id",
                        input: WfsData?.IotData?.map((line) => {
                          console.log("----iotdeviceIds----", line);
                          return {
                            menuItem: line.device_id,
                            value: line.device_id,
                          };
                        }),
                      },
                    ],
                  },
                ]}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Facility
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=" Select Facility"
                  value={selectFacility}
                  onChange={handleFacilityChange}
                >
                  {allFacilities.map((facility) => {
                    return (
                      <MenuItem value={facility.id}>
                        {facility.org_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
            </Box>
            {/* <Box sx={{ padding: "10px", minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Line ID
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Line Id"
                  value={lineId}
                  onChange={(event) => {
                    setLineId(event.target.value);
                  }}
                >
                  {lineIds?.map((line) => {
                    return <MenuItem value={line}>{line}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ padding: "10px", minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select IOT Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Filling Station"
                  value={deviceNumber}
                  onChange={(event) => {
                    setDeviceNumber(event.target.value);
                  }}
                >
                  {iotDevices?.map((iot) => {
                    return <MenuItem value={iot}>{iot}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <CustomButton
                label="Link"
                variant="contained"
                handleClick={handleClickLink}
              />
            </Box> */}
          </Paper>
        </Box>
      </>
    </>
  );
}
